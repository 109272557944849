/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import api from '@/services/nominations-api';

const state = {
  nominations: null,
  nominationsActive: null,
  nominationsCompleted: null,
  teamNominationsActive: null,
  teamNominationsCompleted: null,
  nominationResults: null,
  teamNominationResults: null,
  teamMembersResults: null,
  ratings: null,
  responseStatus: null,
};

const mutations = {
  setNominations(state, value) {
    state.nominations = value;
  },
  setNominationsActive(state, value) {
    state.nominationsActive = value;
  },
  setNominationsCompleted(state, value) {
    state.nominationsCompleted = value;
  },
  setTeamNominationsActive(state, value) {
    state.teamNominationsActive = value;
  },
  setTeamNominationsCompleted(state, value) {
    state.teamNominationsCompleted = value;
  },
  setNominationResults(state, value) {
    state.nominationResults = value;
  },
  setTeamNominationResults(state, value) {
    state.teamNominationResults = value && value.teams ? value.teams : null;
  },
  setTeamMembersResults(state, value) {
    state.teamMembersResults = value && value.participantResults ? value.participantResults : null;
  },
  setRatings(state, value) {
    state.ratings = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
};

const getters = {
  nominations: (state) => state.nominations,
  nominationsActive: (state) => state.nominationsActive,
  nominationsCompleted: (state) => state.nominationsCompleted,
  teamNominationsActive: (state) => state.teamNominationsActive,
  teamNominationsCompleted: (state) => state.teamNominationsCompleted,
  nominationResults: (state) => state.nominationResults,
  teamNominationResults: (state) => state.teamNominationResults,
  teamMembersResults: (state) => state.teamMembersResults,
  ratings: (state) => state.ratings,
  responseStatus: (state) => state.responseStatus,
};

const actions = {
  getNominationsGuest({ commit }, { offset, count }) {
    return api.getNominationsGuest({ state: 'active', offset, count })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setNominations', response.data);
        } else {
          commit('setNominations', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getNominationsActive({ commit }, { offset, count }) {
    return api.getNominations({ state: 'active', offset, count })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setNominationsActive', response.data);
        } else {
          commit('setNominationsActive', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getNominationsCompleted({ commit }, { offset, count }) {
    return api.getNominations({ state: 'completed', offset, count })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setNominationsCompleted', response.data);
        } else {
          commit('setNominationsCompleted', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getTeamNominationsActive({ commit }, { offset, count }) {
    return api.getTeamNominations({ state: 'active', offset, count })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setTeamNominationsActive', response.data);
        } else {
          commit('setTeamNominationsActive', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getTeamNominationsCompleted({ commit }, { offset, count }) {
    return api.getTeamNominations({ state: 'completed', offset, count })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setTeamNominationsCompleted', response.data);
        } else {
          commit('setTeamNominationsCompleted', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getNominationResults({ commit }, {
    nominationId, teamId, offset, count,
  }) {
    return api.getNominationResults({
      nominationId, teamId, offset, count,
    })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setNominationResults', response.data);
        } else {
          commit('setNominationResults', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getTeamNominationResults({ commit }, { nominationId, offset, count }) {
    return api.getTeamNominationResults({ nominationId, offset, count })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setTeamNominationResults', response.data);
        } else {
          commit('setTeamNominationResults', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getTeamMembersResults({ commit }, { teamId, offset, count }) {
    return api.getTeamMembersResults({ teamId, offset, count })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setTeamMembersResults', response.data);
        } else {
          commit('setTeamMembersResults', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getRatings({ commit }, { filter, aggregateField }) {
    return api.getRatings({ filter, aggregateField })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setRatings', response.data);
        } else {
          commit('setRatings', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

<template>
  <section v-if="themeDone">
    <ChooseTracker
      :shown="step === 'choose'"
      :settings="{ noTransition: active, closable: true }"
      :backToPath="backToPath"
      @close="close"
      @connection-guide="onConnectionGuide"
    ></ChooseTracker>
    <Finish
      :shown="step === 'finish' && !fromGuide"
      :provider="tracker ? tracker.provider : ''"
      :settings="{ noTransition: active, closable: true }"
      @close="closeFinish"
    ></Finish>
    <FinishFromGuide
      :shown="step === 'finish' && fromGuide"
      :provider="tracker ? tracker.provider : ''"
      :settings="{ noTransition: active, closable: true }"
      @close="closeFinish"
      @tasks="closeFinishTasks"
    ></FinishFromGuide>
    <component :is="isSpecialSettingsMp  ? 'ConnectionGuideScount' : 'ConnectionGuide'"
      :shown="showGuide"
      :settings="{ noTransition: true, closable: true }"
      :backToPath="backToPath"
      @close="onCloseConnectionGuide"
    ></component>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChooseTracker from './steps/ChooseTracker.vue';
import Finish from './steps/Finish.vue';
import FinishFromGuide from './steps/FinishFromGuide.vue';
import ConnectionGuideOld from '@/components/ConnectionGuide/ConnectionGuideOld.vue';
import ConnectionGuide from '@/components/ConnectionGuide/ConnectionGuide.vue';
import ConnectionGuideScount from '@/components/ConnectionGuide/ConnectionGuideScount.vue';

export default {
  name: 'Onboarding',
  components: {
    ChooseTracker,
    Finish,
    FinishFromGuide,
    ConnectionGuideOld,
    ConnectionGuide,
    ConnectionGuideScount,
  },
  data() {
    return {
      active: false,
      step: null,
      closable: false,
      showGuide: false,
      fromGuide: false,
      backToPath: null,
    };
  },
  computed: {
    ...mapGetters('settings', ['themeDone', 'options']),
    ...mapGetters('trackers', ['tracker', 'trackers', 'responseStatus']),
    showGuideSimpleRun() {
      return this.options ? this.options.showGuideSimpleRun === true : false;
    },
    isSpecialSettingsMp() {
      return this.options ? this.options.isSpecialSettingsMp === true : false;
    },
  },
  methods: {
    ...mapActions('trackers', ['getTracker', 'getTrackers']),
    close() {
      this.step = null;
      this.active = false;
    },
    closeFinish() {
      this.close();
      if (!this.backToPath) {
        window.location.href = '/dashboard';
      } else {
        window.location.href = this.backToPath;
      }
    },
    closeFinishTasks() {
      this.close();
      const taskId = localStorage.getItem('returnToTask') || '';
      if (taskId !== '') {
        localStorage.removeItem('returnToTask');
        window.location.href = `/tasks/available#!showTask=${taskId}`;
      } else if (!this.backToPath) {
        this.$router.push({ path: '/tasks/available' }).catch(() => {});
      } else {
        window.location.href = this.backToPath;
      }
    },
    processReturnFromGuide() {
      if (this.fromGuide) {
        localStorage.setItem('returnFromGuide', 'true');
      }
    },
    open({ step, closable, backToPath }) {
      this.step = step || 'choose';
      this.closable = !!closable;
      this.backToPath = backToPath || null;
      if (this.showGuideSimpleRun) {
        this.fromGuide = this.$route.query.from === 'guideSimpleRun';
      } else {
        this.fromGuide = this.$route.query.from === 'guideSuunto';
      }
      if (this.step !== 'finish') {
        this.getTrackers()
          .then(() => {
            if (this.responseStatus === 200 && this.trackers) {
              const connected = this.trackers.filter((i) => i.state === 'Connected');
              if (connected.length > 0) {
                this.close();
                return;
              }
            }
            setTimeout(() => {
              this.step = 'choose';
              this.active = true;
            }, 400);
          });
      }
      if (this.step === 'finish' && this.$route.query.connect) {
        this.processReturnFromGuide();
        this.getTracker(this.$route.query.connect)
          .then(() => {
            if (this.responseStatus === 200) {
              if (this.tracker && this.tracker.state === 'Connected') {
                setTimeout(() => {
                  this.active = true;
                }, 400);
              }
            } else {
              this.close();
            }
          });
      } else {
        this.close();
      }
    },
    onConnectionGuide() {
      this.close();
      this.showGuide = true;
    },
    onCloseConnectionGuide() {
      this.showGuide = false;
      window.location.hash = '';
      window.location.reload();
    },
  },
  mounted() {
    this.$root.showOnboarding = this.open;
  },
};
</script>

<template>
  <div class="welcome">
    <Modal
      :active="modalActive"
      name="WelcomeModal"
      id="welcomeModal"
      :title="localizedText.title"
      :subtitle="showWelcomeModalMarket ? localizedText.subtitleMarket : localizedText.subtitle"
      :img="showWelcomeModalMarket ? imageMarket : image"
      :class="{ 'for-mobile-app': isMobileApp }"
      :closable="false"
      @close.prevent="$emit('close')"
    >
      <div class="welcome__container">
        <div class="welcome__content text-left">
          <div class="welcome__action">
            <div>
              <Button
                v-if="standardWelcomeModal && !showWelcomeModalMarket"
                variant="primary"
                size="lg"
                mobile
                :wide="false"
                @click="localizedText.actionPrimary.action ? localizedText.actionPrimary.action() : null"
              >
                {{ localizedText.actionPrimary.text }}
              </Button>
              <Button
                v-else-if="showWelcomeModalMarket"
                variant="primary"
                size="lg"
                mobile
                :wide="false"
                @click="localizedText.actionMarket.action()"
              >
                Продолжить
              </Button>
            </div>
            <div v-if="standardWelcomeModal && !showWelcomeModalMarket" class="welcome__divider">
              <div class="divider__line"></div>
              <div class="divider__text">или</div>
              <div class="divider__line"></div>
            </div>
            <div>
              <Button
                v-if="!showWelcomeModalMarket || standardWelcomeModal"
                :variant="!showWelcomeModalMarket ? 'primary' : 'secondary'"
                :size="!standardWelcomeModal ? 'lg' : 'sm'"
                mobile
                :wide="false"
                @click="localizedText.actionSecondary.action ? localizedText.actionSecondary.action() : null"
              >
              {{ !standardWelcomeModal ? 'Продолжить' : localizedText.actionSecondary.text }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Image from '../../assets/img/welcome-modal/gold-b.png';
import ImageMarket from '../../assets/img/welcome-modal/gold-c.png';

export default {
  name: 'WelcomeModal',
  props: {
    active: Boolean,
    localizedText: Object,
    isMobileApp: Boolean,
  },
  data() {
    return {
      modalActive: false,
      image: Image,
      imageMarket: ImageMarket,
    };
  },
  computed: {
    ...mapGetters('settings', ['options']),
    standardWelcomeModal() {
      return this.options ? this.options.customWelcomeModal !== true : true;
    },
    showWelcomeModalMarket() {
      return this.options ? this.options.showWelcomeModalMarket === true : false;
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.createAnimation();
        setTimeout(() => { this.modalActive = true; }, 100);
      }
    },
  },
  methods: {
    createAnimation() {
      const modal = document.getElementById('welcomeModal');
      if (!modal) return;
      const imageContainer = modal.querySelector('.rr-modal__img');
      if (!imageContainer) return;
      const animContainer = imageContainer.querySelector('.animation');
      if (animContainer) return;
      const anim = document.createElement('div');
      anim.setAttribute('class', 'animation');
      const items = ['confetti', 'confetti two', 'confetti three', 'confetti four',
        'confetti--purple', 'confetti--purple two', 'confetti--purple three', 'confetti--purple four'];
      for (let i = 0; i < items.length; i += 1) {
        const item = document.createElement('div');
        item.setAttribute('class', items[i]);
        anim.appendChild(item);
      }
      imageContainer.appendChild(anim);
    },
  },
  mounted() {
    this.createAnimation();
  },
  beforeDestroy() {
    this.modalActive = false;
  },
};
</script>

<style lang="scss" scoped>
  @import './WelcomeModal';
</style>

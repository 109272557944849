import { render, staticRenderFns } from "./WelcomeModal.vue?vue&type=template&id=6b9efe87&scoped=true&"
import script from "./WelcomeModal.vue?vue&type=script&lang=js&"
export * from "./WelcomeModal.vue?vue&type=script&lang=js&"
import style0 from "./WelcomeModal.vue?vue&type=style&index=0&id=6b9efe87&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b9efe87",
  null
  
)

export default component.exports
<template>
  <div
    class="topic-card"
    :class="{
      'topic-card_group': isGroup,
      'topic-card_active': isActive,
    }"
    @click.stop="onCardClick"
    >
    <div class="topic-card__heading" @click.stop="onHeadingClick">
      <div class="topic-card__title" v-html="title"></div>
      <div v-if="isGroup !== true" class="topic-card__down">
        <Icon :name="isActive ? 'up' : 'down'" />
      </div>
    </div>
    <div v-if="isActive && !isGroup" class="topic-card__description" v-html="description"></div>
  </div>
</template>

<script>

export default {
  name: 'TopicCard',
  props: {
    isGroup: Boolean,
    isActive: Boolean,
    title: String,
    description: String,
  },
  data() {
    return {
    };
  },
  methods: {
    onCardClick() {
      if (this.isGroup === true) this.$emit('select');
    },
    onHeadingClick() {
      this.$emit('select');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./TopicCard";
</style>

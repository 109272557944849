<template>
  <div class="menu">
    <!-- Mobile items: begin -->
    <div class="menu__container" v-if="$root.isMobile">
      <div
        v-for="(row, i) in mobileItemsShown"
        :key="i"
        class="menu__row"
      >
        <div
          v-for="(item, k) in row"
          :key="k"
          class="menu__item menu__item_mobile"
          :class="{
            'menu__item_hidden': i === 1 && k === row.length - 1 && !showHiddenItems,
            'menu__item_disabled': isMenuItemDisabled(item)
          }"
        >
          <span class="item__icon" @click.stop="onAction(item)">
            <Icon :name="item.icon" size="xxl" />
          </span>
          <span class="item__text" v-html="item.text" @click.stop="onAction(item)"></span>
        </div>
        <div
          v-if="i === 1 && !showHiddenItems"
          class="menu__item menu__item_mobile"
        >
          <span class="item__icon item__icon_more" @click.stop="showHiddenItems = true">
            <Icon name="more-horizontal" size="l" />
          </span>
          <span class="item__text" v-html="textMore" @click.stop="showHiddenItems = true"></span>
        </div>
      </div>
    </div>
    <!-- Mobile items: end -->
    <!-- Items: begin -->
    <div class="menu__container" v-if="!$root.isMobile">
      <div
        v-for="(row, i) in itemsShown"
        :key="i"
        class="menu__row"
      >
        <div
          v-for="(item, k) in row"
          :key="k"
          class="menu__item"
          :class="{
            'menu__item_hidden': i === 0 && k > 5 && !showHiddenItems,
            'menu__item_active': isMenuItemActive(item),
            'menu__item_disabled': isMenuItemDisabled(item),
            'menu__item_divider': item.divider === true,
          }"
        >
          <span class="item__icon" :class="{'item__icon_active': isMenuItemActive(item)}" @click.stop="onAction(item)">
            <Icon :name="item.icon" :size="isMenuItemActive(item) ? 'l' : 'xxl'" />
          </span>
          <span class="item__text" v-html="item.text" @click.stop="onAction(item)"></span>
        </div>
        <div
          v-if="i === 0 && !showHiddenItems"
          class="menu__item"
          id="menuMoreButton"
        >
          <span v-if="!$root.isDesktop" class="item__icon item__icon_more" @click.stop="showHiddenItems = true">
            <Icon name="more-horizontal" size="l" />
          </span>
          <span v-if="$root.isDesktop" class="item__icon item__icon_more">
            <Icon name="more-horizontal" size="l" />
          </span>
          <span v-if="!$root.isDesktop" class="item__text" v-html="textMore" @click.stop="showHiddenItems = true"></span>
          <span v-if="$root.isDesktop" class="item__text" :class="{'item__text--active': isDropdownActive}" v-html="textMore"></span>
        </div>
        <Dropdown
          v-if="$root.isDesktop"
          triggerId="menuMoreButton"
          placement="bottom"
          hide-overlay
          auto-width
          @toggle="onToggle"
        >
          <template slot="items">
            <div
              class="base-dropdown__item"
              :class="{'menu__item_desktop': item.desktop === true}"
              v-for="(item, i) in itemsHidden"
              :key="i"
            >
              <div v-if="item.divider === true" class="divider"></div>
              <a
                v-else
                class="base-dropdown__link"
                @click.prevent="onAction(item)"
              >
                <span class="link__icon" :class="{'link__icon_active': isMenuItemActive(item)}">
                  <Icon :name="item.icon" :size="isMenuItemActive(item) ? 's' : 'xl'" />
                </span>
                <span v-html="item.text"></span>
              </a>
            </div>
          </template>
        </Dropdown>
      </div>
    </div>
    <!-- Items: end -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Dropdown from '@/components/Dropdown/Dropdown.vue';

export default {
  name: 'Menu',
  components: {
    Dropdown,
  },
  data() {
    return {
      isDropdownActive: false,
      showHiddenItems: false,
      textMore: 'Ещё',
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'options']),
    ...mapGetters('user', ['profile']),
    items() {
      return this.options ? this.options.menu || [] : [];
    },
    isClubEnabled() {
      return this.options ? this.options.isClubEnabled === true : false;
    },
    mobileItems() {
      if (this.$route.name === 'Club' && this.isClubEnabled) {
        return this.options ? this.options.menuMobile || [] : [];
      }
      if (this.$route.name === 'Dashboard' && !this.isClubEnabled) {
        return this.options ? this.options.menuMobile || [] : [];
      }
      return [];
    },
    mobileItemsShown() {
      return !this.showHiddenItems ? this.mobileItems.slice(0, 2) : this.mobileItems;
    },
    itemsShown() {
      return !this.showHiddenItems ? this.items.slice(0, 1) : this.items;
    },
    itemsHidden() {
      return this.items[1] || [];
    },
    isTeamSet() {
      return this.profile ? this.profile.team !== undefined && this.profile.team.teamId !== undefined : false;
    },
    disableTeamsForGuest() {
      return this.options ? this.options.disableTeamsForGuest === true : false;
    },
  },
  methods: {
    ...mapActions('user', ['logout']),
    onAction(item) {
      if (this.isMenuItemDisabled(item)) return;
      if (item.action && typeof item.action === 'string') {
        if (item.action === 'Logout' && this.logout) {
          this.logout();
          return;
        }
        if (item.action === 'Feedback') {
          this.onFeedback();
          return;
        }
        this.$router.push({ name: item.action }).catch(() => {});
      } else if (item.action && typeof item.action === 'function') {
        item.action();
      }
    },
    onToggle(active) {
      this.isDropdownActive = active;
    },
    onFeedback() {
      if (!window.Userback) return;
      setTimeout(() => {
        window.Userback.open();
        this.$emit('feedback');
      }, 250);
    },
    isMenuItemActive(item) {
      if (item && item.action) {
        const route = this.$route.name;
        if (typeof item.action !== 'string') return false;
        const routes = [
          ['Dashboard'],
          ['Tasks', 'TasksAvailable', 'TasksInProgress', 'TasksCompleted'],
          ['Nominations', 'NominationsInProgress', 'NominationsAvailable', 'NominationsCompleted'],
          ['Activities'],
          ['Settings', 'SettingsProfile', 'SettingsTrainings', 'SettingsTrackers', 'SettingsHistory', 'SettingsPersonalization'],
          ['Market', 'RewardDetails', 'MarketCategoryOne', 'MarketCategoryTwo'],
          ['Community'],
          ['About'],
          ['Calendar'],
          ['Support'],
          ['MyTeam'],
          ['Participants', 'ParticipantItem'],
          ['Club'],
        ];
        for (let i = 0; i < routes.length; i += 1) {
          if (routes[i].includes(route) && routes[i].includes(item.action)) {
            return true;
          }
        }
      }
      return false;
    },
    isMenuItemDisabled(item) {
      if (item && item.action) {
        if (typeof item.action !== 'string') return false;
        if (item.action === 'MyTeam' && this.disableTeamsForGuest && !this.isTeamSet) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./Menu";
</style>

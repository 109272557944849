<template>
  <div class="sort">
    <div class="sort__container">
      <div
        v-for="(item, i) in items"
        :key="i"
        class="sort__item"
        :class="{ 'sort__item_active': item.active === true }"
        @click.stop="$emit('selected', i)"
      >
        <span v-html="item.text"></span>
        <span v-if="item.active === true && item.descending !== undefined" v-html="item.descending === false ? ' ↑' : ' ↓'"></span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Sort',
  props: {
    items: Array,
  },
};
</script>

<style lang="scss" scoped>
  @import "./Sort";
</style>

<template>
  <div class="settings">
    <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="!isMobileApp" class="settings__menu">
      <Menu />
      <div class="divider"></div>
    </div>
    <div class="settings__content">
      <div class="settings__nav">
        <div class="nav__club-info">
          <div class="club-info__avatar" :style="{'background-image': `url(${avatar})`}">
          </div>
          <div>
            <div class="club-info__name" :title="fullName" v-html="fullName"></div>
            <div class="club-info__link">Моя страница</div>
          </div>
        </div>
        <div class="divider nav__divider"></div>
        <div class="nav__select">
          <Select
            :options="filteredOptions"
            v-model="currentOption"
            :iconLeft="optionsData.filter((i) => i.id === currentOption)[0].icon"
            @input="onOptionSelected"
            class="d-md-none"
            mobile
          />
          <div
            v-for="item in filteredOptions"
            :key="item.id"
            class="nav__select-item"
            :class="{'nav__select-item--active': currentOption === item.id}"
            @click.stop="onOptionSelected(item.id)"
          >
            <Icon :name="item.icon" size="m" />
            <span v-html="item.name"></span>
          </div>
        </div>
      </div>

      <router-view v-bind="{ avatar }" />

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Select from '@rr-component-library/select/src/main';
import Menu from '@/components/Menu/Menu.vue';
import Avatars from '@/assets/data/avatars';

export default {
  name: 'Settings',
  components: {
    Select,
    Menu,
  },
  data() {
    return {
      optionsData: [
        { id: 'SettingsProfile', name: 'Профиль', icon: 'user' },
        { id: 'SettingsTrainings', name: 'Тренировки', icon: 'time-limit' },
        { id: 'SettingsTrackers', name: 'Трекеры', icon: 'fitness-tracker' },
        { id: 'SettingsHistory', name: 'История покупок', icon: 'shopping-bag' },
        { id: 'SettingsPersonalization', name: 'Персонализация', icon: 'badge' },
      ],
      currentOption: 'SettingsTrainings',
      avatar: '',
    };
  },
  computed: {
    ...mapGetters('user', ['profile', 'isMobileApp']),
    ...mapGetters('settings', ['options']),
    fullName() {
      if (!this.profile) return '';
      return `${this.profile.firstName} ${this.profile.lastName}`;
    },
    routeName() {
      return this.$route.name;
    },
    filteredOptions() {
      if (this.options.questionnaire && this.options.questionnaire.length < 1) {
        return this.optionsData.filter((option) => option.name !== 'Персонализация');
      }
      return this.optionsData;
    },
  },
  watch: {
    routeName() {
      if (this.$route.name === 'Settings') {
        this.onOptionSelected('SettingsTrainings');
      }
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    onOptionSelected(option) {
      this.currentOption = option;
      this.$router.push({ name: option }).catch(() => {});
    },
    getAvatarImage(avatar) {
      if (!avatar) return '';
      if (!avatar.type) return '';
      if (avatar.type === 'custom') {
        return avatar.url || '';
      }
      const found = Avatars.filter((i) => i.id === avatar.type);
      if (found.length === 0) return '';
      return found[0].img || '';
    },
  },
  mounted() {
    this.getProfile()
      .then(() => {
        this.avatar = this.getAvatarImage(this.profile ? this.profile.avatar : null);
      });
    this.getBalance();
    if (this.$route.name === 'Settings') {
      this.onOptionSelected('SettingsProfile');
    } else if (this.$route.name === 'TrainingsTracker') {
      this.currentOption = 'SettingsTrainings';
    } else {
      this.currentOption = this.$route.name;
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "./Settings";
</style>

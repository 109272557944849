<template>
  <div class="top-curators">
    <div class="top-curators__info">
      <div v-if="topCuratorsGerb" class="info__image" :style="`background-image: url('${topCuratorsGerb}');`">
      </div>
      <div class="info__text">
        <div class="info__title" v-html="topCuratorsTitle"></div>
        <div v-if="topCuratorsShowCount && participantCount > 0" class="info__count">
          {{ participantCount }} человек уже с нами
        </div>
      </div>
    </div>
    <div class="top-curators__container">
      <div class="top-curators__content">
        <div class="top-curators__map" :class="{ 'top-curators__map_fullscreen': isFullscreen }">
          <div v-if="sortedCurators && !isFullscreen" class="map-select">
            <Select
              :options="sortedCurators"
              v-model="selectedItem"
              @input="onSelect"
              size="sm"
              :placeholder="topCuratorsListboxTitle"
              mobile
            />
          </div>
          <iframe :src="`/js/map/${theme}/map.html`" @load="onFrameLoaded"></iframe>
        </div>
        <div class="top-curators__rating">
          <div class="rating__entries">
            Показаны {{ showItemsOffset + 1 }}–{{ currentPage === totalPages ? curators.length : showItemsOffset + 5 }} из {{ curators.length }} записей
            <Icon name="info-circle" size="m" ref="infoIcon" />
            <Dropdown
              v-if="!hideDropdown"
              trigger="infoIcon"
              placement="bottom"
              listeners="click"
              hide-overlay
              auto-width
            >
              <template slot="items">
                <div class="base-dropdown__item">
                  <a class="base-dropdown__link" @click.prevent>
                    <span v-html="topCuratorsTip"></span>
                  </a>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="rating__table">
            <table>
              <tr
                v-for="(item, i) in itemsShown"
                :key="i"
                class="rating-item"
                :class="{
                  'rating-item--no-border': isItemOnCurrentPage(item.id) && isPrevActive(i),
                  'rating-item--with-percents': topCuratorsShowPercent === true,
                }"
                @click.stop="onSelect(item.id, $event)"
                @mouseenter="onItemHover(item.id, true)"
                @mouseleave="onItemHover(item.id, false)"
              >
                <td :class="{ 'active': item.id === selectedItem }">
                  {{ item.position }}
                </td>
                <td :class="{ 'active': item.id === selectedItem }">
                  {{ getItemName(item.id) }}
                </td>
                <td v-if="topCuratorsShowPercent" :class="{ 'active': item.id === selectedItem }">
                  <div class="percents">
                    <span v-if="topCuratorsShowPercentNew">{{ getFormattedPercentNew(item.percent) }}</span>
                    <span v-else>{{ getFormattedPercent(item.percent) }}</span>
                    <Icon
                      name="info-circle"
                      size="m"
                      :id="`infoIcon_${item.id}`"
                      @click.stop="onShowPercentTip(item.id)"
                    />
                  </div>
                  <div
                    v-if="percentTipId === item.id"
                    class="percents__tip"
                    :class="{ 'percents__tip_up-arrow': isUpArrow(i) }"
                  >
                    <div class="percents__content">
                      <div class="percents__title" v-html="getTipTitle(percentTipId)"></div>
                      <div class="percents__text" v-html="getTipText(percentTipId)"></div>
                    </div>
                    <div class="percents__close">
                      <Button
                        variant="clear"
                        size="sm"
                        shape="square"
                        iconLeft="close"
                        iconSize="s"
                        @click.stop="onHidePercentTip"
                      ></Button>
                    </div>
                    <div class="percents__arrow"></div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <Pagination
            :currentPage="currentPage"
            :totalPages="totalPages"
            class="rating__pagination"
            @prev-page="onChangePage(currentPage - 1)"
            @next-page="onChangePage(currentPage + 1)"
            @go-page="onChangePage"
          ></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Select from '@rr-component-library/select/src/main';
import Pagination from '@/components/Pagination/Pagination.vue';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import { getWordWithCount } from '@/assets/js/helpers';

export default {
  name: 'TopCurators',
  components: {
    Select,
    Pagination,
    Dropdown,
  },
  data() {
    return {
      currentPage: 1,
      curators: [],
      sortedCurators: [],
      items: [],
      selectedItem: null,
      map: null,
      isFullscreen: false,
      hideDropdown: false,
      participantCount: 0,
      percentTipId: '',
      arrayParticipants: [
        'участник',
        'участника',
        'участников',
      ],
      arrayResidents: [
        'житель',
        'жителя',
        'жителей',
      ],
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'options', 'resources']),
    ...mapGetters('nominations', ['ratings']),
    itemsShown() {
      return this.items.slice(this.showItemsOffset, this.showItemsOffset + 5);
    },
    totalPages() {
      return Math.ceil(this.items.length / 5);
    },
    showItemsOffset() {
      return (this.currentPage - 1) * 5;
    },
    topCuratorsRequestParams() {
      return this.options ? this.options.topCuratorsRequestParams || {} : {};
    },
    topCuratorsMapCenter() {
      return this.options ? this.options.topCuratorsMapCenter || [] : [];
    },
    topCuratorsMapZoom() {
      return this.options ? this.options.topCuratorsMapZoom || 12 : 12;
    },
    topCuratorsMapFillColors() {
      return this.options ? this.options.topCuratorsMapFillColors || [] : [];
    },
    topCuratorsShowCount() {
      return this.options ? this.options.topCuratorsShowCount === true : false;
    },
    topCuratorsShowPercent() {
      return this.options ? this.options.topCuratorsShowPercent === true : false;
    },
    topCuratorsAlternativePercent() {
      return this.options ? this.options.topCuratorsAlternativePercent === true : false;
    },
    topCuratorsMapShowOnlyPlacemarks() {
      return this.options ? this.options.topCuratorsMapShowOnlyPlacemarks === true : false;
    },
    topCuratorsShowPercentNew() {
      return this.options ? this.options.percentNew || false : false;
    },
    topCuratorsGerb() {
      return this.resources ? this.resources.topCuratorsGerb || '' : '';
    },
    topCuratorsTitle() {
      return this.resources ? this.resources.topCuratorsTitle || '' : '';
    },
    topCuratorsTip() {
      return this.resources ? this.resources.topCuratorsTip || '' : '';
    },
    topCuratorsListboxTitle() {
      return this.resources ? this.resources.topCuratorsListboxTitle || '' : '';
    },
    topCuratorsPeople() {
      return this.resources ? this.resources.topCuratorsPeople || this.arrayResidents : '';
    },
  },
  methods: {
    ...mapActions('nominations', ['getRatings']),
    onFrameLoaded() {
      this.getRatings(this.topCuratorsRequestParams)
        .then(() => {
          this.participantCount = this.getParticipantCount(this.ratings || []);
          const curators = this.getComputedRating(this.ratings || []);
          this.setItems(curators);
          this.map = new window.frames[0].InteractiveMap('map', {
            curators,
            coordsFile: `/themes/${this.theme}/data/coords.json`,
            mapCenter: this.topCuratorsMapCenter,
            mapZoom: this.topCuratorsMapZoom,
            mapFillColors: this.topCuratorsMapFillColors,
            listBoxTitle: this.topCuratorsListboxTitle,
            onlyPlacemarks: this.topCuratorsMapShowOnlyPlacemarks,
            balloonOpenCallback: (selected) => {
              this.selectedItem = selected;
              this.resetDropdown();
              this.onHidePercentTip();
            },
            createdCallback: () => {
              if (this.map) this.map.setPoints();
            },
            fullscreenCallback: (isFullscreen) => {
              this.isFullscreen = isFullscreen;
              this.resetDropdown();
              document.body.style.overflowY = isFullscreen ? 'hidden' : 'unset';
            },
            mapActivityCallback: () => {
              this.resetDropdown();
            },
          });
          setTimeout(() => { if (this.map) this.map.addYandexMap(); }, 500);
        });
    },
    onChangePage(page) {
      if (this.currentPage === page) return;
      this.onHidePercentTip();
      this.currentPage = page;
    },
    onSelect(id, event = null) {
      if (this.isTipClicked(id, event)) {
        this.onShowPercentTip(id);
        return;
      }
      if (this.selectedItem !== id) {
        this.onHidePercentTip();
      }
      this.selectedItem = id;
      this.map.showCurator(this.selectedItem);
      this.resetDropdown();
    },
    onItemHover(id, isHovered) {
      this.map.hoverCurator(id, isHovered);
    },
    onShowPercentTip(id) {
      this.percentTipId = id;
    },
    onHidePercentTip() {
      this.percentTipId = '';
    },
    async loadCurators() {
      const url = `/themes/${this.theme}/data/curators.json`;
      let result = null;
      const response = await fetch(url);

      if (response.ok) {
        try {
          result = await response.json();
        } catch (e) {
          result = null;
        }
      } else {
        result = null;
      }

      this.curators = result || [];
      this.sortedCurators = Object.assign([], this.curators).sort(this.sortByName);
    },
    isTipClicked(id, event = null) {
      if (event) {
        const info = document.getElementById(`infoIcon_${id}`);
        if (event.target === info) {
          return true;
        }
      }
      return false;
    },
    getParticipantCount(ratings) {
      let count = 0;
      for (let i = 0; i < ratings.length; i += 1) {
        count += ratings[i].count;
      }
      return count;
    },
    getComputedRating(ratings) {
      let result = [];
      for (let i = 0; i < this.curators.length; i += 1) {
        const item = { ...this.curators[i] };
        const found = ratings.filter((it) => it.id === item.id);
        if (found.length === 0) {
          item.participants = 0;
          item.percent = 0;
          result.push(item);
          continue;
        }
        item.participants = found[0].count;
        if (!this.topCuratorsAlternativePercent) {
          item.percent = item.population ? (found[0].count / item.population) * 100 : 0;
        } else {
          item.percent = this.participantCount > 0 ? (found[0].count / this.participantCount) * 100 : 0;
        }
        result.push(item);
      }
      result = result.sort(this.sortByPercent);
      result.reverse();
      let firstZeroIndex = -1;
      for (let j = 0; j < result.length; j += 1) {
        if (result[j].percent === 0 && firstZeroIndex < 0) firstZeroIndex = j;
        result[j].rating = j + 1;
      }
      if (firstZeroIndex >= 0) {
        const rated = result.slice(0, firstZeroIndex);
        let zero = result.slice(firstZeroIndex);
        zero = zero.sort(this.sortByName);
        rated.push(...zero);
        result = rated;
        for (let j = 0; j < result.length; j += 1) {
          result[j].rating = j + 1;
        }
      }
      return result;
    },
    setItems(curators) {
      const items = [];
      for (let i = 0; i < curators.length; i += 1) {
        items.push({
          id: curators[i].id,
          regionId: curators[i].regionId,
          position: curators[i].rating,
          percent: curators[i].percent,
          population: curators[i].population,
          participants: curators[i].participants,
        });
      }
      this.items = items;
    },
    isPrevActive(index) {
      if (index === 0) return false;
      if (this.itemsShown.length === 0 || index > this.itemsShown.length - 1) return false;
      return this.selectedItem === this.itemsShown[index - 1].id;
    },
    isItemOnCurrentPage(id) {
      return this.itemsShown.filter((i) => i.id === id).length > 0;
    },
    getItemName(id) {
      const found = this.curators.filter((i) => i.id === id);
      if (found.length === 0) return '';
      return found[0].name;
    },
    getItemPopulation(id) {
      const found = this.items.filter((i) => i.id === id);
      if (found.length === 0) return '';
      return found[0].population || 0;
    },
    getItemPaticipants(id) {
      const found = this.items.filter((i) => i.id === id);
      if (found.length === 0) return '';
      return found[0].participants || 0;
    },
    sortByName(a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    },
    sortByPercent(a, b) {
      if (a.percent < b.percent) return -1;
      if (a.percent > b.percent) return 1;
      return 0;
    },
    resetDropdown() {
      if (this.hideDropdown) return;
      this.hideDropdown = true;
      setTimeout(() => { this.hideDropdown = false; }, 250);
    },
    getFormattedPercent(value) {
      const result = value ? value.toFixed(3).replace('.', ',') : '0,000';
      return `${result}\u00A0%`;
    },
    getFormattedPercentNew(value) {
      const result = value ? value.toFixed(1).replace('.', ',') : '0,0';
      return `${result}\u00A0%`;
    },
    getTipTitle(id) {
      if (!id) return '';
      return `${this.getItemName(id)}`;
    },
    getTipText(id) {
      if (!id) return '';
      return `${getWordWithCount(this.getItemPaticipants(id), this.arrayParticipants)}<br>
        <span class="${this.topCuratorsAlternativePercent ? 'd-none' : ''}">
          ${getWordWithCount(this.getItemPopulation(id), this.topCuratorsPeople)}
        </span>`;
    },
    isUpArrow(i) {
      return i === 0 || i === 1;
    },
  },
  created() {
    this.loadCurators();
  },
  beforeDestroy() {
    if (this.map) {
      this.map.destroyYandexMap();
      this.map = null;
    }
  },
};
</script>

<style lang="scss" scoped>
  @import './TopCurators';
</style>

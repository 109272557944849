/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import api from '@/services/dadata-api';

const state = {
  organizations: null,
  address: null,
  responseStatus: null,
};

const mutations = {
  setOrganizations(state, value) {
    state.organizations = value;
  },
  setAddress(state, value) {
    state.address = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
};

const getters = {
  organizations: (state) => state.organizations,
  address: (state) => state.address,
  responseStatus: (state) => state.responseStatus,
};

const actions = {
  searchOrganization({ commit }, query) {
    return api.searchOrganization({ query })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          return response.json();
        }
        return null;
      })
      .then((data) => {
        commit('setOrganizations', data);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  searchAddress({ commit }, { query, locationsBoost }) {
    return api.searchAddress({ query, locationsBoost })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          return response.json();
        }
        return null;
      })
      .then((data) => {
        commit('setAddress', data);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const state = {
  settings: null,
};

const mutations = {
  setSettings(state, settings) {
    state.settings = settings || {};
  },
};

const getters = {
  settings: (state) => state.settings,
  theme(state) {
    return state.settings ? state.settings.theme : null;
  },
  isPrivate(state) {
    return state.settings ? state.settings.isClosed : null;
  },
  showSSO(state) {
    return state.settings ? state.settings.showSSO : null;
  },
  options(state) {
    return state.settings ? state.settings.options : null;
  },
  resources(state) {
    return state.settings ? state.settings.resources : null;
  },
  themeDone(state) {
    return state.settings ? state.settings.themeDone : null;
  },
  checkAppUrl(state) {
    return state.settings ? state.settings.checkAppUrl : null;
  },
};

const actions = {
  loadSettings({ commit }) {
    commit('setSettings', window.MY_SCOUNT_APP_SETTINGS);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

<template>
  <div class="purchase-history">
    <div class="purchase-history__heading">История покупок</div>
    <div v-if="!loading">
      <div class="purchase-history__items" v-if="items.length > 0">
        <PurchaseCard
          v-for="(item, i) in items"
          :key="i"
          :options="item"
          @how-to="onHowTo(i)"
        >
        </PurchaseCard>
      </div>
      <div class="purchase-history__empty" v-if="items.length === 0">
        <img :src="emptyRafiki" class="purchase-history__image" />
        <div class="purchase-history__text">У вас пока нет купленных товаров</div>
        <div class="purchase-history__subtext">Выберите на что потратить баллы среди 100+ товаров в разделе <Link variant="primary" @click="$router.push({ name: 'Market' }).catch(() => {})">«Маркет»</Link></div>
        <div class="purchase-history__button">
          <Button
            variant="primary"
            size="lg"
            text="Начать покупки"
            @click="onMarket"
            mobile
          ></Button>
        </div>
      </div>
    </div>

    <Modal
      name="reward-bought-details"
      title="Как получить товар?"
      subtitle="Ваш промокод для получения товара:"
      :active="boughtModal"
      :noTransition="true"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="onBoughtClosed"
    >
      <div class="reward-bought__promocode" v-html="code"></div>
      <div class="reward-bought__message text-left" v-html="steps"></div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PurchaseCard from './PurchaseCard.vue';

export default {
  name: 'PurchaseHistory',
  components: {
    PurchaseCard,
  },
  data() {
    return {
      items: [],
      boughtModal: false,
      code: '',
      steps: '',
      loading: true,
    };
  },
  computed: {
    ...mapGetters('settings', ['resources']),
    ...mapGetters('operations', ['purchaseOperations']),
    ...mapGetters('user', ['isMobileApp']),
    emptyRafiki() {
      return this.resources ? this.resources.emptyRafiki || '' : '';
    },
  },
  methods: {
    ...mapActions('operations', ['getPurchaseOperations']),
    onHashChange() {
      if (this.$route.name !== 'SettingsHistory') {
        window.removeEventListener('hashchange', this.onHashChange);
        return;
      }
      const hash = window.location.hash.split('=');
      if (hash[0] === '#!showBought') this.showBought(hash);
      else {
        this.clearHash();
        this.boughtModal = false;
      }
    },
    onHowTo(index) {
      if (!this.items) {
        this.code = '';
        this.steps = '';
        return;
      }
      this.code = this.items[index].code || '';
      this.steps = this.items[index].howToGet || '';
      this.boughtModal = true;
      window.location.hash = `#!showBought=${this.items[index].operationId}`;
    },
    onBoughtClosed() {
      window.location.hash = '#!showBought=false';
    },
    onMarket() {
      this.$router.push({ name: 'Market' }).catch(() => {});
    },
    getMapppedOperations(operations) {
      if (!operations) return [];
      const items = [];
      for (let i = 0; i < operations.length; i += 1) {
        const value = operations[i];
        const item = this.getMappedItem(value);
        items.push(item);
      }
      return items;
    },
    getMappedItem(value) {
      const item = {};
      if (value.attributes.OperationSource === 'MarketProduct') {
        item.operationId = value.operationId;
        item.img = value.attributes.ProductMobileImageUrl || '';
        item.title = value.attributes.ProductName;
        item.coins = Math.round(value.attributes.Amount);
        item.date = value.operationDate;
        item.code = value.attributes.PromocodeCode;
        item.howToGet = value.attributes.ProductHowToGet;
        return item;
      }
      return item;
    },
    showBought(hash) {
      if (hash[1] && hash[1] !== 'false') {
        const index = this.items.findIndex((i) => i.operationId === hash[1]);
        if (index >= 0) this.onHowTo(index);
      } else {
        this.boughtModal = false;
        this.clearHash();
      }
    },
    clearHash() {
      setTimeout(() => { window.location.hash = ''; }, 100);
    },
  },
  mounted() {
    this.getPurchaseOperations({ offset: 0, count: 100 })
      .then(() => {
        this.items = this.getMapppedOperations(this.purchaseOperations.values);
        this.loading = false;

        window.addEventListener('hashchange', this.onHashChange);
        this.onHashChange();
      });
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import "./PurchaseHistory";
</style>

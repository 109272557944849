const DIR = '/img/avatars';

export default [
  { id: '1', img: `${DIR}/avatar-step04.svg` },
  { id: '2', img: `${DIR}/avatar-step06.svg` },
  { id: '3', img: `${DIR}/avatar-step01.svg` },
  { id: '4', img: `${DIR}/avatar-step05.svg` },
  { id: '5', img: `${DIR}/avatar-step07.svg` },
  { id: '6', img: `${DIR}/avatar-step03.svg` },
  { id: '7', img: `${DIR}/avatar-step.svg` },
  { id: '8', img: `${DIR}/avatar-step02.svg` },
];

<template>
  <div class="register">
    <div v-if="isMobileApp && isRegisterPage" class="spacing-xl"></div>
    <div v-if="!isMobileApp && isRegisterPage" class="register__header register__header_default">
      <div class="header__row">
        <div class="header__group">
          <div class="header__item header__logo">
            <a href="/"><img :src="logo" alt="Scount"></a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isRegisterPage" class="register__container">
      <div class="container">
        <div class="block">
          <PersonalInfo
            :isShown="true"
            :isMobileApp="isMobileApp"
          ></PersonalInfo>
        </div>
      </div>
    </div>
    <div v-if="isRegisterPage" class="spacing-xl"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonalInfo from '@/components/PersonalInfo/PersonalInfo.vue';

export default {
  name: 'Register',
  components: {
    PersonalInfo,
  },
  computed: {
    ...mapGetters('settings', ['resources']),
    ...mapGetters('user', ['isMobileApp']),
    logo() {
      return this.resources ? this.resources.logo || '' : '';
    },
    isRegisterPage() {
      return this.$route.name === 'Register';
    },
  },
  mounted() {
    localStorage.removeItem('authEmail');
    localStorage.removeItem('authToken');
    localStorage.removeItem('authDate');
  },
};
</script>

<style lang="scss" scoped>
  @import "./Register";
</style>

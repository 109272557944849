const token = 'a8ba39868c1f4fe6701c3f6f62c94698f799d925';

const options = {
  method: 'POST',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Token ${token}`,
  },
  body: '',
};

export default {
  searchOrganization({ query }) {
    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party';
    options.body = JSON.stringify({ query, status: 'ACTIVE' });
    return fetch(url, options);
  },
  searchAddress({ query, locationsBoost }) {
    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
    options.body = JSON.stringify({
      query,
      locations_boost: locationsBoost,
      from_bound: { value: 'city' },
      to_bound: { value: 'settlement' },
    });
    return fetch(url, options);
  },
};

<template>
  <div class="mobile-link">
    <div class="mobile-overlay"></div>
  </div>
</template>

<script>
export default {
  name: 'MobileLink',
  components: {},
  mounted() {
    const { returnPath } = this.$route.query;
    if (returnPath) {
      setTimeout(() => {
        this.$router.replace({ path: returnPath }).catch(() => {});
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "./MobileLink";
</style>

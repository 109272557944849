<template>
  <div class="pagination">
    <div class="pagination__pages">
      <Button
        variant="ghost"
        view="light"
        shape="square"
        size="sm"
        class="pagination__prev"
        iconLeft="left"
        iconSize="s"
        :class="isPrevButtonActive ? 'pagination__prev--active' : ''"
        @click.stop="onPrev"
      ></Button>
      <Button
        variant="ghost"
        view="light"
        shape="square"
        size="sm"
        iconLeft="right"
        iconSize="s"
        class="pagination__next"
        :class="isNextButtonActive ? 'pagination__next--active' : ''"
        @click.stop="onNext"
      ></Button>
      <div
        v-for="(page, i) in pages"
        :key="i"
        class="pages__page"
        :class="{
          'pages__page--active': currentPage === page.num,
          'pages__page--hidden': isPageHidden(page.num),
        }"
        @click.stop="onPage(page.num)"
      >
        <span>{{ isDotsPage(page.num) ? '...' : page.num }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Pagination',
  props: {
    currentPage: Number,
    totalPages: Number,
  },
  data() {
    return {
      pages: [],
    };
  },
  computed: {
    isPrevButtonActive() {
      return this.$props.currentPage > 1;
    },
    isNextButtonActive() {
      return this.$props.currentPage < this.$props.totalPages;
    },
  },
  watch: {
    currentPage() {
      this.updatePages();
    },
    totalPages() {
      this.setPages();
    },
  },
  methods: {
    onPrev() {
      if (!this.isPrevButtonActive) return;
      this.$emit('prev-page');
    },
    onNext() {
      if (!this.isNextButtonActive) return;
      this.$emit('next-page');
    },
    onPage(num) {
      this.$emit('go-page', num);
    },
    setPages() {
      const pages = [];
      for (let i = 0; i < this.$props.totalPages; i += 1) {
        const page = { num: i + 1 };
        pages.push(page);
      }
      this.pages = pages;
    },
    updatePages() {

    },
    isPageHidden(num) {
      const current = this.$props.currentPage;
      const total = this.$props.totalPages;
      if (total <= 5) return false;
      if (num === current) return false;
      if (current <= 3) {
        if (num > 4 && num < total) return true;
        return false;
      }
      if (current > 3 && current <= total - 4) {
        const fromTo = this.getFrameFromTo();
        if (num < fromTo.from || num > fromTo.to) return true;
        return false;
      }
      if (current > total - 4) {
        if (num < total - 4) return true;
      }
      return false;
    },
    isDotsPage(num) {
      const current = this.$props.currentPage;
      const total = this.$props.totalPages;
      if (total <= 5) return false;
      if (num === current) return false;
      if (current <= 3) {
        if (num === 4) return true;
        return false;
      }
      if (current > 3 && current <= total - 4) {
        const fromTo = this.getFrameFromTo();
        if (num === fromTo.from || num === fromTo.to) return true;
        return false;
      }
      if (current > total - 4) {
        if (num === total - 4) return true;
      }
      return false;
    },
    getFrameFromTo() {
      const current = this.$props.currentPage;
      const total = this.$props.totalPages;
      if (total <= 5) return { from: 1, to: total };
      if (current > 3 && current <= total - 4) {
        let from = 3;
        for (let to = 7; to < total; to += 3) {
          if (current > from && current < to) {
            return { from, to };
          }
          from += 3;
        }
      }
      if (current > total - 4) {
        return { from: total - 4, to: total };
      }
      return { from: 1, to: total };
    },
  },
  mounted() {
    this.setPages();
  },
};
</script>

<style lang="scss" scoped>
  @import "./Pagination";
</style>

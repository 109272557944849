/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from 'vue';
import Vuex from 'vuex';

import settings from './settings.module';
import user from './user.module';
import trackers from './trackers.module';
import exercises from './exercises.module';
import wallet from './wallet.module';
import market from './market.module';
import trainings from './trainings.module';
import dadata from './dadata.module';
import achievements from './achievements.module';
import operations from './operations.module';
import nominations from './nominations.module';
import goals from './goals.module';
import teams from './teams.module';
import community from './community.module';
import quizes from './quizes.module';
import partners from './partners.module';
import content from './content.module';
import geolocation from './geolocation.module';
import calendar from './calendar.module';
import communications from './communications.module';
import externalevents from './externalevents.module';
import steps from '@/store/steps.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
  },
  modules: {
    settings,
    user,
    trackers,
    exercises,
    wallet,
    market,
    trainings,
    dadata,
    achievements,
    operations,
    nominations,
    goals,
    teams,
    community,
    quizes,
    partners,
    content,
    geolocation,
    calendar,
    communications,
    externalevents,
    steps,
  },
});

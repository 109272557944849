<template>
  <Modal
    name="onboarding-finish-guide"
    title="Трекер подключён!"
    subtitle="Вы готовы к тому, чтобы начать выполнение спортивных заданий. Не забывайте включать трекер в начале и выключать в конце тренировки."
    :img="currentImg"
    :active="shown"
    v-bind="settings"
    @close.prevent="$emit('close')"
  >
    <div class="text-center">
      <Row class="rr-grid_8" justify="center">
        <br>
        <Col size="auto">
          <Button
            :text="taskId !== '' && $route.name !== 'SettingsTrackers' ? 'Перейти к заданию' : 'Выбрать задание'"
            variant="secondary"
            size="lg"
            wide
            mobile
            @click="$emit('tasks')"
          ></Button>
        </Col>
      </Row>
    </div>
  </Modal>
</template>

<script>
import Garmin from '@rr-component-library/common/src/img/apps/garmin-app.png';
import GoogleFit from '@rr-component-library/common/src/img/apps/google-fit-app.png';
import Polar from '@rr-component-library/common/src/img/apps/polar-app.png';
import Strava from '@rr-component-library/common/src/img/apps/strava-app.png';
import Suunto from '@rr-component-library/common/src/img/apps/suunto-app.png';
import SimpleRun from '@rr-component-library/common/src/img/apps/simple-run-app.png';

export default {
  name: 'FinishFromGuide',
  props: {
    shown: Boolean,
    provider: String,
    settings: Object,
  },
  data() {
    return {
      trackers: [
        { id: 'Garmin', img: Garmin },
        { id: 'GoogleFit', img: GoogleFit },
        { id: 'Polar', img: Polar },
        { id: 'Strava', img: Strava },
        { id: 'Suunto', img: Suunto },
        { id: 'SimpleRun', img: SimpleRun },
      ],
      taskId: '',
    };
  },
  computed: {
    currentImg() {
      const found = this.trackers.filter((i) => i.id === this.provider);
      return found.length > 0 ? found[0].img : '';
    },
  },
  watch: {
    shown(val) {
      if (val) {
        this.taskId = localStorage.getItem('returnToTask') || '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .rr-modal__window {
    border-radius: 8px;
  }

  ::v-deep .rr-modal__img img {
    width: 48px;
    height: 48px;
  }

  ::v-deep .rr-modal__subtitle a,
  ::v-deep .rr-modal__subtitle a:link,
  ::v-deep .rr-modal__subtitle a:hover,
  ::v-deep .rr-modal__subtitle a:active,
  ::v-deep .rr-modal__subtitle a:visited {
    color: var(--color-primary, #FF8233);
    font-weight: 600;
    text-decoration: none;
  }

  ::v-deep .rr-button {
    border-radius: 4px;
  }
</style>

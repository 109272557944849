import { render, staticRenderFns } from "./RulesPrizesModal.vue?vue&type=template&id=0d655578&scoped=true&"
import script from "./RulesPrizesModal.vue?vue&type=script&lang=js&"
export * from "./RulesPrizesModal.vue?vue&type=script&lang=js&"
import style0 from "./RulesPrizesModal.vue?vue&type=style&index=0&id=0d655578&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d655578",
  null
  
)

export default component.exports
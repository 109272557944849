<template>
  <div class="index-ofp">
    <div class="index-ofp__progress-container">
      <div class="index-ofp__progress">
        <ProgressBarCircle :value="value" />
      </div>
      <div class="index-ofp__value">
        {{ value }}
      </div>
    </div>
    <div class="index-ofp__text">
      <!-- <span v-if="simple === true"> -->
        <!-- <span v-html="dates"></span> -->
      <!-- </span> -->
      <span>Индекс ОФП</span>
      <span v-html="dates"></span>
        <!-- <Link
          variant="primary"
          tag="a"
          text="Что это?"
          href="#"
          target="_blank"
          @click.prevent="$emit('show')"
        /> -->
    </div>
  </div>
</template>

<script>
import ProgressBarCircle from '@/components/ProgressBarCircle/ProgressBarCircle.vue';

export default {
  name: 'IndexOFP',
  components: {
    ProgressBarCircle,
  },
  props: {
    value: Number,
    dates: String,
    simple: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  @import "./IndexOFP";
</style>

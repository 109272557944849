<template>
  <div class="text-block">
    <div class="text-block__container">
      <div
        v-for="(back, i) in background"
        :key="i"
        class="back-image"
        :class="`back-image_${back.id}`"
      >
        <img :src="`/img/text-block/${back.img}`" :alt="back.id" />
      </div>
      <div class="text-block__slider">
        <Slider
          :navigationOffset="navigationOffset"
          :paginationOffset="paginationOffset"
          :loop="loop"
          :autoHeight="$root.isDesktop === false"
        >
          <div
            v-for="(item, i) in items"
            :key="i"
            class="slide"
          >
            <div class="slide__title" v-html="item.title || ''"></div>
            <div class="slide__text" v-html="item.content || ''"></div>
          </div>
        </Slider>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TextBlock',
  props: {
    items: Array,
  },
  data() {
    return {
      background: [
        { id: 'coin', img: 'onboarding-coin.png' },
        { id: 'group', img: 'onboarding-group.png' },
        { id: 'points', img: 'onboarding-points.png' },
        { id: 'logo', img: 'onboarding-logo-scount-marketing.png' },
        { id: 'walk', img: 'onboarding-walk.png' },
        { id: 'tracker', img: 'onboarding-tracker.png' },
      ],
    };
  },
  computed: {
    navigationOffset() {
      return {
        default: '0', md: '72', lg: '72', xl: '84',
      };
    },
    paginationOffset() {
      return {
        default: '12', md: '28', lg: '28', xl: '44',
      };
    },
    loop() {
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./TextBlock";
</style>

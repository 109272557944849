<template>
  <div class="level-info">
    <div class="level-info__close">
      <Icon name="close" />
    </div>
    <div class="level-info__content">
      <div class="content__illustration">
        <img :src="levelImage" />
        <div>{{ level }}</div>
      </div>
      <div class="content__message">
        <div class="message__title">
          Ваш уровень опыта
        </div>
        <div class="message__subtitle">
          Ещё <span class="message__subtitle_highlight">{{ needXP }} XP</span> до <span class="message__subtitle_level">{{ level+1 }} ур.</span>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="level-info__description">
      Начисляем 1 очко опыта (XP)<br v-if="$root.isMobile" /> за 1 балл (<img :src="coin" />).
    </div>
  </div>
</template>

<script>
import LevelImage from '@/assets/img/level-info/level.svg';
import Coin from '@/assets/img/coin.svg';

export default {
  name: 'LevelInfo',
  props: {
    level: Number,
    needXP: Number,
  },
  data() {
    return {
      levelImage: LevelImage,
      coin: Coin,
    };
  },
};
</script>

<style lang="scss" scoped>
  @import "./LevelInfo";
</style>

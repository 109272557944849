import api from './api';

export default {
  createGeoActivity({
    activityId, participantExerciseTaskId, latitude, longitude,
  }) {
    return api.post('/geoactivities/api/v1/activities', {
      activityId, participantExerciseTaskId, latitude, longitude,
    });
  },
};

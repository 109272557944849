import api from './api';

export default {
  getOperations({
    operationTypes, sortBy, descending, offset, count,
  }) {
    return api.get('/operationshistory/api/v1/operations', {
      params: {
        operationTypes,
        sortBy,
        descending,
        offset,
        count,
      },
    });
  },
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Cookies from 'js-cookie';
import router from '@/router';
import identityApi from '@/services/identity-api';
import scountApi from '@/services/scount-api';

const state = {
  profile: {},
  emptyProfile: null,
  authToken: null,
  authStatus: null,
  accessToken: null,
  accessAttributes: [],
  isAuthenticated: null,
  isMobileApp: null,
  questionnaire: {},
  questionnaireStatus: null,
  avatarStatus: null,
  emailCheck: null,
  emailCheckStatus: null,
  restrictions: null,
  codeDetails: null,
  codeStatus: null,
  profileBlockedStatus: null,
  code: null,
  hasParticipantActiveDeviceSession: null,
};

const mutations = {
  removeUser() {
    Cookies.remove('token');
    localStorage.removeItem('returnFromGuide');
    if (router && router.currentRoute.meta.requiresAuth) {
      const returnUrl = `${window.location.origin}${router.currentRoute.path}`;
      window.location.href = `${window.location.origin}/?loginReturnUrl=${returnUrl}`;
    }
  },
  userBlocked(state) {
    Cookies.remove('token');
    localStorage.removeItem('returnFromGuide');
    const email = state.profile ? state.profile.emailAddress || '' : '';
    window.location.href = `${window.location.origin}/auth?showLoginBlocked=${email || 'true'}`;
  },
  setProfile(state, profile) {
    state.profile = profile || {};
    state.profile.fullName = `${profile.firstName || ''} ${profile.lastName || ''}`.trim();
  },
  setEmptyProfile(state, empty) {
    state.emptyProfile = empty;
  },
  setAuthToken(state, value) {
    state.authToken = value || '';
  },
  setAuthStatus(state, value) {
    state.authStatus = value;
  },
  setAccessToken(state, value) {
    state.accessToken = value || '';
  },
  setAccessAttributes(state, value) {
    state.accessAttributes = value || [];
  },
  setAuthenticated(state, value) {
    state.isAuthenticated = value;
  },
  setMobileApp(state, value) {
    state.isMobileApp = value;
  },
  setQuestionnaire(state, value) {
    state.questionnaire = value || {};
  },
  setQuestionnaireStatus(state, value) {
    state.questionnaireStatus = value;
  },
  setAvatarStatus(state, value) {
    state.avatarStatus = value;
  },
  setProfileBlockedStatus(state, value) {
    state.profileBlockedStatus = value;
  },
  setEmailCheck(state, value) {
    state.emailCheck = value;
  },
  setEmailCheckStatus(state, value) {
    state.emailCheckStatus = value;
  },
  setRestrictions(state, value) {
    state.restrictions = value || {};
  },
  setCodeDetails(state, value) {
    state.codeDetails = value || {};
  },
  setCodeStatus(state, value) {
    state.codeStatus = value;
  },
  setCode(state, value) {
    state.code = value;
  },
  setHasDeviceSession(state, value) {
    state.hasParticipantActiveDeviceSession = value;
  },
};

const getters = {
  profile: (state) => state.profile,
  emptyProfile: (state) => state.emptyProfile,
  authToken: (state) => state.authToken,
  authStatus: (state) => state.authStatus,
  accessToken: (state) => state.accessToken,
  accessAttributes: (state) => state.accessAttributes,
  isAuthenticated: (state) => state.isAuthenticated,
  isMobileApp: (state) => state.isMobileApp,
  questionnaire: (state) => state.questionnaire,
  questionnaireStatus: (state) => state.questionnaireStatus,
  avatarStatus: (state) => state.avatarStatus,
  emailCheck: (state) => state.emailCheck,
  emailCheckStatus: (state) => state.emailCheckStatus,
  restrictions: (state) => state.restrictions,
  codeDetails: (state) => state.codeDetails,
  codeStatus: (state) => state.codeStatus,
  profileBlockedStatus: (state) => state.profileBlockedStatus,
  code: (state) => state.code,
  hasParticipantActiveDeviceSession: (state) => state.hasParticipantActiveDeviceSession,
};

const actions = {
  startAuth({ commit }, {
    type, emailAddress, russiaRunningToken, attributes,
  }) {
    return identityApi.startAuth({
      type, emailAddress, russiaRunningToken, attributes,
    })
      .then((response) => {
        commit('setAuthStatus', response.status);
        if (response.status === 200) {
          commit('setAuthToken', response.data.token);
        } else {
          commit('setAuthToken', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        const { code } = error.response.data;
        commit('setCodeDetails', code || '');
        commit('setAuthStatus', status || 0);
        commit('setAuthToken', null);
      });
  },
  approveAuth({ commit }, { token, code }) {
    return identityApi.approveAuth({ token, code })
      .then((response) => {
        commit('setAuthStatus', response.status);
        if (response.status === 200) {
          Cookies.set('token', response.data.accessToken || '', { expires: 365 });
          commit('setAccessToken', response.data.accessToken);
          commit('setAccessAttributes', response.data.attributes);
          commit('setAuthenticated', true);
        } else {
          commit('setAccessToken', null);
          commit('setAccessAttributes', []);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setAuthStatus', status || 0);
        commit('setAccessToken', null);
        commit('setAccessAttributes', []);
      });
  },
  getCodeDetails({ commit }, { code }) {
    return scountApi.getCodeDetails({ code })
      .then((response) => {
        commit('setCodeStatus', response.status);
        if (response.status === 200) {
          commit('setCodeDetails', response.data);
        } else {
          commit('setCodeDetails', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setCodeStatus', status || 0);
        commit('setCodeDetails', null);
      });
  },
  setIsMobileApp({ commit }, { value }) {
    commit('setMobileApp', value);
  },
  logout({ commit }) {
    commit('removeUser');
    window.location.href = `${window.location.origin}/`;
  },
  getProfile({ commit, dispatch }) {
    return scountApi.getProfile()
      .then((response) => {
        if (response.status === 200) {
          commit('setProfile', response.data);
          commit('setAuthenticated', true);
          commit('setEmptyProfile', false);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        if (status === 404) {
          commit('setAuthenticated', true);
          commit('setEmptyProfile', true);
        } else if (status === 401) {
          commit('setAuthenticated', false);
        } else if (status === 409) {
          // Когда "закрытая" МП и метод получения профиля вернул 409-й код,
          // тогда выполнить logout().
          const isPrivate = this.getters['settings/isPrivate'];
          if (isPrivate === true) dispatch('logout');
        }
      });
  },
  profileBlocked({ commit }) {
    return scountApi.profileBlocked()
      .then((response) => {
        commit('setProfileBlockedStatus', response.status);
        commit('removeUser');
        window.location.href = `${window.location.origin}/?accountDeleted=true`;
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setProfileBlockedStatus', status || 0);
      });
  },
  setAvatar({ commit }, { avatarType, file }) {
    return scountApi.setAvatar({ avatarType, file })
      .then((response) => {
        commit('setAvatarStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setAvatarStatus', status || 0);
      });
  },
  checkByEmail({ commit }, { emailAddress }) {
    return scountApi.checkByEmail({ emailAddress })
      .then((response) => {
        commit('setEmailCheckStatus', response.status);
        if (response.status === 200) {
          commit('setEmailCheck', response.data);
        } else {
          commit('setEmailCheck', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setEmailCheckStatus', status || 0);
      });
  },
  getQuestionnaire({ commit }) {
    return scountApi.getQuestionnaire()
      .then((response) => {
        commit('setQuestionnaireStatus', response.status);
        if (response.status === 200) {
          commit('setQuestionnaire', response.data);
        } else {
          commit('setQuestionnaireStatus', response.status);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setQuestionnaireStatus', status || 0);
      });
  },
  createQuestionnaire({ commit }) {
    return scountApi.createQuestionnaire()
      .then((response) => {
        commit('setQuestionnaireStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setQuestionnaireStatus', status || 0);
      });
  },
  applyAnswers({ commit }, answers) {
    return scountApi.applyAnswers({ answers })
      .then((response) => {
        commit('setQuestionnaireStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setQuestionnaireStatus', status || 0);
      });
  },
  completeQuestionnaire({ commit }) {
    return scountApi.completeQuestionnaire()
      .then((response) => {
        commit('setQuestionnaireStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setQuestionnaireStatus', status || 0);
      });
  },
  getRestrictions({ commit }) {
    return scountApi.getRestrictions()
      .then((response) => {
        if (response.status === 200) {
          commit('setRestrictions', response.data);
        } else {
          commit('setRestrictions', null);
        }
      })
      .catch(() => {
        commit('setRestrictions', null);
      });
  },
  getCode({ commit }) {
    return scountApi.getCode()
      .then((response) => {
        if (response.status === 200) {
          commit('setCode', response.data);
        } else {
          commit('setCode', null);
        }
      })
      .catch(() => {
        commit('setCode', null);
      });
  },
  hasDeviceSession({ commit }, { participantId }) {
    return scountApi.hasDeviceSession({ participantId })
      .then((response) => {
        if (response.status === 200) {
          commit('setHasDeviceSession', response.data.hasParticipantActiveDeviceSession);
        } else {
          commit('setHasDeviceSession', null);
        }
      })
      .catch(() => {
        commit('setHasDeviceSession', null);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

import api from './api';

export default {
  createSocialAccount({ accountVk, accountTelegram }) {
    return api.patch('/scount-communications/api/v1/profile', {
      accountVk,
      accountTelegram,
    });
  },
  getParticipant(participantId) {
    return api.get(`/scount-communications/api/v1/participants/${participantId}`);
  },
  getProfileCommunications() {
    return api.get('/scount-communications/api/v1/profile');
  },
  getParticipants({
    searchString, searchByCityString, offset, count, filters,
  }) {
    return api.get('/scount-communications/api/v1/participants', {
      params: {
        searchString,
        searchByCityString,
        offset,
        count,
        ...filters,
      },
    });
  },

  getParticipantOperationshistory(participantId) {
    return api.get(`/scount-communications/api/v1/participants/${participantId}/operationshistory`);
  },
  getParticipantRating(participantId) {
    return api.get(`/scount-communications/api/v1/participants/${participantId}/ratings`);
  },
  getParticipantTeamRating(participantId) {
    return api.get(`/scount-communications/api/v1/participants/${participantId}/teamratings`);
  },
  getParticipantStatistics(participantId) {
    return api.get(`/scount-communications/api/v1/participants/${participantId}/activitiesstatistics`);
  },
  getParticipantAchievements(participantId) {
    return api.get(`/scount-communications/api/v1/participants/${participantId}/achievements`);
  },
  getParticipantDistance(participantId) {
    return api.get(`/scount-communications/api/v1/participants/${participantId}/distancestatistics`);
  },
  getParticipantSportindexes(participantId) {
    return api.get(`/scount-communications/api/v1/participants/${participantId}/sportindexes`);
  },
};

<template>
  <div
    class="chip"
    :class="{
      'chip_filled': !multiselect ? selectedOption : hasSelectedValues,
    }"
  >
    <button class="chip__button" type="button" ref="button">
      <div v-if="!multiselect && !valueRange || (valueRange && !savedRange)" class="chip__value" :title="selectedOption ? name : null">
        {{ selectedOption ? selectedOption.name : name }}
      </div>
      <div v-if="!multiselect && valueRange && savedRange" class="chip__value" :title="selectedOption ? name : null">
        {{ `${savedRange.from || 0}&mdash;${savedRange.to || 0}` }}
      </div>
      <div v-if="multiselect && !hasSelectedValues" class="chip__value" :title="null">
        {{ name }}
      </div>
      <div v-if="multiselect && hasSelectedValues" class="chip__value" :title="null">
        <span v-if="showLabel" class="chip__value-label">{{ `${name}: ` }}</span>
        {{ `Выбрано · ${internalValue ? internalValue.length : 0}` }}
      </div>
      <div class="chip__close chip__icon" v-show="!multiselect ? selectedOption : hasSelectedValues" @click.stop="onClear">
        <Icon name="close-circle-s" size="m"></Icon>
      </div>
      <div class="chip__caret chip__icon" v-show="!selectedOption && !hasSelectedValues">
        <Icon name="down" size="m"></Icon>
      </div>
    </button>
    <Dropdown
      ref="dropdown"
      trigger="button"
      class="chip__dropdown"
      :class="{
        'chip__dropdown_searchable': searchable,
        'chip__dropdown_confirmable': confirmable,
      }"
      :style="{ position: 'fixed' }"
      hide-overlay
      auto-width
      @toggle="onReset"
    >
      <template v-slot:items>
        <div @click.stop="">
          <div
            @click.stop=""
          >
            <div class="chip__search" v-if="searchable">
              <Input
                ref="search"
                v-model.trim="search"
                icon-left="search"
                placeholder="Поиск..."
                no-border
                clearable
              ></Input>
            </div>
            <div class="chip__tip" v-if="!options.length">
              Загрузка...
            </div>
            <div class="chip__tip" v-else v-show="search && !filteredOptions.length">
              Ничего не найдено
            </div>
            <div class="chip-options" ref="chipOptions" v-if="!multiselect && !valueRange">
              <FilterItem
                v-for="option in filteredOptions"
                :key="option.id"
                v-model="internalValue"
                :text="option.name"
                :value="option.id"
                type="radio"
                @change.native="$emit('change', internalValue);"
              ></FilterItem>
            </div>
            <div class="chip-options" ref="chipOptions" v-if="multiselect && !valueRange">
              <FilterItem
                v-for="(option, i) in filteredOptions"
                :key="option.id"
                :inputModel="multiValues[i]"
                :text="option.name"
                :value="i"
                type="checkbox"
                @change.native="onValueChanged"
              ></FilterItem>
            </div>
            <div class="chip-options chip-options_range" ref="chipOptions" v-if="valueRange">
              <ValueRange
                :min="sliderMin"
                :max="sliderMax"
                :valueFrom="sliderValueFrom"
                :valueTo="sliderValueTo"
                @change="onSliderValueChange"
              />
            </div>
          </div>
          <div class="divider" v-if="confirmable"></div>
          <div class="chip-confirm" v-if="confirmable">
            <Button
              variant="secondary"
              size="md"
              text="Сбросить"
              mobile
              @click="onResetOptions"
            ></Button>
            <Button
              variant="primary"
              size="md"
              text="Применить"
              mobile
              @click="onApplyOptions"
            ></Button>
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import Vue from 'vue';
import Input from '@rr-component-library/input/src/main';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import FilterItem from './FilterItem.vue';
import ValueRange from './ValueRange.vue';

export default {
  name: 'Chip',
  components: {
    Input,
    FilterItem,
    Dropdown,
    ValueRange,
  },
  model: {
    prop: 'value',
  },
  props: {
    value: {
      default: '',
    },
    name: String,
    options: Array,
    icon: String,
    multiselect: Boolean,
    searchable: Boolean,
    confirmable: Boolean,
    valueRange: Boolean,
    showLabel: Boolean,
  },
  data() {
    return {
      search: null,
      multiValues: [],
      sliderMin: 1,
      sliderMax: 999999,
      sliderValueFrom: 1,
      sliderValueTo: 100,
      savedRange: null,
      optionsMaxHeightMobile: 114,
      optionsMaxHeightDesktop: 192,
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    selectedOption() {
      return !this.valueRange ? this.options.find((o) => o.id === this.internalValue) : this.savedRange !== null;
    },
    filteredOptions() {
      if (!this.search) {
        return this.options;
      }
      return this.options.filter((o) => o.name.toLowerCase().includes(this.search.toLowerCase()));
    },
    hasSelectedValues() {
      if (!this.multiselect) return false;
      return this.internalValue ? this.internalValue.length > 0 : false;
    },
  },
  watch: {
    internalValue(val) {
      if (this.valueRange) {
        if (val === null) {
          this.setSliderValues();
          this.savedRange = null;
        } else {
          this.parseSliderValues(val);
        }
      }
      this.$refs.dropdown.close();
    },
    search() {
      const values = new Array(this.filteredOptions.length);
      values.fill(false);
      this.multiValues = values;
    },
  },
  methods: {
    onClear() {
      this.internalValue = null;
      if (this.valueRange) {
        this.setSliderValues();
        this.savedRange = null;
      }
      this.$emit('change', null);
    },
    onReset(opened) {
      if (opened && this.searchable) {
        this.search = null;
        setTimeout(() => {
          this.$refs.search.$el.querySelector('input')
            .focus();
        }, 200);
      }
      if (opened && this.valueRange) {
        if (this.savedRange !== null) {
          this.sliderValueFrom = this.savedRange.from || this.filteredOptions[2].value;
          this.sliderValueTo = this.savedRange.to || this.filteredOptions[3].value;
        } else {
          this.setSliderValues();
        }
      }
      if (opened && this.multiselect) {
        this.multiValues = this.internalValue ? this.getSelectedValues(this.internalValue) : [];
      }
      this.updateVerticalScroll();
    },
    onValueChanged(event) {
      Vue.set(this.multiValues, event.target.value, !this.multiValues[event.target.value]);
    },
    onSliderValueChange({ index, value }) {
      if (index === 0) this.sliderValueFrom = value;
      if (index === 1) this.sliderValueTo = value;
    },
    onResetOptions() {
      if (this.valueRange) {
        this.setSliderValues();
        this.savedRange = null;
      }
      if (this.multiselect) {
        const values = new Array(this.multiValues.length);
        values.fill(false);
        this.multiValues = values;
      }
    },
    onApplyOptions() {
      if (this.valueRange) {
        this.internalValue = { from: this.sliderValueFrom, to: this.sliderValueTo };
        this.savedRange = { from: this.sliderValueFrom, to: this.sliderValueTo };
        this.$emit('rangeChange');
      }
      if (this.multiselect) {
        const values = this.getSelectedIds(this.multiValues);
        this.internalValue = values.length > 0 ? values : null;
        this.$emit('change');
        this.$refs.dropdown.close();
      }
    },
    setSliderValues() {
      this.sliderMin = this.filteredOptions[0].value;
      this.sliderMax = this.filteredOptions[1].value;
      this.sliderValueFrom = this.filteredOptions[2].value;
      this.sliderValueTo = this.filteredOptions[3].value;
    },
    parseSliderValues(val) {
      if (!val) return;
      if (typeof val !== 'object') return;
      this.sliderValueFrom = val.from || this.sliderMin;
      this.sliderValueTo = val.to || this.sliderMax;
      this.savedRange = { from: this.sliderValueFrom, to: this.sliderValueTo };
    },
    getSelectedIds(values) {
      const result = [];
      if (!values) return result;
      for (let i = 0; i < values.length; i += 1) {
        if (values[i] === true) {
          if (this.filteredOptions[i]) {
            result.push(this.filteredOptions[i].id);
          }
        }
      }
      return result;
    },
    getSelectedValues(ids) {
      const result = [];
      if (!ids) return result;
      for (let i = 0; i < this.filteredOptions.length; i += 1) {
        const item = ids.find((it) => it === this.filteredOptions[i].id);
        if (item) result.push(true);
        else result.push(false);
      }
      return result;
    },
    updateVerticalScroll() {
      const { chipOptions } = this.$refs;
      setTimeout(() => {
        if (!chipOptions) return;
        if (window.innerWidth < 768) {
          if (chipOptions.offsetHeight <= this.optionsMaxHeightMobile) {
            chipOptions.style = 'overflow-y: hidden;';
            return;
          }
          chipOptions.style = `max-height: ${this.optionsMaxHeightMobile}px;`;
        }
        if (window.innerWidth >= 768) {
          if (chipOptions.offsetHeight <= this.optionsMaxHeightDesktop) {
            chipOptions.style = 'overflow-y: hidden;';
            return;
          }
          chipOptions.style = `max-height: ${this.optionsMaxHeightDesktop}px;`;
        }
      }, 50);
    },
  },
  mounted() {
    if (!this.valueRange) {
      if (this.multiselect) {
        const values = this.getSelectedValues(this.internalValue);
        this.multiValues = values;
      } else {
        const values = new Array(this.filteredOptions.length);
        values.fill(false);
        this.multiValues = values;
      }
    } else {
      this.setSliderValues();
      if (typeof this.value === 'object') {
        this.parseSliderValues(this.value);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./Chip";
</style>

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Api from '@/services/geoactivities-api';

const state = {
  geoLocationStatus: null,
};

const mutations = {
  setGeoLocationStatus(state, value) {
    state.geoLocationStatus = value;
  },
};

const getters = {
  geoLocationStatus: (state) => state.geoLocationStatus,
};

const actions = {
  createGeoActivity({ commit }, {
    activityId, participantExerciseTaskId, latitude, longitude,
  }) {
    return Api.createGeoActivity({
      activityId, participantExerciseTaskId, latitude, longitude,
    })
      .then((response) => {
        commit('setGeoLocationStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setGeoLocationStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

<template>
  <div class="distance-charity" :class="{'distance-charity_charity': type === 'charity'}">
    <Modal
      :active="active"
      name="DistanceCharityModal"
      :title="replaceVariables(getTitle())"
      :subtitle="replaceVariables(getSubtitle())"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="$emit('close')"
    >
      <div class="distance-charity__container">
        <div class="distance-charity__content text-left">
          <Goal
            v-if="type === 'distance'"
            :formatTotalKm="formatTotalKm"
            :formatCurrentKm="formatCurrentKm"
            :totalKm="goal ? goal.necessaryDistance : 0"
            :currentKm="goal ? goal.completedDistance : 0"
            :localizedText="{
              distance: 'вместе преодолено', total: 'Вместе преодолеть:'
            }"
            fullWidth
            class="distance-charity__goal"
          ></Goal>
          <div v-if="type === 'distance'">
            <div
            v-for="(item, i) in completedGoals"
            :key="i"
            class="distance-charity__completed text-center"
          >
            <div class="distance-charity__status"><Icon name="check-bold" size="l" /></div>
            <div class="distance-charity__name">{{ item.necessaryDistance ? item.necessaryDistance.toLocaleString() : 0 }} км вместе преодолено</div>
            <div class="distance-charity__date">{{ formatDate(item.completedOn) }}</div>
          </div>
          </div>
          <div v-if="type === 'charity'" class="distance-charity__goal">
            <div class="h3">
              <span class="color-success" v-html="goal ? `${goal.completedAmount} ₽` : '0 ₽'"></span> собрано во благо
            </div>
          </div>
          <div v-if="false && type === 'charity'" class="distance-charity__action">
            <Link variant="secondary" tag="a" :href="moreLink" target="_blank">
              <Button
                variant="secondary"
                size="lg"
                mobile
              >
                Узнать больше
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import Goal from '@sc-component-library/goal/src/main';
import DistanceImage from '@/assets/img/distance-charity-modal/distance.svg';
import CharityImage from '@/assets/img/distance-charity-modal/charity.svg';
import { formatNumber } from '@/assets/js/helpers';

export default {
  name: 'DistanceCharityModal',
  components: {
    Goal,
  },
  props: {
    active: Boolean,
    type: String,
    localizedText: Object,
    moreLink: String,
    isMobileApp: Boolean,
  },
  data() {
    return {
      distanceImage: DistanceImage,
      charityImage: CharityImage,
    };
  },
  computed: {
    ...mapGetters('goals', ['goal']),
    completedGoals() {
      let completedGoals = this.goal ? this.goal.completedGoals : [];
      completedGoals = completedGoals.reverse();
      return completedGoals;
    },
    formatTotalKm() {
      return this.goal ? formatNumber(this.goal.necessaryDistance, 'км') : '0 км';
    },
    formatCurrentKm() {
      return this.goal ? formatNumber(this.goal.completedDistance, 'км') : '0 км';
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      const value = dayjs.utc(date).local();
      if (value.isValid()) {
        return value.format('DD.MM.YYYY');
      }
      return '';
    },
    getTitle() {
      if (this.type === 'distance') {
        return this.goal ? this.goal.title : '';
      } return this.goal ? this.goal.costTitle : '';
    },
    getSubtitle() {
      if (this.type === 'distance') {
        return this.goal ? this.goal.description : '';
      } return this.goal ? this.goal.costDescription : '';
    },
    replaceVariables(text) {
      if (!text) return '';
      const vars = [
        { id: 'totalKm', value: this.formatTotalKm },
      ];
      let result = text;
      for (let i = 0; i < vars.length; i += 1) {
        result = result.replaceAll(`{{${vars[i].id}}}`, vars[i].value);
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import './DistanceCharityModal';
</style>

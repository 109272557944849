import api from './api';

export default {
  getActivityRegistration({ participantExerciseTaskId }) {
    return api.get(`/partners/api/v1/activities/registration/${participantExerciseTaskId}`);
  },
  inviteActivity({ activityId, participantExerciseTaskId, participantId }) {
    return api.post('/invites/api/v1/activities', {
      activityId,
      participantExerciseTaskId,
      participantId,
    });
  },
  createActivity({ activityId, exerciseTaskId }) {
    return api.post('/partners/api/v1/activities/partnerlink', {
      activityId,
      exerciseTaskId,
    });
  },
  createParticipatingActivities({ activityId, exerciseTaskId, sessionId }) {
    return api.post('/partners/api/v1/activities/participating', {
      activityId,
      exerciseTaskId,
      sessionId,
    });
  },
  createLinkActivity({ activityId, exerciseTaskId }) {
    return api.post('/partners/api/v1/activities/partnerlink', {
      activityId,
      exerciseTaskId,
    });
  },
  createRegistrationActivity({
    activityId, participantExerciseTaskId, sessionId, raceId,
  }) {
    return api.post('/partners/api/v1/activities/registration', {
      activityId,
      participantExerciseTaskId,
      sessionId,
      raceId,
    });
  },
  createUploadActivity({ activityId, exerciseTaskId, file }) {
    const form = new FormData();
    form.append('activityId', activityId);
    form.append('exerciseTaskId', exerciseTaskId);
    if (file) form.append('file', file, file.name.toLowerCase());

    return api.post('/scount-uploadimageactivitiestaskmanager/api/v1/activities/uploadimage', form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  },
};

<template>
  <Modal
    name="connection-guide"
    :active="shown"
    v-bind="settings"
    @close.prevent="$emit('close')"
  >
    <div class="text-center">
      <!-- STEP 1 -->
      <div v-if="step === 1">
        <div class="images">
          <img :src="onlinePersonalTrainerRafiki"/>
        </div>
        <div class="guide__title">Вы на правильном пути!</div>
        <div class="guide__subtitle">Вместе настроим трекер на смартфоне и посмотрим, как правильно записывать тренировки для успешного выполнения спортивных заданий.</div>
        <div class="note">
          <div class="note__item"><Icon name="smartphone" size="m" />Любой смартфон</div>
          <div class="note__item"><Icon name="timer" size="m" />Менее 5 минут</div>
        </div>
      </div>
      <!-- STEP 2 -->
      <div v-if="step === 2 && !loading">
        <div class="guide__title guide__title_steps">Установите трекер «SimpleRun» на смартфон и войдите по коду</div>
        <div v-if="!desktop" class="guide__subtitle guide__subtitle_steps"><b>Шаг 1.</b> Скопируйте этот <b>код</b> и используйте его для входа в приложении
          <div class="guide__passcode">
            <span>{{ passCode || '' }}</span>
            <span @click.stop="onCopyCode"><Icon name="copy" @click="onCopyCode" /></span>
          </div>
        </div>
        <div v-if="!desktop" class="guide__subtitle guide__subtitle_steps">
          <b>Шаг 2.</b> Установите приложение Simple Run
          <div class="guide__tracker-img"><img :src="simpleRunImg" /></div>
          <div v-if="desktop" class="guide__platform-link">
            <a :href="appiOS" target="_blank"><img :src="appStoreImg"/></a>
            <a :href="appAndroid" target="_blank"><img :src="googlePlayImg"/></a>
          </div>
          <div v-if="android || iOS" class="guide__platform-link">
            <a :href="android ? appAndroid : appiOS" target="_blank"><img :src="android ? googlePlayImg : appStoreImg"/></a>
          </div>
        </div>
        <div v-if="!desktop" class="guide__subtitle guide__subtitle_steps">
          <b>Шаг 3.</b> Откройте приложение и войдите используя <b>код</b>
          <div class="guide__step-three"><img :src="stepThreeImg" /></div>
        </div>
        <div v-if="desktop" class="guide__subtitle guide__subtitle_steps">
          <div class="text-left"><b>Шаг 1.</b> Установите приложение Simple Run</div>
          <div class="guide__tracker-img"><img :src="simpleRunImg" /></div>
          <div v-if="desktop" class="guide__platform-link">
            <a :href="appiOS" target="_blank"><img :src="appStoreImg"/></a>
            <a :href="appAndroid" target="_blank"><img :src="googlePlayImg"/></a>
          </div>
          <div v-if="android || iOS" class="guide__platform-link">
            <a :href="android ? appAndroid : appiOS" target="_blank"><img :src="android ? googlePlayImg : appStoreImg"/></a>
          </div>
        </div>
        <div v-if="desktop" class="guide__subtitle guide__subtitle_steps">
          <div class="text-left"><b>Шаг 2.</b> Скопируйте этот код и используйте его для входа в приложении</div>
          <div class="guide__passcode">
            <span>{{ passCode || '' }}</span>
            <span @click.stop="onCopyCode"><Icon name="copy" /></span>
          </div>
          <div class="guide__step-three"><img :src="stepThreeImg" /></div>
        </div>
      </div>
      <div class="next" :class="{ 'next_steps': step === 2 }">
        <Button
          text="Продолжить"
          variant="secondary"
          size="lg"
          :wide="$root.isMobile"
          mobile
          :disabled="loading"
          :loading="loading"
          @click="onNext"
        ></Button>
      </div>
      <div class="dots">
        <div class="dots__item" :class="{ 'dots__item_active': step === 1 }"></div>
        <div class="dots__item" :class="{ 'dots__item_active': step === 2 }"></div>
      </div>
    </div>

    <div class="sneak-bar" v-if="showCopied">
      <Alert
        text="Код скопирован."
        actionText="Понятно"
        state="success"
        closable
        autoClose
        @close="showCopied = false"
      ></Alert>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Alert from '@rr-component-library/alert/src/main';
import SimpleRunImg from '@rr-component-library/common/src/img/apps/simple-run-app.png';
import StepThreeImg from '@/assets/img/connection-guide/step3.png';
import GooglePlayImg from '@/assets/img/connection-guide/google-play.svg';
import AppStoreImg from '@/assets/img/connection-guide/app-store.svg';
import Trackers from '@/assets/data/trackers';

const { v4: uuidv4 } = require('uuid');

export default {
  name: 'ConnectionGuide',
  components: {
    Alert,
  },
  props: {
    shown: Boolean,
    settings: Object,
    backToPath: String,
  },
  data() {
    return {
      loading: false,
      step: 1,
      trackerId: null,
      android: false,
      iOS: false,
      desktop: false,
      simpleRunImg: SimpleRunImg,
      stepThreeImg: StepThreeImg,
      googlePlayImg: GooglePlayImg,
      appStoreImg: AppStoreImg,
      appiOS: '',
      appAndroid: '',
      showCopied: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['resources']),
    ...mapGetters('trackers', ['connectUrl', 'passCode', 'errorCode', 'responseStatus', 'tracker']),
    onlinePersonalTrainerRafiki() {
      return this.resources ? this.resources.onlinePersonalTrainerRafiki || '' : '';
    },
  },
  watch: {
    shown(val) {
      if (val) {
        this.detectOS();
        this.updateURL();
        this.loading = false;
      } else {
        window.location.hash = '';
        setTimeout(() => { this.step = 1; }, 1000);
      }
    },
  },
  methods: {
    ...mapActions('trackers', ['createTracker', 'authTracker', 'getTracker']),
    detectOS() {
      if (this.$root.isDesktop) {
        this.desktop = true;
      } else {
        const { platform } = window.navigator;
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        if (iosPlatforms.indexOf(platform) !== -1) {
          this.iOS = true;
          return;
        }
        this.android = true;
      }
    },
    updateURL() {
      window.location.hash = `#!showConnectionGuide&step=${this.step}`;
    },
    setLinks() {
      const found = Trackers.filter((i) => i.id === 'simplerun');
      if (found.length > 0) {
        this.appiOS = found[0].ios || '';
        this.appAndroid = found[0].android || '';
      }
    },
    checkTracker() {
      if (!this.trackerId) return;
      this.getTracker(this.trackerId)
        .then(() => {
          if (this.responseStatus === 200) {
            if (this.tracker && this.tracker.state !== 'Connected') {
              setTimeout(() => {
                if (this.shown) this.checkTracker();
              }, 2000);
            } else if (this.tracker && this.tracker.state === 'Connected') {
              window.location.href = `${this.backToPath ? this.backToPath : window.location.origin}/tracker?connect=${this.trackerId}&from=guideSimpleRun`;
            }
          }
        });
    },
    onNext() {
      const step = this.step + 1;
      if (step === 2) {
        this.onConnect();
      } else if (step > 2) {
        this.$emit('close');
        return;
      }
      this.step = step;
      this.updateURL();
    },
    onConnect() {
      this.loading = true;
      const trackerId = uuidv4();
      this.createTracker({
        provider: 'SimpleRun',
        trackerId,
        redirectUrl: `${this.backToPath ? this.backToPath : window.location.origin}/tracker?connect=${trackerId}&from=guideSimpleRun`,
        parameters: {
          authorizationType: 'Simple',
        },
      })
        .then(() => {
          if (this.responseStatus === 200 || this.responseStatus === 204) {
            this.authTracker({
              trackerId,
              parameters: {
                AuthorizationType: 'Simple',
              },
            })
              .then(() => {
                this.trackerId = trackerId;
                if (this.responseStatus === 200 || this.responseStatus === 204) {
                  localStorage.removeItem('returnFromGuide');
                  this.loading = false;
                  this.checkTracker();
                } else if (this.responseStatus === 409) {
                  if (this.errorCode === 'normal_authorization_is_required' && this.connectUrl) {
                    window.location.href = this.connectUrl;
                  }
                } else {
                  this.trackerId = null;
                  this.$emit('close');
                }
              });
          } else {
            this.trackerId = null;
            this.$emit('close');
          }
        });
    },
    onCopyCode() {
      navigator.clipboard.writeText(this.passCode);
      if (this.showCopied) this.showCopied = false;
      setTimeout(() => { this.showCopied = true; }, 500);
    },
  },
  mounted() {
    this.setLinks();
  },
};
</script>

<style lang="scss" scoped>
  @import './ConnectionGuide';
</style>

<template>
  <Modal
    name="connection-guide"
    :active="shown"
    v-bind="settings"
    @close.prevent="$emit('close')"
  >
    <div class="text-center">
      <!-- STEP 1 -->
      <div v-if="step === 1">
        <div class="images">
          <img :src="onlinePersonalTrainerRafiki"/>
        </div>
        <div class="guide__title">Вы на правильном пути!</div>
        <div class="guide__subtitle" v-html="guideSubtitle"></div>
        <div class="note">
          <div class="note__item"><Icon name="smartphone" size="m" />Любой смартфон</div>
          <div class="note__item"><Icon name="timer" size="m" />Менее 5 минут</div>
        </div>
      </div>
      <!-- STEP 2 -->

      <div v-if="step === 2">
        <Loader size="24" v-if="loading"/>
        <div v-else>
          <img :src="scountImg"/>
          <div class="guide__title guide__title_steps" v-html="guideTitleSteps"></div>
            <div class="guide__subtitle guide__subtitle_steps">
              <b>Шаг 1.</b> Установите приложение
              <div class="guide__platform-link">
                <a v-if="OS === 'unknown'" href="https://apps.apple.com/ru/app/%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82/id6504521298" target="_blank"><img :src="appStoreImg"/></a>
                <a v-if="OS === 'unknown'" href="https://www.rustore.ru/catalog/app/pro.scount.app" target="_blank"><img :src="rustoreImg"/></a>
                <a v-if="OS === 'iOS'" href="https://apps.apple.com/ru/app/%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82/id6504521298" target="_blank"><img :src="appStoreImg"/></a>
                <a v-if="OS === 'Android'" href="https://www.rustore.ru/catalog/app/pro.scount.app" target="_blank"><img :src="rustoreImg"/></a>
              </div>
            </div>
            <div class="guide__subtitle guide__subtitle_steps">
              <b>Шаг 2.</b> Скопируйте этот код и используйте его для входа в приложении
              <div class="guide__passcode guide__passcode_color">
                <span>{{ code ? code.code : '' }}</span>
                <span @click.stop="onCopyCode"><Icon name="copy" /></span>
              </div>
            </div>
            <div class="guide__subtitle guide__subtitle_steps">
              <b>Шаг 3.</b> Разрешите приложению доступ к данным физической активности
            </div>
            <div v-if="desktop">
            </div>
              <span v-if="OS === 'unknown'"><img :src="healthConnectImg"/></span>
              <span v-if="OS === 'unknown'"><img :src="healthImg"/></span>
              <span v-if="OS === 'Android'"><img :src="healthConnectImg"/></span>
              <span v-if="OS === 'iOS'"><img :src="healthImg"/></span>
          </div>
        </div>
        <div class="next" :class="{ 'next_steps': step === 2 }">
          <Button
            v-if="step === 1 && showGuideSimpleRun"
            text="Продолжить"
            variant="secondary"
            size="lg"
            :wide="$root.isMobile"
            mobile
            :disabled="loading"
            :loading="loading"
            @click="onNext"
          ></Button>
        </div>
      <div class="dots">
        <div class="dots__item" :class="{ 'dots__item_active': step === 1 }"></div>
        <div class="dots__item" :class="{ 'dots__item_active': step === 2 }"></div>
      </div>
    </div>

    <div class="sneak-bar" v-if="showCopied">
      <Alert
        text="Код скопирован."
        actionText="Понятно"
        state="success"
        closable
        autoClose
        @close="showCopied = false"
      ></Alert>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Alert from '@rr-component-library/alert/src/main';
import SimpleRunImg from '@rr-component-library/common/src/img/apps/simple-run-app.png';
import StepThreeImg from '@/assets/img/connection-guide/step3.png';
import GooglePlayImg from '@/assets/img/connection-guide/google-play.svg';
import AppStoreImg from '@/assets/img/connection-guide/app-store.svg';
import RuStoreImg from '@/assets/img/connection-guide/rustore.svg';
import HealthImg from '@/assets/img/connection-guide/health.svg';
import HealthConnectImg from '@/assets/img/connection-guide/health-connect-app.svg';
import ScountImgMiddle from '@/assets/img/scount-middle.svg';
import Trackers from '@/assets/data/trackers';
import { addNonBreakingSpaces } from '@/assets/js/helpers';

// const { v4: uuidv4 } = require('uuid');

export default {
  name: 'ConnectionGuide',
  components: {
    Alert,
  },
  props: {
    shown: Boolean,
    settings: Object,
    backToPath: String,
  },
  data() {
    return {
      loading: false,
      step: 1,
      trackerId: null,
      android: false,
      iOS: false,
      desktop: false,
      simpleRunImg: SimpleRunImg,
      stepThreeImg: StepThreeImg,
      googlePlayImg: GooglePlayImg,
      appStoreImg: AppStoreImg,
      rustoreImg: RuStoreImg,
      healthImg: HealthImg,
      healthConnectImg: HealthConnectImg,
      scountImg: ScountImgMiddle,
      appiOS: '',
      appAndroid: '',
      showCopied: false,
    };
  },
  computed: {
    ...mapGetters('user', ['code', 'profile']),
    ...mapGetters('settings', ['resources', 'options']),
    ...mapGetters('trackers', ['connectUrl', 'passCode', 'errorCode', 'responseStatus', 'tracker']),
    showGuideSimpleRun() {
      return this.options ? this.options.showGuideSimpleRun === true : false;
    },
    onlinePersonalTrainerRafiki() {
      return this.resources ? this.resources.onlinePersonalTrainerRafiki || '' : '';
    },
    guideTitleSteps() {
      return addNonBreakingSpaces('Установите трекер СКАУНТ на смартфон и войдите по коду');
    },
    guideSubtitle() {
      return addNonBreakingSpaces('Вместе настроим трекер на смартфоне и посмотрим, как правильно записывать тренировки для успешного выполнения спортивных заданий.');
    },
    OS() {
      const userAgent = navigator.userAgent || navigator.vendor || (window).opera;
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }
      return 'unknown';
    },
  },
  watch: {
    shown(val) {
      if (val) {
        this.detectOS();
        this.updateURL();
        this.loading = false;
      } else {
        window.location.hash = '';
        setTimeout(() => { this.step = 1; }, 1000);
      }
    },
  },
  methods: {
    ...mapActions('trackers', ['createTracker', 'authTracker', 'getTracker']),
    ...mapActions('user', ['getCode']),
    detectOS() {
      if (this.$root.isDesktop) {
        this.desktop = true;
      } else {
        const { platform } = window.navigator;
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        if (iosPlatforms.indexOf(platform) !== -1) {
          this.iOS = true;
          return;
        }
        this.android = true;
      }
    },
    updateURL() {
      window.location.hash = `#!showConnectionGuide&step=${this.step}`;
    },
    setLinks() {
      const found = Trackers.filter((i) => i.id === 'simplerun');
      if (found.length > 0) {
        this.appiOS = found[0].ios || '';
        this.appAndroid = found[0].android || '';
      }
    },
    checkTracker() {
      if (!this.trackerId) return;
      this.getTracker(this.trackerId)
        .then(() => {
          if (this.responseStatus === 200) {
            if (this.tracker && this.tracker.state !== 'Connected') {
              setTimeout(() => {
                if (this.shown) this.checkTracker();
              }, 2000);
            } else if (this.tracker && this.tracker.state === 'Connected') {
              window.location.href = `${this.backToPath ? this.backToPath : window.location.origin}/tracker?connect=${this.trackerId}&from=guideSimpleRun`;
            }
          }
        });
    },
    onNext() {
      const step = this.step + 1;
      if (step === 2) {
        if (this.profile) {
          this.getCode(this.profile.marketingProgramId)
            .then(() => {
              if (this.responseStatus === 200) {
                this.loading = false;
              }
            });
        }
        // this.onConnect();
      } else if (step > 2) {
        this.$emit('close');
        return;
      }
      this.step = step;
      this.updateURL();
    },
    // onConnect() {
    //   this.loading = true;
    //   const trackerId = uuidv4();
    //   this.createTracker({
    //     provider: 'SimpleRun',
    //     trackerId,
    //     redirectUrl: `${this.backToPath ? this.backToPath : window.location.origin}/tracker?connect=${trackerId}&from=guideSimpleRun`,
    //     parameters: {
    //       authorizationType: 'Simple',
    //     },
    //   })
    //     .then(() => {
    //       if (this.responseStatus === 200 || this.responseStatus === 204) {
    //         this.authTracker({
    //           trackerId,
    //           parameters: {
    //             AuthorizationType: 'Simple',
    //           },
    //         })
    //           .then(() => {
    //             this.trackerId = trackerId;
    //             if (this.responseStatus === 200 || this.responseStatus === 204) {
    //               localStorage.removeItem('returnFromGuide');
    //               this.loading = false;
    //               this.checkTracker();
    //             } else if (this.responseStatus === 409) {
    //               if (this.errorCode === 'normal_authorization_is_required' && this.connectUrl) {
    //                 window.location.href = this.connectUrl;
    //               }
    //             } else {
    //               this.trackerId = null;
    //               this.$emit('close');
    //             }
    //           });
    //       } else {
    //         this.trackerId = null;
    //         this.$emit('close');F
    //       }
    //     });
    // },
    onCopyCode() {
      navigator.clipboard.writeText(this.code.code);
      if (this.showCopied) this.showCopied = false;
      setTimeout(() => { this.showCopied = true; }, 500);
    },
  },
  mounted() {
    this.setLinks();
  },
};
</script>

<style lang="scss" scoped>
  @import './ConnectionGuide';
</style>

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import achievementsApi from '@/services/achievements-api';

const state = {
  achievements: null,
  responseStatus: null,
};

const mutations = {
  setAchievements(state, value) {
    state.achievements = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
};

const getters = {
  achievements: (state) => state.achievements,
  responseStatus: (state) => state.responseStatus,
};

const actions = {
  getAchievements({ commit }) {
    return achievementsApi.getAchievements()
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setAchievements', response.data);
        } else {
          commit('setAchievements', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

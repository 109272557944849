import api from './api';

export default {
  getNominationsGuest({ state, offset, count }) {
    return api.get('/ratings/api/v1/nominations', {
      params: {
        state,
        offset,
        count,
      },
    });
  },
  getNominations({ state, offset, count }) {
    return api.get('/ratings/api/v1/nominations/participant', {
      params: {
        state,
        offset,
        count,
      },
    });
  },
  getTeamNominations({ state, offset, count }) {
    return api.get('/ratings/api/v1/nominations/team-nominations', {
      params: {
        state,
        offset,
        count,
      },
    });
  },
  getNominationResults({
    nominationId, teamId, offset, count,
  }) {
    return api.get(`/ratings/api/v1/nominations/${nominationId}/results`, {
      params: {
        teamId,
        offset,
        count,
      },
    });
  },
  getTeamNominationResults({ nominationId, offset, count }) {
    return api.get(`/ratings/api/v1/nominations/${nominationId}/team-results-for-nomination`, {
      params: {
        offset,
        count,
      },
    });
  },
  getTeamMembersResults({ teamId, offset, count }) {
    return api.get(`/ratings/api/v1/nominations/${teamId}/participant-results-for-team`, {
      params: {
        offset,
        count,
      },
    });
  },
  getRatings({ filter, aggregateField }) {
    const payload = {
      filter,
      aggregateField,
    };
    if (!payload.filter) delete payload.filter;
    return api.post('/ratings/api/v1/ratings', payload);
  },
};

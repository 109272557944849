<template>
  <div class="progressbar">
    <svg
      class="progressbar__svg"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
    >
      <circle
        class="progressbar__background"
        cx="12"
        cy="12"
        r="12"
        fill="#E5E7EB"
      />
      <circle
        class="progressbar__progress"
        cx="12"
        cy="12"
        r="9"
        fill="white"
      />
      <circle
        class=""
        cx="12"
        cy="12"
        r="10.5"
        :stroke="fillColor"
        :stroke-width="3"
        :stroke-dasharray="dashArray"
        :stroke-dashoffset="dashOffset"
        transform="rotate(-90 12 12)"
        fill="transparent"
      />

      <path
        class="progressbar__checkmark"
        d="M8.5 12.5L11 15L15.5 10"
        fill="transparent"
        :stroke="checklColor"
        :stroke-width="strokeWidth"
      />
    </svg>
  </div>
</template>

<script>
const colors = {
  success: '#51C41A',
  warning: '#FAAD14',
  danger: '#ED1C24',
};

export default {
  props: {
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    size: {
      type: Number,
      default: 24,
    },
  },
  computed: {
    dashArray() {
      const circumference = 2 * Math.PI * 10.5;
      return `${circumference}`;
    },
    dashOffset() {
      const circumference = 2 * Math.PI * 10.5;
      const percentage = Math.min(150, Math.max(0, this.value)) / 150;
      return circumference * (1 - percentage);
    },
    strokeWidth() {
      return this.size / 10;
    },
    checklColor() {
      if (this.value >= 150) {
        return colors.success;
      }
      return 'rgba(0,0,0, 0.09)';
    },
    fillColor() {
      if (this.value >= 100) {
        return colors.success;
      }
      if (this.value >= 50) {
        return colors.warning;
      }
      if (this.value > 0) {
        return colors.danger;
      }
      return 'rgba(0,0,0, 0.09)';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./ProgressBarCircle";
</style>

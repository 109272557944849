<template>
  <div class="nominations">
    <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="!isMobileApp" class="nominations__menu">
      <Menu />
      <div class="divider"></div>
    </div>
    <div v-if="isTeamsEnabled && showTeamRatings" class="nominations__types">
      <Button
        v-for="(item, i) in types"
        :key="i"
        :variant="i === selectedType ? 'primary' : 'secondary'"
        :disabled="isRatingTypeDisabled(i)"
        size="md"
        mobile
        @click="changeType(i)"
      >
        {{ item }}
      </Button>
    </div>
    <div class="nominations__tabs">
      <Tabs :items="getTabs()" :activeItem="selectedTab" @changed="changeTab" />
    </div>
    <div class="nominations__items">
      <!-- PERSONAL RATING -->
      <div v-show="selectedType === 0 && selectedTab === 0">
        <NominationCard
          v-for="(item, i) in itemsActive"
          :key="i"
          :ratingText="ratingText"
          :lockedText="lockedText"
          type="personal"
          state="in-progress"
          :image="getNominationImage(item)"
          :nominationText="getNominationDates(item)"
          :title="item.title"
          :values="getNominationRating(item)"
          @show-details="onShowRating(item)"
        ></NominationCard>
      </div>
      <div v-show="selectedType === 0 && selectedTab === 1">
        <NominationCard
          v-for="(item, i) in itemsAvailable"
          :key="i"
          :ratingText="ratingText"
          :lockedText="lockedText"
          type="personal"
          state="in-progress"
          :image="getNominationImage(item)"
          :nominationText="getNominationDates(item)"
          :rules="{ text: 'Правила', action: () => onShowRules(item) }"
          :prizes="{ text: 'Награды', action: () => onShowPrizes(item) }"
          :title="item.title"
          :values="getNominationRating(item)"
          @show-details="onShowRating(item)"
        ></NominationCard>
      </div>
      <div v-show="selectedType === 0 && selectedTab === 2">
        <NominationCard
          v-for="(item, i) in itemsCompleted"
          :key="i"
          :ratingText="ratingText"
          :lockedText="lockedText"
          :resultText="resultText"
          type="personal"
          state="result"
          :image="getNominationImage(item)"
          :nominationText="getNominationDates(item)"
          :title="item.title"
          :resultValues="getNominationResult(item)"
          @show-details="onShowRating(item)"
        ></NominationCard>
      </div>
      <!-- TEAM RATING -->
      <div v-show="selectedType === 1 && selectedTab === 0">
        <NominationCard
          v-for="(item, i) in itemsTeamActive"
          :key="i"
          :ratingText="teamRatingText"
          :lockedText="lockedText"
          type="personal"
          state="in-progress"
          class="team-rating"
          :nominationText="getNominationDates(item)"
          :title="item.title"
          :values="getNominationRating(item, true)"
          @show-details="onShowRating(item, true)"
        ></NominationCard>
      </div>
      <div v-show="selectedType === 1 && selectedTab === 2">
        <NominationCard
          v-for="(item, i) in itemsTeamCompleted"
          :key="i"
          :ratingText="teamRatingText"
          :lockedText="lockedText"
          :resultText="resultText"
          type="personal"
          state="result"
          class="team-rating"
          :nominationText="getNominationDates(item)"
          :title="item.title"
          :resultValues="getNominationResult(item, true)"
          @show-details="onShowRating(item, true)"
        ></NominationCard>
      </div>
      <div class="nominations__loader" v-if="updating">
        <Loader size="24" />
      </div>
      <div class="nominations__loader-block"></div>
    </div>

    <RulesPrizesModal
      :active="rulesPrizesModal"
      :image="rulesPrizesImage"
      :title="rulesPrizesTitle"
      :subtitle="rulesPrizesSubtitle"
      :nominationId="rulesPrizesNominationId"
      :isTeam="rulesPrizesIsTeam"
      state="personal"
      :tabs="rulesPrizesTabs"
      :activeTab="rulesPrizesTabActive"
      :isMobileApp="isMobileApp"
      @close="onRulesPrizesClosed"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import NominationCard from '@sc-component-library/nomination-card/src/main';
import Menu from '@/components/Menu/Menu.vue';
import RulesPrizesModal from '@/components/RulesPrizesModal/RulesPrizesModal.vue';
import { formatDateRange } from '@/assets/js/helpers';

export default {
  name: 'Nominations',
  components: {
    NominationCard,
    Menu,
    RulesPrizesModal,
  },
  data() {
    return {
      types: ['Личный', 'Командный'],
      selectedType: null,
      selectedTab: 0,
      ratingText: { general: 'Текущий рейтинг', personal: 'Вы в рейтинге' },
      teamRatingText: { general: 'Текущий рейтинг', personal: 'Команда в рейтинге' },
      lockedText: { text: 'Для участия необходимо', link: 'подключить трекер' },
      selectTaskText: 'Начните одно из заданий',
      resultText: { rating: 'Итоговый результат', reward: 'Ваша награда' },
      itemsActive: [],
      itemsAvailable: [],
      itemsCompleted: [],
      itemsTeamActive: [],
      itemsTeamCompleted: [],
      offsetActive: 0,
      offsetCompleted: 0,
      offsetTeamActive: 0,
      offsetTeamCompleted: 0,
      totalActive: 0,
      rulesPrizesModal: false,
      rulesPrizesTitle: '',
      rulesPrizesSubtitle: '',
      rulesPrizesNominationId: '',
      rulesPrizesIsTeam: false,
      rulesPrizesImage: '',
      rulesPrizesTabs: [
        {
          title: 'Правила',
          enabled: true,
          content: '',
        },
        {
          title: 'Награды',
          enabled: true,
          content: '',
        },
        {
          title: 'Рейтинг',
          enabled: true,
        },
      ],
      rulesPrizesTabActive: 0,
      isTrackerConnected: false,
      updating: false,
      loaderBlock: null,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeDone', 'options']),
    ...mapGetters('user', ['profile', 'isMobileApp']),
    ...mapGetters('trackers', ['trackers']),
    ...mapGetters('nominations', ['nominationsActive', 'nominationsCompleted', 'teamNominationsActive', 'teamNominationsCompleted', 'nominationResults', 'responseStatus']),
    routeName() {
      return this.$route.name;
    },
    isTeamSet() {
      return this.profile ? this.profile.team !== undefined && this.profile.team.teamId !== undefined : false;
    },
    isTeamsEnabled() {
      return this.options ? this.options.isTeamsEnabled === true : false;
    },
    showTeamRatings() {
      return this.options ? this.options.showTeamRatings === true : false;
    },
    disableTeamsForGuest() {
      return this.options ? this.options.disableTeamsForGuest === true : false;
    },
    isAllNominationsLoaded() {
      if (!this.themeDone) return false;
      if (this.isTeamsEnabled && this.showTeamRatings) {
        return this.teamNominationsActive !== null && this.teamNominationsCompleted !== null
               && this.nominationsActive !== null && this.nominationsCompleted !== null;
      }
      return this.nominationsActive !== null && this.nominationsCompleted !== null;
    },
  },
  watch: {
    isAllNominationsLoaded(val) {
      if (val) {
        setTimeout(() => {
          this.addHashEventListener();
          this.setActiveTab();
          const vm = this;
          setTimeout(() => { vm.setupLoader(); }, 250);
        }, 250);
      }
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('trackers', ['getTrackers']),
    ...mapActions('nominations', ['getNominationsActive', 'getNominationsCompleted', 'getTeamNominationsActive', 'getTeamNominationsCompleted', 'getNominationResults']),
    defineCurrentTab() {
      let tab = 0;

      if (this.$route.name === 'NominationsInProgress') tab = 0;
      else if (this.$route.name === 'NominationsAvailable') tab = 1;
      else if (this.$route.name === 'NominationsCompleted') tab = 2;
      else tab = 0;

      if (this.$route.query.team === 'true') {
        this.selectedType = 1;
      } else {
        this.selectedType = 0;
      }

      return tab;
    },
    changeTab(index) {
      const { hash } = this.$route;
      let url = '';
      if (index === 0) url = '/rating/in-progress';
      if (index === 1) url = '/rating/available';
      if (index === 2) url = '/rating/completed';
      if (this.selectedType === 1) {
        if (index === 1) url = '/rating/completed';
        url += '?team=true';
        if (url && this.$route.path !== url) this.$router.replace(`${url}${hash}`).catch(() => {});
        if (index === 1) {
          this.selectedTab = 2;
        } else {
          this.selectedTab = index;
        }
        return;
      }
      if (url && this.$route.path !== url) this.$router.replace(`${url}${hash}`).catch(() => {});
      this.selectedTab = index;
    },
    changeType(index) {
      const prevSelectedType = this.selectedType;
      if (prevSelectedType === 1 && index === 0) {
        this.$router.replace({ query: {} }).catch(() => {});
      }
      this.selectedType = index;
      if (index === 1) {
        this.changeTab(0);
      }
      if (index === 0) {
        if (!this.isTrackerConnected) this.changeTab(1);
        else this.changeTab(0);
      }
    },
    getTabs() {
      if (this.selectedType === 0) {
        return [this.getTabText(0), this.getTabText(1), this.getTabText(2)];
      }
      if (this.selectedType === 1) {
        return [this.getTabText(0), this.getTabText(2)];
      }
      return [];
    },
    getTabText(tab) {
      if (this.selectedType === 0) {
        if (tab === 0) return { title: `<div>${this.isTrackerConnected ? this.totalActive : 0}</div><div>Активны</div>`, enabled: this.isTrackerConnected ? this.totalActive > 0 : false };
        if (tab === 1) return { title: `<div>${!this.isTrackerConnected ? this.totalActive : 0}</div><div>Доступны</div>`, enabled: !this.isTrackerConnected ? this.totalActive > 0 : false };
        if (tab === 2) return { title: `<div>${this.nominationsCompleted ? this.nominationsCompleted.total : 0}</div><div>Завершены</div>`, enabled: this.nominationsCompleted ? this.nominationsCompleted.total > 0 : false };
        return { title: '<div>0</div><div>-</div>', enabled: true };
      }
      if (tab === 0) return { title: `<div>${this.teamNominationsActive ? this.teamNominationsActive.total : 0}</div><div>Активны</div>`, enabled: this.teamNominationsActive ? this.teamNominationsActive.total > 0 : false };
      if (tab === 2) return { title: `<div>${this.teamNominationsCompleted ? this.teamNominationsCompleted.total : 0}</div><div>Завершены</div>`, enabled: this.teamNominationsCompleted ? this.teamNominationsCompleted.total > 0 : false };
      return { title: '<div>0</div><div>-</div>', enabled: true };
    },
    onShowRules(item) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = false;
      this.rulesPrizesTabActive = 0;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesModal = true;
      window.location.hash = `#!showNomination=${item.nominationId}&tab=0`;
    },
    onShowPrizes(item) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = false;
      this.rulesPrizesTabActive = 1;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesModal = true;
      window.location.hash = `#!showNomination=${item.nominationId}&tab=1`;
    },
    onShowRating(item, isTeam = false) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = true;
      this.rulesPrizesTabActive = 2;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesNominationId = item.nominationId;
      this.rulesPrizesIsTeam = isTeam;
      this.rulesPrizesModal = true;
      window.location.hash = `#!showNomination=${item.nominationId}&tab=2`;
    },
    onShowOnboarding() {
      this.$root.showOnboarding({ step: 'choose' });
    },
    onHashChange() {
      const routeName = this.$route.name;
      if (!['Nominations', 'NominationsInProgress', 'NominationsAvailable', 'NominationsCompleted'].includes(routeName)) {
        window.removeEventListener('hashchange', this.onHashChange);
        return;
      }
      const hash = window.location.hash.split('=');
      if (hash[0] === '#!showNomination') this.showNomination(hash);
      else {
        this.clearHash();
        this.rulesPrizesModal = false;
      }
    },
    onRulesPrizesClosed() {
      window.location.hash = '';
      this.rulesPrizesModal = false;
    },
    showNomination(hash) {
      if (!hash[1]) {
        this.rulesPrizesModal = false;
        this.clearHash();
        return;
      }
      if (hash[1] === 'false') {
        this.rulesPrizesModal = false;
        this.clearHash();
        return;
      }

      let nominationId = hash[1] || '';
      if (!nominationId) {
        this.clearHash();
        return;
      }

      const tabIndex = nominationId.indexOf('&tab');
      let tab = '0';
      if (tabIndex >= 0) {
        nominationId = nominationId.slice(0, tabIndex);
        tab = hash[2] || '0';
      }
      if (!['0', '1', '2'].includes(tab)) tab = '0';

      const values = [];
      values.push(this.nominationsActive ? this.nominationsActive.values : []);
      values.push(this.nominationsCompleted ? this.nominationsCompleted.values : []);
      values.push(this.teamNominationsActive ? this.teamNominationsActive.values : []);
      values.push(this.teamNominationsCompleted ? this.teamNominationsCompleted.values : []);

      for (let i = 0; i < values.length; i += 1) {
        const item = values[i].find((it) => it.nominationId === nominationId);
        if (!item) continue;
        if (tab === '0') this.onShowRules(item);
        else if (tab === '1') this.onShowPrizes(item);
        else if (tab === '2') this.onShowRating(item, this.selectedType === 1);
      }
    },
    setupLoader() {
      const vm = this;
      if (vm.loaderBlock !== null) return;
      if (vm.$route.name !== 'Nominations'
          && vm.$route.name !== 'NominationsInProgress'
          && vm.$route.name !== 'NominationsAvailable'
          && vm.$route.name !== 'NominationsCompleted') {
        return;
      }
      const loaders = document.getElementsByClassName('nominations__loader-block');
      vm.loaderBlock = loaders[0] || null;
      window.addEventListener('scroll', vm.checkForLoading);
      window.addEventListener('touchmove', vm.checkForLoading);
      vm.checkForLoading();
    },
    checkForLoading() {
      if (this.updating) return;
      if (!this.loaderBlock) return;
      if (this.$route.name !== 'Nominations'
          && this.$route.name !== 'NominationsInProgress'
          && this.$route.name !== 'NominationsAvailable'
          && this.$route.name !== 'NominationsCompleted') {
        this.cleanup();
        return;
      }

      if (this.getNominations() === null) return;
      if (!this.hasNominationsForLoading()) return;

      const bottomCoord = this.loaderBlock.getBoundingClientRect().bottom;
      const height = document.documentElement.clientHeight;
      if (height >= bottomCoord) {
        this.updating = true;
        setTimeout(() => {
          if (this.selectedType === 0) {
            this.updateNominations();
          } else {
            this.updateTeamNominations();
          }
        }, 250);
      }
    },
    cleanup() {
      window.removeEventListener('scroll', this.checkForLoading);
      window.removeEventListener('touchmove', this.checkForLoading);
    },
    loadNominations() {
      this.getTrackers()
        .then(() => {
          if (this.trackers.filter((i) => i.state === 'Connected').length > 0) {
            localStorage.removeItem('returnFromGuide');
            this.isTrackerConnected = true;
          } else {
            this.isTrackerConnected = false;
          }
          this.getNominationsActive({ offset: 0, count: 12 })
            .then(() => {
              this.totalActive = this.nominationsActive ? this.nominationsActive.total : 0;
              if (this.isTrackerConnected) {
                this.itemsActive = this.nominationsActive ? this.nominationsActive.values : [];
              } else {
                this.itemsAvailable = this.nominationsActive ? this.nominationsActive.values : [];
              }
            });
          this.getNominationsCompleted({ offset: 0, count: 12 })
            .then(() => {
              this.itemsCompleted = this.nominationsCompleted ? this.nominationsCompleted.values : [];
            });

          if (this.isTeamsEnabled && this.showTeamRatings) {
            this.getTeamNominationsActive({ offset: 0, count: 12 })
              .then(() => {
                this.itemsTeamActive = this.teamNominationsActive ? this.teamNominationsActive.values : [];
              });
            this.getTeamNominationsCompleted({ offset: 0, count: 12 })
              .then(() => {
                this.itemsTeamCompleted = this.teamNominationsCompleted ? this.teamNominationsCompleted.values : [];
              });
          }
        });
    },
    updateNominations() {
      if (this.selectedTab === 0) {
        this.offsetActive += 12;
        this.getNominationsActive({ offset: this.offsetActive, count: 12 })
          .then(() => {
            if (this.responseStatus === 200) {
              this.itemsActive.push(...this.nominationsActive.values);
              this.totalActive = this.nominationsActive ? this.nominationsActive.total : 0;
            }
            this.updating = false;
          });
        return;
      }
      if (this.selectedTab === 1) {
        this.offsetActive += 12;
        this.getNominationsActive({ offset: this.offsetActive, count: 12 })
          .then(() => {
            if (this.responseStatus === 200) {
              this.itemsAvailable.push(...this.nominationsActive.values);
              this.totalActive = this.nominationsActive ? this.nominationsActive.total : 0;
            }
            this.updating = false;
          });
        return;
      }
      if (this.selectedTab === 2) {
        this.offsetCompleted += 12;
        this.getNominationsCompleted({ offset: this.offsetCompleted, count: 12 })
          .then(() => {
            if (this.responseStatus === 200) {
              this.itemsCompleted.push(...this.nominationsCompleted.values);
            }
            this.updating = false;
          });
      }
    },
    updateTeamNominations() {
      if (this.selectedTab === 0) {
        this.offsetTeamActive += 12;
        this.getTeamNominationsActive({ offset: this.offsetTeamActive, count: 12 })
          .then(() => {
            if (this.responseStatus === 200) {
              this.itemsTeamActive.push(...this.teamNominationsActive.values);
            }
            this.updating = false;
          });
        return;
      }
      if (this.selectedTab === 2) {
        this.offsetTeamCompleted += 12;
        this.getTeamNominationsCompleted({ offset: this.offsetTeamCompleted, count: 12 })
          .then(() => {
            if (this.responseStatus === 200) {
              this.itemsTeamCompleted.push(...this.teamNominationsCompleted.values);
            }
            this.updating = false;
          });
      }
    },
    getNominations() {
      if (this.selectedType === 0) {
        if (this.selectedTab === 0) return this.nominationsActive;
        if (this.selectedTab === 1) return this.nominationsActive;
        if (this.selectedTab === 2) return this.nominationsCompleted;
      } else {
        if (this.selectedTab === 0) return this.teamNominationsActive;
        if (this.selectedTab === 2) return this.teamNominationsCompleted;
      }
      return null;
    },
    hasNominationsForLoading() {
      let nominations = { count: 0 };
      let items = [];
      if (this.selectedType === 0) {
        if (this.selectedTab === 0) {
          nominations = this.nominationsActive;
          items = this.itemsActive;
        }
        if (this.selectedTab === 1) {
          nominations = this.nominationsActive;
          items = this.itemsActive;
        }
        if (this.selectedTab === 2) {
          nominations = this.nominationsCompleted;
          items = this.itemsCompleted;
        }
      } else {
        if (this.selectedTab === 0) {
          nominations = this.teamNominationsActive;
          items = this.itemsTeamActive;
        }
        if (this.selectedTab === 2) {
          nominations = this.nominationsCompleted;
          items = this.itemsTeamCompleted;
        }
      }
      if (nominations.count === 0) return false;
      if (items.length >= nominations.total) return false;
      return true;
    },
    getNominationImage(item) {
      if (!item || !item.images) return '';
      return this.$root.isDesktop ? item.images.desktop || '' : item.images.mobile || '';
    },
    getNominationDates(item) {
      if (!item) return '';
      const startDate = dayjs.utc(item.startDate);
      const finishDate = dayjs.utc(item.finishDate);
      if (startDate.isValid() && finishDate.isValid()) {
        return formatDateRange({ start: startDate, finish: finishDate, showYear: true });
      }
      return '';
    },
    getNominationRating(item, isTeam = false) {
      if (!item) return [];
      const rating = [];
      for (let i = 0; i < item.leaders.length; i += 1) {
        const it = {
          number: item.leaders[i].position.toString(),
          name: `${item.leaders[i].lastName} ${item.leaders[i].firstName}`,
          value: item.leaders[i].points.toString(),
          current: isTeam ? item.leaders[i].isCurrentTeam === true : item.leaders[i].isCurrentUser === true,
        };
        if (this.selectedType === 1) {
          it.name = item.leaders[i].teamName;
        }
        rating.push(it);
      }
      return rating;
    },
    getNominationResult(item, isTeam = false) {
      const emptyResult = {
        rating: { number: '', name: '', value: '' },
      };
      if (!item) return emptyResult;
      let result = item.leaders.filter((i) => i.isCurrentUser === true);
      if (isTeam) result = item.leaders.filter((i) => i.isCurrentTeam === true);
      if (result.length === 0) return emptyResult;
      if (this.selectedType === 1) {
        return {
          rating: { number: result[0].position.toString(), name: result[0].teamName, value: result[0].points.toString() },
        };
      }
      return {
        rating: { number: result[0].position.toString(), name: `${result[0].lastName} ${result[0].firstName}`, value: result[0].points.toString() },
      };
    },
    isRatingTypeDisabled(index) {
      if (index === 0) {
        if (this.nominationsActive ? this.nominationsActive.total === 0 : true) return true;
      }
      if (index === 1) {
        if (this.isTeamRatingsDisabled()) return true;
        if (this.teamNominationsActive ? this.teamNominationsActive.total === 0 && this.teamNominationsCompleted.total === 0 : true) return true;
      }
      return false;
    },
    isTeamRatingsDisabled() {
      return !this.isTeamSet && this.disableTeamsForGuest;
    },
    clearHash() {
      setTimeout(() => { window.location.hash = ''; }, 100);
    },
    addHashEventListener() {
      setTimeout(() => {
        window.addEventListener('hashchange', this.onHashChange);
        this.onHashChange();
      }, 250);
    },
    setActiveTab() {
      let selectedTab = this.defineCurrentTab();

      if (this.selectedType === 0) {
        selectedTab = this.computePersonalTab(selectedTab);
      } else if (this.selectedType === 1) {
        selectedTab = this.computeTeamTab(selectedTab);
      }

      this.changeTab(selectedTab);
    },
    computePersonalTab(tab) {
      let selectedTab = tab;
      const nominationsActive = this.nominationsActive ? this.nominationsActive.values : [];
      const nominationsCompleted = this.nominationsCompleted ? this.nominationsCompleted.values : [];
      if (this.isTrackerConnected) {
        if (tab === 0) {
          if (nominationsActive.length > 0) selectedTab = 0;
          if (nominationsActive.length === 0) selectedTab = 2;
        }
        if (tab === 1) {
          if (nominationsActive.length > 0) selectedTab = 0;
          if (nominationsActive.length === 0) selectedTab = 2;
        }
        if (tab === 2) {
          if (nominationsCompleted.length > 0) selectedTab = 2;
          if (nominationsCompleted.length === 0) selectedTab = 0;
        }
      }
      if (!this.isTrackerConnected) {
        if (tab === 0) {
          if (nominationsActive.length > 0) selectedTab = 1;
          if (nominationsActive.length === 0) selectedTab = 2;
        }
        if (tab === 1) {
          if (nominationsActive.length > 0) selectedTab = 1;
          if (nominationsActive.length === 0) selectedTab = 2;
        }
        if (tab === 2) {
          if (nominationsCompleted.length > 0) selectedTab = 2;
          if (nominationsCompleted.length === 0) selectedTab = 1;
        }
      }

      return selectedTab;
    },
    computeTeamTab(tab) {
      let selectedTab = tab;
      const itemsTeamActive = this.teamNominationsActive ? this.teamNominationsActive.values : [];
      const itemsTeamCompleted = this.teamNominationsCompleted ? this.teamNominationsCompleted.values : [];
      if (tab === 0) {
        if (itemsTeamActive.length > 0) selectedTab = 0;
        if (itemsTeamActive.length === 0) selectedTab = 2;
      }
      if (tab === 1) {
        selectedTab = 0;
      }
      if (tab === 2) {
        if (itemsTeamCompleted.length > 0) selectedTab = 2;
        if (itemsTeamCompleted.length === 0) selectedTab = 0;
      }

      return selectedTab;
    },
  },
  mounted() {
    this.getProfile();
    this.getBalance();
    this.loadNominations();
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import "./Nominations";
</style>

<template>
  <div class="questionnaire">
    <Modal
      :active="active"
      name="QuestionnaireModal"
      :title="localizedText.title"
      :subtitle="localizedText.subtitle"
      icon="solution"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="$emit('close')"
    >
      <div class="questionnaire__container">
        <div class="questionnaire__content text-left">
          <div class="questionnaire__questions">
            <div
              v-for="(item, i) in items"
              :key="i"
              class="questions__item"
            >
              <QuestionCard
                :title="`${i + 1}. ${item.question}`"
                :items="item.answers"
                @changed="onAnswered(i, $event)"
              />
            </div>
          </div>
          <div class="questionnaire__action">
            <div>
              <Button
                variant="primary"
                size="lg"
                mobile
                :wide="false"
                :disabled="!hasAnsweredQuestions"
                @click="onAction"
              >
                {{ localizedText.action }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import QuestionCard from '@sc-component-library/question-card/src/main';

export default {
  name: 'QuestionnaireModal',
  components: {
    QuestionCard,
  },
  props: {
    active: Boolean,
    localizedText: Object,
    isMobileApp: Boolean,
  },
  data() {
    return {
      items: [],
      answered: [],
      answers: [],
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'options']),
    hasAnsweredQuestions() {
      return this.answered.filter((i) => i === true).length > 0;
    },
    questionnaire() {
      return this.options ? this.options.questionnaire || [] : [];
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.setItems();
      }
    },
  },
  methods: {
    onAnswered(index, answer) {
      if (index < 0 || index > this.items.length - 1) return;
      Vue.set(this.answered, index, true);
      this.answers[index] = answer;
    },
    onAction() {
      this.$emit('save', this.answers);
    },
    setItems() {
      this.items = this.questionnaire;
      this.answered = new Array(this.items.length).fill(false);
      this.answers = new Array(this.items.length).fill(null);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import './QuestionnaireModal';
</style>

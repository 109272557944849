/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Api from '@/services/communications-api';

const state = {
  communications: null,
  responseStatus: null,
  profileCommunications: null,
  participants: null,
  participant: null,
  participantsStatus: null,
  participantStatus: null,
  participantOperationshistory: null,
  participantRatings: null,
  participantTeamRatings: null,
  participantTeamRatingsStatus: null,
  participantRatingsStatus: null,
  participantStatistics: null,
  participantStatisticsStatus: null,
  participantAchievements: null,
  participantAchievementsStatus: null,
  participantDistance: null,
  participantDistanceStatus: null,
  participantSportIndexes: null,
  participantSportIndexesStatus: null,
};
const mutations = {
  setCommunications(state, value) {
    state.communications = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
  setProfileCommunications(state, value) {
    state.profileCommunications = value;
  },
  setParticipants(state, value) {
    state.participants = value;
  },
  setParticipant(state, value) {
    state.participant = value;
  },
  setParticipantStatus(state, value) {
    state.participantsStatus = value;
  },
  setParticipantOperationshistory(state, value) {
    state.participantOperationshistory = value;
  },
  setParticipantRatings(state, value) {
    state.participantRatings = value;
  },
  setParticipantRatingsStatus(state, value) {
    state.participantRatingsStatus = value;
  },
  setParticipantTeamRatings(state, value) {
    state.participantTeamRatings = value;
  },
  setParticipantTeamRatingsStatus(state, value) {
    state.participantTeamRatingsStatus = value;
  },
  setParticipantStatistics(state, value) {
    state.participantStatistics = value;
  },
  setParticipantStatisticsStatus(state, value) {
    state.participantStatisticsStatus = value;
  },
  setParticipantAchievements(state, value) {
    state.participantAchievements = value;
  },
  setParticipantAchievementsStatus(state, value) {
    state.participantAchievementsStatus = value;
  },
  setParticipantDistance(state, value) {
    state.participantDistance = value;
  },
  setParticipantDistanceStatus(state, value) {
    state.participantDistanceStatus = value;
  },
  setParticipantSportIndex(state, value) {
    state.participantSportIndexes = value;
  },
  setParticipantSportIndexStatus(state, value) {
    state.participantSportIndexesStatus = value;
  },
};

const getters = {
  communications: (state) => state.communications,
  responseStatus: (state) => state.responseStatus,
  profileCommunications: (state) => state.profileCommunications,
  participants: (state) => state.participants,
  participant: (state) => state.participant,
  participantsStatus: (state) => state.participantsStatus,
  participantStatus: (state) => state.participantStatus,
  participantOperationshistory: (state) => state.participantOperationshistory,
  participantRatings: (state) => state.participantRatings,
  participantTeamRatings: (state) => state.participantTeamRatings,
  participantTeamRatingsStatus: (state) => state.participantTeamRatingsStatus,
  participantRatingsStatus: (state) => state.participantRatingsStatus,
  participantStatistics: (state) => state.participantStatistics,
  participantStatisticsStatus: (state) => state.participantStatisticsStatus,
  participantAchievements: (state) => state.participantAchievements,
  participantAchievementsStatus: (state) => state.participantAchievementsStatus,
  participantDistance: (state) => state.participantDistance,
  participantDistanceStatus: (state) => state.participantDistanceStatus,
  participantSportIndexes: (state) => state.participantSportIndexes,
  participantSportIndexesStatus: (state) => state.participantSportIndexesStatus,
};

const actions = {
  getActivityOperations({ commit }, participantId) {
    return Api.getParticipantOperationshistory(participantId)
      .then((response) => {
        commit('setParticipantOperationshistory', response.data);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getProfileCommunications({ commit }) {
    return Api.getProfileCommunications()
      .then((response) => {
        commit('setProfileCommunications', response.data);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  createSocialAccount({ commit }, { accountVk, accountTelegram }) {
    return Api.createSocialAccount({ accountVk, accountTelegram })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setCommunications', response.data);
        } else {
          commit('setCommunications', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getParticipants({ commit }, {
    searchString, searchByCityString, offset, count, filters,
  }) {
    return Api.getParticipants({
      searchString, searchByCityString, offset, count, filters,
    })
      .then((response) => {
        commit('setParticipantStatus', response.status);
        if (response.status === 200) {
          commit('setParticipants', response.data);
        } else {
          commit('setParticipants', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setParticipantStatus', status || 0);
      });
  },
  getParticipant({ commit }, participantId) {
    return Api.getParticipant(participantId)
      .then((response) => {
        commit('setParticipantStatus', response.status);
        if (response.status === 200) {
          commit('setParticipant', response.data);
        } else {
          commit('setParticipant', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setParticipantStatus', status || 0);
      });
  },
  getParticipantRatings({ commit }, participantId) {
    return Api.getParticipantRating(participantId)
      .then((response) => {
        commit('setParticipantRatingsStatus', response.status);
        if (response.status === 200) {
          commit('setParticipantRatings', response.data);
        } else {
          commit('setParticipantRatings', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setParticipantRatingsStatus', status || 0);
      });
  },
  getParticipantTeamRatings({ commit }, participantId) {
    return Api.getParticipantTeamRating(participantId)
      .then((response) => {
        commit('setParticipantTeamRatingsStatus', response.status);
        if (response.status === 200) {
          commit('setParticipantTeamRatings', response.data);
        } else {
          commit('setParticipantTeamRatings', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setParticipantTeamRatingsStatus', status || 0);
      });
  },
  getParticipantStatistic({ commit }, participantId) {
    return Api.getParticipantStatistics(participantId)
      .then((response) => {
        commit('setParticipantStatisticsStatus', response.status);
        if (response.status === 200) {
          commit('setParticipantStatistics', response.data);
        } else {
          commit('setParticipantStatistics', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setParticipantStatisticsStatus', status || 0);
      });
  },
  getParticipantAchievement({ commit }, participantId) {
    return Api.getParticipantAchievements(participantId)
      .then((response) => {
        commit('setParticipantAchievementsStatus', response.status);
        if (response.status === 200) {
          commit('setParticipantAchievements', response.data);
        } else {
          commit('setParticipantAchievements', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setParticipantAchievementsStatus', status || 0);
      });
  },
  getParticipantDistance({ commit }, participantId) {
    return Api.getParticipantDistance(participantId)
      .then((response) => {
        commit('setParticipantDistanceStatus', response.status);
        if (response.status === 200) {
          commit('setParticipantDistance', response.data);
        } else {
          commit('setParticipantDistance', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setParticipantDistanceStatus', status || 0);
      });
  },
  getParticipantSportIndex({ commit }, participantId) {
    return Api.getParticipantSportindexes(participantId)
      .then((response) => {
        commit('setParticipantSportIndexStatus', response.status);
        if (response.status === 200) {
          commit('setParticipantSportIndex', response.data);
        } else {
          commit('setParticipantSportIndex', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setParticipantSportIndexStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

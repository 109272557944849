/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import scountApi from '@/services/scount-api';

const state = {
  goal: null,
  responseStatus: null,
};

const mutations = {
  setGoal(state, value) {
    state.goal = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
};

const getters = {
  goal: (state) => state.goal,
  responseStatus: (state) => state.responseStatus,
};

const actions = {
  getGoal({ commit }) {
    return scountApi.getGoal()
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setGoal', response.data);
        } else {
          commit('setGoal', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

<template>
  <Modal
    name="connection-guide"
    :active="shown"
    v-bind="settings"
    @close.prevent="$emit('close')"
  >
    <div class="text-center">
      <!-- STEP 1 -->
      <div v-if="step === 1">
        <div class="images">
          <img :src="onlinePersonalTrainerRafiki"/>
        </div>
        <div class="guide__title">Вы на правильном пути!</div>
        <div class="guide__subtitle">Вместе настроим трекер на смартфоне и посмотрим, как правильно записывать тренировки для успешного выполнения спортивных заданий.</div>
        <div class="note">
          <div class="note__item"><Icon name="smartphone" size="m" />Любой смартфон</div>
          <div class="note__item"><Icon name="timer" size="m" />Менее 5 минут</div>
        </div>
      </div>
      <!-- STEP 2 -->
      <div v-if="step === 2">
        <div class="guide__title guide__title_steps">Установите мобильное приложение «Suunto» и авторизуйтесь в нём</div>
        <div class="screens">
          <img :src="desktop ? guideOneWeb : (android ? guideOneAndroid : guideOneiOS)"/>
        </div>
        <div v-if="desktop" class="install">
          <a :href="appiOS" target="_blank"><img :src="appStoreImg"/></a>
          <a :href="appAndroid" target="_blank"><img :src="googlePlayImg"/></a>
        </div>
        <div v-if="android || iOS" class="install">
          <a :href="android ? appAndroid : appiOS" target="_blank"><img :src="android ? googlePlayImg : appStoreImg"/></a>
        </div>
        <div class="guide__subtitle guide__subtitle_steps">После авторизации в приложении «Suunto» вернитесь сюда и продолжите.</div>
      </div>
      <!-- STEP 3 -->
      <div v-if="step === 3">
        <div class="guide__title guide__title_steps">Нажмите «Подключить» и авторизуйтесь ещё раз на открывшейся странице</div>
        <div class="screens">
          <img :src="guideTwo"/>
        </div>
      </div>
      <div v-if="step !== 3" class="next">
        <Button
          text="Продолжить"
          variant="secondary"
          size="lg"
          :wide="$root.isMobile"
          mobile
          @click="onNext"
        ></Button>
      </div>
      <div v-if="step === 3" class="next">
        <Button
          text="Подключить"
          variant="primary"
          size="lg"
          :wide="$root.isMobile"
          mobile
          :disabled="loading"
          :loading="loading"
          @click="onConnect"
        ></Button>
      </div>
      <div class="dots">
        <div class="dots__item" :class="{ 'dots__item_active': step === 1 }"></div>
        <div class="dots__item" :class="{ 'dots__item_active': step === 2 }"></div>
        <div class="dots__item" :class="{ 'dots__item_active': step === 3 }"></div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GuideOneAndroid from '@/assets/img/connection-guide/guide-suunto-1-android.jpg';
import GuideOneiOS from '@/assets/img/connection-guide/guide-suunto-1-ios.jpg';
import GuideOneWeb from '@/assets/img/connection-guide/guide-suunto-1-web.jpg';
import GuideTwo from '@/assets/img/connection-guide/guide-suunto-2.gif';
import GooglePlayImg from '@/assets/img/connection-guide/google-play.svg';
import AppStoreImg from '@/assets/img/connection-guide/app-store.svg';
import Trackers from '@/assets/data/trackers';

const { v4: uuidv4 } = require('uuid');

export default {
  name: 'ConnectionGuide',
  props: {
    shown: Boolean,
    settings: Object,
  },
  data() {
    return {
      loading: false,
      step: 1,
      android: false,
      iOS: false,
      desktop: false,
      guideOneAndroid: GuideOneAndroid,
      guideOneiOS: GuideOneiOS,
      guideOneWeb: GuideOneWeb,
      guideTwo: GuideTwo,
      googlePlayImg: GooglePlayImg,
      appStoreImg: AppStoreImg,
      appiOS: '',
      appAndroid: '',
    };
  },
  computed: {
    ...mapGetters('settings', ['resources']),
    ...mapGetters('trackers', ['connectUrl']),
    onlinePersonalTrainerRafiki() {
      return this.resources ? this.resources.onlinePersonalTrainerRafiki || '' : '';
    },
  },
  watch: {
    shown(val) {
      if (val) {
        this.detectOS();
        this.updateURL();
      } else {
        this.step = 1;
        window.location.hash = '';
      }
    },
  },
  methods: {
    ...mapActions('trackers', ['createTracker']),
    detectOS() {
      if (this.$root.isDesktop) {
        this.desktop = true;
      } else {
        const { platform } = window.navigator;
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        if (iosPlatforms.indexOf(platform) !== -1) {
          this.iOS = true;
          return;
        }
        this.android = true;
      }
    },
    updateURL() {
      window.location.hash = `#!showConnectionGuide&step=${this.step}`;
    },
    setLinks() {
      const found = Trackers.filter((i) => i.id === 'suunto');
      if (found.length > 0) {
        this.appiOS = found[0].ios || '';
        this.appAndroid = found[0].android || '';
      }
    },
    onNext() {
      const step = this.step + 1;
      if (step > 3) return;
      this.step = step;
      this.updateURL();
    },
    onConnect() {
      this.loading = true;
      const trackerId = uuidv4();
      this.createTracker({
        provider: 'Suunto',
        trackerId,
        redirectUrl: `${window.location.origin}/tracker?connect=${trackerId}&from=guideSuunto`,
      })
        .then(() => {
          window.location.href = this.connectUrl;
        });
    },
  },
  mounted() {
    this.setLinks();
  },
};
</script>

<style lang="scss" scoped>
  @import './ConnectionGuideOld';
</style>

<template>
  <div class="badge">
    <Icon v-if="iconName" :name="iconName" size="s" class="badge__icon" :class="iconClass" />
    <span class="badge__text" v-html="text"></span>
  </div>
</template>

<script>
import Icon from '@rr-component-library/icon/src/main';

export default {
  name: 'Badge',
  components: {
    Icon,
  },
  props: {
    state: String,
    text: String,
  },
  computed: {
    iconClass() {
      return `badge__icon_${this.state.toLowerCase()}`;
    },
    iconName() {
      const iconNames = {
        inProgress: 'check-circle-s',
        countdown: 'hourglass',
        checking: 'loading-3-quarters',
        completed: 'check-circle-s',
        failed: 'close-circle-s',
      };
      return iconNames[this.state] || '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Badge";
</style>

<template>
  <div>
    <div v-show="state">
      <div class="banner-mobile" v-if="!$root.isMobile">
        <div class="banner-mobile__body">
          <div class="banner-mobile__text banner-mobile__text_bold">
            <img :src="scountImg">
            <span>Установите мобильное приложение СКАУНТ</span>
          </div>
          <div class="banner-mobile__text">Шагомер, трекер, пуш-уведомления</div>
          <div class="banner-mobile__link" @click.stop="onAction">
            <Icon name="code" size="s"></Icon>
            <span>Подробнее</span>
          </div>
        </div>
        <div class="banner-mobile__close" @click.stop="onHide">
          <Icon name="close" size="s"></Icon>
        </div>
      </div>
      <div class="banner-mobile banner-mobile_mobile" v-if="$root.isMobile">
        <div class="banner-mobile__body">
          <img :src="scountImg">
          <div class="banner-mobile__text">
            <b>СКАУНТ</b>
            <span>Открыть в приложении</span>
          </div>
        </div>
        <Button
          variant="primary"
          size="sm"
          text="Продолжить"
          @click.stop="onAction"
          mobile
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileAppBanner',
  props: {
    scountImg: String,
    state: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onHide() {
      this.$emit('hide');
    },
    onAction() {
      this.$emit('action');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./MobileAppBanner";
</style>

<template>
  <div class="personalization">

    <div v-if="questions.length > 0">
      <div class="personalization__heading">Анкета участника</div>
      <div class="personalization__subheading">Ответьте на вопросы, чтобы получить монеты.</div>
      <div v-if="showAnswers" class="questions">
        <div
          v-for="(item, i) in items"
          :key="i"
          class="questions__item"
        >
          <QuestionCard
            :title="`${i + 1}. ${item.question}`"
            :items="item.answers"
            :selectedItem="answers[i]"
            :state="answered[i] ? 'paid' : 'general'"
            @changed="onAnswered(i, $event)"
          />
        </div>
      </div>
      <div v-if="showAnswers" class="divider"></div>
      <Alert v-if="showSuccess" state="success" noAction autoClose closable>
        Ваши ответы сохранены.
      </Alert>
      <Alert v-if="showError" state="error" noAction autoClose closable>
        Произшла ошибка при сохранении ответов: {{ questionnaireStatus || 0 }}.
      </Alert>
      <div class="personalization__action">
        <Button
          v-if="showAnswers"
          variant="primary"
          size="lg"
          mobile
          :wide="$root.isMobile ? true : false"
          @click="onSaveAnswers"
          :disabled="!hasAnsweredQuestions || saving"
        >
          Сохранить
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import QuestionCard from '@sc-component-library/question-card/src/main';
import Alert from '@rr-component-library/alert/src/main';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

export default {
  name: 'Profile',
  components: {
    QuestionCard,
    Alert,
  },
  data() {
    return {
      items: [],
      answered: [],
      answers: [],
      showAnswers: false,
      showSuccess: false,
      showError: false,
      saving: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['themeDone', 'options']),
    ...mapGetters('user', ['questionnaire', 'questionnaireStatus']),
    hasAnsweredQuestions() {
      return this.answered.filter((i) => i === true).length > 0;
    },
    questions() {
      return this.options ? this.options.questionnaire || [] : [];
    },
  },
  watch: {
    themeDone(val) {
      if (val) {
        this.setItems();
        this.setAnswers();
      }
    },
  },
  methods: {
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('user', ['getQuestionnaire', 'applyAnswers']),
    onAnswered(index, answer) {
      if (index < 0 || index > this.items.length - 1) return;
      Vue.set(this.answered, index, true);
      this.answers[index] = answer;
    },
    onSaveAnswers() {
      const data = {};
      for (let i = 0; i < this.answers.length; i += 1) {
        if (this.answers[i] !== null) data[`Question${i + 1}`] = this.answers[i].toString();
      }
      this.showSuccess = false;
      this.showError = false;
      this.saving = true;
      this.applyAnswers(data)
        .then(() => {
          if (this.questionnaireStatus === 204) {
            this.getBalance();
            this.showSuccess = true;
            this.saving = false;
            return;
          }
          this.showError = true;
          this.saving = false;
        });
    },
    setItems() {
      this.items = this.questions;
      this.answered = new Array(this.items.length).fill(false);
      this.answers = new Array(this.items.length).fill(null);
    },
    setAnswers() {
      for (let i = 0; i < this.answers.length; i += 1) {
        this.answers[i] = this.getNumber(this.getValue(this.questionnaire.answers, `Question${i + 1}`));
        this.answered[i] = this.answers[i] !== null;
      }
      this.showAnswers = true;
    },
    getValue(answers, key) {
      if (!answers) return '';
      const found = answers.filter((i) => i.key === key);
      if (found.length === 0) return '';
      return found[0].value;
    },
    getNumber(value) {
      try {
        const v = Number.parseInt(value, 10);
        return v >= 0 ? v : null;
      } catch {
        return null;
      }
    },
  },
  mounted() {
    this.getQuestionnaire()
      .then(() => {
        if (this.themeDone) {
          this.setItems();
          this.setAnswers();
        }
      });
  },
};
</script>

<style lang="scss" scoped>
  @import "./Personalization";
</style>

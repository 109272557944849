import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from 'js-cookie';
import Home from '../views/Home.vue';
import Auth from '../views/Auth.vue';
import Register from '../views/Register.vue';
import RegisterComplete from '../views/RegisterComplete.vue';
import Club from '../views/Club.vue';
import Team from '../views/Team.vue';
import TeamCreate from '../views/TeamCreate.vue';
import Dashboard from '../views/Dashboard.vue';
import Tasks from '../views/Tasks.vue';
import Nominations from '../views/Nominations.vue';
import Activities from '../views/Activities.vue';
import Settings from '../views/Settings.vue';
import Profile from '../components/Profile/Profile.vue';
import Trackers from '../components/Trackers/Trackers.vue';
import Trainings from '../components/Trainings/Trainings.vue';
import PurchaseHistory from '../components/PurchaseHistory/PurchaseHistory.vue';
import Personalization from '../components/Personalization/Personalization.vue';
import Market from '../views/Market.vue';
import RewardDetails from '../components/RewardDetails/RewardDetails.vue';
import Community from '../views/Community.vue';
import About from '../views/About.vue';
import Support from '../views/Support.vue';
import Participants from '../views/Participants.vue';
import ParticipantItem from '../views/ParticipantItem.vue';
import Calendar from '../views/Calendar.vue';
import MobileTask from '../views/MobileTask.vue';
import MobileLink from '../views/MobileLink.vue';
import Rules from '../views/Rules.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
  },
  {
    path: '/authentication',
    name: 'AuthConfirm',
    component: Auth,
  },
  {
    path: '/sso',
    name: 'AuthSSO',
    component: Auth,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    children: [
      {
        path: 'complete',
        name: 'RegisterComplete',
        component: RegisterComplete,
      },
    ],
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules,
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/club',
    name: 'Club',
    component: Club,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/my-team',
    name: 'MyTeam',
    component: Team,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/team/create',
    name: 'TeamCreate',
    component: TeamCreate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/my-team/create',
    name: 'MyTeamCreate',
    component: TeamCreate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tracker',
    name: 'TrackerConnect',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'available',
        name: 'TasksAvailable',
        alias: '/tasks',
        component: Tasks,
      },
      {
        path: 'in-progress',
        name: 'TasksInProgress',
        component: Tasks,
      },
      {
        path: 'completed',
        name: 'TasksCompleted',
        component: Tasks,
      },
    ],
  },
  {
    path: '/rating',
    name: 'Nominations',
    component: Nominations,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'in-progress',
        name: 'NominationsInProgress',
        alias: '/rating',
        component: Nominations,
      },
      {
        path: 'available',
        name: 'NominationsAvailable',
        component: Nominations,
      },
      {
        path: 'completed',
        name: 'NominationsCompleted',
        component: Nominations,
      },
    ],
  },
  {
    path: '/activities',
    name: 'Activities',
    component: Activities,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/my-settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'profile',
        name: 'SettingsProfile',
        alias: '/my-settings',
        component: Profile,
      },
      {
        path: 'trackers',
        name: 'SettingsTrackers',
        component: Trackers,
      },
      {
        path: 'trainings',
        name: 'SettingsTrainings',
        component: Trainings,
      },
      {
        path: 'trainings/tracker',
        name: 'TrainingsTracker',
        component: Trainings,
      },
      {
        path: 'purchase-history',
        name: 'SettingsHistory',
        component: PurchaseHistory,
      },
      {
        path: 'personalization',
        name: 'SettingsPersonalization',
        component: Personalization,
      },
    ],
  },
  {
    path: '/market',
    name: 'Market',
    component: Market,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'reward',
        name: 'RewardDetails',
        component: RewardDetails,
      },
      {
        path: '/market/:catOne',
        name: 'MarketCategoryOne',
        component: Market,
      },
      {
        path: '/market/:catOne/:catTwo',
        name: 'MarketCategoryTwo',
        component: Market,
      },
    ],
  },
  {
    path: '/community',
    name: 'Community',
    component: Community,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/participants',
    name: 'Participants',
    component: Participants,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/participants/:participantId',
    name: 'ParticipantItem',
    component: ParticipantItem,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/showExerciseTask',
    name: 'MobileTask',
    component: MobileTask,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/goPage',
    name: 'GoPage',
    component: MobileLink,
  },
  {
    path: '/openInExternal',
    name: 'MobileLink',
    component: MobileLink,
  },
  {
    path: '/copyURL',
    name: 'MobileCopyURL',
    component: MobileLink,
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from && to.name === from.name && to.path === from.path) {
      return null;
    }
    return savedPosition || { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const appCheckedParam = '#app_checked=true';
  const appCheckedIndex = window.location.href.indexOf(appCheckedParam);
  const isAppChecked = window.sessionStorage.getItem('appCheckedForMobile') === 'true';
  if (!isAppChecked && appCheckedIndex !== -1) {
    window.sessionStorage.setItem('appCheckedForMobile', 'true');
    window.location.href = window.location.href.replace(appCheckedParam, '');
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isAuthorized = !!(Cookies.get('token'));
    if (!isAuthorized) {
      const returnUrl = `${window.location.origin}${to.path}`;
      let query = '';
      if (to.query) {
        const entries = Object.entries(to.query);
        for (let i = 0; i < entries.length; i += 1) {
          if (entries[i].length === 2) {
            query += `&${entries[i][0]}=${entries[i][1]}`;
          }
        }
      }
      window.location.href = `${window.location.origin}/auth?loginReturnUrl=${returnUrl}${query}`;
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

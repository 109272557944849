import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

function scrollToId(id, changeHash = false) {
  const el = document.getElementById(id);
  if (!el) {
    return;
  }
  el.scrollIntoView({
    behavior: 'smooth',
  });
  if (changeHash) {
    setTimeout(() => { window.location.hash = id; }, 500);
  }
}

function stringPadLeft(string, pad, length) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}

function getCountdown(dateString, withDays) {
  const distance = !dateString ? 0 : new Date(dateString).getTime() - new Date().getTime();
  let values = [0, 0, 0, 0];

  if (distance > 0) {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = withDays ? hour * 24 : 1;

    values = [
      Math.floor(distance / day),
      Math.floor((distance % day) / hour),
      Math.floor((distance % hour) / minute),
      Math.floor((distance % minute) / second),
    ];
  }

  return {
    values,
    finished: distance <= 0,
  };
}

function formatDateRange({ start, finish, showYear = false }) {
  const first = dayjs.utc(start).local();
  const last = dayjs.utc(finish).local();
  let dates = '';
  if (first.format('DD-MM-YYYY') === last.format('DD-MM-YYYY')) {
    dates = last.format('DD MMMM');
  } else if (first.format('YYYY') === last.format('YYYY')) {
    if (first.format('MM') === last.format('MM')) {
      dates = `${first.format('DD')}-${last.format('DD MMMM')}`;
    } else {
      dates = `${first.format('DD')} ${first.format('MMM').slice(0, 3)} - ${last.format('DD')} ${last.format('MMM').slice(0, 3)}`;
    }
  } else if (first.format('YYYY') !== last.format('YYYY')) {
    if (showYear) {
      dates = `${first.format('DD')} ${first.format('MMM').slice(0, 3)} '${first.format('YY')} - ${last.format('DD')} ${last.format('MMM').slice(0, 3)} '${last.format('YY')}`;
    } else {
      dates = `${first.format('DD')} ${first.format('MMM').slice(0, 3)} - ${last.format('DD')} ${last.format('MMM').slice(0, 3)}`;
    }
  }
  return dates;
}

function addNonBreakingSpaces(str) {
  const prepositions = ['в', 'на', 'по', 'за', 'у', 'о', 'с', 'к', 'от', 'до', 'из', 'перед', 'под', 'над', 'и'];
  const words = str.split(' ');
  const result = [];
  for (let i = 0; i < words.length; i += 1) {
    const word = words[i];
    if (prepositions.includes(word.toLowerCase())) {
      result.push(`${word}\u00A0`); // Вставляем неразрывный пробел после предлога
    } else {
      result.push(`${word} `); // В остальных случаях добавляем слово с пробелом
    }
  }
  return result.join('').trimEnd(); // Объединяем слова без пробелов
}

function formatNumber(number, units) {
  if (number === null || number === undefined || number === '') {
    return '';
  }
  const numString = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u202F');
  if (units) {
    return `${numString}\u00A0${units}`;
  }
  return numString;
}

function getWordWithCount(number, wordForms) {
  if (number % 10 === 1 && number % 100 !== 11) {
    return `${number} ${wordForms[0]}`;
  } if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 12 || number % 100 > 14)) {
    return `${number} ${wordForms[1]}`;
  }
  return `${number} ${wordForms[2]}`;
}

export {
  scrollToId,
  stringPadLeft,
  getCountdown,
  formatDateRange,
  addNonBreakingSpaces,
  formatNumber,
  getWordWithCount,
};

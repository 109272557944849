<template>
  <div class="mobile-task">
    <div v-if="isMobileApp" class="mobile-overlay"></div>

    <QuizModal
      :active="quizModal"
      :options="quizOptions"
      :isMobileApp="isMobileApp"
      @close="onQuizClose"
      @back="onQuizBack"
      @quizEnd="onQuizEnd"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Base64 } from 'js-base64';
import QuizModal from '@/components/QuizModal/QuizModal.vue';

const { v4: uuidv4 } = require('uuid');

export default {
  name: 'MobileTask',
  components: { QuizModal },
  data() {
    return {
      quizModal: false,
      quizOptions: null,
      quizResult: {},
    };
  },
  computed: {
    ...mapGetters('user', ['isMobileApp', 'profile']),
    ...mapGetters('exercises', ['task', 'responseStatus']),
    ...mapGetters('partners', ['registration', 'partnerStatus']),
    ...mapGetters('externalevents', ['exerciseTasksStaus', 'exerciseTasksUrl']),
    exerciseId() {
      return this.$route.query.exerciseId;
    },
    taskId() {
      return this.$route.query.taskId;
    },
    teamId() {
      return this.profile ? this.profile?.team?.teamId : null;
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('exercises', ['getTask']),
    ...mapActions('partners', ['createLinkActivity']),
    ...mapActions('externalevents', ['getExerciseTasks']),
    loadMyTask(exerciseId, taskId) {
      this.getTask(exerciseId)
        .then(() => {
          if (this.task) {
            const participantTask = this.task.participantExercise ? this.task.participantExercise.tasks.find((i) => i.participantExerciseTaskId === taskId) : null;
            if (participantTask) this.showExerciseTask(participantTask);
          }
        });
    },
    showExerciseTask(participantTask) {
      if (!participantTask) return;

      const task = this.getExerciseTask(participantTask.exerciseTaskId);

      if (task.type === 'Registration') {
        this.processRegistration();
      }

      if (task.type === 'Quiz') {
        this.processQuiz(participantTask);
      }

      if (task.type === 'PartnerLink') {
        this.processPartnerLink(participantTask);
      }

      if (task.type === 'Invite') {
        this.processInvite(participantTask);
      }
    },
    processRegistration() {
      this.getExerciseTasks(this.exerciseId)
        .then(() => {
          if (this.exerciseTasksStaus === 200) {
            if (this.exerciseTasksUrl) this.openInExternal(this.exerciseTasksUrl);
          }
        });
    },
    processQuiz(participantTask) {
      const task = this.getExerciseTask(participantTask.exerciseTaskId);
      const options = {};
      options.quizId = task.settings ? task.settings.QuizId : '';
      options.participantExerciseTaskId = participantTask.participantExerciseTaskId || '';
      options.activityId = uuidv4();
      if (options.quizId) {
        this.quizOptions = options;
        this.quizModal = true;
      }
    },
    processPartnerLink(participantTask) {
      const payload = {
        activityId: uuidv4(),
        exerciseTaskId: participantTask.participantExerciseTaskId || '',
      };
      this.createLinkActivity(payload)
        .then(() => {
          if (this.partnerStatus === 200) {
            // success
            setTimeout(() => {
              const task = this.getExerciseTask(participantTask.exerciseTaskId);
              this.openInExternal(task.settings.PartnerLink);
            }, 500);
          }
        });
    },
    processInvite(participantTask) {
      let inviteLink = '';
      const task = this.getExerciseTask(participantTask.exerciseTaskId);
      const base64Ref = Base64.encode(participantTask.participantExerciseTaskId);
      if (this.teamId !== undefined && this.teamId !== null) {
        const teamId = Base64.encode(this.teamId);
        const link = task.settings.Link;
        inviteLink = `${link}/?t=${teamId}&ref=${base64Ref}`;
      } else {
        const link = task.settings.Link;
        inviteLink = `${link}/?ref=${base64Ref}`;
      }

      this.$router.push({ path: `/copyURL?url=${inviteLink}` }).catch(() => {});
    },
    onQuizClose() {
      this.quizModal = false;
      this.setFinished();
    },
    onQuizBack() {
      this.quizModal = false;
      this.setFinished();
    },
    onQuizEnd() {
      this.quizModal = false;
      this.setFinished();
    },
    getExerciseTask(exerciseTaskId) {
      return this.task ? this.task?.tasks.find((i) => i.exerciseTaskId === exerciseTaskId) : null;
    },
    openInExternal(url) {
      this.$router.push({ path: `/openInExternal?url=${url}` }).catch(() => {});
    },
    setFinished() {
      window.location.hash = 'closed';
    },
  },
  mounted() {
    if (!this.isMobileApp) {
      this.$router.push({ name: 'Dashboard' }).catch(() => {});
      return;
    }

    this.getProfile();
    this.loadMyTask(this.exerciseId, this.taskId);
  },
};
</script>

<style lang="scss" scoped>
  @import "./MobileTask";
</style>

<template>
  <div class="value-range">
    <div class="value-range__slider">
      <vue-slider
        v-model="sliderValue"
        :dotSize="16"
        :min="min"
        :max="max"
        :tooltip="'none'"
        :enableCross="false"
        :dotOptions="{
          style: { 'box-shadow': 'none', 'background-color': '#ffffff', 'border': '2px solid var(--color-primary-5, #FFA05C)' },
          focusStyle: { 'box-shadow': 'none', 'background-color': '#ffffff', 'border': '2px solid var(--color-primary-5, #FFA05C)' },
        }"
        :processStyle="{ 'background-color': 'var(--color-primary-4, #FFBC85)' }"
        @change="onSliderChange"
      ></vue-slider>
    </div>
    <div class="value-range__inputs">
      <Input
        size="md"
        v-model.trim="inputValue[0]"
        :state="inputError[0] ? 'error' : ''"
        :noStateIcons="true"
        @keydown="onKeyDown(0, $event)"
        @blur="onBlur(0)"
        mobile
      />
      <div class="divider"></div>
      <Input
        size="md"
        v-model.trim="inputValue[1]"
        :state="inputError[1] ? 'error' : ''"
        :noStateIcons="true"
        @keydown="onKeyDown(1, $event)"
        @blur="onBlur(1)"
        mobile
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import Input from '@rr-component-library/input/src/main';

export default {
  name: 'ValueRange',
  props: {
    min: Number,
    max: Number,
    valueFrom: Number,
    valueTo: Number,
  },
  components: {
    VueSlider,
    Input,
  },
  data() {
    return {
      sliderValue: [0, 0],
      inputValue: ['', ''],
      inputError: [false, false],
    };
  },
  watch: {
    valueFrom() {
      this.setValues();
    },
    valueTo() {
      this.setValues();
    },
  },
  methods: {
    onSliderChange(value, index) {
      this.$emit('change', { index, value: value[index] });
    },
    onKeyDown(index, event) {
      if (index !== 0 && index !== 1) return;
      if (event.key === 'Backspace' || event.key === 'Delete') return;
      if (event.key === 'Enter') {
        this.onBlur(index);
        return;
      }
      const r = new RegExp(/^[0-9]$/);
      if (r.test(event.key)) {
        return;
      }
      event.preventDefault();
    },
    onBlur(index) {
      if (index !== 0 && index !== 1) return;
      if (this.inputValue[index] === '') {
        if (index === 0) Vue.set(this.inputValue, 0, this.min.toString());
        if (index === 1) Vue.set(this.inputValue, 1, this.max.toString());
      }
      this.checkAndUpdate(index);
    },
    checkAndUpdate(index) {
      let val = parseInt(this.inputValue[index], 10);
      const savedVal = val;
      if (index === 0 && val > parseInt(this.inputValue[1], 10)) val = parseInt(this.inputValue[1], 10);
      if (index === 1 && val < parseInt(this.inputValue[0], 10)) val = parseInt(this.inputValue[0], 10);
      if (val < this.min) val = this.min;
      if (val > this.max) val = this.max;
      if (val !== savedVal) {
        Vue.set(this.inputError, index, true);
        setTimeout(() => { Vue.set(this.inputError, index, false); }, 500);
      }
      Vue.set(this.inputValue, index, val.toString());
      this.$emit('change', { index, value: val });
    },
    setValues() {
      Vue.set(this.sliderValue, 0, this.valueFrom);
      Vue.set(this.sliderValue, 1, this.valueTo);
      Vue.set(this.inputValue, 0, this.valueFrom.toString());
      Vue.set(this.inputValue, 1, this.valueTo.toString());
    },
  },
  created() {
    this.setValues();
  },
};
</script>

<style lang="scss" scoped>
  @import "./ValueRange";
</style>

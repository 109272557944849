import axios from 'axios';
import Cookies from 'js-cookie';

let configs = {
  store: undefined,
};

const apiConfig = ({ store }) => {
  configs = { ...configs, store };
};

const api = axios.create({
  baseURL: '/public',
});

axios.defaults.withCredentials = true;

api.interceptors.request.use(
  (config) => {
    const c = config;
    const token = Cookies.get('token');
    if (token) {
      c.headers.common.Authorization = `Bearer ${token}`;
    }
    return c;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      configs.store.commit('user/removeUser');
    } else if (error.response.status === 403) {
      configs.store.commit('user/userBlocked');
    }
    return Promise.reject(error);
  },
);

export default api;
export { apiConfig };

import api from './api';

export default {
  getCommunity() {
    return api.get('/community/api/v1/communities');
  },
  getArticles({
    communityId, offset, count, sort,
  }) {
    return api.get(`/community/api/v1/${communityId}/articles`, {
      params: {
        ...sort,
        offset,
        count,
      },
    });
  },
};

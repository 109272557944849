<template>
  <div v-if="themeDone" class="team-create">
    <div v-if="!isMobileApp && !isMyTeamCreate" class="team-create__header team-create__header_default">
      <div class="header__row">
        <div class="header__group">
          <div class="header__item header__logo">
            <a href="/" @click.prevent><img :src="logo" alt="Scount"></a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isMobileApp && isMyTeamCreate" class="divider"></div>
    <div v-if="!isMobileApp && isMyTeamCreate" class="team-create__menu">
      <Menu />
      <div class="divider"></div>
    </div>
    <div v-if="!isMobileApp && !isMyTeamCreate" class="divider"></div>

    <div class="team-create__container">
      <div class="container">
        <div class="block">
          <TeamCreateForm :isShown="true" />
        </div>
      </div>
    </div>
    <div class="spacing-xl"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TeamCreateForm from '@/components/TeamCreateForm/TeamCreateForm.vue';

export default {
  name: 'TeamCreate',
  components: {
    TeamCreateForm,
  },
  computed: {
    ...mapGetters('settings', ['themeDone', 'options', 'resources']),
    ...mapGetters('user', ['isMobileApp']),
    isMyTeamCreate() {
      return this.$route.name === 'MyTeamCreate';
    },
    showTeamCreate() {
      return this.options ? this.options.showTeamCreate === true : false;
    },
    logo() {
      return this.resources ? this.resources.logo || '' : '';
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    checkProfile() {
      this.getProfile()
        .then(() => {
          if (this.isAuthenticated) {
            if (this.emptyProfile === true) {
              this.$router.push({ name: 'Register' }).catch(() => {});
              return;
            }
            if (!this.showTeamCreate) {
              this.$router.push({ name: 'Dashboard' }).catch(() => {});
            }
          }
        });
    },
  },
  mounted() {
    this.checkProfile();
  },
};
</script>

<style lang="scss" scoped>
  @import "./TeamCreate";
</style>

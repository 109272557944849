<template>
  <div class="login">
    <div v-if="isMobileApp" class="mobile-overlay"></div>

    <div class="login__container">
      <div class="login__main">
        <div class="login__header login__header_default">
          <div class="header__row">
            <div class="header__group">
              <div class="header__item header__logo">
                <a href="/"><img :src="logo" alt="Scount"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="login__content" v-if="step === 'start'">
          <div class="main__loader" v-if="sending">
            <Loader size="24" />
          </div>
          <div class="main__title">Вход в кабинет участника</div>
          <div class="main__form">
            <div v-if="showSSO" class="form__sso">
              <Alert
                v-if="ssoError"
                title="Что-то пошло не так"
                text="Пожалуйста, попробуйте ещё раз."
                state="error"
                no-action
              ></Alert>
              <Button
                variant="secondary"
                size="lg"
                text="Войти с RR"
                mobile
                wide
                @click="onSSO"
              >
                <slot name="before">
                  <span class="rr-button__icon rr-button__icon_auto">
                    <img src="/img/sso/RR.svg" width="20" height="20" alt="RR" />
                  </span>
                </slot>
              </Button>
              <div class="form__or">
                <div class="or__line"></div>
                <div class="or__text">или</div>
                <div class="or__line"></div>
              </div>
            </div>
            <div class="form__label">Электронная почта</div>
            <div class="form__inputs">
              <Input
                size="md"
                placeholder="your@email.com"
                name="email"
                type="email"
                id="login-email"
                v-model.trim="email"
                mobile
                :state="emailError ? 'error' : null"
                @keydown="onEmailKeydown"
              />
              <div v-if="$root.isMobile === true" class="email-errors">
                <div class="color-danger" v-if="emailError && !emailInvalid && !emailWait">Введите электронную почту</div>
                <div class="color-danger" v-if="emailError && emailInvalid && !emailWait">Введите e-mail, которым вы пользуетесь</div>
                <div class="color-danger" v-if="emailError && !emailInvalid && !emailWait && !emailAcceptable">Для e-mail разрешены следующие домены: {{ emailDomains }} </div>
                <div class="color-danger" v-if="emailError && emailWait">Вы уже запросили код.<br/>Проверьте почту или попробуйте снова через 1 минуту.</div>
                <div class="color-danger" v-if="teamError">Произошла ошибка: {{ teamStatus }}</div>
              </div>
              <Button
                variant="primary"
                size="md"
                text="Получить код"
                mobile
                @click="onGetCode"
                :disabled="emailError"
              ></Button>
            </div>
            <div v-if="$root.isMobile === false" class="email-errors">
              <div class="color-danger" v-if="emailError && !emailInvalid && !emailWait">Введите электронную почту</div>
              <div class="color-danger" v-if="emailError && emailInvalid && !emailWait">Введите e-mail, которым вы пользуетесь</div>
              <div class="color-danger" v-if="emailError && !emailInvalid && !emailWait && !emailAcceptable">Для e-mail разрешены следующие домены: {{ emailDomains }} </div>
              <div class="color-danger" v-if="emailError && emailWait">Вы уже запросили код.<br/>Проверьте почту или попробуйте снова через 1 минуту.</div>
              <div class="color-danger" v-if="teamError">Произошла ошибка: {{ teamStatus }}</div>
            </div>
          </div>
        </div>
        <div class="login__content" v-if="step === 'code'">
          <div class="main__loader" v-if="sending || sendingCode">
            <Loader size="24" />
          </div>
          <div class="main__title main__title_code">Введите код</div>
          <div class="main__subtitle">Мы отправили код на почту <b>{{email}}</b></div>
          <div class="main__form">
            <div class="form__label form__label_code">Код из письма</div>
            <div class="form__inputs form__inputs_code">
              <Input
                id="login-auth-code0"
                size="md"
                type="number"
                placeholder="•"
                :state="codeError ? 'error' : null"
                @keydown="onKeyDown(0, $event)"
                @input="onInput"
                v-model="code[0]"
                noStateIcons
                mobile
              />
              <Input
                id="login-auth-code1"
                size="md"
                type="number"
                placeholder="•"
                :state="codeError ? 'error' : null"
                @keydown="onKeyDown(1, $event)"
                v-model="code[1]"
                noStateIcons
                mobile
              />
              <Input
                id="login-auth-code2"
                size="md"
                type="number"
                placeholder="•"
                :state="codeError ? 'error' : null"
                @keydown="onKeyDown(2, $event)"
                v-model="code[2]"
                noStateIcons
                mobile
              />
              <Input
                id="login-auth-code3"
                size="md"
                type="number"
                placeholder="•"
                :state="codeError ? 'error' : null"
                @keydown="onKeyDown(3, $event)"
                v-model="code[3]"
                noStateIcons
                mobile
              />
            </div>
            <div class="code-errors">
              <div class="color-danger" v-if="codeError && !tokenError">Неверный код. Попробуйте ещё раз.</div>
              <div class="color-danger" v-if="codeError && tokenError">
                <span>Код устарел.</span> <Link variant="secondary" size="xs" @click="onGetCodeAgain">Запросить повторно.</Link>
              </div>
            </div>
            <div v-if="!isConfirmStep && countdownText !== '00:00'" class="form__retry">Запросить повторно через {{countdownText}}</div>
            <div v-if="!isConfirmStep && countdownText === '00:00'" class="form__retry">
              <Link variant="secondary" size="sm" @click="onGetCodeAgain">Запросить код повторно</Link>
            </div>
          </div>
          <div class="main__links">
            <Link variant="secondary" size="sm" icon-left="left" @click="onResetAuth">
              Указать другую почту
            </Link>
            <Link variant="primary" size="sm" icon-left="info-circle-s" @click="codeInfoModal = true">
              Не приходит письмо?
            </Link>
          </div>
        </div>
      </div>
      <div v-if="$root.isDesktop" class="login__image">
        <div class="image" :style="`background-image: url('${cover}');`"></div>
      </div>
    </div>

    <Modal
      :active="codeInfoModal"
      name="CodeInfoModal"
      :title="codeInfoText.title"
      :subtitle="codeInfoText.subtitle"
      icon="info-circle"
      @close.prevent="codeInfoModal = false"
    >
      <Button
        variant="secondary"
        size="lg"
        mobile
        @click="codeInfoModal = false"
      >
        Понятно
      </Button>
    </Modal>

    <Modal
      :active="emailBlocked"
      :img="feelingBlueRafiki"
      title="Ваш аккаунт заблокирован"
      name="EmailBlockedModal"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="onEmailBlockedClose"
    >
      <div class="rr-modal__subtitle modal__item">
        Результаты аннулированы за нарушение <a :href="supportReglament" target="_blank">регламента проекта</a>.<br/><br/>
        Если вы считаете, что это произошло по ошибке,<br/>
        <a href="#" @click.prevent="onSupport">напишите в службу поддержки</a>.<br/>
        Порядок подачи апелляции прописан в <a :href="supportReglament" target="_blank">регламенте</a>.
      </div>
    </Modal>

    <Modal
      :active="teamOffLimits"
      name="TeamOffLimitsModal"
      title="Ваша команда уже собрана"
      :subtitle="teamOffLimitsSubtitle"
      icon="info-circle"
      @close.prevent="onTeamOffLimitsClose"
    >
      <Button
        variant="secondary"
        size="lg"
        mobile
        @click="onTeamOffLimitsClose"
      >
        Понятно
      </Button>
    </Modal>

    <Modal
      :active="teamNoInvitation"
      name="TeamNoInvitationModal"
      title="У вас нет приглашения"
      :subtitle="teamNoInvitationSubtitle"
      icon="info-circle"
      @close.prevent="onTeamNoInvitationClose"
    >
      <Button
        variant="secondary"
        size="lg"
        mobile
        @click="onTeamNoInvitationClose"
      >
        Понятно
      </Button>
    </Modal>

    <SupportDialog
      :active="supportModal"
      :isMobileApp="isMobileApp"
      @close="onSupportModalClose()"
      @form-submitted="handleFormSubmit"
    />

    <Modal
      name="successModal"
      :active="successModal"
      :closable="true"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="onSuccessModalClose()"
      >
      <div class="modal__success">
        <Icon name="check-circle"></Icon>
        <p class="modal__success-title">Вопрос отправлен</p>
        <p class="modal__success-text">Обычно отвечаем в течение трёх рабочих дней. Ответ придёт на почту <span>{{ email }}</span> </p>
        <div class="moadal__success-action">
          <Button
              variant="secondary"
              size="lg"
              tag="a"
              mobile
              @click="onSuccessModalClose"
            >
              Закрыть
            </Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Alert from '@rr-component-library/alert/src/main';
import Button from '@rr-component-library/button/src/main';
import Input from '@rr-component-library/input/src/main';
import SupportDialog from '@/components/SupportDialog/SupportDialog.vue';
import authTypes from '@/assets/data/authTypes';
import { getCountdown, stringPadLeft } from '@/assets/js/helpers';

export default {
  name: 'Login',
  components: {
    Alert,
    Button,
    Input,
    SupportDialog,
  },
  props: {
    logo: String,
    cover: String,
  },
  data() {
    return {
      step: 'start',
      email: null,
      code: ['', '', '', ''],
      token: '',
      startDate: '',
      countdown: [],
      emailError: false,
      emailInvalid: false,
      emailWait: false,
      emailAcceptable: true,
      emailBlocked: false,
      codeError: false,
      tokenError: false,
      ssoError: false,
      sending: false,
      sendingCode: false,
      codeInfoModal: false,
      supportModal: false,
      successModal: false,
      codeInfoText: {
        title: 'Не приходит письмо?',
        subtitle: 'Проверьте, пожалуйста, корректность ввода электронного адреса и папку "Спам".<br/>Если адрес был введен некорректно, то письмо не придет. Также письмо могло попасть в "Спам".',
      },
      teamOffLimits: false,
      teamNoInvitation: false,
      teamError: false,
      marketingProgramAttributes: [],
      teamId: '',
    };
  },
  computed: {
    ...mapGetters('settings', ['isPrivate', 'showSSO', 'options', 'resources']),
    ...mapGetters('user', ['emailCheck', 'emailCheckStatus', 'authStatus', 'authToken', 'accessToken', 'isAuthenticated', 'emptyProfile', 'codeDetails', 'codeStatus', 'isMobileApp']),
    ...mapGetters('teams', ['teamLimit', 'teamStatus']),
    ...mapGetters('content', ['rule']),
    countdownText() {
      const c = this.countdown;
      return `${stringPadLeft(c[2], '0', 2)}:${stringPadLeft(c[3], '0', 2)}`;
    },
    isConfirmStep() {
      return this.$route.name === 'AuthConfirm';
    },
    isAuthSSO() {
      return this.$route.name === 'AuthSSO';
    },
    queryToken() {
      return this.$route.query.token;
    },
    queryCode() {
      return this.$route.query.code;
    },
    queryAuthToken() {
      return this.$route.query.authToken;
    },
    loginReturnUrl() {
      return this.$route.query.loginReturnUrl;
    },
    showLoginBlocked() {
      return this.$route.query.showLoginBlocked;
    },
    emailFilters() {
      return this.options ? this.options.emailFilters || [] : [];
    },
    emailDomains() {
      return this.emailFilters.length > 0 ? this.emailFilters.join(', ') : '';
    },
    feelingBlueRafiki() {
      return this.resources ? this.resources.feelingBlueRafiki || '' : '';
    },
    supportReglament() {
      return this.rule ? this.rule.url || '#' : '#';
    },
    supportEmail() {
      return this.resources ? this.resources.supportEmail || '#' : '#';
    },
    supportEmailText() {
      return this.resources ? this.resources.supportEmailText || '' : '';
    },
    emailBlockedSubtitle() {
      return `Результаты аннулированы за нарушение <a href="${this.supportReglament}" target="_blank">регламента проекта</a>.<br/><br/>
      Если вы считаете, что это произошло по ошибке,<br/>
      <a @click="clickModalSupport">напишите в службу поддержки</a>.<br/>
      Порядок подачи апелляции прописан в <a href="${this.supportReglament}" target="_blank">регламенте</a>.`;
    },
    teamOffLimitsSubtitle() {
      return `В вашей команде больше нет свободных слотов для участия. Попросите организатора в вашей компании связаться с нами<br/>
      <a href="mailto:${this.supportEmail}">${this.supportEmailText || this.supportEmail}</a>`;
    },
    teamNoInvitationSubtitle() {
      return this.resources ? this.resources.teamNoInvitationSubtitle || '' : '';
    },
    isClubEnabled() {
      return this.options ? this.options.isClubEnabled === true : false;
    },
  },
  watch: {
    email(val) {
      if (val) {
        this.emailError = false;
      }
      this.emailInvalid = false;
      this.emailAcceptable = true;
      this.teamError = false;
    },
  },
  methods: {
    ...mapActions('user', ['checkByEmail', 'startAuth', 'approveAuth', 'getProfile', 'getCodeDetails']),
    ...mapActions('teams', ['getTeamLimit']),
    ...mapActions('content', ['getRule']),
    ...mapActions('exercises', ['sendSupport']),
    onHashChange() {
      const hash = window.location.hash.split('=');
      if (hash[0] === '#!showSupport') this.showSupport(hash);
      else if (hash[0] === '#!showSuccess') this.showSuccess(hash);
      else {
        this.clearHash();
        this.supportModal = false;
        this.successModal = false;
      }
    },
    onSupport() {
      this.emailBlocked = false;
      this.$router.replace({ path: '/auth' });
      setTimeout(() => { window.location.hash = '#!showSupport=true'; }, 100);
    },
    onSupportModalClose() {
      window.location.hash = '#!showSupport=false';
    },
    onSuccess() {
      window.location.hash = '#!showSuccess=true';
    },
    onSuccessModalClose() {
      window.location.hash = '#!showSuccess=false';
      this.email = '';
    },
    handleFormSubmit(formData) {
      this.sendSupport(formData)
        .then(() => {
          this.email = formData.email;
          this.supportModal = false;
          setTimeout(() => { window.location.hash = '#!showSuccess=true'; }, 100);
        });
    },
    onEmailKeydown(event) {
      if (event.key === 'Enter') {
        this.onGetCode();
      }
    },
    onSSO() {
      this.sending = true;
      this.ssoError = false;
      const baseUrl = 'https://russiarunning.com';
      const currentUrl = new URL(`${window.location.origin}/sso?type=RR`);
      currentUrl.searchParams.append('from', 'mp');
      const encodedUrl = encodeURIComponent(currentUrl.href);
      const loginUrl = `${baseUrl}/login?returnUrl=${encodedUrl}`;
      window.location.href = loginUrl;
    },
    async onGetCode() {
      if (this.sending) {
        return;
      }

      await this.setProgramAttributes();

      if (!this.isPrivate) {
        this.getCode();
        return;
      }

      this.checkEmailStatus();
    },
    onGetCodeAgain() {
      this.removeAuthFromStorage();
      setTimeout(() => { this.onGetCode(); }, 100);
    },
    onKeyDown(digitIndex, event) {
      if (digitIndex < 0 || digitIndex > 3) return;
      const r = new RegExp(/^[0-9]$/);
      const exclude = new RegExp(/^[-+.,e]$/, 'i');
      if (r.test(event.key)) {
        if (this.code[digitIndex].length === 1) {
          event.preventDefault();
          return;
        }
        if (digitIndex + 1 <= 3) {
          const nextItem = document.getElementById(`login-auth-code${digitIndex + 1}`);
          if (nextItem) {
            setTimeout(() => {
              nextItem.focus();
              if (this.code[digitIndex + 1].length === 1) {
                setTimeout(() => { this.sendCode(); }, 100);
              }
            }, 100);
          }
        } else {
          setTimeout(() => { this.sendCode(); }, 100);
        }
      } else if (exclude.test(event.key)) {
        event.preventDefault();
        return;
      } else if (event.key === 'Backspace') {
        if (this.code[digitIndex].length === 0 && digitIndex > 0) {
          this.code[digitIndex - 1] = '';
          const prevItem = document.getElementById(`login-auth-code${digitIndex - 1}`);
          if (prevItem) setTimeout(() => { prevItem.focus(); }, 100);
        }
      }
      this.codeError = false;
      this.tokenError = false;
    },
    onInput(value) {
      if (typeof value !== 'string') return;
      const code = value.trim();
      for (let i = 0; i < 4; i += 1) {
        this.code[i] = code[i] !== undefined ? code[i] : '';
      }
      if (code.length >= 4) {
        setTimeout(() => { this.sendCode(); }, 500);
      } else {
        const nextItem = document.getElementById(`login-auth-code${code.length}`);
        if (nextItem) setTimeout(() => { nextItem.focus(); }, 100);
      }
    },
    onResetAuth() {
      this.removeAuthFromStorage();
      this.email = null;
      this.code = ['', '', '', ''];
      this.token = '';
      this.step = 'start';
      this.setFocusToEmail();
    },
    onEmailBlockedClose() {
      this.removeAuthFromStorage();
      const url = '/auth';
      this.$router.replace(url).catch(() => {});
      window.location.reload();
    },
    onTeamOffLimitsClose() {
      this.teamOffLimits = false;
      setTimeout(() => { this.$router.push({ name: 'Home' }).catch(() => {}); }, 500);
    },
    onTeamNoInvitationClose() {
      this.teamNoInvitation = false;
      setTimeout(() => { this.$router.push({ name: 'Home' }).catch(() => {}); }, 500);
    },
    getCode() {
      this.emailInvalid = false;
      this.emailWait = false;
      this.emailError = (!this.email);
      this.emailAcceptable = this.isDomainAcceptable(this.email);
      if (this.emailError) {
        return;
      }
      if (!this.emailAcceptable) {
        this.emailError = true;
        return;
      }
      this.teamError = false;
      this.sending = true;

      const payload = {
        type: authTypes.email, emailAddress: this.email, attributes: this.marketingProgramAttributes,
      };

      this.startAuth(payload)
        .then(() => {
          if (this.authStatus === 200) {
            this.sending = false;
            this.token = this.authToken;
            this.startDate = new Date(Date.now() + 60000).toISOString();
            localStorage.setItem('authEmail', this.email);
            localStorage.setItem('authToken', this.authToken);
            localStorage.setItem('authDate', (Date.now() + 60000).toString());
            this.code = ['', '', '', ''];
            this.step = 'code';
            this.updateCountdown();
            setTimeout(() => {
              const item = document.getElementById('login-auth-code0');
              if (item) item.focus();
            }, 100);
          } else {
            this.sending = false;

            if (this.authStatus === 500) {
              this.emailError = true;
              this.emailInvalid = true;
            }

            if (this.authStatus === 422 && this.codeDetails === 'user_is_blocked') {
              this.emailBlocked = true;
            }

            if (this.authStatus === 422 && this.codeDetails === 'invitation_required') {
              this.teamNoInvitation = true;
            }
            if (this.authStatus === 422 && this.codeDetails === 'team_is_completed') {
              this.teamOffLimits = true;
            }
            if (this.authStatus === 422 && this.codeDetails === 'team_not_found') {
              this.teamOffLimits = true;
            }
            if (this.authStatus === 422 && this.codeDetails === 'bad_request') {
              this.emailError = true;
              this.emailWait = true;
            }
            if (this.authStatus === 403 || this.authStatus === 409) {
              this.emailBlocked = true;
            }
          }
        });
    },
    async setProgramAttributes() {
      const marketingProgramCode = sessionStorage.getItem('marketingProgramCode');
      const invitationTeamId = sessionStorage.getItem('invitationTeamId');
      if (marketingProgramCode) {
        await this.getCodeDetails({ code: marketingProgramCode });
        this.marketingProgramAttributes = this.codeDetails?.code?.attributes || [];
        const teamId = this.marketingProgramAttributes.find((i) => i.key === 'TeamId');
        if (teamId) {
          this.teamId = teamId.value;
        } else if (invitationTeamId) {
          this.marketingProgramAttributes.push({ key: 'TeamId', value: invitationTeamId });
          this.teamId = invitationTeamId;
        }
      } else if (invitationTeamId) {
        this.marketingProgramAttributes.push({ key: 'TeamId', value: invitationTeamId });
        this.teamId = invitationTeamId;
      }
    },
    checkEmailStatus() {
      this.checkByEmail({ emailAddress: this.email })
        .then(() => {
          if (this.emailCheckStatus === 200 && this.emailCheck) {
            // Участника удалили из команды в "закрытой" МП:
            if (this.emailCheck.hasTeam === false && this.emailCheck.hasParticipant === true && this.emailCheck.hasUser === true) {
              this.teamNoInvitation = true;
              return;
            }

            if (this.teamId) {
              if (this.emailCheck.hasUser === false) {
                this.getTeamLimit({ teamId: this.teamId })
                  .then(() => {
                    if (this.teamStatus === 200) {
                      if (this.teamLimit?.limit && this.teamLimit?.completed) {
                        if (this.teamLimit.completed >= this.teamLimit.limit) {
                          this.teamOffLimits = true;
                          return;
                        }
                      }
                      this.getCode();
                      return;
                    }
                    this.teamError = true;
                  });
                return;
              }
              this.getCode();
            } else if (!this.teamId) {
              if (this.emailCheck.hasUser === false) {
                this.teamNoInvitation = true;
                return;
              }
              this.getCode();
            } else {
              this.teamError = true;
            }
            return;
          }
          this.teamError = true;
        });
    },
    setCode() {
      const code = this.queryCode.slice(0, 4);
      if (code.length !== 4) return;
      for (let i = 0; i < 4; i += 1) {
        this.code[i] = code[i];
      }
    },
    sendCode() {
      for (let i = 0; i < 4; i += 1) {
        if (this.code[i] === '') {
          return;
        }
      }
      this.codeError = false;
      this.tokenError = false;
      this.sendingCode = true;
      this.approveAuth({ token: this.token, code: this.code.join('') })
        .then(() => {
          if (this.authStatus === 200) {
            this.checkProfile();
          } else {
            this.sendingCode = false;
            // 401 - invalid code
            if (this.authStatus === 401) {
              this.codeError = true;
            }
            // 422 - invalid token
            if (this.authStatus === 422) {
              this.codeError = true;
              this.tokenError = true;
            }
            // 409 - user blocked
            if (this.authStatus === 409) {
              this.emailBlocked = true;
            }
          }
        });
    },
    checkProfile() {
      this.getProfile()
        .then(() => {
          if (this.isAuthenticated) {
            this.removeAuthFromStorage();
            if (this.emptyProfile === true) {
              this.$router.push({ name: 'Register' }).catch(() => {});
              return;
            }
            if (this.loginReturnUrl) {
              window.location.href = this.loginReturnUrl;
              return;
            }
            if (this.isClubEnabled) {
              this.$router.push({ name: 'Club' }).catch(() => {});
            } else {
              this.$router.push({ name: 'Dashboard' }).catch(() => {});
            }
          }
        });
    },
    async authSSO() {
      this.sending = true;
      this.ssoError = false;

      await this.setProgramAttributes();

      const payload = {
        type: authTypes.ssoRR, russiaRunningToken: this.queryAuthToken, attributes: this.marketingProgramAttributes,
      };

      this.startAuth(payload)
        .then(() => {
          if (this.authStatus === 200) {
            this.approveAuth({ token: this.authToken })
              .then(() => {
                if (this.authStatus === 200) {
                  sessionStorage.setItem('authFromSSO', 'true');
                  this.checkProfile();
                  return;
                }

                this.ssoError = true;
                this.sending = false;
              });
            return;
          }

          this.ssoError = true;
          this.sending = false;
        });
    },
    updateCountdown() {
      if (this.step !== 'code') return;
      const countdown = getCountdown(this.startDate, false);
      if (!countdown.finished) {
        setTimeout(() => { this.updateCountdown(); }, 1000);
      }
      this.countdown = countdown.values;
    },
    setFocusToEmail() {
      if (this.step !== 'start') return;
      setTimeout(() => {
        const input = document.getElementById('login-email');
        if (!input) return;
        input.focus();
      }, 500);
    },
    isDomainAcceptable(email) {
      if (!email) return true;
      const index = email.indexOf('@');
      if (index < 0 || index === email.length - 1) return true;
      if (this.emailFilters.length === 0) return true;
      const domain = email.slice(index + 1);
      if (this.emailFilters.includes(domain.toLowerCase())) return true;
      return false;
    },
    removeAuthFromStorage() {
      localStorage.removeItem('authEmail');
      localStorage.removeItem('authToken');
      localStorage.removeItem('authDate');
    },
    showSupport(hash) {
      if (hash[1] === 'true') this.supportModal = true;
      else {
        this.supportModal = false;
        this.clearHash();
      }
    },
    showSuccess(hash) {
      if (hash[1] === 'true') this.successModal = true;
      else {
        this.successModal = false;
        this.clearHash();
      }
    },
    clearHash() {
      setTimeout(() => { window.location.hash = ''; }, 100);
    },
  },
  async mounted() {
    window.addEventListener('hashchange', this.onHashChange);
    this.onHashChange();

    this.getRule();

    if (this.isAuthSSO) {
      await this.authSSO();
      return;
    }

    if (this.showLoginBlocked) {
      this.email = this.showLoginBlocked === 'true' ? '' : this.showLoginBlocked;
      this.emailBlocked = true;
      return;
    }

    if (this.isConfirmStep) {
      if (this.queryToken && this.queryCode) {
        this.setCode();
        this.token = unescape(this.queryToken).replaceAll(' ', '+');
        this.step = 'code';
        setTimeout(() => { this.sendCode(); }, 500);
      } else {
        this.$router.push({ name: 'Auth' }).catch(() => {});
      }
    } else {
      const authEmail = localStorage.getItem('authEmail');
      const authToken = localStorage.getItem('authToken');
      const authDate = localStorage.getItem('authDate');
      if (authEmail && authToken && authDate) {
        if (Date.now() >= authDate) {
          this.removeAuthFromStorage();
          this.setFocusToEmail();
          return;
        }
        this.email = authEmail;
        this.token = authToken;
        this.startDate = new Date(parseInt(authDate, 10)).toISOString();
        this.code = ['', '', '', ''];
        this.step = 'code';
        this.updateCountdown();
        setTimeout(() => {
          const item = document.getElementById('login-auth-code0');
          if (item) item.focus();
        }, 100);
      } else {
        this.removeAuthFromStorage();
      }
    }

    this.setFocusToEmail();
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import "./Login";
</style>

<template>
  <Modal
    name="onboarding-finish"
    title="Трекер подключён!"
    :img="currentImg"
    :active="shown"
    v-bind="settings"
    @close.prevent="$emit('close')"
  >
    <div class="text-center">
      <div class="onboarding-finish__images">
        <div v-if="!desktop">
          <a v-if="iOS" :href="trackerDetails ? trackerDetails.ios || '#' : '#'" target="_blank"><img :src="appStoreImg"/></a>
          <a v-if="android" :href="trackerDetails ? trackerDetails.android || '#' : '#'" target="_blank"><img :src="googlePlayImg"/></a>
          <a v-if="huawei && trackerDetails && trackerDetails.huawei !== ''" :href="trackerDetails.huawei" target="_blank"><img :src="appGalleryImg"/></a>
          <a v-if="huawei && trackerDetails && trackerDetails.huawei === ''" :href="trackerDetails ? trackerDetails.android || '#' : '#'" target="_blank"><img :src="googlePlayImg"/></a>
        </div>
        <div v-if="desktop">
          <a :href="trackerDetails ? trackerDetails.android || '#' : '#'" target="_blank"><img :src="googlePlayImg"/></a>
          <a :href="trackerDetails ? trackerDetails.ios || '#' : '#'" target="_blank"><img :src="appStoreImg"/></a>
          <a v-if="trackerDetails && trackerDetails.huawei !== ''" :href="trackerDetails.huawei" target="_blank"><img :src="appGalleryImg"/></a>
        </div>
      </div>
      <div class="onboarding-finish__subtitle">Результаты спортивной активности будут синхронизироваться автоматически ежедневно и учитываться в выбранных вами заданиях. А любая тренировка принесёт вам очки рейтинга и баллы.</div>
      <div>
        <Button
          text="Продолжить"
          variant="secondary"
          size="lg"
          :wide="$root.isMobile ? true : false"
          mobile
          @click="$emit('close')"
        ></Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Garmin from '@rr-component-library/common/src/img/apps/garmin-app.png';
import GoogleFit from '@rr-component-library/common/src/img/apps/google-fit-app.png';
import Polar from '@rr-component-library/common/src/img/apps/polar-app.png';
import Strava from '@rr-component-library/common/src/img/apps/strava-app.png';
import Suunto from '@rr-component-library/common/src/img/apps/suunto-app.png';
import SimpleRun from '@rr-component-library/common/src/img/apps/simple-run-app.png';
import GooglePlayImg from '@/assets/img/connection-guide/google-play.svg';
import AppStoreImg from '@/assets/img/connection-guide/app-store.svg';
import AppGalleryImg from '@/assets/img/connection-guide/app-gallery.svg';
import Trackers from '@/assets/data/trackers';

export default {
  name: 'Finish',
  props: {
    shown: Boolean,
    provider: String,
    settings: Object,
  },
  data() {
    return {
      trackers: [
        { id: 'Garmin', img: Garmin },
        { id: 'GoogleFit', img: GoogleFit },
        { id: 'Polar', img: Polar },
        { id: 'Strava', img: Strava },
        { id: 'Suunto', img: Suunto },
        { id: 'SimpleRun', img: SimpleRun },
      ],
      android: false,
      iOS: false,
      huawei: false,
      desktop: false,
      googlePlayImg: GooglePlayImg,
      appStoreImg: AppStoreImg,
      appGalleryImg: AppGalleryImg,
    };
  },
  computed: {
    currentImg() {
      const found = this.trackers.filter((i) => i.id === this.provider);
      return found.length > 0 ? found[0].img : '';
    },
    trackerDetails() {
      const found = Trackers.filter((i) => i.id === this.provider.toLowerCase());
      return found.length > 0 ? found[0] : null;
    },
  },
  watch: {
    shown(val) {
      if (val) {
        this.detectOS();
      }
    },
  },
  methods: {
    detectOS() {
      if (this.$root.isDesktop) {
        this.desktop = true;
      } else {
        const userAgent = window.navigator.userAgent || window.navigator.vendor || window.window.opera;
        if (/huawei/i.test(userAgent)) {
          this.huawei = true;
          return;
        }
        const { platform } = window.navigator;
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        if (iosPlatforms.indexOf(platform) !== -1) {
          this.iOS = true;
          return;
        }
        this.android = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .rr-modal__window {
    border-radius: 8px;
  }

  ::v-deep .rr-modal__img img {
    width: 48px;
    height: 48px;
  }

  ::v-deep .rr-modal__subtitle a,
  ::v-deep .rr-modal__subtitle a:link,
  ::v-deep .rr-modal__subtitle a:hover,
  ::v-deep .rr-modal__subtitle a:active,
  ::v-deep .rr-modal__subtitle a:visited {
    color: var(--color-primary, #FF8233);
    font-weight: 600;
    text-decoration: none;
  }

  ::v-deep .rr-button {
    border-radius: 4px;
  }

  ::v-deep .rr-modal__item:nth-child(2) {
    @media (min-width: breakpoint(md)) {
      margin-top: 40px !important;
    }
  }

  .onboarding-finish {
    &__images {
      padding-top: 1rem;
      @include v-spacing('margin-bottom', l);

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        height: 48px;

        @media (min-width: breakpoint(md)) {
          height: 40px;
        }
      }

      @media (min-width: breakpoint(md)) {
        padding-top: 0;
        margin-bottom: 40px !important;

        a {
          margin-right: 20px;
        }

        a:last-child {
          margin-right: 0;
        }
      }
    }

    &__subtitle {
      font-size: map-get($text-md, font-size);
      line-height: map-get($text-md, line-height);
      font-weight: normal;
      color: $color-black-85;
      padding-top: 1rem;
      @include v-spacing('padding-bottom', l);
      margin-bottom: 1rem;

      @media (min-width: breakpoint(md)) {
        padding-top: 0;
        margin-bottom: 0;
      }
    }
  }
</style>

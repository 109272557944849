/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import scountApi from '@/services/scount-api';

const state = {
  tasksGuest: null,
  taskGuest: null,
  taskPoints: null,
  tasksAvailable: null,
  tasksInProgress: null,
  tasksCompleted: null,
  task: null,
  disciplines: null,
  responseStatus: null,
  cancelStatus: null,
  sendStatus: null,
};

const mutations = {
  setGuest(state, value) {
    state.tasksGuest = value;
  },
  setTaskGuest(state, value) {
    state.taskGuest = value;
  },
  setTaskPoints(state, value) {
    state.taskPoints = value;
  },
  setAvailable(state, value) {
    state.tasksAvailable = value;
  },
  setInProgress(state, value) {
    state.tasksInProgress = value;
  },
  setCompleted(state, value) {
    state.tasksCompleted = value;
  },
  setTask(state, value) {
    state.task = value;
  },
  setDisciplines(state, value) {
    state.disciplines = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
  setCancelStatus(state, value) {
    state.cancelStatus = value;
  },
  setSendStatus(state, value) {
    state.sendStatus = value;
  },
};

const getters = {
  tasksGuest: (state) => state.tasksGuest,
  taskGuest: (state) => state.taskGuest,
  taskPoints: (state) => state.taskPoints,
  tasksAvailable: (state) => state.tasksAvailable,
  tasksInProgress: (state) => state.tasksInProgress,
  tasksCompleted: (state) => state.tasksCompleted,
  task: (state) => state.task,
  disciplines: (state) => state.disciplines,
  responseStatus: (state) => state.responseStatus,
  cancelStatus: (state) => state.cancelStatus,
  sendStatus: (state) => state.sendStatus,
};

const actions = {
  getTasksGuest({ commit }, { offset, count }) {
    return scountApi.getExercisesGuest({
      sortBy: 'startDate', descending: true, offset, count,
    })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setGuest', response.data);
        } else {
          commit('setGuest', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getTaskGuest({ commit }, exerciseId) {
    return scountApi.getExerciseGuest({ exerciseId })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setTaskGuest', response.data);
        } else {
          commit('setTaskGuest', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
        commit('setTaskGuest', null);
      });
  },
  getTaskPoints({ commit }, exerciseId) {
    return scountApi.getExercisePoints({ exerciseId })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setTaskPoints', response.data);
        } else {
          commit('setTaskPoints', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
        commit('setTaskPoints', null);
      });
  },
  getTasksAvailable({ commit }, { discipline, offset, count }) {
    return scountApi.getExercises({
      state: 'Available', discipline, sortBy: 'endDate', descending: false, offset, count,
    })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setAvailable', response.data);
        } else {
          commit('setAvailable', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getTasksInProgress({ commit }, { offset, count }) {
    return scountApi.getExercises({
      state: 'InProgress', sortBy: 'endDate', descending: false, offset, count,
    })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setInProgress', response.data);
        } else {
          commit('setInProgress', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getTasksCompleted({ commit }, { offset, count }) {
    return scountApi.getExercises({
      state: 'Completed', sortBy: 'completedOn', descending: true, offset, count,
    })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setCompleted', response.data);
        } else {
          commit('setCompleted', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getTask({ commit }, exerciseId) {
    return scountApi.getExercise({ exerciseId })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setTask', response.data);
        } else {
          commit('setTask', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
        commit('setTask', null);
      });
  },
  assignTask({ commit }, { participantExerciseId, exerciseId }) {
    return scountApi.assignExercise({ participantExerciseId, exerciseId })
      .then((response) => {
        commit('setResponseStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  cancelTask({ commit }, { participantExerciseId }) {
    return scountApi.cancelExercise({ participantExerciseId })
      .then((response) => {
        commit('setCancelStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setCancelStatus', status || 0);
      });
  },
  getDisciplines({ commit }) {
    return scountApi.getDisciplines()
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setDisciplines', response.data);
        } else {
          commit('setDisciplines', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
        commit('setDisciplines', null);
      });
  },
  sendSupport({ commit }, {
    email, text, title, file,
  }) {
    return scountApi.sendSupport({
      email, text, title, file,
    })
      .then((response) => {
        commit('setSendStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setSendStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import scountApi from '@/services/scount-api';

const state = {
  teams: null,
  team: null,
  teamLimit: null,
  teamStatus: null,
};

const mutations = {
  setTeams(state, value) {
    state.teams = value;
  },
  setTeam(state, value) {
    state.team = value;
  },
  setTeamLimit(state, value) {
    state.teamLimit = value;
  },
  setTeamStatus(state, value) {
    state.teamStatus = value;
  },
};

const getters = {
  teams: (state) => state.teams,
  team: (state) => state.team,
  teamLimit: (state) => state.teamLimit,
  teamStatus: (state) => state.teamStatus,
};

const actions = {
  getTeams({ commit }, { searchString, offset, count }) {
    return scountApi.getTeams({ searchString, offset, count })
      .then((response) => {
        commit('setTeamStatus', response.status);
        if (response.status === 200) {
          commit('setTeams', response.data);
        } else {
          commit('setTeams', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setTeamStatus', status || 0);
      });
  },
  getTeamById({ commit }, { teamId }) {
    return scountApi.getTeamById({ teamId })
      .then((response) => {
        commit('setTeamStatus', response.status);
        if (response.status === 200) {
          commit('setTeam', response.data);
        } else {
          commit('setTeam', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setTeamStatus', status || 0);
      });
  },
  getTeamLimit({ commit }, { teamId }) {
    return scountApi.getTeamLimit({ teamId })
      .then((response) => {
        commit('setTeamStatus', response.status);
        if (response.status === 200) {
          commit('setTeamLimit', response.data);
        } else {
          commit('setTeamLimit', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setTeamStatus', status || 0);
      });
  },
  sendTeamApplication({ commit }, { application }) {
    return scountApi.sendTeamApplication({ application })
      .then((response) => {
        commit('setTeamStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setTeamStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

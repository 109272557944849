import api from './api';

export default {
  getStatistic({
    startFrom, startTo, refereeingSystemCheckState = 'Accepted',
  }) {
    return api.get('/sportactivities/api/v1/activities/statistic', {
      params: {
        startFrom,
        startTo,
        refereeingSystemCheckState,
      },
    });
  },
  getActivities({
    startFrom, startTo, discipline, refereeingSystemCheckState, offset, count, sortBy = 'startDate', descending = 'true',
  }) {
    return api.get('/sportactivities/api/v1/activities', {
      params: {
        startFrom,
        startTo,
        discipline,
        refereeingSystemCheckState,
        offset,
        count,
        sortBy,
        descending,
      },
    });
  },
  getIndexes() {
    return api.get('/sportactivities/api/v1/sport-indexes');
  },
};

import api from './api';

export default {
  getOnboardingItems() {
    return api.get('/content/api/v1/onboarding');
  },
  getFAQThemes() {
    return api.get('/content/api/v1/faq/themes');
  },
  getFAQTheme({ themeId }) {
    return api.get(`/content/api/v1/faq/themes/${themeId}`);
  },
  getFAQThemeAnswers({ themeId, themeQuestionId }) {
    return api.get(`/content/api/v1/faq/themes/${themeId}/questions/${themeQuestionId}/answers`);
  },
  getRule() {
    return api.get('/content/api/v1/rules');
  },
  getAmbassadors() {
    return api.get('/content/api/v1/ambassadors');
  },
  getBanners() {
    return api.get('/content/api/v1/banners');
  },
};

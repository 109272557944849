import api from './api';

export default {
  startAuth({
    type, emailAddress, russiaRunningToken, attributes,
  }) {
    return api.post('/identity/api/v1/authentication', {
      type,
      emailAddress,
      russiaRunningToken,
      attributes,
    });
  },
  approveAuth({ token, code }) {
    return api.patch('/identity/api/v1/authentication', {
      token,
      code,
    });
  },
};

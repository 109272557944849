<template>
  <div v-show="state" class="setting-up-guide">
    <div class="setting-up-guide__container">
      <div class="setting-up-guide__close" @click.stop="onHide">
        <div class="setting-up-guide__circle">
          <Icon name="close" />
        </div>
      </div>
      <div class="setting-up-guide__content">
        <div class="setting-up-guide__icon"><Icon :name="icon" /></div>
        <div class="setting-up-guide__text" v-html="text"></div>
        <div class="setting-up-guide__action">
          <Button
            variant="primary"
            size="md"
            mobile
            :wide="$root.isMobile"
            :text="actionText"
            :iconRight="actionIcon || ''"
            :iconLeft="actionIconLeft || ''"
            :href="href"
            :target="showTarget"
            @click="onAction"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SettingUpGuide',
  props: {
    icon: String,
    text: String,
    actionText: String,
    actionIcon: String,
    actionIconLeft: String,
    actionLink: String,
    href: String,
    state: {
      type: Boolean,
      default: true,
    },
    storageKey: {
      type: String,
      default: 'hideSettingUpGuide',
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onHide() {
      this.$emit('hide');
      // sessionStorage.setItem(this.storageKey, 'true');
    },
    onAction() {
      if (!this.href) {
        this.$emit('click');
      }
    },
  },
  computed: {
    showTarget() {
      return this.href ? '_blank' : false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./SettingUpGuide";
</style>

<template>
  <form
    class="form"
    novalidate="novalidate"
    @submit.prevent="submit"
    v-if="isShown"
  >
    <div class="form__title">Заявка на регистрацию команды</div>
    <div class="form__subtitle">
      Оператор проверит информацию о вашем клубе или ЗОЖ сообществе и создаст команду.  Вам на email придет уведомление по факту создания команды.
    </div>
    <div class="form__container">
      <div class="form__item">
        <Field
          label="Название"
          label-for="title"
          row
        >
          <Input
            v-model.trim="fields.title"
            placeholder="Название клуба"
            id="title"
            name="title"
            :state="
              showErrors && $v.fields.title.$error ? 'error' : null
            "
            :disabled="sending"
          ></Input>
          <template v-slot:msg>
            <div class="color-danger" v-if="$v.fields.title.$error">Введите название клуба</div>
          </template>
        </Field>
      </div>
      <div class="form__item">
        <Field
          label="Ваши ФИО"
          label-for="fullName"
          row
        >
          <Input
            v-model.trim="fields.fullName"
            placeholder="Смирнов Денис Альбертович"
            id="fullName"
            name="fullName"
            :state="
              showErrors && $v.fields.fullName.$error ? 'error' : null
            "
            :disabled="sending"
          ></Input>
          <template v-slot:msg>
            <div class="color-danger" v-if="$v.fields.fullName.$error">Введите ваши ФИО</div>
          </template>
        </Field>
      </div>
      <div class="form__item">
        <Field
          label="Ваш Email"
          label-for="email"
          row
        >
          <Input
            v-model.trim="fields.email"
            placeholder="example@email.com"
            id="email"
            name="email"
            type="email"
            :state="
              showErrors && $v.fields.email.$error ? 'error' : null
            "
            :disabled="sending"
          ></Input>
          <template v-slot:msg>
            <div class="color-danger" v-if="$v.fields.email.$error">Проверьте правильность email</div>
          </template>
        </Field>
      </div>
      <div class="form__item">
        <Field
          label="Ваша позиция в клубе"
          label-for="position"
          row
        >
          <Input
            v-model.trim="fields.position"
            placeholder="Руководитель, администратор"
            id="position"
            name="position"
            :state="
              showErrors && $v.fields.position.$error ? 'error' : null
            "
            :disabled="sending"
          ></Input>
          <template v-slot:msg>
            <div class="color-danger" v-if="$v.fields.position.$error">Укажите вашу позицию</div>
          </template>
        </Field>
      </div>
      <div v-show="false">
        <form id="imageForm" action="#" method="post" enctype="multipart/form-data">
          <input id="imageInput" name="imageInput" type="file" accept="image/jpeg,image/png" />
        </form>
      </div>
      <div class="form__item">
        <Field
          label="Логотип"
          label-for="logo"
          row
        >
          <Input
            v-model="fields.logo"
            placeholder="Выберите файл до 2Mb…"
            id="logo"
            name="logo"
            iconLeft="upload"
            :readonly="true"
            :state="
              showErrors && $v.fields.logo.$error ? 'error' : null
            "
            :disabled="sending"
            @click="onSelectLogo"
          ></Input>
          <template v-slot:msg>
            <div class="color-danger" v-if="$v.fields.logo.$error">Загрузите логотип в jpg или png</div>
          </template>
        </Field>
      </div>
      <div class="form__item">
        <Field
          label="Где находится клуб"
          label-for="location"
          row
        >
          <Input
            v-model.trim="fields.location"
            :placeholder="!fields.isOnlineClub ? 'Место нахождения' : ''"
            id="location"
            name="location"
            :state="
              showErrors && $v.fields.location.$error ? 'error' : null
            "
            :disabled="fields.isOnlineClub === true || sending"
          ></Input>
          <template v-slot:msg>
            <div class="color-danger" v-if="$v.fields.location.$error">Укажите страну, город или полный адрес клуба</div>
          </template>
        </Field>
      </div>
      <div class="form__item form__item_relative">
        <Field
          label=" "
          label-for="isOnlineClub"
          row
        >
          <Checkbox
            v-model="fields.isOnlineClub"
            id="isOnlineClub"
            name="isOnlineClub"
            :disabled="sending"
            @input="onIsOnlineClubChanged"
          >Онлайн клуб</Checkbox>
        </Field>
      </div>
      <div class="form__item">
        <Field
          label="Ссылка на сайт или соц. сети"
          label-for="link"
          row
        >
          <Input
            v-model.trim="fields.link"
            placeholder="vk.com, telegram"
            id="link"
            name="link"
            type="url"
            :state="
              showErrors && $v.fields.link.$error ? 'error' : null
            "
            :disabled="sending"
          ></Input>
          <template v-slot:msg>
            <div class="color-danger" v-if="$v.fields.link.$error">Проверьте правильность ссылки</div>
          </template>
        </Field>
      </div>
      <div class="form__item">
        <Field
          label="О клубе"
          label-for="description"
        >
          <div class="item__small-label">Кратко опишите специализацию клуба, условия вступления, преимущества</div>
          <textarea
            v-model.trim="fields.description"
            placeholder="Начните писать здесь..."
            id="description"
            name="description"
            class="form__textarea"
            :class="{ 'form__textarea--error': showErrors && $v.fields.description.$error }"
            @input="$v.fields.description.$touch()"
            :disabled="sending"
          ></textarea>
          <template v-slot:msg>
            <div class="color-danger" v-if="showErrors && $v.fields.description.$error">Опишите ваш клуб</div>
          </template>
        </Field>
      </div>

      <div class="section__group">
        <div class="form__item">
          <div class="divider"></div>
        </div>
        <div class="form__item form__alert" v-if="backendMessage">
          <Alert
            :text="backendMessage"
            state="error"
            no-action
          >
          </Alert>
        </div>
        <div class="form__item">
          <Field
            :has-error="showErrors && $v.fields.agreement.$error"
          >
            <Checkbox
              v-model="fields.agreement"
              :disabled="sending"
            >
              С <Link text="условиями" :href="docOferta" variant="primary" target="_blank" /> предоставления услуг ознакомлен и согласен
            </Checkbox>
          </Field>
        </div>
        <div class="form__item">
          <Row align="center">
            <Col :size="{md: '24'}">
              <Button
                text="Отправить заявку"
                variant="primary"
                size="lg"
                type="submit"
                :loading="sending"
                :disabled="!fields.agreement || hasErrors || sending"
                wide
                mobile
              ></Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, sameAs, requiredIf } from 'vuelidate/lib/validators';
import Alert from '@rr-component-library/alert/src/main';
import Field from '@rr-component-library/field/src/main';
import Input from '@rr-component-library/input/src/main';
import Checkbox from '@rr-component-library/checkbox/src/main';

export default {
  name: 'TeamCreateForm',
  mixins: [validationMixin],
  components: {
    Alert,
    Field,
    Input,
    Checkbox,
  },
  props: {
    isShown: Boolean,
  },
  data() {
    return {
      showErrors: false,
      sending: false,
      backendMessage: null,
      fields: {
        title: null,
        fullName: null,
        email: null,
        position: null,
        logo: null,
        location: null,
        isOnlineClub: false,
        link: null,
        description: null,
        agreement: false,
      },
      input: null,
      selectedFile: null,
      avatarImage: '',
      MAX_FILE_SIZE: 2 ** 20 * 2,
    };
  },
  validations: {
    fields: {
      title: { required },
      fullName: { required },
      email: { required },
      position: { required },
      logo: { required },
      location: {
        required: requiredIf(function f() {
          return this.fields.isOnlineClub === false;
        }),
      },
      isOnlineClub: { required },
      link: { required },
      description: { required },
      agreement: {
        sameAs: sameAs(() => true),
      },
    },
  },
  computed: {
    ...mapGetters('settings', ['options', 'resources']),
    ...mapGetters('teams', ['teamStatus']),
    isMyTeamCreate() {
      return this.$route.name === 'MyTeamCreate';
    },
    hasErrors() {
      return !!(this.showErrors && this.$v.$anyError);
    },
    showRegisterAdditionalStep() {
      return this.options ? this.options.showRegisterAdditionalStep === true : false;
    },
    isTeamsEnabled() {
      return this.options ? this.options.isTeamsEnabled === true : false;
    },
    registerSubtitle() {
      return this.resources ? this.resources.registerSubtitle || '' : '';
    },
    docOferta() {
      return this.resources ? this.resources.docOferta || '#' : '#';
    },
  },
  watch: {
    isShown(val) {
      if (val) {
        this.showErrors = false;
        this.sending = false;
      }
    },
  },
  methods: {
    ...mapActions('teams', ['sendTeamApplication']),
    onSelectLogo() {
      if (this.input) this.input.click();
    },
    onPictureChange() {
      if (!this.input) return;

      const curFiles = this.input.files;

      if (curFiles.length === 0) {
        this.input.value = '';
        this.selectedFile = null;
        this.fields.logo = null;
      } else {
        // eslint-disable-next-line prefer-destructuring
        const file = curFiles[0];
        if (this.isFileValid(file)) {
          this.selectedFile = file;
          this.fields.logo = file.name;
        }
      }
    },
    onIsOnlineClubChanged(value) {
      if (value === true) {
        this.fields.location = null;
      }
    },
    setPictureHandlers() {
      const input = document.getElementById('imageInput');
      if (!input) return;
      this.input = input;
      input.onchange = this.onPictureChange;
    },
    isFileValid(file) {
      if (!file) return false;
      if (file.size > this.MAX_FILE_SIZE) return false;
      const name = file.name.toLowerCase();
      if (name.length < 5) return false;
      if (name.slice(name.length - 4) !== '.jpg' && name.slice(name.length - 4) !== '.png') {
        if (name.length < 6) return false;
        if (name.slice(name.length - 5) !== '.jpeg') return false;
      }
      return true;
    },
    getApplication() {
      return {
        title: this.fields.title,
        fullName: this.fields.fullName,
        email: this.fields.email,
        position: this.fields.position,
        logo: this.selectedFile,
        location: this.fields.location,
        isOnlineClub: this.fields.isOnlineClub,
        link: this.fields.link,
        description: this.fields.description,
      };
    },
    submit() {
      if (this.sending) {
        return;
      }
      this.showErrors = true;
      this.$v.$touch();
      if (this.hasErrors) {
        return;
      }
      this.sending = true;
      this.backendMessage = null;
      const application = this.getApplication();
      this.sendTeamApplication({ application })
        .then(() => {
          if (this.teamStatus === 200) {
            const route = this.isMyTeamCreate ? 'MyTeam' : 'Team';
            setTimeout(() => {
              this.$router.push({ name: route, query: { isTeamCreated: 'true' } }).catch(() => {});
            }, 500);
            return;
          }
          this.sending = false;
          this.backendMessage = `Произшла ошибка: ${this.teamStatus}`;
        });
    },
  },
  mounted() {
    this.setPictureHandlers();
    setTimeout(() => { window.scrollTo(0, 0); }, 1000);
  },
};
</script>

<style lang="scss" scoped>
  @import "./TeamCreateForm";
</style>

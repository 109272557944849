<template>
  <div class="profile-experience">
    <div class="profile-experience__xp-achievements">
      <div class="xp-achievements__achievements">
        <div
          v-for="item in activeAchievements"
          :key="item.achievementId"
          class="achievements__item"
          :class="getActiveClass(item)"
          @click.stop="onSelected(item.achievementType)"
        ></div>
        <div
          v-for="(item, i) in grayAchievements"
          :key="i"
          class="achievements__item"
          :class="getGrayClass(item)"
          @click.stop="onSelected(item.achievementType)"
        >
          <img :src="item.img" />
        </div>
        <div
          v-if="countMore > 0"
          class="achievements__item"
          @click.stop="onSelected('Welcome')"
        >
          <img :src="imageMore" />
          <div class="item__more">+{{ countMore }}</div>
        </div>
      </div>
    </div>
    <div class="profile-experience__item">
      <div class="profile-experience__steps">
        <div class="profile-experience__steps-top">
          <Icon name="steps" size="xl"></Icon>
          <span>0</span>
        </div>
        <p>Шагов<br/>сегодня</p>
      </div>
    </div>
    <div v-if="showIndexOFP" class="profile-experience__item">
      <IndexOFP
        v-if="sportIndexes"
        :value="sportIndexes.index"
        :dates="formatDateRange({ start: sportIndexes.startDate, finish: sportIndexes.finishDate })"
        @show="onShowIndexOFP"
      />
    </div>

    <Modal
      :active="achievementsModal"
      name="AchievementsModal"
      title="Мои достижения"
      :subtitle="`Получено ${ achievements ? achievements.length : 0 } / ${ achievementsAll ?achievementsAll.length :0 }`"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="achievementsModal = false"
    >
      <div class="modal-content text-left">
        <div
          v-for="cat in categories"
          :key="cat.id"
          class="modal-content__group"
          :class="{ 'd-none': getCountOfCat(cat.id) === 0 }"
        >
          <div class="modal-content__group-title" @click.stop="!$root.isDesktop ? setCollapsed(cat.id) : null">
            <div class="group-title__name">
              <div class="group-title__text" v-html="cat.name || ''"></div>
              <div class="group-title__count">Получено {{ getActiveOfCatCount(cat.id) }} / {{ getCountOfCat(cat.id) }}</div>
            </div>
            <div class="group-title__icon" @click.stop="setCollapsed(cat.id)">
              <Icon v-if="cat.collapsed" name="down" size="xl" />
              <Icon v-else name="up" size="xl" />
            </div>
          </div>
          <div v-if="!cat.collapsed" class="modal-content__group-details">
            <div
              v-for="item in getAchievementsOfCat(cat.id)"
              :key="item.achievementType"
              class="group-details__achievement text-center"
            >
              <div
                class="group-details__image"
                :class="getActiveClass(item, true)"
                @click.stop="onAchievementDetails(item)"
              ></div>
              <div class="group-details__title" v-html="item.name || ''" @click.stop="onAchievementDetails(item)"></div>
              <div class="group-details__description" v-html="item.desc || ''"></div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <Modal
      :active="descriptionModal"
      name="AchievementDescriptionModal"
      class="achievement__description"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="descriptionModal = false"
    >
      <div class="achievement__description-img" :class="descriptionImageClass"></div>
      <div class="achievement__description-title" v-html="descriptionTitle"></div>
      <div class="achievement__description-subtitle" v-html="descriptionSubtitle"></div>
      <Button
        text="Назад к достижениям"
        variant="secondary"
        size="lg"
        wide
        mobile
        @click="descriptionModal = false"
      ></Button>
    </Modal>

    <Modal
      :active="indexOFPModal"
      :img="ofpImage"
      :title="ofpTitle"
      :subtitle="ofpSubtitle"
      name="IndexOFPModal"
      class="index-ofp"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="indexOFPModal = false"
    >
      <div class="ofp">
        <div class="ofp__value">
          <Slider
            v-if="indexOFPModal && ofpValues.length > 0"
            :initialSlide="initialSlide"
            :navigationOffset="navigationOffset"
            :noPagination="noPagination"
            class="ofp__slider"
          >
            <IndexOFP
              v-for="(item, i) in ofpValues"
              :key="i"
              :value="ofpValues[i].value"
              :dates="ofpValues[i].dates"
              :simple="true"
            />
          </Slider>
        </div>
        <div class="ofp__description" v-html="ofpDescription"></div>
        <Button
          class="ofp-more"
          text="Узнать больше"
          variant="secondary"
          tag="a"
          :target="!isMobileApp ? '_blank' : ''"
          :href="articleAboutIndexOFP"
          size="lg"
          mobile
        ></Button>
      </div>
    </Modal>

    <SetAvatarModal
      :active="showSetAvatar"
      :isMobileApp="isMobileApp"
      class="set-avatar"
      @close="showSetAvatar = false"
    ></SetAvatarModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import SetAvatarModal from '@/components/SetAvatarModal/SetAvatarModal.vue';
import IndexOFP from '@/components/IndexOFP/IndexOFP.vue';
import AchievementsAll from '@/assets/data/achievements';
import WHOImage from '@/assets/img/index-ofp/who.svg';
import { formatDateRange } from '@/assets/js/helpers';

dayjs.extend(weekday);

const DIR = '/img/achievements';

export default {
  name: 'ProfileExperience',
  props: {
    firstName: String,
    avatar: String,
    experience: Object,
    achievements: Array,
    sportIndexes: Object,
    hideEdit: Boolean,
    isMobileApp: Boolean,
  },
  components: {
    SetAvatarModal,
    IndexOFP,
  },
  data() {
    return {
      achievementsAll: AchievementsAll,
      categories: [
        { id: 'common', name: 'Общие', collapsed: false },
        { id: 'walking', name: 'Ходьба', collapsed: true },
        { id: 'running', name: 'Бег', collapsed: true },
        { id: 'cycling', name: 'Велозаезд', collapsed: true },
        { id: 'swimming', name: 'Плавание', collapsed: true },
        { id: 'skiing', name: 'Лыжи', collapsed: true },
        { id: 'market', name: 'Маркет', collapsed: true },
      ],
      imageMore: `${DIR}/system-empty.svg`,
      countMore: 0,
      activeAchievements: [],
      grayAchievements: [],
      maxMobileItems: 8,
      maxDesktopItems: 8,
      achievementsModal: false,
      descriptionModal: false,
      descriptionImageClass: '',
      descriptionTitle: '',
      descriptionSubtitle: '',
      showSetAvatar: false,
      indexOFPModal: false,
      ofpImage: WHOImage,
      ofpTitle: 'ОФП-индекс на основе<br/>рекомендаций ВОЗ',
      ofpSubtitle: 'Еженедельно достигая <b>150 ОФП</b>, вы можете продлить себе жизнь, улучшить качество сна и поднять настроение.',
      ofpDescription: 'Индекс ОФП повышается за каждую минуту активных физических нагрузок, например бега.',
      ofpValues: [],
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'options', 'resources']),
    currentLevel() {
      return this.experience ? this.experience.level : 1;
    },
    currentValue() {
      return this.experience ? this.experience.value : 0;
    },
    nextLevelValue() {
      return this.experience ? this.experience.nextLevelValue : 0;
    },
    currentLevelStartValue() {
      return this.experience ? this.experience.currentLevelStartValue : 0;
    },
    needXP() {
      return this.nextLevelValue - this.currentValue;
    },
    showIndexOFP() {
      return this.options ? this.options.showIndexOFP === true : false;
    },
    showAchievementTypes() {
      return this.options ? this.options.showAchievementTypes || [] : [];
    },
    articleAboutIndexOFP() {
      return this.resources ? this.resources.articleAboutIndexOFP : '#';
    },
    navigationOffset() {
      return {
        default: '0', md: '72', lg: '72', xl: '84',
      };
    },
    noPagination() {
      return true;
    },
    initialSlide() {
      return this.ofpValues.length > 0 ? this.ofpValues.length - 1 : 0;
    },
  },
  methods: {
    onSelected(achievementType) {
      if (achievementType) {
        this.achievementsModal = true;
      }
    },
    onAchievementDetails(item) {
      if (item && !this.$root.isDesktop) {
        this.descriptionImageClass = this.getDescriptionImageClass(item);
        this.descriptionTitle = item.name;
        this.descriptionSubtitle = item.desc;
        this.descriptionModal = true;
      }
    },
    onShowIndexOFP() {
      this.indexOFPModal = true;
    },
    onResize() {
      this.setAchievements();
    },
    onHashChange() {
      if (this.$route.name !== 'Dashboard') {
        window.removeEventListener('hashchange', this.onHashChange);
        return;
      }
      const hash = window.location.hash.split('=');
      if (hash[0] === '#!showAchievements') this.showAchievements(hash);
      else if (hash[0] === '#!showOFP') this.showOFP(hash);
      else {
        this.achievementsModal = false;
        this.indexOFPModal = false;
      }
    },
    onSettings() {
      if (this.$props.hideEdit !== true) return;
      this.$router.push({ name: 'Settings' }).catch(() => {});
    },
    onEditAvatar() {
      this.showSetAvatar = true;
    },
    getPercent() {
      if (this.nextLevelValue > 0) {
        let percent = ((this.currentValue - this.currentLevelStartValue) / (this.nextLevelValue - this.currentLevelStartValue)) * 100 + 5;
        if (percent > 100) percent = 100;
        return percent;
      }
      return 5;
    },
    setAchievementsAll() {
      if (this.theme === '' || this.showAchievementTypes.length === 0) {
        this.achievementsAll = AchievementsAll;
        return;
      }
      this.achievementsAll = AchievementsAll.filter((i) => this.showAchievementTypes.includes(i.cat));
    },
    setAchievements() {
      this.activeAchievements = this.getAchievements();
      this.grayAchievements = this.getGrayAchievements();
      let countMore = 0;
      if (!this.$root.isMobile) countMore = this.achievementsAll.length - this.maxDesktopItems;
      else countMore = this.achievementsAll.length - this.maxMobileItems;
      this.countMore = countMore > 0 ? countMore : 0;
    },
    getAchievements() {
      if (this.$props.achievements) {
        if (!this.$root.isMobile) return this.$props.achievements.slice(0, 8);
        return this.$props.achievements.slice(0, 8);
      }
      return [];
    },
    getGrayAchievements() {
      const achievements = this.activeAchievements;
      if (achievements.length > 0) {
        const gray = [];
        for (let i = 0; i < this.achievementsAll.length; i += 1) {
          const found = achievements.filter((it) => it.achievementType === this.achievementsAll[i].achievementType);
          if (found.length > 0) continue;
          gray.push(this.achievementsAll[i]);
        }
        return this.getVisibleItems(gray);
      }
      return this.getVisibleItems(this.achievementsAll);
    },
    getAchievementsOfCat(cat) {
      return this.achievementsAll.filter((i) => i.cat === cat);
    },
    getActiveOfCatCount(cat) {
      const achievements = this.getAchievementsOfCat(cat);
      let count = 0;
      for (let i = 0; i < this.$props.achievements.length; i += 1) {
        const found = achievements.filter((it) => it.achievementType === this.$props.achievements[i].achievementType);
        if (found.length === 0) continue;
        if (found[0].cat === cat) count += 1;
      }
      return count;
    },
    getCountOfCat(cat) {
      return this.achievementsAll.filter((it) => it.cat === cat).length;
    },
    getVisibleItems(items) {
      if (this.activeAchievements.length > 0) {
        let count = 0;
        if (!this.$root.isMobile) count = this.maxDesktopItems - this.activeAchievements.length;
        else count = this.maxMobileItems - this.activeAchievements.length;
        if (count > 0) return items.slice(0, count);
        return [];
      }
      if (!this.$root.isMobile) return items.slice(0, this.maxDesktopItems);
      return items.slice(0, this.maxMobileItems);
    },
    isAchievementActive(achievementType) {
      const found = this.$props.achievements.filter((i) => i.achievementType === achievementType);
      return found.length > 0;
    },
    setCollapsed(id) {
      const index = this.categories.findIndex((i) => i.id === id);
      if (index < 0) return;
      this.categories[index].collapsed = !this.categories[index].collapsed;
    },
    getActiveClass(item, inDialog = false) {
      const className = this.getAchievementClass(item.achievementType);
      if (!inDialog) return `${className}-40`;
      const isActive = this.isAchievementActive(item.achievementType);
      if (this.$root.isMobile) return isActive ? `${className}-96` : `${className}-96-disabled`;
      return isActive ? `${className}-128` : `${className}-128-disabled`;
    },
    getGrayClass(item) {
      const className = this.getAchievementClass(item.achievementType);
      return `${className}-40-disabled`;
    },
    getAchievementClass(achievementType) {
      const found = this.achievementsAll.filter((i) => i.achievementType === achievementType);
      if (found.length === 0) return '';
      return found[0].class;
    },
    getDescriptionImageClass(item) {
      const className = this.getAchievementClass(item.achievementType);
      const isActive = this.isAchievementActive(item.achievementType);
      return isActive ? `${className}-96` : `${className}-96-disabled`;
    },
    setOFPvalues() {
      const values = [];
      if (this.sportIndexes) {
        const items = [];
        if (this.sportIndexes.history) {
          items.push(...this.sportIndexes.history);
        }
        items.push({
          index: this.sportIndexes.index,
          startDate: this.sportIndexes.startDate,
          finishDate: this.sportIndexes.finishDate,
        });
        for (let i = 0; i < items.length; i += 1) {
          const dates = formatDateRange({ start: items[i].startDate, finish: items[i].finishDate, showYear: true });
          values.push({
            value: items[i].index,
            dates,
          });
        }
      }
      this.ofpValues = values;
    },
    formatDateRange(payload) {
      return formatDateRange(payload);
    },
    showAchievements(hash) {
      if (hash[1] === 'true') this.onSelected('Welcome');
      else {
        this.achievementsModal = false;
        this.clearHash();
      }
    },
    showOFP(hash) {
      if (hash[1] === 'true') this.onShowIndexOFP();
      else {
        this.indexOFPModal = false;
        this.clearHash();
      }
    },
    clearHash() {
      setTimeout(() => { window.location.hash = ''; }, 100);
    },
  },
  mounted() {
    this.setAchievementsAll();
    this.setOFPvalues();
    window.addEventListener('resize', this.onResize);
    this.onResize();
    window.addEventListener('hashchange', this.onHashChange);
    this.onHashChange();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import "./AchievementsParticipant";
</style>

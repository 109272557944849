<template>
  <div v-if="themeDone" class="rules">
    <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="isAuthenticated === true && !isMobileApp" class="rules__menu">
      <Menu />
      <div class="divider"></div>
    </div>

    <div class="rules__content">
      <iframe v-if="rule ? rule.url : false" :src="rule.url" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Menu from '@/components/Menu/Menu.vue';

export default {
  name: 'Rules',
  components: {
    Menu,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['themeDone', 'resources', 'options']),
    ...mapGetters('user', ['isAuthenticated', 'isMobileApp']),
    ...mapGetters('content', ['contentStatus', 'rule']),
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('content', ['getRule']),
    ...mapActions('wallet', ['getBalance']),
  },
  mounted() {
    if (!this.isMobileApp) {
      this.$router.push({ name: 'Home' }).catch(() => {});
      return;
    }
    this.getRule();
    this.getProfile()
      .then(() => {
        if (this.isAuthenticated) {
          this.getBalance();
        }
      });
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/vars";
@import "~@rr-component-library/common/src/scss/mixins";

.rules {
  position: relative;
  display: flex;
  flex-direction: column;

  &__menu {
    display: none;

    @media (min-width: breakpoint(md)) {
      display: block;
    }
  }

  &__content {
    width: 100%;
    height: fit-content;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    iframe {
      width: 100%;
      min-height: 400px;
      overflow-y: auto;
      flex: 1 1 auto;
    }
  }
}
</style>

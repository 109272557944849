/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import scountApi from '@/services/scount-api';

const state = {
  balance: null,
  operations: null,
  responseStatus: null,
};

const mutations = {
  setBalance(state, value) {
    state.balance = value;
  },
  setOperations(state, value) {
    state.operations = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
};

const getters = {
  balance: (state) => state.balance,
  operations: (state) => state.operations,
  responseStatus: (state) => state.responseStatus,
};

const actions = {
  getBalance({ commit }) {
    return scountApi.getBalance()
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setBalance', response.data);
        } else {
          commit('setBalance', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getOperations({ commit }, {
    sortBy, descending, offset, count,
  }) {
    return scountApi.getBalanceOperations({
      sortBy, descending, offset, count,
    })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setOperations', response.data);
        } else {
          commit('setOperations', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

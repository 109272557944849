<template>
  <div>
    <Modal
    name="onboarding-choose"
    title="Подключите трекер за пару шагов"
    subtitle="Трекер — приложение для записи  ваших тренировок. Подойдёт любой смартфон на iOS или Android."
    :active="shown"
    v-bind="settings"
    @close.prevent="$emit('close')"
  >
    <div class="text-center">
      <div v-if="showTrackerBeginner" class="cta-novice">
        <Button
          :text="$root.isMobile ? 'Подключить трекер для&nbsp;новичка' : 'Подключить трекер для новичка'"
          class="cta-novice__button"
          variant="primary"
          size="lg"
          wide
          mobile
          :loading="loading"
          @click="$emit('connection-guide')"
        ></Button>
        <div class="cta-novice__note">
          <div class="cta-novice__note-item"><Icon name="smartphone" size="m" />Любой смартфон</div>
          <div class="cta-novice__note-item"><Icon name="timer" size="m" />Менее 5 минут</div>
        </div>
      </div>
      <div class="has-tracker">
        <div class="has-tracker__divider"></div>
        <div class="has-tracker__text">Уже есть трекер?</div>
        <div class="has-tracker__divider"></div>
      </div>
      <div class="trackers">
        <Tracker
          v-for="(item, i) in availableTrackers"
          :key="i"
          :id="item ? item.id : ''"
          :name="item ? item.name : ''"
          :img="item ? item.img : ''"
          :instructionLink="getTrackerInstruction(item.name.toLowerCase())"
          instructionTarget="_blank"
          :hideInstruction="getTrackerInstruction(item.name.toLowerCase()) === '#'"
          :loading="loading"
          :class="{ 'tracker-no-discipline-limit': showGuideSimpleRun, 'none': item.name === 'ScountTracker' && !isSpecialSettingsMp }"
          @connect="item.id === 'ScountTracker' || item.id === 'ScountHealth' ? onScountTracker() : onConnect(item.id)"
        />
      </div>
      <div class="more">
        У вас другой трекер? <Link text="Узнать больше" variant="primary" :href="articleChooseTracker" target="_blank"></Link>
      </div>
    </div>
  </Modal>
  <Modal
    name="guide-scount"
    :active="guideScount"
    @close.prevent="guideScount=false"
    :img="scountImg"
  >
  <div class="guide__title guide__title_steps" v-html="guideTitle"></div>
  <div class="guide__subtitle guide__subtitle_steps">
    <b>Шаг 1.</b> Установите приложение
    <div class="guide__platform-link">
      <a v-if="OS === 'unknown'" href="https://apps.apple.com/ru/app/%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82/id6504521298" target="_blank"><img :src="appStoreImg"/></a>
      <a v-if="OS === 'unknown'" href="https://www.rustore.ru/catalog/app/pro.scount.app" target="_blank"><img :src="rustoreImg"/></a>
    </div>
    <div class="guide__platform-link">
      <a v-if="OS === 'iOS'" href="https://apps.apple.com/ru/app/%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82/id6504521298" target="_blank"><img :src="appStoreImg"/></a>
      <a v-if="OS === 'Android'" href="https://www.rustore.ru/catalog/app/pro.scount.app" target="_blank"><img :src="rustoreImg"/></a>
    </div>
  </div>

  <div class="guide__subtitle guide__subtitle_steps">
    <b>Шаг 2.</b> Скопируйте этот код и используйте его для входа в приложении
    <div class="guide__passcode guide__passcode_color">
      <span>{{ code ? code.code : '' }}</span>
      <span @click.stop="onCopyCode"><Icon name="copy" /></span>
    </div>
  </div>
  <div class="guide__subtitle guide__subtitle_steps">
    <b>Шаг 3.</b> Разрешите приложению доступ к данным физической активности
    <div>
      <span v-if="OS === 'unknown'"><img :src="healthConnectImg"/></span>
      <span v-if="OS === 'unknown'"><img :src="healthImg"/></span>
      <span v-if="OS === 'Android'"><img :src="healthConnectImg"/></span>
      <span v-if="OS === 'iOS'"><img :src="healthImg"/></span>
    </div>
  </div>
  <div class="sneak-bar" v-if="showCopied">
    <Alert
      text="Код скопирован."
      actionText="Понятно"
      state="success"
      closable
      autoClose
      @close="showCopied = false"
    ></Alert>
  </div>
  </Modal>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Tracker from '@rr-component-library/tracker/src/main';
import Alert from '@rr-component-library/alert/src/main';
import trackerInstructions from '@/assets/data/trackers';
import trackersList from '@/assets/data/trackersList';
import GooglePlayImg from '@/assets/img/connection-guide/google-play.svg';
import AppStoreImg from '@/assets/img/connection-guide/app-store.svg';
import RuStoreImg from '@/assets/img/connection-guide/rustore.svg';
import HealthImg from '@/assets/img/connection-guide/health.svg';
import HealthConnectImg from '@/assets/img/connection-guide/health-connect-app.svg';
import ScountImgMiddle from '@/assets/img/scount-middle.svg';
import { addNonBreakingSpaces } from '@/assets/js/helpers';
import ScountHealthImg from '@/assets/img/trackers/scount-health-app.png';
import AndroidHealthImg from '@/assets/img/trackers/android-health.png';
import AppleHealthImg from '@/assets/img/trackers/apple-health.png';

const { v4: uuidv4 } = require('uuid');

export default {
  name: 'Choose',
  components: {
    Tracker,
    Alert,
  },
  props: {
    shown: Boolean,
    settings: Object,
    backToPath: String,
  },
  data() {
    return {
      loading: false,
      links: trackerInstructions,
      trackersList,
      items: [],
      guideScount: false,
      showCopied: false,
      googlePlayImg: GooglePlayImg,
      appStoreImg: AppStoreImg,
      rustoreImg: RuStoreImg,
      healthImg: HealthImg,
      healthConnectImg: HealthConnectImg,
      scountImg: ScountImgMiddle,
      scountHealthImg: ScountHealthImg,
      androidHealthImg: AndroidHealthImg,
      appleHealthImg: AppleHealthImg,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'options', 'resources']),
    ...mapGetters('user', ['isAuthenticated', 'code', 'profile']),
    ...mapGetters('trackers', ['providers', 'connectUrl']),
    availableTrackers() {
      const providers = this.providers ? this.providers : [];
      return this.trackersList.filter((tracker) => providers.includes(tracker.id));
    },
    showGuideSimpleRun() {
      return this.options ? this.options.showGuideSimpleRun === true : false;
    },
    showTrackerBeginner() {
      return this.options && (this.options.isTrackerBeginner === undefined || this.options.isTrackerBeginner === true);
    },
    articleChooseTracker() {
      return this.resources ? this.resources.articleChooseTracker || '#' : '#';
    },
    isSpecialSettingsMp() {
      return this.options ? this.options.isSpecialSettingsMp === true : false;
    },
    guideTitle() {
      return addNonBreakingSpaces('Установите мобильное приложение СКАУНТ на смартфон и войдите по коду');
    },
    OS() {
      const userAgent = navigator.userAgent || navigator.vendor || (window).opera;
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }
      return 'unknown';
    },
  },
  watch: {
    isAuthenticated(val) {
      if (val) {
        this.loadItems();
      }
    },
    shown(val) {
      if (val) {
        this.loadItems();
        window.location.hash = '#!showOnboarding=true';
      } else {
        window.location.hash = '';
      }
    },
  },
  methods: {
    ...mapActions('trackers', ['getProviders', 'createTracker']),
    ...mapActions('user', ['getCode']),

    onCopyCode() {
      navigator.clipboard.writeText(this.code.code);
      if (this.showCopied) this.showCopied = false;
      setTimeout(() => { this.showCopied = true; }, 500);
    },
    getCodeMp() {
      if (this.profile) {
        this.getCode(this.profile.marketingProgramId);
      }
    },
    onScountTracker() {
      this.guideScount = true;
    },
    onConnect(provider) {
      this.$emit('choose', provider);
      this.loading = true;
      if (provider) {
        const trackerId = uuidv4();
        this.createTracker({
          provider,
          trackerId,
          redirectUrl: `${this.$props.backToPath ? this.$props.backToPath : window.location.origin}/tracker?connect=${trackerId}`,
          parameters: {
            authorizationType: 'Normal',
          },
        })
          .then(() => {
            window.location.href = this.connectUrl;
          });
      }
      this.loading = false;
    },
    loadItems() {
      this.getProviders()
        .then(() => {
          const items = [];
          for (let i = 0; i < this.providers.length; i += 1) {
            if (this.getTrackerInstruction(this.providers[i].toLowerCase()) !== '') {
              items.push({
                name: this.providers[i],
                order: this.getTrackerOrder(this.providers[i].toLowerCase()),
              });
            }
          }
          this.items = items.sort(this.sort);
        });
    },
    getTrackerInstruction(id) {
      const found = this.links.filter((i) => i.id === id);
      if (found.length === 0) return '';
      if (this.theme === '') return '#';
      if (!this.theme) return '';
      if (!found[0].link) return '';
      if (!found[0].link[this.theme]) return '';
      return found[0].link[this.theme];
    },
    getTrackerOrder(id) {
      const found = this.links.filter((i) => i.id === id);
      if (found.length === 0) return '';
      return found[0].order;
    },
    sort(a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    },
    updateTrackerImage(trackerId) {
      const tracker = this.trackersList.find((item) => item.id === trackerId);
      if (tracker) {
        tracker.img = ScountHealthImg;
      }
    },
  },
  mounted() {
    this.updateTrackerImage('ScountHealth');
    this.getCodeMp();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@rr-component-library/common/src/scss/vars";
  @import "~@rr-component-library/common/src/scss/mixins";
  @import '../../ConnectionGuide/ConnectionGuide';

  ::v-deep .rr-modal__window {
    border-radius: 8px;
  }

  ::v-deep .rr-tracker {
    border-radius: 8px;
    margin-bottom: 12px;
  }

  ::v-deep .rr-tracker:last-child {
    margin-bottom: 0;
  }

  ::v-deep .rr-button {
    border-radius: 4px;
  }

  ::v-deep .tracker-no-discipline-limit .rr-tracker__name_limit {
    display: none;
  }

  .cta-novice {
    width: 100%;
    max-width: 360px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0 auto;

    &__button {
      min-height: 82px;
      margin-bottom: 1.5rem;

      @media (min-width: breakpoint(md)) {
        min-height: 48px;
        padding-left: 19px;
        padding-right: 19px;
      }

      ::v-deep .rr-button__text {
        line-height: 26px;
      }
    }

    &__note {
      width: 100%;
      height: 18px;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__note-item {
      margin-right: 20px;
      display: flex;
      align-items: center;
      font-size: map-get($text-xs, font-size);
      line-height: map-get($text-xs, line-height);
      font-weight: 600;
      color: $color-black;

      ::v-deep .rr-icon {
        color: $color-warning;
        margin-right: 8px;
      }
    }

    &__note-item:last-child {
      margin-right: 0;
    }
  }

  .has-tracker {
    height: 26px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include v-spacing('margin-top', l);
    @include v-spacing('margin-bottom', l);

    &__text {
      font-size: map-get($text-lg, font-size);
      line-height: map-get($text-lg, line-height);
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      color: $color-black-85;
      padding-left: 8px;
      padding-right: 8px;
    }

    &__divider {
      width: 100%;
      height: 1px;
      position: relative;
      top: 12px;
      background: $color-black-25;
    }
  }

  .trackers {
    @include v-spacing('padding-bottom', l);
  }

  .more {
    width: 100%;
    margin-top: 1rem;
    font-size: map-get($text-md, font-size);
    line-height: map-get($text-md, line-height);
    font-weight: normal;
    color: $color-black-85;
  }
</style>

<template>
  <main class="layout__main">
    <div v-if="isMobileApp" class="mobile-overlay"></div>

    <div class="divider"></div>
    <div :class="getSectionOrderClass('intro')">
      <Intro
        :image="$root.isMobile ? introCoverMobile : introCoverWide || introCover"
        :imagePadding="false"
        :imageAsBackground="introImageAsBackground"
        :titleAbove="introTitleAbove"
        :title="introTitle"
        :subtitle="introSubtitle"
        :buttonText="introButtonText"
        :buttonAction="!isFinished ? { action: login } : null"
        :note="introNote"
        :invertTextColor="introInvertTextColor"
      ></Intro>
    </div>

    <div :class="getSectionOrderClass('textBlock')">
      <TextBlock
        v-if="textBlockItems.length > 0"
        :items="textBlockItems"
      />
    </div>

    <div :class="getSectionOrderClass('ambassador')">
      <Ambassador
        v-if="ambassadorItems.length > 0"
        :type="ambassadorsType || 'general'"
        :title="ambassadorsTitle"
        :items="ambassadorsValues"
      ></Ambassador>
    </div>

    <div :class="getSectionOrderClass('calendarBanner')">
      <div v-if="bannerCalendar" class="divider"></div>
      <div v-if="$root.isMobile" class="banner">
        <a href="#" @click.prevent="login"><img :src="calendarData && calendarData.calendarImages ? calendarData.calendarImages.mobile : ''" /></a>
      </div>
      <div v-if="!$root.isMobile" class="banner">
        <a href="#" @click.prevent="login"><img :src="calendarData && calendarData.calendarImages ? calendarData.calendarImages.desktop : ''" /></a>
      </div>
    </div>

    <div :class="getSectionOrderClass('goal')">
      <div class="divider"></div>
      <Goal
        :formatTotalKm="formatTotalKm"
        :formatCurrentKm="formatCurrentKm"
        :totalKm="goal ? goal.necessaryDistance : 0"
        :currentKm="goal ? goal.completedDistance : 0"
        :currentMoney="goal && goalShowCharity ? `${goal.completedAmount} ₽` : ''"
        :localizedText="{
          distance: 'вместе преодолено', total: 'Вместе преодолеть:', amount: 'собрано во благо',
        }"
        fullWidth
        @distance-question="onShowDistance()"
        @money-question="onShowCharity()"
      ></Goal>
    </div>
    <div :class="getSectionOrderClass('getTasks')">
      <div class="divider"></div>
      <GetTasks
        v-if="tasksGuest && tasksGuest.values"
        id="tasks"
        :image="getTasksCover"
        title="Зарабатывайте баллы, выполняя задания"
        :subtitle="getTasksSubtitle"
        :fullWidth="$root.isMobile"
      >
        <TaskCard
          v-for="(task, i) in tasksGuest.values"
          :key="i"
          :stateLabels="{ available: 'до ', inProgress: 'Выполняется', checking: 'Проверяется', completed: 'Выполнено', failed: 'Вы не успели :(' }"
          :timeLabels="{ days: 'д', hours: 'ч' }"
          :styleLabels="{ special: 'Особое задание', daily: 'Ежедневное', repeat: 'Многоразовое' }"
          :image="task.images.mobile"
          :action="{ func: () => onShowTask(i) }"
          type="coins"
          state="available"
          :title="task.title"
          :subtitle="task.shortDescription"
          :cost="getTaskRewardRange(task)"
          :date="`${task.endDate}`"
          :itemsTotal="0"
          :itemsDone="0"
          :complexity="task.complexity"
          :isSpecial="task.isSpecial || false"
          :isDaily="task.isDaily || false"
          :isRepeat="false"
        ></TaskCard>
      </GetTasks>
    </div>

    <div v-if="bannerVk" :class="getSectionOrderClass('vkBanner')">
      <div v-if="$root.isMobile" class="banner">
        <a :href="bannerVkLink" target="_blank">
          <img :src="bannerVk ? bannerVk.mobileImageUrl : ''" />
        </a>
      </div>
      <div v-if="!$root.isMobile" class="banner">
        <a :href="bannerVkLink" target="_blank">
          <img :src="bannerVk ? bannerVk.desktopImageUrl : ''" />
        </a>
      </div>
    </div>

    <div :class="getSectionOrderClass('getRewards')">
      <GetRewards
        v-if="promotions !== null"
        id="market"
        :image="getRewardsCover"
        :title="getRewardsTitle"
        :subtitle="getRewardsSubtitle"
        :note="getRewardsNote"
        :fullWidth="false"
      >
        <RewardCard
          v-for="(promotion, i) in promotions.values"
          :key="i"
          :image="promotion.images ? promotion.images.mobile : ''"
          :action="{ func: () => onAction(i) }"
          type='coins'
          :state="isSmallQuantity(promotion) ? 'count' : ''"
          :stateLabels="{ available: 'В наличии', count: `${promotion.promocodeQty} шт.` }"
          :title="promotion.name"
          :subtitle="promotion.subTitle"
          :cost="promotion.priceInPoints"
        ></RewardCard>
      </GetRewards>
    </div>

    <div v-if="bannerCommunity" :class="getSectionOrderClass('dummyBanner')">
      <div v-if="$root.isMobile" class="banner">
        <a href="#" @click.prevent="onBanner">
          <img :src="bannerCommunity ? bannerCommunity.mobileImageUrl : ''" />
        </a>
      </div>
      <div v-if="!$root.isMobile" class="banner">
        <a href="#" target="_blank" @click.prevent="onBanner">
          <img :src="bannerCommunity ? bannerCommunity.desktopImageUrl : ''" />
        </a>
      </div>
    </div>

    <div :class="getSectionOrderClass('nominations')">
      <Nominations
        v-if="nominationsItems.length > 0 && isNominationsActiveTabSelected"
        id="rating"
        style="width: 100%; max-width: 1024px;"
        :image="nominationsCover"
        :title="nominationsTitle ? nominationsTitle : 'Побеждайте в номинациях'"
        :tabs="nominationsTabs"
        :activeTab="nominationsActiveTab"
        :fullWidth="false"
      >
        <NominationCard
          v-for="item in nominationsItems"
          :key="item.nominationId"
          :image="getNominationImage(item)"
          :nominationText="getNominationDates(item)"
          :title="item.title"
          :rules="{ text: 'Правила', action: () => onShowRules(item) }"
          :prizes="{ text: 'Награды', action: () => onShowPrizes(item) }"
          :ratingText="{ general: 'Текущий рейтинг', personal: 'Вы в рейтинге' }"
          :values="getNominationRating(item)"
          @show-details="onShowRating(item)"
          data-group-id="0"
        ></NominationCard>
        <NominationCard
          v-for="item in teamNominationsItems"
          :key="item.nominationId"
          :nominationText="getNominationDates(item)"
          :title="item.title"
          type="personal"
          state="in-progress"
          class="team-rating"
          :ratingText="{ general: 'Текущий рейтинг', personal: 'Команда в рейтинге' }"
          :values="getNominationRating(item, true)"
          @show-details="onShowRating(item, true)"
          data-group-id="1"
        ></NominationCard>
      </Nominations>
    </div>

    <div :class="getSectionOrderClass('superprize')">
      <Superprize
        v-if="false"
        :image="superprizeCover"
        title="Главный приз"
        :subtitle="superprizeSubtitle"
        buttonText="Узнать больше"
        :buttonAction="{ action: onShowPrizes }"
      ></Superprize>
    </div>

    <div :class="getSectionOrderClass('howItWorks')">
      <HowItWorks
        v-if="false"
        :items="[
          { text: '<a href=/register>Зарегистрируйтесь</a> на чемпионат' },
          { text: 'Установите трекер <a href=https://strava.com target=_blank>Strava</a> на смартфон' },
          { text: 'Подключите ваш трекер к чемпионату' },
          { text: 'Включайте трекер во время тренировки <span>для учёта ваших километров</span>' },
        ]"
      ></HowItWorks>
    </div>

    <div :class="getSectionOrderClass('topCurators')">
      <TopCurators
        v-if="themeDone && showTopCurators"
        id="involvement-rating"
      ></TopCurators>
    </div>

    <div :class="getSectionOrderClass('partners')">
      <div v-if="showPartners" class="divider"></div>
      <Partners
        v-if="showPartners"
        id="partners"
        title="Партнёры и спонсоры"
        :items="partnersValues"
        fullWidth
      ></Partners>
    </div>

    <div :class="getSectionOrderClass('cta')">
      <div v-if="showPartners" class="divider"></div>
      <Intro
        class="cta"
        :image="$root.isMobile ? ctaCoverMobile : ctaCoverWide || ctaCover"
        :imagePadding="false"
        :imageAsBackground="ctaImageAsBackground"
        :title="ctaTitle ? ctaTitle : 'Станьте участником уже сегодня'"
        :subtitle="ctaSubtitle ? ctaSubtitle : 'Cпорт → Баллы → Награды'"
        :buttonText="ctaButtonText ? ctaButtonText : 'Участвовать'"
        :buttonAction="!isFinished ? { action: login } : null"
        :note="ctaNote"
        :invertTextColor="introInvertTextColorCta"
      ></Intro>
    </div>

    <div class="under-maintenance" v-if="showUnderMaintenance">
      <Alert
        text="Внимание! Идет сборка и настройка тестовой среды. Некоторые функции могут не работать или работать некорректно."
        actionText="Понятно"
        state="warning"
        closable
        stacked
      >
      </Alert>
    </div>

    <RulesPrizesModal
      :active="rulesPrizesModal"
      :image="rulesPrizesImage"
      :title="rulesPrizesTitle"
      :subtitle="rulesPrizesSubtitle"
      :nominationId="rulesPrizesNominationId"
      :isTeam="rulesPrizesIsTeam"
      :state="rulesPrizesState"
      :tabs="!isPrivate ? rulesPrizesTabs : rulesPrizesCurrentTabs"
      :activeTab="rulesPrizesTabActive"
      @close="rulesPrizesModal = false"
    />

    <DistanceCharityModal
      :active="distanceCharityModal"
      :type="distanceCharityType"
      :localizedText="distanceCharityLocalizedText"
      :moreLink="'https://mail.ru/'"
      @close="distanceCharityModal = false"
    />

    <TaskDetailsModal
      :active="taskDetailsModal"
      state="available"
      :modalOptions="taskDetailsOptions"
      :rating="taskRating"
      @close="taskDetailsModal = false"
    />

  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
// componenets
import Intro from '@sc-component-library/intro/src/main';
import Goal from '@sc-component-library/goal/src/main';
import Nominations from '@sc-component-library/nominations/src/main';
import NominationCard from '@sc-component-library/nomination-card/src/main';
import Superprize from '@sc-component-library/superprize/src/main';
import HowItWorks from '@sc-component-library/how-it-works/src/main';
import GetTasks from '@sc-component-library/get-tasks/src/main';
import TaskCard from '@sc-component-library/task-card/src/main';
import Ambassador from '@sc-component-library/ambassador/src/main';
import GetRewards from '@sc-component-library/get-rewards/src/main';
import RewardCard from '@sc-component-library/reward-card/src/main';
import Partners from '@sc-component-library/partners/src/main';
import Alert from '@rr-component-library/alert/src/main';
import TopCurators from '@/components/TopCurators/TopCurators.vue';
import TextBlock from '@/components/TextBlock/TextBlock.vue';
import RulesPrizesModal from '@/components/RulesPrizesModal/RulesPrizesModal.vue';
import DistanceCharityModal from '@/components/DistanceCharityModal/DistanceCharityModal.vue';
import TaskDetailsModal from '@/components/TaskDetailsModal/TaskDetailsModal.vue';
import {
  scrollToId, formatDateRange, addNonBreakingSpaces, formatNumber,
} from '@/assets/js/helpers';
// resources
import SuperprizeCover from '../assets/img/superprize/superprize.jpg';
import NominationsCover from '../assets/img/nominations/winner.png';
import GetTasksCover from '../assets/img/get-tasks/get-coins.svg';
import GetRewardsCover from '../assets/img/get-rewards/spend-coins.svg';

export default {
  name: 'Home',
  components: {
    Intro,
    Goal,
    Nominations,
    NominationCard,
    Superprize,
    HowItWorks,
    GetTasks,
    TaskCard,
    Ambassador,
    GetRewards,
    RewardCard,
    Partners,
    Alert,
    TopCurators,
    TextBlock,
    RulesPrizesModal,
    DistanceCharityModal,
    TaskDetailsModal,
  },
  data() {
    return {
      superprizeCover: SuperprizeCover,
      rulesPrizesModal: false,
      rulesPrizesImage: '',
      rulesPrizesTitle: '',
      rulesPrizesSubtitle: '',
      rulesPrizesNominationId: '',
      rulesPrizesIsTeam: false,
      rulesPrizesState: 'general',
      rulesPrizesTabs: [
        {
          title: 'Правила',
          enabled: true,
          content: '',
        },
        {
          title: 'Награды',
          enabled: true,
          content: '',
        },
        {
          title: 'Рейтинг',
          enabled: true,
        },
      ],
      rulesPrizesCurrentTabs: [
        {
          title: 'Правила',
          enabled: true,
          content: '',
        },
        {
          title: 'Награды',
          enabled: true,
          content: '',
        },
      ],
      rulesPrizesTabActive: 0,
      distanceCharityModal: false,
      distanceCharityType: '',
      distanceCharityLocalizedText: {
        title: '',
        subtitle: '',
      },
      taskDetailsModal: false,
      taskDetailsOptions: {},
      showUnderMaintenance: false,
      nominationsTabs: [],
      nominationsItems: [],
      nominationsActiveTab: null,
      teamNominationsItems: [],
      calendarModal: false,
      textBlockItems: [],
      ambassadorItems: [],
      bannersItems: [],
      taskRating: {},
      calendarData: [],
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeDone', 'isPrivate', 'options', 'resources']),
    ...mapGetters('exercises', ['tasksGuest', 'taskGuest', 'taskPoints', 'responseStatus']),
    ...mapGetters('market', ['promotions']),
    ...mapGetters('nominations', ['nominations', 'teamNominationsActive']),
    ...mapGetters('goals', ['goal']),
    ...mapGetters('content', ['onboardingItems', 'contentStatus', 'ambassadors', 'banners']),
    ...mapGetters('calendar', ['calendar']),
    ...mapGetters('user', ['isMobileApp']),
    showTopCurators() {
      return this.options ? this.options.showTopCurators === true : false;
    },
    isTeamsEnabled() {
      return this.options ? this.options.isTeamsEnabled === true : false;
    },
    showTeamRatings() {
      return this.options ? this.options.showTeamRatings === true : false;
    },
    showAmbassadors() {
      return this.options ? this.options.showAmbassadors === true : false;
    },
    ambassadorsType() {
      return this.options ? this.options.ambassadorsType || '' : 'general';
    },
    ambassadorsValues() {
      return this.ambassadors ? this.updatedAmbassadors() || [] : [];
    },
    bannersValues() {
      return this.banners ? this.updateBanners() || [] : [];
    },
    bannerCalendar() {
      return this.banners ? this.banners.filter((i) => i.locationPlacement === 'calendar')[0] : {};
    },
    bannerVk() {
      return this.banners ? this.banners.filter((i) => i.locationPlacement === 'vk')[0] : {};
    },
    bannerCommunity() {
      return this.banners ? this.banners.filter((i) => i.locationPlacement === 'community')[0] : {};
    },
    showPartners() {
      return this.options ? this.options.showPartners === true : false;
    },
    partnersValues() {
      return this.options ? this.options.partnersValues || [] : [];
    },
    introImageAsBackground() {
      return this.options ? this.options.introImageAsBackground === true : false;
    },
    ctaImageAsBackground() {
      return this.options && this.options.ctaImageAsBackground !== undefined ? this.options.ctaImageAsBackground : this.introImageAsBackground;
    },
    introInvertTextColor() {
      return this.options ? this.options.introInvertTextColor === true : false;
    },
    introInvertTextColorCta() {
      return this.options ? this.options.introInvertTextColorCta === true : false;
    },
    showCalendarBanner() {
      return this.options ? this.options.showCalendarBanner === true : false;
    },
    showCalendarOnHome() {
      return this.options ? this.options.showCalendarOnHome === true : false;
    },
    goalShowCharity() {
      return this.options ? this.options.goalShowCharity === true : false;
    },
    homeSections() {
      return this.options ? this.options.homeSections || null : null;
    },
    isFinished() {
      return this.options ? this.options.isFinished === true : false;
    },
    showCalendar() {
      return this.showCalendarBanner && this.showCalendarOnHome;
    },
    introCover() {
      return this.resources ? this.resources.introCover || '' : '';
    },
    introCoverWide() {
      return this.resources ? this.resources.introCoverWide || '' : '';
    },
    introCoverMobile() {
      return this.resources ? this.resources.introCoverMobile || '' : '';
    },
    introTitleAbove() {
      return this.resources ? this.resources.introTitleAbove || '' : '';
    },
    introTitle() {
      return this.resources ? this.resources.introTitle || '' : '';
    },
    introSubtitle() {
      return this.resources ? this.resources.introSubtitle || '' : '';
    },
    introButtonText() {
      return this.resources ? this.resources.introButtonText || '' : '';
    },
    introNote() {
      return this.resources ? this.resources.introNote || '' : '';
    },
    ambassadorsTitle() {
      return this.resources ? this.resources.ambassadorsTitle || '' : 'Вас будут поддерживать и мотивировать';
    },
    calendarBanner() {
      return this.resources ? this.resources.calendarBanner || '' : '';
    },
    calendarBannerMobile() {
      return this.resources ? this.resources.calendarBannerMobile || '' : '';
    },
    dummyBanner() {
      return this.resources ? this.resources.dummyBanner || '' : '';
    },
    dummyBannerMobile() {
      return this.resources ? this.resources.dummyBannerMobile || '' : '';
    },
    goalModalSubtitle() {
      return this.resources ? this.resources.goalModalSubtitle || '' : '';
    },
    goalModalCharityTitle() {
      return this.resources ? this.resources.goalModalCharityTitle || '' : '';
    },
    goalModalCharitySubtitle() {
      return this.resources ? this.resources.goalModalCharitySubtitle || '' : '';
    },
    ctaCover() {
      return this.resources ? this.resources.ctaCover || '' : '';
    },
    ctaCoverWide() {
      return this.resources ? this.resources.ctaCoverWide || '' : '';
    },
    ctaCoverMobile() {
      return this.resources ? this.resources.ctaCoverMobile || '' : '';
    },
    getTasksCover() {
      return this.resources ? this.resources.getTasksCover || GetTasksCover : GetTasksCover;
    },
    getTasksSubtitle() {
      return this.resources ? this.resources.getTasksSubtitle || '' : '';
    },
    getRewardsCover() {
      return this.resources ? this.resources.getRewardsCover || GetRewardsCover : GetRewardsCover;
    },
    getRewardsTitle() {
      return this.resources ? this.resources.getRewardsTitle || '' : '';
    },
    getRewardsSubtitle() {
      return this.resources ? this.resources.getRewardsSubtitle || '' : '';
    },
    getRewardsNote() {
      return this.resources ? this.resources.getRewardsNote || '' : '';
    },
    nominationsCover() {
      return this.resources ? this.resources.nominationsCover || NominationsCover : NominationsCover;
    },
    nominationsTitle() {
      return this.resources ? this.resources.nominationsTitle || '' : '';
    },
    ctaTitle() {
      return this.resources ? this.resources.ctaTitle || '' : '';
    },
    ctaSubtitle() {
      return this.resources ? this.resources.ctaSubtitle || '' : '';
    },
    ctaButtonText() {
      return this.resources ? this.resources.ctaButtonText || '' : '';
    },
    ctaNote() {
      return this.resources ? this.resources.ctaNote || '' : '';
    },
    isNominationsActiveTabSelected() {
      if (!this.isTeamsEnabled || !this.showTeamRatings) return true;
      return this.nominationsActiveTab !== null;
    },
    superprizeSubtitle() {
      return addNonBreakingSpaces('200 слотов на марафон «Моя&nbsp;столица» получат первые 200 человек в номинации «Лучший&nbsp;атлет»');
    },
    formatTotalKm() {
      return this.goal ? formatNumber(this.goal.necessaryDistance, 'км') : '0';
    },
    formatCurrentKm() {
      return this.goal ? formatNumber(this.goal.completedDistance, 'км') : '0';
    },
    showTextBlock() {
      return this.homeSections ? this.homeSections.textBlock !== undefined : false;
    },
    showAmbassadorBlock() {
      return this.homeSections ? this.homeSections.ambassador !== undefined : false;
    },
    bannerDesktopVk() {
      return this.resources ? this.resources.vkBanner || '' : '';
    },
    bannerMobileVk() {
      return this.resources ? this.resources.vkBannerMobile || '' : '';
    },
    bannerVkLink() {
      return this.resources ? this.resources.vkBannerLink || '' : '';
    },
  },
  watch: {
    themeDone(val) {
      if (val) {
        this.setNominationsTabs();
        this.setAmbassadorsBlock();
        this.setTextBlock();
      }
    },
  },
  methods: {
    ...mapActions('exercises', ['getTasksGuest', 'getTaskPoints']),
    ...mapActions('market', ['getPromotions']),
    ...mapActions('nominations', ['getNominationsGuest', 'getTeamNominationsActive']),
    ...mapActions('goals', ['getGoal']),
    ...mapActions('content', ['getOnboardingItems', 'getAmbassadors', 'getBanners']),
    ...mapActions('calendar', ['getCalendar']),
    onBanner() {
      if (this.bannerCommunity && this.bannerCommunity.redirectUrl) {
        window.open(this.bannerCommunity.redirectUrl, '_blank');
      } else {
        this.$router.push({ name: 'Community' }).catch(() => {});
      }
    },
    updateBanners() {
      return this.banners.map((banner) => ({
        location: banner.locationPlacement,
        desktopImage: banner.desktopImageUrl,
        mobileImage: banner.mobileImageUrl,
        url: banner.redirectUrl,
        order: banner.order,
      }));
    },
    updatedAmbassadors() {
      return this.ambassadors.map((ambassador) => ({
        name: ambassador.fullName,
        avatar: ambassador.desktopImageUrl,
        avatarMobile: ambassador.mobileImageUrl,
        taskText: ambassador.content,
        quote: ambassador.description,
      }));
    },
    login() {
      this.$router.push({ name: 'Auth' }).catch(() => {});
    },
    onShowRules(item) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = true;
      this.rulesPrizesCurrentTabs[0].content = item.rules || '';
      this.rulesPrizesCurrentTabs[1].content = item.awards || '';
      this.rulesPrizesTabActive = 0;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesModal = true;
    },
    onShowPrizes(item) {
      this.rulesPrizesTabActive = 1;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesNominationId = item.nominationId;
      this.rulesPrizesIsTeam = false;
      this.rulesPrizesState = 'general';
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = true;
      this.rulesPrizesCurrentTabs[0].content = item.rules || '';
      this.rulesPrizesCurrentTabs[1].content = item.awards || '';
      this.rulesPrizesModal = true;
    },
    onShowRating(item, isTeam = false) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = true;
      this.rulesPrizesCurrentTabs[0].content = item.rules || '';
      this.rulesPrizesCurrentTabs[1].content = item.awards || '';
      this.rulesPrizesTabActive = 2;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesNominationId = item.nominationId;
      this.rulesPrizesIsTeam = isTeam;
      this.rulesPrizesModal = true;
    },
    onShowDistance() {
      this.distanceCharityType = 'distance';
      this.distanceCharityLocalizedText = {
        title: 'Вместе преодолеть',
        subtitle: this.goalModalSubtitle ? this.goalModalSubtitle : 'Бегайте и ходите. Внесите свой вклад в достижение общей цели.',
      };
      this.distanceCharityModal = true;
    },
    onShowCharity() {
      this.distanceCharityType = 'charity';
      this.distanceCharityLocalizedText = {
        title: this.goalModalCharityTitle,
        subtitle: this.goalModalCharitySubtitle,
      };
      this.distanceCharityModal = true;
    },
    onShowTask() {
      this.$router.push({ name: 'Tasks' }).catch(() => {});
    },
    onAction(i) {
      this.$router.push({ name: 'RewardDetails', query: { id: this.promotions.values[i].promotionId } }).catch(() => {});
    },
    getCalendarBanner() {
      this.getCalendar()
        .then(() => {
          if (this.responseStatus === 200) {
            this.calendarData = this.calendar ? this.calendar : [];
          }
        });
    },
    isSmallQuantity(reward) {
      if (!reward) return false;
      return reward.promocodeQty <= 5;
    },
    isRepeat(task) {
      return task.type === 'Reusable';
    },
    getTaskReward(task) {
      let reward = 0;
      if (!task || !task.tasks) return reward;
      for (let i = 0; i < task.tasks.length; i += 1) {
        reward += task.tasks[i].reward;
      }
      return Math.round(reward);
    },
    getTaskRewardRange(task) {
      let reward = 0;
      let maxReward = 0;
      if (!task || !task.tasks) return reward;
      for (let i = 0; i < task.tasks.length; i += 1) {
        reward += task.tasks[i].reward;
        maxReward += task.tasks[i].maxReward;
      }
      return maxReward && reward < maxReward ? `${Math.round(reward)}-${maxReward}` : Math.round(reward);
    },
    getIsFlexibleCost(task) {
      const reward = this.getTaskReward(task);
      if (!task || !task.tasks) return false;
      let maxReward = 0;
      for (let i = 0; i < task.tasks.length; i += 1) {
        maxReward += task.tasks[i].maxReward;
      }
      return reward < maxReward;
    },
    getNominationImage(item) {
      if (!item || !item.images) return '';
      return this.$root.isDesktop ? item.images.desktop || '' : item.images.mobile || '';
    },
    getNominationDates(item) {
      if (!item) return '';
      const startDate = dayjs.utc(item.startDate);
      const finishDate = dayjs.utc(item.finishDate);
      if (startDate.isValid() && finishDate.isValid()) {
        return formatDateRange({ start: startDate, finish: finishDate, showYear: true });
      }
      return '';
    },
    getNominationRating(item, isTeam = false) {
      if (!item) return [];
      const rating = [];
      for (let i = 0; i < item.leaders.length; i += 1) {
        const it = {
          number: item.leaders[i].position.toString(),
          name: isTeam ? item.leaders[i].teamName : item.leaders[i].name,
          value: item.leaders[i].points.toString(),
          current: false,
        };
        rating.push(it);
      }
      return rating;
    },
    setNominationsTabs() {
      if (this.isTeamsEnabled && this.showTeamRatings) {
        if (this.nominationsItems.length === 0 && this.teamNominationsItems.length === 0) {
          return;
        }
        this.nominationsTabs = [
          { title: 'Участники', enabled: this.nominationsItems.length > 0 },
          { title: 'Команды', enabled: this.teamNominationsItems.length > 0 },
        ];
        if (!this.nominationsTabs[0].enabled && this.nominationsTabs[1].enabled) {
          this.nominationsActiveTab = 1;
        } else {
          this.nominationsActiveTab = 0;
        }
        return;
      }
      this.nominationsActiveTab = 0;
    },
    getSectionOrderClass(id) {
      const hideClass = 'd-none';
      if (!this.homeSections) return 'flex-order-unset';
      if (!this.homeSections[id]) return hideClass;
      return `flex-order-${this.homeSections[id]}`;
    },
    setTextBlock() {
      if (this.showTextBlock) {
        this.getOnboardingItems()
          .then(() => {
            if (this.contentStatus === 200) {
              this.textBlockItems = this.onboardingItems || [];
            }
          });
      }
    },
    setAmbassadorBlock() {
      if (this.showAmbassadorBlock) {
        this.getAmbassadors()
          .then(() => {
            if (this.contentStatus === 200) {
              this.ambassadorItems = this.ambassadors || [];
            }
          });
      }
    },
    setBannersBlocks() {
      this.getBanners()
        .then(() => {
          if (this.contentStatus === 200) {
            this.bannersItems = this.banners || [];
          }
        });
    },
  },
  mounted() {
    const token = Cookies.get('token');
    if (token) {
      const involvementRating = '#involvement-rating';
      if (window.location.hash === involvementRating) {
        this.$router.replace(`/about${involvementRating}`).catch(() => {});
      } else {
        this.$router.push({ name: 'Dashboard' }).catch(() => {});
      }
      return;
    }
    if (this.themeDone) {
      this.setNominationsTabs();
      this.setTextBlock();
      this.setAmbassadorBlock();
      this.setBannersBlocks();
      this.getCalendarBanner();
    }
    this.getGoal();
    this.getTasksGuest({ offset: 0, count: 12 });
    this.getPromotions({ offset: 0, count: 12 });
    this.getNominationsGuest({ offset: 0, count: 12 })
      .then(() => {
        this.nominationsItems = this.nominations ? this.nominations.values : [];
        if (!this.isTeamsEnabled || !this.showTeamRatings) return;
        this.getTeamNominationsActive({ offset: 0, count: 12 })
          .then(() => {
            this.teamNominationsItems = this.teamNominationsActive ? this.teamNominationsActive.values : [];
            this.setNominationsTabs();
          });
      });

    setTimeout(() => {
      if (window.location.hash) {
        const id = window.location.hash.length > 1 ? window.location.hash.slice(1) : '';
        if (id !== '') scrollToId(id, true);
      }
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
  .layout {
    &__main {
      display: flex;
      flex-direction: column;
    }
  }

  .under-maintenance {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $color-warning-1;
    z-index: 2;

    ::v-deep .rr-alert {
      margin: 0 auto;
      max-width: 1024px;
      width: 100%;
      border-color: transparent;
    }

    ::v-deep .rr-alert__icon {
      width: 18px;
      height: 18px;
    }
  }

  ::v-deep .partners__container {
    @media (min-width: breakpoint(xl)) {
      max-width: 1272px;
    }
  }

  ::v-deep .intro__main {
    margin: 0;
    @media (min-width: breakpoint(xl)) {
      padding-left: 64px;
      padding-right: 64px;
    }

    @media (min-width: breakpoint(xxl)) {
      width: 50%;
    }
  }

  ::v-deep .intro-bottom {
    @media (min-width: breakpoint(xl)) {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  ::v-deep .intro-bottom .intro__main {
    @media (min-width: calc(breakpoint(xl) + 240px)) {
      padding-left: 0;
    }
  }

  ::v-deep .intro-bottom .intro__image {
    @include v-spacing('padding-top', xxl);
    width: 193px;
    margin: 0 auto;

    @media (min-width: breakpoint(md)) {
      width: 290px;
    }

    @media (min-width: breakpoint(xl)) {
      width: 50%;
      padding-left: 0;
      padding-right: 120px;
    }
  }

  ::v-deep .intro-bottom .intro__image_with-padding {
    @media (min-width: calc(breakpoint(xl) + 240px)) {
      padding-right: 0;
      width: 600px;
    }
  }

  ::v-deep .intro-bottom .intro__image_with-padding div.image {
    background-size: contain;

    @media (min-width: breakpoint(xl)) {
      background-position: center right;
    }
  }

  ::v-deep .intro .main__title {
    font-family: var(--font-family-2, inherit);
  }

  ::v-deep .intro .main__subtitle {
    a,
    a:link,
    a:hover,
    a:active,
    a:visited {
      color: var(--color-primary, #FF8233);
      font-weight: 600;
      text-decoration: none;
    }
  }

  ::v-deep .goal .goal__title {
    font-family: var(--font-family-2, inherit);
  }

  ::v-deep .get-tasks .get-tasks__info .info__title {
    font-family: var(--font-family-2, inherit);
  }

  ::v-deep .get-rewards .get-rewards__info .info__title {
    font-family: var(--font-family-2, inherit);
  }

  ::v-deep .nominations .info__title {
    font-family: var(--font-family-2, inherit);
  }

  ::v-deep .partners .partners__title {
    font-family: var(--font-family-2, inherit);
  }

  .team-rating ::v-deep .nomination-card__info--personal {
    justify-content: flex-end;
  }

  .team-rating ::v-deep .nomination-card__cover {
    display: none;
  }

  .banner {
    width: 100%;
    display: flex;
    background-color: $color-black-9;

    ::v-deep img {
      width: 100%;
    }
  }

  ::v-deep .info__title {
    overflow-wrap: break-word;
  }

  .mobile-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: $color-white;
  }
</style>

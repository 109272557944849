/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import api from '@/services/operations-api';

const state = {
  balanceOperations: null,
  purchaseOperations: null,
  activityOperations: null,
  responseStatus: null,
};

const mutations = {
  setBalanceOperations(state, value) {
    state.balanceOperations = value;
  },
  setPurchaseOperations(state, value) {
    state.purchaseOperations = value;
  },
  setActivityOperations(state, value) {
    state.activityOperations = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
};

const getters = {
  balanceOperations: (state) => state.balanceOperations,
  purchaseOperations: (state) => state.purchaseOperations,
  activityOperations: (state) => state.activityOperations,
  responseStatus: (state) => state.responseStatus,
};

const actions = {
  getBalanceOperations({ commit }, { offset, count }) {
    return api.getOperations({
      operationTypes: 'BalanceOperation', sortBy: 'operationDate', descending: true, offset, count,
    })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setBalanceOperations', response.data);
        } else {
          commit('setBalanceOperations', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getPurchaseOperations({ commit }, { offset, count }) {
    return api.getOperations({
      operationTypes: 'Purchase', sortBy: 'operationDate', descending: true, offset, count,
    })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setPurchaseOperations', response.data);
        } else {
          commit('setPurchaseOperations', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getActivityOperations({ commit }, { offset, count }) {
    return api.getOperations({
      operationTypes: 'Achievement,ParticipantExercise,SportActivity,Purchase,ParticipantExperienceLevel,RejectedManually,TeamParticipant,ParticipantSteps', sortBy: 'operationDate', descending: true, offset, count,
    })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setActivityOperations', response.data);
        } else {
          commit('setActivityOperations', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

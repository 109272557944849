<template>
  <div class="register">
  </div>
</template>

<script>
export default {
  name: 'RegisterComplete',
};
</script>

<template>
  <div
    class="tasks"
    :class="{ 'tasks_empty': tasksAvailable && selectedTab === 0 && availableItems.length === 0 }"
  >
  <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="!isMobileApp" class="tasks__menu">
      <Menu />
      <div class="divider"></div>
    </div>

    <SettingUpGuide
      v-bind="settingUpGuideOptions"
      :href="articleAboutTasks"

    />

    <div class="tasks__tabs">
      <Tabs
        v-if="!loading"
        :items="[getTabText(0), getTabText(1), getTabText(2)]"
        :activeItem="selectedTab"
        @changed="changeTab($event, false)"
      />
    </div>

    <div v-if="showTaskFilters && selectedTab === 0" class="divider"></div>
    <Filters
      v-if="showTaskFilters && isFiltersLoaded && selectedTab === 0 && ((!hasActiveFilters && availableItems.length !== 0) || hasActiveFilters)"
      :options="filters"
      :hideDropdown="hideFiltersDropdown"
      @change="onFilterChange"
      @reset="onFilterReset"
    />

    <div class="tasks__items">
      <div v-if="tasksAvailable" v-show="selectedTab === 0 && availableItems.length > 0">
        <TaskCard
          v-for="(task, i) in availableItems"
          :key="i"
          :stateLabels="stateLabels"
          :timeLabels="timeLabels"
          :styleLabels="styleLabels"
          :image="task.images.mobile"
          :action="{ func: () => onShowTask(i) }"
          type="coins"
          state="available"
          :title="task.title"
          :subtitle="task.shortDescription"
          :cost="getTaskRewardRange(task)"
          :date="`${task.endDate}`"
          :itemsTotal="0"
          :itemsDone="0"
          :complexity="task.complexity"
          :isSpecial="task.isSpecial || false"
          :isDaily="task.isDaily || false"
          :isRepeat="false"
          :disciplines="disciplineIcon(task)"
        ></TaskCard>
      </div>
      <div v-if="tasksAvailable ? tasksAvailable.values.length === 0 : false" v-show="selectedTab === 0 && availableItems.length === 0">
        <div class="tasks__empty">
          <div class="empty__img"><img :src="onTheWayRafiki" /></div>
          <div class="empty__title" v-html="emptyMessages[emptyState] ? emptyMessages[emptyState].title : ''"></div>
          <div class="empty__subtitle" v-html="emptyMessages[emptyState] ? emptyMessages[emptyState].subtitle : ''"></div>
        </div>
      </div>
      <div v-if="tasksInProgress" v-show="selectedTab === 1">
        <TaskCard
          v-for="(task, i) in inProgressItems"
          :key="task.exerciseId"
          :stateLabels="stateLabels"
          :timeLabels="timeLabels"
          :styleLabels="styleLabels"
          :image="task.images.mobile"
          :action="{ func: () => onShowTask(i) }"
          type="coins"
          state="inProgress"
          :title="task.title"
          :subtitle="task.shortDescription"
          :cost="getTaskRewardRange(task)"
          :date="`${task.endDate}`"
          :itemsTotal="task.participantExercise.tasks.length || 0"
          :itemsDone="task.participantExercise.tasks.filter((i) => i.state === 'Completed').length || 0"
          :complexity="task.complexity"
          :isSpecial="task.isSpecial || false"
          :isDaily="task.isDaily || false"
          :isRepeat="false"
          :disciplines="disciplineIcon(task)"
        ></TaskCard>
      </div>
      <div v-if="tasksCompleted" v-show="selectedTab === 2">
        <TaskCard
          v-for="(task, i) in completedItems"
          :key="i"
          :stateLabels="stateLabels"
          :timeLabels="timeLabels"
          :styleLabels="styleLabels"
          :image="task.images.mobile"
          :action="{ func: () => onShowTask(i) }"
          type="coins"
          :state="getCompletedTaskState(task)"
          :title="task.title"
          :subtitle="task.shortDescription"
          :cost="task.participantExercise.state === 'Completed' ? task.participantExercise.reward : getTaskRewardRange(task)"
          :date="`${task.participantExercise.completedOn}`"
          :itemsTotal="task.participantExercise.tasks.length || 0"
          :itemsDone="task.participantExercise.tasks.filter((i) => i.state === 'Completed').length || 0"
          :complexity="task.complexity"
          :isSpecial="task.isSpecial || false"
          :isDaily="task.isDaily || false"
          :isRepeat="false"
          :disciplines="disciplineIcon(task)"
        ></TaskCard>
      </div>
      <div class="tasks__loader" v-if="updating">
        <Loader size="24" />
      </div>
    </div>

    <div class="tasks__loader-block"></div>

    <TaskDetailsModal
      :active="taskDetailsModal"
      :state="taskDetailsState"
      :modalOptions="taskDetailsOptions"
      :rating="taskRating"
      @close="onTaskDetailsClose()"
      @start="onStart"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TaskCard from '@sc-component-library/task-card/src/main';
import Menu from '@/components/Menu/Menu.vue';
import TaskDetailsModal from '@/components/TaskDetailsModal/TaskDetailsModal.vue';
import Filters from '@/components/Filters/Filters.vue';
import SettingUpGuide from '@/components/SettingUpGuide/SettingUpGuide.vue';

const { v4: uuidv4 } = require('uuid');

export default {
  name: 'Tasks',
  components: {
    TaskCard,
    Menu,
    TaskDetailsModal,
    Filters,
    SettingUpGuide,
  },
  data() {
    return {
      disciplinesData: ['walking', 'running', 'swimming', 'cycling'],
      selectedTab: 0,
      stateLabels: {
        available: 'до ', inProgress: 'Выполняется', checking: 'Проверяется', completed: 'Выполнено', failed: 'Вы не успели :(',
      },
      styleLabels: {
        special: 'Особое задание', daily: 'Ежедневное', repeat: 'Многоразовое',
      },
      timeLabels: { days: 'д', hours: 'ч' },
      loading: false,
      updating: false,
      availableItems: [],
      inProgressItems: [],
      completedItems: [],
      offsetAvailable: 0,
      offsetInProgress: 0,
      offsetCompleted: 0,
      taskDetailsModal: false,
      taskDetailsOptions: {},
      taskDetailsState: 'available',
      taskDetailsExerciseId: '',
      pageScrollY: 0,
      loaderBlock: null,
      disciplineNames: [
        { id: 'Running', name: 'Тренировки - бег', order: 1 },
        { id: 'Walking', name: 'Тренировки - ходьба', order: 2 },
        { id: 'Cycling', name: 'Тренировки - вело', order: 3 },
        { id: 'Swimming', name: 'Тренировки - плавание', order: 4 },
        { id: 'Skiing', name: 'Тренировки - лыжи', order: 5 },
        {
          id: 'PartnerLink', name: 'Сообщество', order: 6, group: 'Partner',
        },
        {
          id: 'UploadImage', name: 'Сообщество', order: 7, group: 'Partner',
        },
        { id: 'GeoLocation', name: 'Посети объект', order: 8 },
        { id: 'Invite', name: 'Пригласи друга', order: 9 },
        { id: 'Quiz', name: 'Тесты', order: 10 },
        { id: 'Registration', name: 'Регистрация на событие', order: 11 },
        { id: 'Participation', name: 'Участие в событии', order: 12 },
      ],
      filters: {
        discipline: [],
      },
      availableFilters: {
        discipline: [],
      },
      activeFilters: {},
      isFiltersLoaded: false,
      hideFiltersDropdown: false,
      emptyMessages: {
        unfilteredEmpty: {
          title: 'Ого, вы взяли все задания!',
          subtitle: 'Желаем удачи в выполнении ваших амбициозных планов',
        },
        filteredEmpty: {
          title: 'Задания не найдены',
          subtitle: 'Попробуйте изменить или отключить фильтры',
        },
      },
      settingUpGuideOptions: {
        icon: 'question',
        text: 'Узнайте какие бывают задания и как копить баллы быстрее',
        actionText: 'Узнать больше',
        actionIcon: 'externa-link',
        storageKey: 'hideTasksBanner',
        view: 'default',
      },
      taskRating: {},
      registrationParticipant: {},
    };
  },
  computed: {
    ...mapGetters('settings', ['options', 'resources']),
    ...mapGetters('user', ['isAuthenticated', 'isMobileApp']),
    ...mapGetters('trackers', ['trackers', 'responseStatus']),
    ...mapGetters('exercises', ['taskPoints', 'tasksAvailable', 'tasksInProgress', 'tasksCompleted', 'task', 'taskGuest', 'disciplines', 'responseStatus']),
    ...mapGetters('partners', ['registration']),
    routeName() {
      return this.$route.name;
    },
    showTaskFilters() {
      return this.options ? this.options.showTaskFilters === true : false;
    },
    enabledTaskFilters() {
      return this.options ? this.options.enabledTaskFilters || [] : [];
    },
    onTheWayRafiki() {
      return this.resources ? this.resources.onTheWayRafiki || '' : '';
    },
    articleAboutTasks() {
      return this.resources ? this.resources.articleAboutTasks || '#' : '#';
    },
    hasActiveFilters() {
      return Object.keys(this.activeFilters).length > 0;
    },
    emptyState() {
      if (this.hasActiveFilters) return 'filteredEmpty';
      return 'unfilteredEmpty';
    },
  },
  watch: {
    isAuthenticated(val) {
      if (val) {
        this.onHashChange();
      }
    },
    routeName() {
      this.defineCurrentTab();
    },
    themeDone(val) {
      if (val) {
        this.setupFiltersAndSorting();
      }
    },
    isFiltersLoaded(val) {
      if (val) {
        this.loadTasks();
      }
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('trackers', ['getTrackers']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('exercises', ['getTaskPoints', 'getTasksAvailable', 'getTasksInProgress', 'getTasksCompleted', 'getTask', 'getTaskGuest', 'assignTask', 'getDisciplines']),
    ...mapActions('partners', ['getActivityRegistration', 'createParticipatingActivities']),
    defineCurrentTab() {
      if (this.$route.name === 'TasksAvailable') this.selectedTab = 0;
      else if (this.$route.name === 'TasksInProgress') this.selectedTab = 1;
      else if (this.$route.name === 'TasksCompleted') this.selectedTab = 2;
      else this.selectedTab = 0;
    },
    changeTab(index, willUpdateTasks = true) {
      const prevTab = this.selectedTab;
      let url = '';
      if (index === 0) url = '/tasks/available';
      if (index === 1) url = '/tasks/in-progress';
      if (index === 2) url = '/tasks/completed';
      if (url && this.$route.path !== url) this.$router.replace(url);
      this.selectedTab = index;

      if (prevTab === 0 && this.selectedTab !== prevTab && this.hasActiveFilters) {
        this.removeFilters();
        window.scrollTo(0, 0);
        if (!willUpdateTasks) this.loadTasks();
      }
    },
    onStart() {
      this.taskDetailsModal = false;
      if (this.taskDetailsOptions) {
        if (this.taskDetailsOptions.isTrackerNeeded !== true) {
          const participantExerciseId = uuidv4();
          this.assignTask({ participantExerciseId, exerciseId: this.taskDetailsExerciseId })
            .then(() => {
              if (this.responseStatus === 204) {
                this.changeTab(1);
                this.removeFilters();
                window.scrollTo(0, 0);
                window.location.hash = `#!showTask=${this.taskDetailsExerciseId}&peId=${participantExerciseId}`;
                this.loadTasks();
              }
            });
          return;
        }
      }
      this.getTrackers()
        .then(() => {
          if (this.trackers.filter((i) => i.state === 'Connected').length > 0) {
            localStorage.removeItem('returnFromGuide');
            const participantExerciseId = uuidv4();
            this.assignTask({ participantExerciseId, exerciseId: this.taskDetailsExerciseId })
              .then(() => {
                if (this.responseStatus === 204) {
                  this.changeTab(1);
                  this.removeFilters();
                  window.scrollTo(0, 0);
                  window.location.hash = `#!showTask=${this.taskDetailsExerciseId}&peId=${participantExerciseId}`;
                  this.loadTasks();
                }
              });
            return;
          }
          if (this.$root.showOnboarding) {
            localStorage.setItem('returnToTask', this.taskDetailsExerciseId);
            this.$root.showOnboarding({ step: 'choose' });
          }
        });
    },
    onShowTask(index) {
      let tasks = null;
      if (this.selectedTab === 0) {
        tasks = this.availableItems;
        this.hideFiltersDropdown = true;
        setTimeout(() => { this.hideFiltersDropdown = false; }, 250);
      }
      if (this.selectedTab === 1) tasks = this.inProgressItems;
      if (this.selectedTab === 2) tasks = this.completedItems;

      if (tasks) {
        const task = tasks[index];
        const participantExerciseTaskId = task.participantExercise ? task.participantExercise?.tasks[0]?.participantExerciseTaskId : null;
        const participantExerciseTaskId1 = task.participantExercise ? task.participantExercise?.tasks[1]?.participantExerciseTaskId : null;
        localStorage.removeItem('returnToTask');
        window.location.hash = `#!showTask=${task.exerciseId}${this.selectedTab !== 0 ? `&peId=${task.participantExercise.participantExerciseId}` : ''}`;
        if (task.participantExercise ? task.participantExercise.tasks[0].state === 'Completed' : false) {
          this.getActivityRegistration({ participantExerciseTaskId })
            .then(() => {
              const id = uuidv4();
              this.createParticipatingActivities(
                {
                  activityId: id,
                  exerciseTaskId: participantExerciseTaskId1,
                  sessionId: this.registration.sessionId,
                },
              );
            })
            .catch((error) => {
              console.error('Ошибка', error);
            });
        }
      }
    },
    onTaskDetailsClose() {
      this.taskDetailsModal = false;
      window.scroll(0, this.pageScrollY);
      window.location.hash = '';
    },
    onHashChange() {
      if (this.$route.name !== 'Tasks'
          && this.$route.name !== 'TasksAvailable'
          && this.$route.name !== 'TasksInProgress'
          && this.$route.name !== 'TasksCompleted') {
        window.removeEventListener('hashchange', this.onHashChange);
        return;
      }
      const hash = window.location.hash.split('=');
      if (hash[0] !== '#!showTask') return;
      if (hash.length === 2) this.loadTask(hash[1]);
      if (hash.length === 3 && hash[1].indexOf('&peId') >= 0) this.loadMyTask(hash[1].split('&peId')[0], hash[2]);
    },
    onFilterChange(filters) {
      const result = {};
      const keys = Object.keys(filters);
      for (let i = 0; i < keys.length; i += 1) {
        if (filters[keys[i]].val !== null) result[keys[i]] = filters[keys[i]];
      }
      this.activeFilters = result;
      const vm = this;
      this.offsetAvailable = 0;
      this.getTasksAvailable({ offset: 0, count: 12, ...this.getFilters() })
        .then(() => {
          if (this.responseStatus === 200) {
            window.scrollTo(0, 0);
            this.availableItems = this.tasksAvailable ? this.tasksAvailable.values : [];
            if (this.availableItems.length === 0 && this.selectedTab === 0 && !this.hasActiveFilters) {
              this.changeTab(1);
              return;
            }
            if (this.selectedTab === 0) {
              setTimeout(() => { vm.setupLoader(); }, 250);
            }
          }
        });
    },
    onFilterReset() {
      this.activeFilters = {};
      const vm = this;
      this.offsetAvailable = 0;
      this.getTasksAvailable({ offset: 0, count: 12, ...this.getFilters() })
        .then(() => {
          if (this.responseStatus === 200) {
            window.scrollTo(0, 0);
            this.availableItems = this.tasksAvailable ? this.tasksAvailable.values : [];
            if (this.availableItems.length === 0 && this.selectedTab === 0) {
              this.changeTab(1);
              return;
            }
            if (this.selectedTab === 0) {
              setTimeout(() => { vm.setupLoader(); }, 250);
            }
          }
        });
    },
    setupFiltersAndSorting() {
      if (this.enabledMarketFilters) {
        const keys = Object.keys(this.filters);
        for (let i = 0; i < keys.length; i += 1) {
          if (!this.enabledMarketFilters.includes(keys[i])) {
            delete this.filters[keys[i]];
          }
        }
      }
    },
    setDisciplineFilter() {
      if (this.disciplines && this.filters.discipline) {
        let filters = [];
        for (let i = 0; i < this.disciplines.length; i += 1) {
          const discipline = this.disciplineNames.find((item) => item.id === this.disciplines[i]);
          const name = discipline ? discipline.name : this.disciplines[i];
          const order = discipline ? discipline.order : this.disciplineNames.length + i + 1;
          const group = discipline ? discipline.group : null;
          const item = { id: this.disciplines[i], name, order };
          if (group) item.group = group;
          filters.push(item);
        }
        filters = filters.sort(this.sortItemsByOrder);
        this.availableFilters.discipline = [...filters];

        filters = this.getCollapsedByGroup(filters);
        this.filters.discipline = filters;
      }
    },
    getFilters() {
      const result = {};
      if (this.activeFilters.discipline) {
        result.discipline = this.activeFilters.discipline.val || [];
      }
      return this.expandGroups(result);
    },
    removeFilters() {
      this.$router.replace({ }).catch(() => { });
      this.activeFilters = {};
    },
    sortItemsByOrder(a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    },
    getCollapsedByGroup(filters) {
      const collapsed = [];
      for (let i = 0; i < filters.length; i += 1) {
        if (!filters[i].group) {
          collapsed.push(filters[i]);
          continue;
        }
        if (collapsed.filter((item) => item.group === filters[i].group).length === 0) {
          const item = { ...filters[i] };
          item.id = filters[i].group;
          collapsed.push(item);
        }
      }
      return collapsed;
    },
    expandGroups(filters) {
      // eslint-disable-next-line prefer-object-spread
      const result = Object.assign({}, filters);
      const keys = Object.keys(result);
      for (let i = 0; i < keys.length; i += 1) {
        const val = Object.assign([], result[keys[i]]);
        const values = [];
        for (let k = 0; k < val.length; k += 1) {
          const items = this.availableFilters.discipline.filter((item) => item.group && item.group === val[k]);
          if (items.length > 0) {
            values.push(...(items.map((item) => item.id)));
            continue;
          }
          values.push(val[k]);
        }
        result[keys[i]] = values;
      }
      return result;
    },
    loadTask(id) {
      this.getTaskGuest(id)
        .then(() => {
          if (this.taskGuest) {
            this.pageScrollY = window.scrollY;
            this.taskDetailsState = 'available';
            this.taskDetailsExerciseId = id;
            this.taskDetailsOptions = this.taskGuest;
            this.getTaskPoints(id)
              .then(() => {
                this.taskRating = this.taskPoints;
              });
            this.taskDetailsModal = true;
            return;
          }
          window.location.hash = '';
        });
    },
    loadMyTask(id, peId) {
      this.getTask(peId)
        .then(() => {
          if (this.task) {
            this.pageScrollY = window.scrollY;
            this.taskDetailsState = this.getTaskDetailsState(this.task);
            this.taskDetailsExerciseId = peId;
            this.taskDetailsOptions = this.task;
            this.getTaskPoints(id)
              .then(() => {
                this.taskRating = this.taskPoints;
              });
            this.taskDetailsModal = true;
            return;
          }
          this.changeTab(0);
          window.location.hash = `#!showTask=${id}`;
        });
    },
    getTabText(tab) {
      if (tab === 0) return { title: `<div>${this.tasksAvailable ? this.tasksAvailable.total : 0}</div><div>Доступны</div>`, enabled: true };
      if (tab === 1) return { title: `<div>${this.tasksInProgress ? this.tasksInProgress.total : 0}</div><div>Выполняются</div>`, enabled: this.tasksInProgress ? this.tasksInProgress.total > 0 : false };
      return { title: `<div>${this.tasksCompleted ? this.tasksCompleted.total : 0}</div><div>Завершены</div>`, enabled: this.tasksCompleted ? this.tasksCompleted.total > 0 : false };
    },
    getTaskReward(task) {
      let reward = 0;
      if (!task || !task.tasks) return reward;
      for (let i = 0; i < task.tasks.length; i += 1) {
        reward += task.tasks[i].reward;
      }
      return Math.round(reward);
    },

    getTaskRewardRange(task) {
      let reward = 0;
      let maxReward = 0;
      if (!task || !task.tasks) return reward;
      for (let i = 0; i < task.tasks.length; i += 1) {
        reward += task.tasks[i].reward;
        maxReward += task.tasks[i].maxReward;
      }
      return maxReward && reward < maxReward ? `${Math.round(reward)}-${maxReward}` : Math.round(reward);
    },
    getIsFlexibleCost(task) {
      const reward = this.getTaskReward(task);
      if (!task || !task.tasks) return false;
      let maxReward = 0;
      for (let i = 0; i < task.tasks.length; i += 1) {
        maxReward += task.tasks[i].maxReward;
      }
      return reward < maxReward;
    },
    getCompletedTaskState(task) {
      if (task.participantExercise.state === 'Completed') return 'completed';
      if (task.participantExercise.state === 'Rejected') return 'failed';
      if (task.participantExercise.state === 'AwaitingConfirmation') return 'checking';
      return '';
    },
    getState() {
      if (this.selectedTab === 0) return 'available';
      if (this.selectedTab === 1) return 'inProgress';
      return 'completed';
    },
    getTaskDetailsState(task) {
      if (!task.participantExercise) return 'available';
      if (task.participantExercise.state === 'Created') return 'inProgress';
      if (task.participantExercise.state === 'Completed') return 'completed';
      if (task.participantExercise.state === 'Rejected') return 'failed';
      if (task.participantExercise.state === 'AwaitingConfirmation') return 'checking';
      return '';
    },
    setupLoader() {
      const vm = this;
      if (vm.loaderBlock !== null) return;
      if (vm.$route.name !== 'Tasks'
          && vm.$route.name !== 'TasksAvailable'
          && vm.$route.name !== 'TasksInProgress'
          && vm.$route.name !== 'TasksCompleted') {
        return;
      }
      const loaders = document.getElementsByClassName('tasks__loader-block');
      vm.loaderBlock = loaders[0] || null;
      window.addEventListener('scroll', vm.checkForLoading);
      window.addEventListener('touchmove', vm.checkForLoading);
      vm.checkForLoading();
    },
    checkForLoading() {
      if (this.updating) return;
      if (!this.loaderBlock) return;
      if (this.$route.name !== 'Tasks'
          && this.$route.name !== 'TasksAvailable'
          && this.$route.name !== 'TasksInProgress'
          && this.$route.name !== 'TasksCompleted') {
        this.cleanup();
        return;
      }

      if (this.getTasks() === null) return;
      if (!this.hasTasksForLoading()) return;

      const bottomCoord = this.loaderBlock.getBoundingClientRect().bottom;
      const height = document.documentElement.clientHeight;
      if (height >= bottomCoord) {
        this.updating = true;
        setTimeout(() => { this.updateTasks(); }, 250);
      }
    },
    cleanup() {
      window.removeEventListener('scroll', this.checkForLoading);
      window.removeEventListener('touchmove', this.checkForLoading);
    },
    loadTasks() {
      const vm = this;
      this.getTasksAvailable({ offset: 0, count: 12, ...this.getFilters() })
        .then(() => {
          if (this.responseStatus === 200) {
            this.availableItems = this.tasksAvailable ? this.tasksAvailable.values : [];
            if (this.availableItems.length === 0 && this.selectedTab === 0 && !this.hasActiveFilters) {
              this.changeTab(1);
              return;
            }
            if (this.selectedTab === 0) {
              setTimeout(() => { vm.setupLoader(); }, 250);
            }
          }
        });
      this.getTasksInProgress({ offset: 0, count: 12 })
        .then(() => {
          if (this.responseStatus === 200) {
            this.inProgressItems = this.tasksInProgress ? this.tasksInProgress.values : [];
            if (this.selectedTab === 1) {
              setTimeout(() => { vm.setupLoader(); }, 250);
            }
          }
        });
      this.getTasksCompleted({ offset: 0, count: 12 })
        .then(() => {
          if (this.responseStatus === 200) {
            this.completedItems = this.tasksCompleted ? this.tasksCompleted.values : [];
            if (this.selectedTab === 2) {
              setTimeout(() => { vm.setupLoader(); }, 250);
            }
          }
        });
    },
    updateTasks() {
      if (this.selectedTab === 0) {
        this.offsetAvailable += 12;
        this.getTasksAvailable({ offset: this.offsetAvailable, count: 12, ...this.getFilters() })
          .then(() => {
            if (this.responseStatus === 200) {
              this.availableItems.push(...this.tasksAvailable.values);
            }
            this.updating = false;
          });
        return;
      }
      if (this.selectedTab === 1) {
        this.offsetInProgress += 12;
        this.getTasksInProgress({ offset: this.offsetInProgress, count: 12 })
          .then(() => {
            if (this.responseStatus === 200) {
              this.inProgressItems.push(...this.tasksInProgress.values);
            }
            this.updating = false;
          });
        return;
      }
      if (this.selectedTab === 2) {
        this.offsetCompleted += 12;
        this.getTasksCompleted({ offset: this.offsetCompleted, count: 12 })
          .then(() => {
            if (this.responseStatus === 200) {
              this.completedItems.push(...this.tasksCompleted.values);
            }
            this.updating = false;
          });
      }
    },
    getTasks() {
      if (this.selectedTab === 0) return this.tasksAvailable;
      if (this.selectedTab === 1) return this.tasksInProgress;
      return this.tasksCompleted;
    },
    hasTasksForLoading() {
      let tasks = { count: 0 };
      let items = [];
      if (this.selectedTab === 0) {
        tasks = this.tasksAvailable;
        items = this.availableItems;
      }
      if (this.selectedTab === 1) {
        tasks = this.tasksInProgress;
        items = this.inProgressItems;
      }
      if (this.selectedTab === 2) {
        tasks = this.tasksCompleted;
        items = this.completedItems;
      }
      if (tasks.count === 0) return false;
      if (items.length >= tasks.total) return false;
      return true;
    },
    isRepeat(task) {
      return task.type === 'Reusable';
    },
    disciplineIcon(task) {
      const disciplineIconMap = {
        Invite: 'invite',
        Running: 'running',
        Cycling: 'cycling',
        Skiing: 'skiing',
        Swimming: 'swimming',
        Walking: 'walking',
        Quiz: 'quiz',
        GeoLocation: 'geolocation',
        PartnerLink: 'partnerlink',
        Registration: 'registration',
        Participation: 'participation',
        UploadImage: 'uploadimage',
      };
      if (!task || !task.tasks) return [];
      const tasks = task ? task.tasks : null;
      const disciplineIcons = [];
      const uniqueIcons = new Set();
      for (let i = 0; i < tasks.length; i += 1) {
        const discipline = tasks[i].settings.Discipline;
        const icon = disciplineIconMap[discipline] || '';
        if (!uniqueIcons.has(icon)) {
          uniqueIcons.add(icon);
          disciplineIcons.push(icon);
        }
      }
      return disciplineIcons;
    },
  },
  mounted() {
    this.getProfile();
    this.getBalance();
    this.defineCurrentTab();
    if (this.themeDone) {
      this.setupFiltersAndSorting();
    }
    this.getDisciplines()
      .then(() => {
        this.setDisciplineFilter();
        this.isFiltersLoaded = true;
      });
    window.addEventListener('hashchange', this.onHashChange);
    this.onHashChange();
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import "./Tasks";
</style>

<template>
  <Login
    v-if="show"
    :logo="logo"
    :cover="$root.isMobile ? introCoverMobile : introCover"
  ></Login>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Login from '@/components/Login/Login.vue';

export default {
  name: 'Auth',
  components: {
    Login,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['resources', 'options']),
    ...mapGetters('user', ['isAuthenticated', 'emptyProfile']),
    logo() {
      return this.resources ? this.resources.logo || '' : '';
    },
    introCover() {
      return this.resources ? this.resources.introCover || '' : '';
    },
    introCoverMobile() {
      return this.resources ? this.resources.introCoverMobile || '' : '';
    },
    isFinished() {
      return this.options ? this.options.isFinished === true : false;
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    checkProfile() {
      this.getProfile()
        .then(() => {
          if (this.isAuthenticated) {
            if (this.emptyProfile === true) {
              this.$router.push({ name: 'Register' }).catch(() => {});
            } else {
              this.$router.push({ name: 'Dashboard' }).catch(() => {});
            }
          } else {
            this.show = true;
          }
        });
    },
  },
  mounted() {
    if (this.isFinished) {
      this.$router.push({ name: 'Home' }).catch(() => {});
      return;
    }
    this.checkProfile();
  },
};
</script>

<template>
  <div class="about">
    <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="!isMobileApp" class="about__menu">
      <Menu />
      <div class="divider"></div>
    </div>

    <Intro
      :image="$root.isMobile ? aboutCoverMobile : aboutCoverWide || aboutCover"
      :imagePadding="false"
      :imageAsBackground="introImageAsBackground"
      :titleAbove="introTitleAbove"
      :title="introTitle"
      :subtitle="introSubtitle"
      :invertTextColor="introInvertTextColor"
    ></Intro>

    <TextBlock
      v-if="showTextBlock && textBlockItems.length > 0"
      :items="textBlockItems"
      id="textBlock"
    />

    <div class="divider"></div>
    <Goal
      v-if="showGoal"
      :formatTotalKm="formatTotalKm"
      :formatCurrentKm="formatCurrentKm"
      :totalKm="goal ? goal.necessaryDistance : 0"
      :currentKm="goal ? goal.completedDistance : 0"
      :currentMoney="goal && goalShowCharity ? `${goal.completedAmount} ₽` : ''"
      :localizedText="{
        distance: 'вместе преодолено', total: 'Вместе преодолеть:', amount: 'собрано во благо',
      }"
      fullWidth
      @distance-question="onShowDistance()"
      @money-question="onShowCharity()"
    ></Goal>

    <Ambassador
      v-if="ambassadorItems.length > 0"
      :type="ambassadorsType || 'general'"
      :title="ambassadorsTitle"
      :items="ambassadorsValues"
    ></Ambassador>

    <div v-if="showDummyBanner && $root.isMobile" class="banner">
      <img :src="dummyBannerMobile" />
    </div>
    <div v-if="showDummyBanner && !$root.isMobile" class="banner">
      <img :src="dummyBanner" />
    </div>

    <div class="nominations">
      <div class="nominations__slider">
        <Slider
          v-if="nominationsActive ? nominationsActive.total > 0 : false"
          cols="1"
          :slidesPerView="1"
          :loop="true"
          :navigationOffset="nominationsNavigationOffset"
          :paginationOffset="nominationsPaginationOffset"
          class="nominations-slider"
        >
          <NominationCard
            v-for="item in nominationsActive.values"
            :key="item.nominationId"
            type="personal"
            state="in-progress"
            :image="getNominationImage(item)"
            :nominationText="getNominationDates(item)"
            :lockedText="{ text: 'Для участия необходимо', link: 'подключить трекер' }"
            :title="item.title"
            :rules="{ text: 'Правила', action: () => onShowRules(item) }"
            :prizes="{ text: 'Награды', action: () => onShowPrizes(item) }"
            :ratingText="{ general: 'Текущий рейтинг', personal: 'Вы в рейтинге' }"
            :values="getNominationRating(item)"
            @show-details="onShowRating(item)"
            @locked-click="onShowOnboarding()"
          ></NominationCard>
        </Slider>
      </div>
    </div>

    <TopCurators v-if="themeDone && showTopCurators"></TopCurators>
    <div id="involvement-rating"></div>

    <RulesPrizesModal
      :active="rulesPrizesModal"
      :image="rulesPrizesImage"
      :title="rulesPrizesTitle"
      :subtitle="rulesPrizesSubtitle"
      :nominationId="rulesPrizesNominationId"
      state="personal"
      :tabs="rulesPrizesTabs"
      :activeTab="rulesPrizesTabActive"
      :isMobileApp="isMobileApp"
      @close="rulesPrizesModal = false"
    />

    <DistanceCharityModal
      :active="distanceCharityModal"
      :type="distanceCharityType"
      :localizedText="distanceCharityLocalizedText"
      moreLink=""
      @close="distanceCharityModal = false"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Intro from '@sc-component-library/intro/src/main';
import Goal from '@sc-component-library/goal/src/main';
import Ambassador from '@sc-component-library/ambassador/src/main';
import NominationCard from '@sc-component-library/nomination-card/src/main';
import Menu from '@/components/Menu/Menu.vue';
import TopCurators from '@/components/TopCurators/TopCurators.vue';
import TextBlock from '@/components/TextBlock/TextBlock.vue';
import RulesPrizesModal from '@/components/RulesPrizesModal/RulesPrizesModal.vue';
import DistanceCharityModal from '@/components/DistanceCharityModal/DistanceCharityModal.vue';
import {
  scrollToId, formatDateRange, formatNumber, addNonBreakingSpaces,
} from '@/assets/js/helpers';

export default {
  name: 'About',
  components: {
    Intro,
    Goal,
    Ambassador,
    NominationCard,
    Menu,
    TopCurators,
    TextBlock,
    RulesPrizesModal,
    DistanceCharityModal,
  },
  data() {
    return {
      distanceCharityModal: false,
      distanceCharityType: '',
      distanceCharityLocalizedText: {
        title: '',
        subtitle: '',
      },
      rulesPrizesModal: false,
      rulesPrizesImage: '',
      rulesPrizesTitle: '',
      rulesPrizesSubtitle: '',
      rulesPrizesNominationId: '',
      rulesPrizesTabs: [
        {
          title: 'Правила',
          enabled: true,
          content: '',
        },
        {
          title: 'Награды',
          enabled: true,
          content: '',
        },
        {
          title: 'Рейтинг',
          enabled: true,
        },
      ],
      rulesPrizesTabActive: 0,
      nominations: [],
      nominationsState: 'in-progress',
      totalDistance: 0,
      completedDistance: 0,
      contributionPercentage: 0,
      textBlockItems: [],
      ambassadorItems: [],
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeDone', 'options', 'resources']),
    ...mapGetters('goals', ['goal']),
    ...mapGetters('nominations', ['nominationsActive']),
    ...mapGetters('trackers', ['trackers']),
    ...mapGetters('content', ['onboardingItems', 'contentStatus', 'ambassadors']),
    ...mapGetters('user', ['isMobileApp']),
    showTopCurators() {
      return this.options ? this.options.showTopCurators === true : false;
    },
    introImageAsBackground() {
      return this.options ? this.options.introImageAsBackground === true : false;
    },
    introInvertTextColor() {
      return this.options ? this.options.introInvertTextColor === true : false;
    },
    homeSections() {
      return this.options ? this.options.homeSections || null : null;
    },
    // showAmbassadors() {
    //   return this.options ? this.options.showAmbassadors === true : false;
    // },
    ambassadorsType() {
      return this.options ? this.options.ambassadorsType || '' : 'general';
    },
    ambassadorsValues() {
      return this.ambassadors ? this.updatedAmbassadors() || [] : [];
    },
    goalShowCharity() {
      return this.options ? this.options.goalShowCharity === true : false;
    },
    showGoal() {
      return this.options && this.options.showGoal !== undefined ? this.options.showGoal === true : true;
    },
    aboutCover() {
      return this.resources ? this.resources.aboutCover || '' : '';
    },
    aboutCoverWide() {
      return this.resources ? this.resources.aboutCoverWide || '' : '';
    },
    aboutCoverMobile() {
      return this.resources ? this.resources.aboutCoverMobile || '' : '';
    },
    introTitleAbove() {
      return this.resources ? this.resources.introTitleAbove || '' : '';
    },
    introTitle() {
      return this.resources ? this.resources.introTitle || '' : '';
    },
    introSubtitle() {
      return this.resources ? this.resources.introSubtitle || '' : '';
    },
    introButtonText() {
      return this.resources ? this.resources.introButtonText || '' : '';
    },
    introNote() {
      return this.resources ? this.resources.introNote || '' : '';
    },
    ambassadorsTitle() {
      return this.resources ? this.resources.ambassadorsTitle || this.ambassadorsTitleDefault : this.ambassadorsTitleDefault;
    },
    ambassadorsTitleDefault() {
      return addNonBreakingSpaces('Вас будут поддерживать и мотивировать');
    },
    dummyBanner() {
      return this.resources ? this.resources.dummyBanner || '' : '';
    },
    dummyBannerMobile() {
      return this.resources ? this.resources.dummyBannerMobile || '' : '';
    },
    goalModalSubtitle() {
      return this.resources ? this.resources.goalModalSubtitle || '' : '';
    },
    goalModalCharityTitle() {
      return this.resources ? this.resources.goalModalCharityTitle || '' : '';
    },
    goalModalCharitySubtitle() {
      return this.resources ? this.resources.goalModalCharitySubtitle || '' : '';
    },
    nominationsNavigationOffset() {
      return {
        default: '0', md: '72', lg: '72', xl: '84',
      };
    },
    nominationsPaginationOffset() {
      return {
        default: '28', md: '44', lg: '44', xl: '60',
      };
    },
    showTextBlock() {
      return this.homeSections ? this.homeSections.textBlock !== undefined : false;
    },
    showDummyBanner() {
      return this.homeSections ? this.homeSections.dummyBanner !== undefined : false;
    },
    formatTotalKm() {
      return this.goal ? formatNumber(this.goal.necessaryDistance, 'км') : '0км';
    },
    formatCurrentKm() {
      return this.goal ? formatNumber(this.goal.completedDistance, 'км') : '0км';
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('goals', ['getGoal']),
    ...mapActions('nominations', ['getNominationsActive']),
    ...mapActions('trackers', ['getTrackers']),
    ...mapActions('content', ['getOnboardingItems', 'getAmbassadors']),
    updatedAmbassadors() {
      return this.ambassadors.map((ambassador) => ({
        name: ambassador.fullName,
        avatar: ambassador.desktopImageUrl,
        avatarMobile: ambassador.mobileImageUrl,
        taskText: ambassador.content,
        quote: ambassador.description,
      }));
    },
    onLogin() {
      this.$router.push({ name: 'Auth' }).catch(() => {});
    },
    onShowDistance() {
      this.distanceCharityType = 'distance';
      const subtitleDefault = addNonBreakingSpaces('Бегайте и ходите. Внесите свой вклад в достижение общей цели.');
      this.distanceCharityLocalizedText = {
        title: 'Вместе преодолеть',
        subtitle: this.goalModalSubtitle ? this.goalModalSubtitle : subtitleDefault,
      };
      this.distanceCharityModal = true;
    },
    onShowCharity() {
      this.distanceCharityType = 'charity';
      this.distanceCharityLocalizedText = {
        title: this.goalModalCharityTitle,
        subtitle: this.goalModalCharitySubtitle,
      };
      this.distanceCharityModal = true;
    },
    onShowRules(item) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = false;
      this.rulesPrizesTabActive = 0;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesModal = true;
    },
    onShowPrizes(item) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = false;
      this.rulesPrizesTabActive = 1;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesModal = true;
    },
    onShowRating(item) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = true;
      this.rulesPrizesTabActive = 2;
      this.rulesPrizesImage = this.getNominationImage(item);
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesNominationId = item.nominationId;
      this.rulesPrizesModal = true;
    },
    onShowOnboarding() {
      this.$root.showOnboarding({ step: 'choose' });
    },
    getNominationImage(item) {
      if (!item || !item.images) return '';
      return this.$root.isDesktop ? item.images.desktop || '' : item.images.mobile || '';
    },
    getNominationDates(item) {
      if (!item) return '';
      const startDate = dayjs.utc(item.startDate);
      const finishDate = dayjs.utc(item.finishDate);
      if (startDate.isValid() && finishDate.isValid()) {
        return formatDateRange({ start: startDate, finish: finishDate, showYear: true });
      }
      return '';
    },
    getNominationRating(item) {
      if (!item) return [];
      const rating = [];
      for (let i = 0; i < item.leaders.length; i += 1) {
        const it = {
          number: item.leaders[i].position.toString(),
          name: `${item.leaders[i].lastName} ${item.leaders[i].firstName}`,
          value: item.leaders[i].points.toString(),
          current: item.leaders[i].isCurrentUser === true,
        };
        rating.push(it);
      }
      return rating;
    },
    setNominationsState() {
      if (this.trackers.filter((i) => i.state === 'Connected').length > 0) {
        this.nominationsState = 'in-progress';
      } else {
        this.nominationsState = 'locked';
      }
    },
    setTextBlock() {
      if (this.showTextBlock) {
        this.getOnboardingItems()
          .then(() => {
            if (this.contentStatus === 200) {
              this.textBlockItems = this.onboardingItems || [];
            }
          });
      }
    },
    setAmbassadorBlock() {
      this.getAmbassadors()
        .then(() => {
          if (this.contentStatus === 200) {
            this.ambassadorItems = this.ambassadors || [];
          }
        });
    },
  },
  mounted() {
    this.getProfile();
    this.getBalance();
    this.setTextBlock();
    this.setAmbassadorBlock();
    this.getGoal()
      .then(() => {
        if (this.goal) {
          this.contributionPercentage = Math.round(this.goal.contribution.contributionPercentage);
          this.totalDistance = this.goal.contribution.totalDistance;
          this.completedDistance = this.goal.completedDistance;
        }
      });
    this.getTrackers()
      .then(() => {
        if (this.trackers) {
          const connected = this.trackers.filter((i) => i.state === 'Connected');
          if (connected.length > 0) {
            localStorage.removeItem('returnFromGuide');
          }
          this.getNominationsActive({ offset: 0, count: 12 })
            .then(() => {
              this.setNominationsState();
              this.nominations = this.nominationsActive ? this.nominationsActive.values || [] : [];
              setTimeout(() => {
                if (window.location.hash) {
                  const id = window.location.hash.length > 1 ? window.location.hash.slice(1) : '';
                  if (id !== '') scrollToId(id);
                }
              }, 1000);
            });
        }
      });
  },
};
</script>

<style lang="scss" scoped>
  @import "./About";
</style>

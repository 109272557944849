<template>
  <div>
    <Modal
      :active="active"
      :closable="true"
      name="SupportModal"
      class="support__modal"
      title="Задайте вопрос"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="$emit('close')"
      >
        <form
          class="form"
          novalidate="novalidate"
          @submit.prevent="submitForm"
        >
        <div class="support__item">
          <Field
            label="Электронная почта для ответа"
            label-for="email"
            >
            <Input
              v-model.trim="fields.email"
              placeholder="example@email.com"
              id="email"
              name="email"
              type="email"
              class="support__input"
              :state="
                $v.fields.email.$error ? 'error' : null
              "
              :disabled="sending"
              @input="$v.fields.email.$touch()"
            ></Input>
            <template v-slot:msg>
              <div class="color-danger" v-if="$v.fields.email.$error">Проверьте правильность email</div>
            </template>
          </Field>
        </div>
        <div class="support__item">
          <span class="support__count">{{ descriptionCharacterCount }}/120</span>
          <Field
            label="Тема вопроса"
            label-for="description"
            >
            <textarea
              v-model.trim="fields.description"
              placeholder="Кратко опишите суть вопроса"
              id="description"
              name="description"
              class="support__textarea support__textarea--descr"
              :class="{ 'support__textarea--error': $v.fields.description.$error }"
              @input="$v.fields.description.$touch()"
              :maxlength="120"
              :disabled="sending"
            />
            <template v-slot:msg>
              <div class="color-danger" v-if="$v.fields.description.$error">Укажите тему вопроса</div>
            </template>
          </Field>
        </div>
        <div class="support__item">
          <span class="support__count">{{ textCharacterCount }}/2000</span>
          <Field
            label="Текст вопроса"
            label-for="text"
            >
            <textarea
              v-model.trim="fields.text"
              placeholder="Дополните вопрос"
              id="text"
              name="text"
              class="support__textarea support__textarea--text"
              :class="{ 'support__textarea--error': $v.fields.text.$error }"
              @input="$v.fields.text.$touch()"
              :maxlength="2000"
              :disabled="sending"
            />
            <template v-slot:msg>
              <div class="color-danger" v-if="$v.fields.text.$error">Укажите суть вопроса</div>
            </template>
          </Field>
        </div>
        <div v-show="false">
      <form id="imageForm" action="#" method="post" enctype="multipart/form-data">
        <input id="imageInput" name="imageInput" type="file" accept="image/jpeg" />
      </form>
    </div>
    <div class="form__item">
      <p class="form__upload-label">Скриншот</p>
      <p class="form__upload-sublabel">Загрузите скриншот, чтобы мы могли лучше понять проблему.</p>
      <div class="form__upload" @click="onSelectLogo">
        <div class="form__upload-info">
          <p class="form__upload-title">Выберите файл на компьютере</p>
          <p class="form__upload-subtitle">
            <Icon name="upload" />
            Загрузить скриншот
          </p>
        </div>
      </div>
      <p class="form__upload-rules">Файл (общий размер — до 10 МБ). Допустимые форматы файла: jpg, jpeg</p>
      <div v-if="itemHide" class="form__upload--row">
        <div class="form__upload--item">
          <img v-if="imagePreview" :src="imagePreview" alt="Превью картинки" class="form__upload--img">
          <div class="form__upload--info">
            <p class="form__upload--name">{{ selectedFile.name }}</p>
            <p class="form__upload--text"><span class="form__upload--text-type">{{ textType }}</span> - <span>{{ textMb }}Mb</span></p>
          </div>
          <div class="form__upload--delete" @click="formInputDelete" >
            <Icon name="delete" />
          </div>
        </div>
      </div>
    </div>
      <div class="support__divider divider"></div>
        <div class="support__item">
          <Row align="center">
            <Col :size="{md: '24'}">
              <Button
                text="Отправить заявку"
                variant="primary"
                size="lg"
                type="submit"
                :loading="sending"
                :disabled="!fields.email || !fields.text || !fields.description || hasErrors"
                wide
                mobile
              ></Button>
            </Col>
          </Row>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Field from '@rr-component-library/field/src/main';
import Input from '@rr-component-library/input/src/main';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default {
  name: 'SupportDialog',
  components: { Input, Field },
  mixins: [validationMixin],
  props: {
    active: Boolean,
    state: String,
    modalOptions: Object,
    rating: Object,
    isMobileApp: Boolean,
  },
  data() {
    return {
      showErrors: false,
      sending: false,
      fields: {
        email: null,
        description: null,
        text: null,
        file: null,
      },
      input: null,
      selectedFile: null,
      avatarImage: '',
      MAX_FILE_SIZE: 2 ** 20 * 2,
      itemHide: false,
      imagePreview: null,
    };
  },
  validations: {
    fields: {
      email: { required, email },
      description: { required },
      text: { required },
    },
  },
  computed: {
    hasErrors() {
      return (this.showErrors && this.$v.$anyError);
    },
    descriptionCharacterCount() {
      return this.fields.description ? this.fields.description.length : 0;
    },
    textCharacterCount() {
      return this.fields.text ? this.fields.text.length : 0;
    },
    textMb() {
      return this.selectedFile ? this.bytesToMegabytes(this.selectedFile.size) : null;
    },
    textType() {
      return this.selectedFile ? this.getFileExtension(this.selectedFile.type) : null;
    },
  },
  watch: {
    isShown(val) {
      if (val) {
        this.showErrors = false;
        this.sending = false;
      }
    },
  },
  methods: {
    ...mapActions('exercises', ['sendSupport']),
    bytesToMegabytes(bytes) {
      return (bytes / (1024 * 1024)).toFixed(2);
    },
    getFileExtension(mimeType) {
      const parts = mimeType.split('/');
      if (parts.length === 2) {
        return parts[1];
      }
      return '';
    },
    formInputDelete() {
      this.fields.file = null;
      this.itemHide = false;
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        // Формируем объект formData с данными формы
        const formData = {
          email: this.fields.email,
          title: this.fields.description,
          text: this.fields.text,
          file: this.fields.file,
        };
        // Вызываем событие, передавая formData в родительский компонент
        this.$emit('form-submitted', formData);
        this.fields.email = null;
        this.fields.description = null;
        this.fields.text = null;
        this.fields.file = null;
        this.itemHide = false;
      } else {
        // Возможно, вы также хотите показать сообщение об ошибке пользователю
      }
    },
    onSelectLogo() {
      if (this.input) this.input.click();
    },
    setPictureHandlers() {
      const input = document.getElementById('imageInput');
      if (input) {
        this.input = input;
        input.addEventListener('change', this.onPictureChange);
      }
    },
    onPictureChange() {
      if (!this.input) return;
      const curFiles = this.input.files;
      this.itemHide = true;
      if (curFiles.length === 0) {
        this.input.value = '';
        this.selectedFile = null;
        this.fields.file = null;
      } else {
        // eslint-disable-next-line prefer-destructuring
        const file = curFiles[0];
        if (this.isFileValid(file)) {
          this.selectedFile = file;
          // this.fields.file = file.name;
          const reader = new FileReader();
          this.fields.file = file;
          reader.onload = (e) => {
            this.imagePreview = this.fields.file;
            this.imagePreview = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      }
    },
    isFileValid(file) {
      if (!file) return false;
      if (file.size > this.MAX_FILE_SIZE) return false;
      const name = file.name.toLowerCase();
      if (name.length < 5) return false;
      if (name.slice(name.length - 4) !== '.jpg' && name.slice(name.length - 4) !== '.png') {
        if (name.length < 6) return false;
        if (name.slice(name.length - 5) !== '.jpeg') return false;
      }
      return true;
    },
  },
  mounted() {
    setTimeout(() => { this.setPictureHandlers(); }, 1000);
  },
};
</script>

<style lang="scss" scoped>
  @import './SupportDialog';
</style>

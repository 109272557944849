<template>
  <div class="trainings">
    <div class="trainings__heading-container">
      <div class="trainings__heading">
        Мои тренировки<span @click="onChange"><Icon name="sync" size="xl"/></span>
      </div>
      <div class="trainings__updated" v-if="activitiesUpdatedOn">
        Обновлено {{ getFormattedActivitiesUpdated(activitiesUpdatedOn) }}
      </div>
    </div>
    <div v-show="false" class="trainings__range">
      <div class="trainings__timeframe">
        <Button
          v-for="(item, i) in types"
          :key="i"
          :variant="i === selectedType ? 'primary' : 'secondary'"
          size="md"
          mobile
          @click="onChangeType(i)"
        >
          {{ item }}
        </Button>
      </div>
      <div v-if="selectedType !== 2" class="trainings__time-nav">
        <Button
          variant="ghost"
          view="light"
          shape="square"
          size="md"
          class="time-nav__prev"
          :disabled="!isPrevButtonActive"
          iconLeft="left"
          mobile
          @click.stop="onPrev"
        ></Button>
        <div class="time-nav__text" v-html="pages[currentPage] ? pages[currentPage].text : ''"></div>
        <Button
          variant="ghost"
          view="light"
          shape="square"
          size="md"
          class="time-nav__next"
          :disabled="!isNextButtonActive"
          iconLeft="right"
          mobile
          @click.stop="onNext"
        ></Button>
      </div>
    </div>
    <div class="trainings__activities">
      <Metric
        v-for="(item, i) in activities"
        :key="i"
        :icon="item.icon"
        :value="item.value"
        :subtitle="item.subtitle"
        :disabled="!connectedTracker"
      />
    </div>
    <div
      v-if="emptyState"
      class="trainings__empty"
      :class="{ 'empty_has-days': days.length > 0 }"
    >
      <div class="empty__img"><img :src="sunbatheInAHammockRafiki" /></div>
      <div class="empty__title" v-html="emptyMessages[emptyState] ? emptyMessages[emptyState].title : ''"></div>
      <div class="empty__subtitle" v-html="emptyMessages[emptyState] ? emptyMessages[emptyState].subtitle : ''"></div>
      <div v-if="emptyState !== 'hasTrackerNoItems'" class="empty__action">
        <Button
          variant="primary"
          size="lg"
          text="Подключить трекер"
          icon-left="plus"
          @click="onConnectTracker"
          mobile
        ></Button>
      </div>
    </div>
    <div
      v-for="(day, i) in days"
      :key="i"
      class="trainings__day"
    >
      <div class="trainings__subheading" v-html="day.date"></div>
      <TrainingActivity
        v-for="(activity, k) in day.items"
        :key="k"
        :activity="activity.code"
        :name="activityNames.filter((i) => i.code === activity.code)[0] || ''"
        :details="getActivityDetails(activity.items)"
        class="day__activity"
      >
        <Timeline
          v-for="(item, a) in activity.items"
          :key="a"
          :from="getFormattedTime(item.startDate)"
          :to="getFormattedTime(item.finishDate)"
        >
          <Training
            :provider="item.trackerCode"
            :metrics="getMetrics(item, activity.code)"
          >
            <template v-slot:before v-if="isAlertShown(item)">
              <Alert state="warning" no-action>
                Тренировка не учтена. <Link tag="a" @click.prevent="onRejected(item.alert)">Почему?</Link>
              </Alert>
              <Alert v-if="false" state="success" no-action iconAction="check-circle-s">
                Тренировка учтена в <Link :text="item.alert || ''" tag="a" @click.stop></Link>
              </Alert>
            </template>
          </Training>
        </Timeline>
      </TrainingActivity>
    </div>
    <div class="trainings__loader" v-if="updating">
      <Loader size="24" />
    </div>
    <div class="trainings__loader-block"></div>

    <Modal
      name="trainings-sync"
      icon="sync"
      title="Синхронизация в очереди"
      subtitle="Мы обновим данные о ваших тренировках <b>в течение дня</b>."
      :active="showChange"
      :noTransition="true"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="onChangeClosed"
    >
      <div class="buttons-group">
        <div class="buttons-group__item">
          <Button
            variant="secondary"
            size="lg"
            text="Понятно"
            @click="onChangeClosed"
            wide
            mobile
          ></Button>
        </div>
      </div>
    </Modal>

    <Modal
      name="training-rejected"
      state="warning"
      title="Эта тренировка отклонена:"
      :subtitle="rejectedText"
      :active="showRejected"
      :noTransition="true"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="onRejectedClosed"
    >
    </Modal>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Metric from '@rr-component-library/metric/src/main';
import TrainingActivity from '@rr-component-library/training-activity/src/main';
import Training from '@rr-component-library/training/src/main';
import Timeline from '@rr-component-library/timeline/src/main';
import Alert from '@rr-component-library/alert/src/main';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { formatDateRange } from '@/assets/js/helpers';

dayjs.extend(weekday);

export default {
  name: 'Trainings',
  components: {
    Metric,
    TrainingActivity,
    Training,
    Timeline,
    Alert,
  },
  data() {
    return {
      updating: false,
      showChange: false,
      types: ['Неделя', 'Месяц', 'Все'],
      selectedType: -1,
      pages: [],
      page: 0,
      activityNames: [
        { id: 'walking', name: 'Ходьба' },
        { id: 'running', name: 'Бег' },
        { id: 'cycling', name: 'Велозаезд' },
        { id: 'swimming', name: 'Плавание' },
        { id: 'skiing', name: 'Лыжи' },
      ],
      activities: [],
      days: [],
      rawDays: [],
      dateFrom: '',
      dateTo: '',
      offset: 0,
      loaderBlock: null,
      activitiesUpdatedOn: null,
      showRejected: false,
      rejectedReasons: null,
      rejectedText: '',
      connectedTracker: null,
      emptyMessages: {
        noTrackerNoItems: {
          title: 'Нет информации о вашей активности',
          subtitle: 'Для ведения журнала тренировок подключите трекер активности',
        },
        noTrackerHasItems: {
          title: 'Нет подключённого трекера',
          subtitle: 'Для ведения журнала тренировок подключите трекер активности',
        },
        hasTrackerNoItems: {
          title: 'Нет информации о прошлых тренировках',
          subtitle: 'Тренируйтесь каждый день, а мы будем записывать в журнал тренировок ваши достижения за прошедший день.',
        },
      },
      reasonFormattingTypes: {
        disciplines: 'disciplines',
        distance: 'distance',
        time: 'timeInSeconds',
        averagePace: 'averagePace',
        momentumPace: 'momentumPace',
      },
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'options', 'resources']),
    ...mapGetters('user', ['profile', 'restrictions', 'isMobileApp']),
    ...mapGetters('trackers', ['tracker', 'trackers', 'responseStatus']),
    ...mapGetters('trainings', ['statistic', 'trainings', 'trainingsStatus']),
    isPrevButtonActive() {
      return this.currentPage > 0;
    },
    isNextButtonActive() {
      return this.currentPage < this.pages.length - 1;
    },
    isTrackerConnect() {
      return this.$route.name === 'TrainingsTracker' && this.$route.query.connect;
    },
    firstDate() {
      if (!this.profile) return '';
      const date = dayjs.utc(this.profile.createdOn);
      if (date.isValid) {
        return date.format('YYYY-MM-DD');
      }
      return '';
    },
    firstDayOfCurrentWeek() {
      return dayjs().weekday(0).format('YYYY-MM-DD');
    },
    firstDayOfCurrentMonth() {
      return dayjs().date(1).format('YYYY-MM-DD');
    },
    currentPage() {
      return this.page;
    },
    emptyState() {
      if (!this.connectedTracker && this.days.length === 0) return 'noTrackerNoItems';
      if (!this.connectedTracker && this.days.length > 0) return 'noTrackerHasItems';
      if (this.connectedTracker && this.days.length === 0) return 'hasTrackerNoItems';
      return '';
    },
    hiddenActivities() {
      return this.options ? this.options.hiddenActivities || [] : [];
    },
    sunbatheInAHammockRafiki() {
      return this.resources ? this.resources.sunbatheInAHammockRafiki || '' : '';
    },
  },
  watch: {
    profile(val) {
      if (val) {
        this.fillPages();
      }
    },
    currentPage(val) {
      if (val < 0) return;
      if (this.selectedType === 0 || this.selectedType === 1) {
        this.updateFilters(this.pages[val].date.format('YYYY-MM-DD'));
        this.loadTrainings();
      }
    },
  },
  methods: {
    ...mapActions('user', ['getRestrictions']),
    ...mapActions('trackers', ['getTracker', 'getTrackers']),
    ...mapActions('trainings', ['getStatistic', 'getTrainings']),
    onHashChange() {
      if (this.$route.name !== 'SettingsTrainings') {
        window.removeEventListener('hashchange', this.onHashChange);
        return;
      }
      const hash = window.location.hash.split('=');
      if (hash[0] === '#!showSync') this.showSync(hash);
      if (hash[0] === '#!showRejected') this.showRejectedReason(hash);
      else {
        this.clearHash();
        this.showChange = false;
        this.showRejected = false;
      }
    },
    onChange() {
      window.location.hash = '#!showSync=true';
    },
    onChangeClosed() {
      window.location.hash = '#!showSync=false';
    },
    onRejected(text) {
      this.rejectedText = text;
      window.location.hash = '#!showRejected=true';
    },
    onRejectedClosed() {
      window.location.hash = '#!showRejected=false';
    },
    onChangeType(index) {
      if (this.updating) return;
      if (this.selectedType !== index) {
        this.selectedType = index;
        this.fillPages();
      }
    },
    onPrev() {
      if (this.updating) return;
      if (this.page > 0) this.page -= 1;
    },
    onNext() {
      if (this.updating) return;
      if (this.page < this.pages.length - 1) this.page += 1;
    },
    onConnectTracker() {
      if (this.$root.showOnboarding) {
        this.$root.showOnboarding({ step: 'choose', backToPath: `${window.location.origin}/my-settings/trainings` });
      }
    },
    setActivitiesUpdatedOn() {
      this.getTrackers()
        .then(() => {
          if (this.responseStatus === 200 && this.trackers) {
            const connected = this.trackers.filter((i) => i.state === 'Connected');
            if (connected.length > 0) {
              // eslint-disable-next-line prefer-destructuring
              this.connectedTracker = connected[0];
              localStorage.removeItem('returnFromGuide');
              this.getTracker(connected[0].trackerId)
                .then(() => {
                  if (this.tracker) this.activitiesUpdatedOn = this.tracker.activitiesUpdatedOn || null;
                  else this.activitiesUpdatedOn = null;
                });
            } else {
              this.connectedTracker = null;
              this.activitiesUpdatedOn = null;
            }
          } else {
            this.connectedTracker = null;
            this.activitiesUpdatedOn = null;
          }
        });
    },
    fillPages() {
      const pages = [];
      if (this.selectedType === 0) {
        const d = dayjs.utc(this.firstDate);
        const first = d.weekday(0);
        const last = dayjs.utc(this.firstDayOfCurrentWeek).add(7, 'day');
        let date = first;
        do {
          const page = { text: '', date };
          page.text = formatDateRange({ start: date, finish: date.weekday(6) });
          pages.push(page);
          date = date.add(7, 'day');
        } while (date.format('YYYY-MM-DD') !== last.format('YYYY-MM-DD'));
        this.updateFilters(this.firstDayOfCurrentWeek);
      }
      if (this.selectedType === 1) {
        const d = dayjs.utc(this.firstDate);
        const first = d.date(1);
        const last = dayjs.utc(this.firstDayOfCurrentMonth).add(1, 'month');
        let date = first;
        do {
          const page = { text: `${date.weekday(6).format('MMMM YYYY')}`, date };
          pages.push(page);
          date = date.add(1, 'month');
        } while (date.format('YYYY-MM-DD') !== last.format('YYYY-MM-DD'));
        this.updateFilters(this.firstDayOfCurrentMonth);
      }
      if (this.selectedType === 2) {
        this.updateFilters('');
        this.loadTrainings();
        this.pages = pages;
        this.page = 0;
        return;
      }
      this.pages = pages;
      if (this.pages.length > 0) {
        this.page = -1;
        setTimeout(() => { this.page = this.pages.length - 1; }, 100);
      }
    },
    updateFilters(date) {
      this.offset = 0;
      if (this.selectedType === 0) {
        this.dateFrom = date;
        this.dateTo = dayjs.utc(date).weekday(6).format('YYYY-MM-DD');
      }
      if (this.selectedType === 1) {
        this.dateFrom = date;
        this.dateTo = dayjs.utc(date).add(1, 'month').add(-1, 'day').format('YYYY-MM-DD');
      }
      if (this.selectedType === 2) {
        this.dateFrom = '';
        this.dateTo = '';
      }
    },
    loadTrainings() {
      this.updating = true;
      this.getStatistic({ startFrom: this.dateFrom, startTo: this.dateTo })
        .then(() => {
          if (this.trainingsStatus === 200) {
            this.setActivities();
            this.getTrainings({
              startFrom: this.dateFrom, startTo: this.dateTo, offset: this.offset, count: 20,
            })
              .then(() => {
                if (this.trainingsStatus === 200) {
                  this.rawDays = Object.assign([], this.trainings.values);
                  this.setDays();
                } else {
                  this.rawDays = [];
                  this.days = [];
                  this.updating = false;
                }
              });
          } else {
            this.activities = [];
          }
        });
    },
    updateTrainings() {
      this.offset += 20;
      this.getTrainings({
        startFrom: this.dateFrom, startTo: this.dateTo, offset: this.offset, count: 20,
      })
        .then(() => {
          if (this.trainingsStatus === 200) {
            this.rawDays.push(...this.trainings.values);
            this.setDays();
          } else {
            this.updating = false;
          }
        });
    },
    getFormattedReasons(restrictions) {
      const templates = this.getRejectedTemplates();
      const reasons = {};

      for (let i = 0; i < templates.length; i += 1) {
        const { type } = templates[i];
        if (!type) {
          reasons[templates[i].id] = templates[i].val;
          continue;
        }

        let value = '';
        if (type === this.reasonFormattingTypes.disciplines) value = this.getFormattedDisciplines(restrictions);
        else value = this.getFormattedValue({ ...templates[i], restrictions });

        if (typeof value === 'string') reasons[templates[i].id] = templates[i].val.replace('{{0}}', value);
        if (typeof value === 'object') reasons[templates[i].id] = value;
      }

      return reasons;
    },
    getRejectedTemplates() {
      return [
        { id: 'ManualProhibited', val: 'Вручную добавленные тренировки запрещены' },
        { id: 'DuplicatedByPeriod', val: 'Тренировка пересекается по времени с другой уже загруженной тренировкой' },
        { id: 'PauseTimeHasBeenExceeded', val: 'Суммарное время пауз тренировки превышает 30 мин' },
        {
          id: 'DisciplineProhibited',
          val: 'Дисциплина не принимается. Учитываются дисциплины: {{0}}',
          type: this.reasonFormattingTypes.disciplines,
        },
        {
          id: 'MinimumDistanceNotReached',
          val: 'Слишком маленькая дистанция. Минимальная дистанция одной тренировки - {{0}}',
          type: this.reasonFormattingTypes.distance,
          min: true,
        },
        {
          id: 'MaximumDistanceExceeded',
          val: 'Слишком большая дистанция. Максимальная дистанция одной тренировки - {{0}}',
          type: this.reasonFormattingTypes.distance,
        },
        {
          id: 'MinimumTimeNotReached',
          val: 'Слишком короткая по времени. Минимальное время одной тренировки - {{0}} минут',
          type: this.reasonFormattingTypes.time,
          min: true,
        },
        {
          id: 'MaximumTimeExceeded',
          val: 'Слишком длинная по времени. Максимальное время одной тренировки - {{0}} минут',
          type: this.reasonFormattingTypes.time,
        },
        {
          id: 'MinimumAveragePaceNotReached',
          val: 'Не достигнут минимальный средний темп. Минимальный темп одной тренировки - {{0}} мин/км',
          type: this.reasonFormattingTypes.averagePace,
          min: true,
        },
        {
          id: 'MaximumAveragePaceExceeded',
          val: 'Превышен максимальный средний темп. Максимальный темп одной тренировки - {{0}} мин/км',
          type: this.reasonFormattingTypes.averagePace,
        },
        {
          id: 'MinimumMomentumPaceNotReached',
          val: 'Не достигнут минимальный пиковый темп. Минимальный темп одной тренировки - {{0}} мин/км',
          type: this.reasonFormattingTypes.momentumPace,
          min: true,
        },
        {
          id: 'MaximumMomentumPaceExceeded',
          val: 'Превышен максимальный пиковый темп. Максимальный темп одной тренировки - {{0}} мин/км',
          type: this.reasonFormattingTypes.momentumPace,
        },
      ];
    },
    getFormattedDisciplines(restrictions) {
      let formatted = '';
      if (!restrictions || !restrictions.disciplines) return '';

      const allowed = this.getAllowedDisciplines(restrictions);

      for (let i = 0; i < allowed.length; i += 1) {
        const activity = this.activityNames.find((item) => item.id === allowed[i].toLowerCase());
        const name = activity ? activity.name.toLowerCase() : '';
        formatted += name ? `${name}, ` : '';
      }
      formatted = formatted.trim();
      if (formatted.length > 2) formatted = formatted.slice(0, formatted.length - 1);

      return formatted;
    },
    getAllowedDisciplines(restrictions) {
      const allowed = [];
      if (!restrictions || !restrictions.disciplines) return [];

      const disciplines = Object.keys(restrictions.disciplines);
      for (let i = 0; i < disciplines.length; i += 1) {
        const discipline = restrictions.disciplines[disciplines[i]];
        if (!discipline) {
          continue;
        }
        if (discipline.discipline === 'Allowed') {
          allowed.push(disciplines[i]);
        }
      }

      return allowed;
    },
    getFormattedValue(options) {
      if (!options || !options.restrictions) return '';

      const allowed = this.getAllowedDisciplines(options.restrictions);
      const result = {};

      const value = options.restrictions;
      if (options.type === this.reasonFormattingTypes.distance) {
        for (let i = 0; i < allowed.length; i += 1) {
          const discipline = value.disciplines[allowed[i]];
          const distance = options.min === true ? discipline.distance.min : discipline.distance.max;
          const formatted = `${this.getFormattedDistance(distance)} ${distance >= 1000 ? 'км' : 'м'}`;
          result[allowed[i].toLowerCase()] = options.val.replace('{{0}}', formatted);
        }
      }
      if (options.type === this.reasonFormattingTypes.time) {
        for (let i = 0; i < allowed.length; i += 1) {
          const discipline = value.disciplines[allowed[i]];
          const time = options.min === true ? discipline.timeInSeconds.min : discipline.timeInSeconds.max;
          let minutes = (time / 60).toFixed(1);
          if (minutes[minutes.length - 1] === '0') minutes = minutes.slice(0, minutes.length - 1);
          if (minutes.indexOf('.0') >= 0) minutes = minutes.slice(0, minutes.indexOf('.0'));
          if (minutes[minutes.length - 1] === '.') minutes = minutes.slice(0, minutes.length - 1);
          minutes = minutes.replace('.', ',');
          const formatted = `${minutes}`;
          result[allowed[i].toLowerCase()] = options.val.replace('{{0}}', formatted);
        }
      }
      if (options.type === this.reasonFormattingTypes.averagePace) {
        for (let i = 0; i < allowed.length; i += 1) {
          const discipline = value.disciplines[allowed[i]];
          const pace = options.min === true ? discipline.averagePace.min : discipline.averagePace.max;
          const minutes = Math.floor(pace / 60);
          const seconds = `${pace - (minutes * 60)}`.padStart(2, '0');
          const formatted = seconds !== '00' ? `${minutes}:${seconds}` : `${minutes}`;
          result[allowed[i].toLowerCase()] = options.val.replace('{{0}}', formatted);
        }
      }
      if (options.type === this.reasonFormattingTypes.momentumPace) {
        for (let i = 0; i < allowed.length; i += 1) {
          const discipline = value.disciplines[allowed[i]];
          const pace = options.min === true ? discipline.momentumPace.min : discipline.momentumPace.max;
          const minutes = Math.floor(pace / 60);
          const seconds = `${pace - (minutes * 60)}`.padStart(2, '0');
          const formatted = seconds !== '00' ? `${minutes}:${seconds}` : `${minutes}`;
          result[allowed[i].toLowerCase()] = options.val.replace('{{0}}', formatted);
        }
      }

      return result;
    },
    getMetrics(activity) {
      const metrics = [];
      metrics.push({
        value: this.getFormattedDistance(activity.distance),
        units: activity.distance >= 1000 ? 'км' : 'м',
        name: 'Дистанция',
      });
      metrics.push({
        value: this.getFormattedResult(activity.timeResult),
        name: 'Чистое время',
      });
      if (activity.averagePace) {
        metrics.push({
          value: this.getFormattedPace(activity.averagePace),
          units: '',
          name: 'Средний темп',
        });
      }
      if (activity.height) {
        metrics.push({
          value: activity.height,
          units: 'м',
          name: 'Перепад высот',
        });
      }
      if (activity.pulse) {
        metrics.push({
          value: activity.pulse,
          units: 'BPM',
          name: 'Средний пульс',
        });
      }
      return metrics;
    },
    getFormattedActivitiesUpdated(date) {
      const d = dayjs.utc(date).local();
      if (d.isValid()) {
        return `${d.format('D')} ${d.format('MMM').slice(0, 3)} в ${d.format('HH:mm')}`;
      }
      return '';
    },
    getFormattedTime(date) {
      const d = dayjs.utc(date).local();
      if (d.isValid()) {
        return d.format('HH:mm');
      }
      return '';
    },
    getFormattedDistance(distance) {
      let result = '';
      if (distance >= 1000) {
        result = (distance / 1000).toFixed(2);
        if (result[result.length - 1] === '0') result = result.slice(0, result.length - 1);
        if (result.indexOf('.0') >= 0) result = result.slice(0, result.indexOf('.0'));
        result = result.replace('.', ',');
      } else {
        result = distance.toString();
      }
      return result;
    },
    getFormattedResult(result) {
      const hours = dayjs.duration(result, 'seconds').format('H');
      const format = hours === '0' ? 'mm:ss' : 'H:mm:ss';
      return dayjs.duration(result, 'seconds').format(format);
    },
    getFormattedPace(pace) {
      return dayjs.duration(pace, 'seconds').format('m:ss');
    },
    getActivityDetails(items) {
      if (!items) return '';
      let distance = 0;
      let time = 0;
      for (let i = 0; i < items.length; i += 1) {
        distance += items[i].distance;
        time += items[i].timeResult;
      }
      return `${this.getFormattedDistance(distance)} ${distance >= 1000 ? 'км' : 'м'} • ${this.getFormattedResult(time)}`;
    },
    getFormattedWeekday(day) {
      if (!day) return '';
      return `${day.slice(0, 1).toUpperCase()}${day.slice(1)}`;
    },
    isAlertShown(item) {
      if (item && item.alert) return true;
      return false;
    },
    setActivities() {
      if (!this.statistic) {
        this.activities = [];
        return;
      }
      if (this.statistic.byDiscipline) {
        const activities = [];
        if (this.statistic.byDiscipline.Walking && !this.isActivityHidden('walking')) {
          const walkingDistance = this.statistic.byDiscipline.Walking.distance;
          activities.push({
            icon: 'walking',
            value: this.getFormattedDistance(walkingDistance),
            subtitle: `${walkingDistance >= 1000 ? 'км' : 'м'}, ходьба`,
          });
        }
        if (this.statistic.byDiscipline.Running && !this.isActivityHidden('running')) {
          const runningDistance = this.statistic.byDiscipline.Running.distance;
          activities.push({
            icon: 'running',
            value: this.getFormattedDistance(runningDistance),
            subtitle: `${runningDistance >= 1000 ? 'км' : 'м'}, бег`,
          });
        }
        if (this.statistic.byDiscipline.Cycling && !this.isActivityHidden('cycling')) {
          const cyclingDistance = this.statistic.byDiscipline.Cycling.distance;
          activities.push({
            icon: 'cycling',
            value: this.getFormattedDistance(cyclingDistance),
            subtitle: `${cyclingDistance >= 1000 ? 'км' : 'м'}, вело`,
          });
        }
        if (this.statistic.byDiscipline.Swimming && !this.isActivityHidden('swimming')) {
          const swimmingDistance = this.statistic.byDiscipline.Swimming.distance;
          activities.push({
            icon: 'swimming',
            value: this.getFormattedDistance(swimmingDistance),
            subtitle: `${swimmingDistance >= 1000 ? 'км' : 'м'}, плавание`,
          });
        }
        if (this.statistic.byDiscipline.Skiing && !this.isActivityHidden('skiing')) {
          const skiingDistance = this.statistic.byDiscipline.Skiing.distance;
          activities.push({
            icon: 'skiing',
            value: this.getFormattedDistance(skiingDistance),
            subtitle: `${skiingDistance >= 1000 ? 'км' : 'м'}, лыжи`,
          });
        }
        this.activities = activities;
      }
    },
    setDays() {
      const days = [];
      const last = { date: '', index: 0 };
      for (let i = 0; i < this.rawDays.length; i += 1) {
        const value = this.rawDays[i];
        const day = { date: '', items: [] };
        const date = dayjs.utc(value.startDate).local();
        if (date.isValid()) {
          day.date = `${this.getFormattedWeekday(date.format('dddd'))} • ${date.format('D MMMM')}`;
        } else {
          continue;
        }
        const item = {
          trackerCode: value.provider.toLowerCase(),
          startDate: value.startDate,
          finishDate: value.finishDate,
          distance: value.distance,
          timeResult: value.timeInSeconds,
          averagePace: value.averagePace,
          alert: this.getRejectedText(value),
        };
        if (i === 0) {
          last.date = date.format('YYYY-MM-DD');
          last.index = 0;
          day.items = [{
            code: value.discipline.toLowerCase(),
            items: [item],
          }];
          days.push(day);
          continue;
        }
        if (last.date === date.format('YYYY-MM-DD')) {
          const index = days[last.index].items.findIndex((it) => it.code === value.discipline.toLowerCase());
          if (index >= 0) {
            days[last.index].items[index].items.push(item);
          } else {
            days[last.index].items.push({
              code: value.discipline.toLowerCase(),
              items: [item],
            });
          }
          continue;
        }
        day.items = [{
          code: value.discipline.toLowerCase(),
          items: [item],
        }];
        days.push(day);
        last.date = date.format('YYYY-MM-DD');
        last.index = days.length - 1;
      }

      this.days = days;
      this.updating = false;
    },
    getRejectedText(value) {
      if (value.refereeingSystemCheckState !== 'Rejected') return '';
      const reason = value.refereeingSystemCheckStateReason;
      const discipline = value.discipline.toLowerCase();
      if (!this.rejectedReasons) return '';
      if (this.rejectedReasons[reason]) {
        if (typeof this.rejectedReasons[reason] === 'string') {
          return this.rejectedReasons[reason];
        }
        if (typeof this.rejectedReasons[reason] === 'object') {
          if (this.rejectedReasons[reason][discipline]) {
            return this.rejectedReasons[reason][discipline];
          }
          return this.rejectedReasons[reason].default || '';
        }
      }
      return '';
    },
    isActivityHidden(id) {
      return this.hiddenActivities.includes(id);
    },
    checkForLoading() {
      if (this.updating) return;
      if (!this.loaderBlock) return;
      if (this.days.length === 0) return;
      if (this.$route.name !== 'SettingsTrainings') {
        this.cleanup();
        return;
      }

      if (this.trainings.count < 20) return;

      const bottomCoord = this.loaderBlock.getBoundingClientRect().bottom;
      const height = document.documentElement.clientHeight;
      if (height >= bottomCoord) {
        this.updating = true;
        setTimeout(() => { this.updateTrainings(); }, 250);
      }
    },
    cleanup() {
      window.removeEventListener('scroll', this.checkForLoading);
      window.removeEventListener('touchmove', this.checkForLoading);
      window.removeEventListener('hashchange', this.onHashChange);
    },
    showTrackerConnect() {
      if (this.$root.showOnboarding) {
        this.$root.showOnboarding({ step: 'finish', backToPath: `${window.location.origin}/my-settings/trainings` });
      }
    },
    showSync(hash) {
      if (hash[1] === 'true') this.showChange = true;
      else {
        this.showChange = false;
        this.clearHash();
      }
    },
    showRejectedReason(hash) {
      if (hash[1] === 'true') this.showRejected = true;
      else {
        this.showRejected = false;
        this.clearHash();
      }
    },
    clearHash() {
      setTimeout(() => { window.location.hash = ''; }, 100);
    },
  },
  mounted() {
    window.addEventListener('hashchange', this.onHashChange);
    this.onHashChange();

    this.getRestrictions()
      .then(() => {
        this.rejectedReasons = this.getFormattedReasons({ ...this.restrictions });
      });

    this.setActivitiesUpdatedOn();
    this.onChangeType(2);

    if (this.isTrackerConnect) {
      setTimeout(() => { this.showTrackerConnect(); }, 500);
    }

    const vm = this;
    setTimeout(() => {
      const loaders = document.getElementsByClassName('trainings__loader-block');
      vm.loaderBlock = loaders[0] || null;
      window.addEventListener('scroll', vm.checkForLoading);
      window.addEventListener('touchmove', vm.checkForLoading);
      vm.checkForLoading();
    },
    3000);
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import "./Trainings";
</style>

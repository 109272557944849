<template>
  <div class="section-container">
    <div class="content">
      <div class="content__header">
        <div class="header__icon" @click.stop="$emit('goto')">
          <Icon :name="icon" size="xs" />
        </div>
        <div class="header__title">
          <span v-html="title" @click.stop="$emit('goto')"></span>
        </div>
        <div class="header__button">
          <Button
            variant="clear"
            size="sm"
            iconLeft="right"
            mobile
            @click="$emit('goto')"
          >
          </Button>
        </div>
      </div>
      <div class="content__slot">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionContainer',
  props: {
    title: String,
    icon: String,
  },
};
</script>

<style lang="scss" scoped>
  @import "./SectionContainer";
</style>

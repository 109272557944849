<template>
  <div class="activity-card" :class="{'activity-card--last': isLast}">
    <div v-if="!isLast">
      <div class="activity-card__title" v-html="title"></div>
      <div
        v-for="(item, i) in items"
        :key="i"
        class="activity-card__item"
        @click.stop="$emit('item-click', i)"
      >
        <div
          class="item__image"
          :class="getClasses(item)"
          :style="{ 'background-image': !item.achievementType ? `url('${item.img}')` : '' }"
        >
          <span v-if="item.isTaskRejected"></span>
          <Icon v-if="item.isTaskRejected" name="close-circle-s" size="xl" />
          <Icon v-if="item.isTaskSteps" name="steps" size="l" />
        </div>
        <div class="item__title">
          <div v-html="item.title || ''"></div>
          <div style="display: flex;">
            <div v-if="item.time" class="title__time">
              <Icon name="clock" :size="$root.isMobile ? 'xs' : 's'" /><span v-html="item.time"></span>
            </div>
            <div v-if="item.coins" class="title__coins">
              <img :src="coin" /><span v-html="item.coins"></span>
            </div>
            <div v-if="item.points" class="title__points">
              <img :src="point" /><span v-html="item.points"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLast">
      <div class="last__icon"><Icon name="history" /></div>
      <div class="last__text" v-html="title"></div>
      <div class="last__action">
        <Button
          variant="secondary"
          size="lg"
          mobile
          @click="$emit('show-all')"
        >
          Смотреть все
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Coin from '@/assets/img/coin.png';
import Point from '@/assets/img/point.svg';
import AchievementsAll from '@/assets/data/achievements';

export default {
  name: 'ActivityCard',
  props: {
    title: String,
    items: Array,
    isLast: Boolean,
  },
  data() {
    return {
      coin: Coin,
      point: Point,
      achievementsAll: AchievementsAll,
    };
  },
  methods: {
    getClasses(item) {
      const classes = [];
      if (item.isInternalImg) classes.push('item__image_internal');
      if (item.isTaskRejected) classes.push('item__image_rejected');
      if (item.isTaskSteps) classes.push('item__image_steps');
      if (item.achievementType) {
        const className = this.getAchievementClass(item.achievementType);
        if (this.$root.isMobile) classes.push(`${className}-40`);
        else classes.push(`${className}-48`);
      } else {
        classes.push('item__image_no-sprite');
      }
      return classes;
    },
    getAchievementClass(achievementType) {
      const found = this.achievementsAll.filter((i) => i.achievementType === achievementType);
      if (found.length === 0) return '';
      return found[0].class;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./ActivityCard";
</style>

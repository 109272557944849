import Vue from 'vue';
import VCalendar from 'v-calendar';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { apiConfig } from './services/api';
import '@/assets/scss/main.scss';

Vue.config.productionTip = false;

Vue.component('Icon', () => import('@rr-component-library/icon/src/main'));
Vue.component('Link', () => import('@rr-component-library/link/src/main'));
Vue.component('Button', () => import('@rr-component-library/button/src/main'));
Vue.component('Row', () => import('@rr-component-library/row/src/main'));
Vue.component('Col', () => import('@rr-component-library/col/src/main'));
Vue.component('Loader', () => import('@rr-component-library/loader/src/main'));
Vue.component('Tabs', () => import('@rr-component-library/tabs/src/main'));
Vue.component('Modal', () => import('@rr-component-library/modal/src/main'));
Vue.component('Slider', () => import('@sc-component-library/slider/src/main'));

Vue.use(VCalendar);

const lang = 'ru';
Vue.prototype.$lang = lang;

i18n.locale = lang;
i18n.pluralizationRules.ru = (val) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return (val % 100 > 4 && val % 100 < 20) ? 2 : cases[(val % 10 < 5) ? val % 10 : 5];
};
document.querySelector('html').setAttribute('lang', lang);

dayjs.locale('ru');

window.MY_SCOUNT_APP = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

apiConfig({ store });

let isTouch = false;
if ('ontouchstart' in document.documentElement) {
  isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Api from '@/services/community-api';

const state = {
  community: null,
  articles: null,
  responseStatus: null,
};

const mutations = {
  setCommunity(state, value) {
    state.community = value;
  },
  setArticles(state, value) {
    state.articles = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
};

const getters = {
  community: (state) => state.community,
  articles: (state) => state.articles,
  responseStatus: (state) => state.responseStatus,
};

const actions = {
  getCommunity({ commit }) {
    return Api.getCommunity()
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setCommunity', response.data);
        } else {
          commit('setCommunity', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getArticles({ commit }, {
    communityId, offset, count, sort,
  }) {
    return Api.getArticles({
      communityId, offset, count, sort,
    })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setArticles', response.data);
        } else {
          commit('setArticles', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

<template>
  <div class="set-avatar">
    <Modal
      :active="active"
      name="SetAvatarModal"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="$emit('close')"
    >
      <div class="set-avatar__container">
        <div class="set-avatar__content">
          <div v-if="!avatarImage" class="set-avatar__photo-empty">
            <div class="photo-empty__icon"><Icon name="camera" size="" /></div>
            <div class="photo-empty__title">Ваш аватар</div>
            <div class="photo-empty__action">
              <Button
                variant="primary"
                size="lg"
                text="Добавить своё фото"
                @click="onChangePhoto"
                :loading="saving"
                :wide="$root.isMobile ? true : false"
                mobile
              ></Button>
              <div class="photo-empty__action-hint" :class="{ 'photo-empty__action-hint_error': showError }">
                Поддерживаются фото в формате JPEG размером не более 4 Мб
              </div>
            </div>
          </div>
          <div v-show="false">
            <form id="imageForm" action="#" method="post" enctype="multipart/form-data">
              <input id="imageInput" name="imageInput" type="file" accept="image/jpeg" />
            </form>
          </div>
          <div v-if="avatarImage" class="set-avatar__photo">
            <div class="photo__image">
              <img :src="avatarImage" />
              <Icon name="check-circle-s" size="s" />
            </div>
            <div class="photo__action">
              <Button
                variant="secondary"
                size="lg"
                text="Изменить фото"
                @click="onChangePhoto"
                :loading="saving"
                :wide="$root.isMobile ? true : false"
                mobile
              ></Button>
              <div class="photo-empty__action-hint" :class="{ 'photo-empty__action-hint_error': showError }">
                Поддерживаются фото в формате JPEG размером не более 4 Мб
              </div>
            </div>
          </div>
          <div class="set-avatar__or">
            <div class="or__line"></div>
            <div class="or__text">или</div>
            <div class="or__line"></div>
          </div>
          <div class="set-avatar__preset">
            <div class="preset__title">Выбрать аватар</div>
            <div class="preset__images">
              <div
                v-for="item in presets"
                :key="item.id"
                class="images__item"
                :class="{ 'images__item_selected': item.id === selectedPreset }"
                @click.stop="onSelected(item.id)"
              >
                <img :src="item.img" />
                <Icon v-if="item.id === selectedPreset" name="check-circle-s" size="s" />
              </div>
            </div>
          </div>
          <div class="buttons-group">
            <div class="buttons-group__item">
              <Button
                variant="primary"
                size="lg"
                text="Сохранить"
                @click="onSave"
                :disabled="saveDisabled"
                :loading="saving"
                wide
                mobile
              ></Button>
            </div>
            <div class="buttons-group__item buttons-group__item_first">
              <Button
                variant="secondary"
                size="lg"
                text="Отменить"
                @click="$emit('close')"
                :loading="saving"
                :wide="$root.isMobile ? true : false"
                mobile
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Avatars from '@/assets/data/avatars';

export default {
  name: 'SetAvatarModal',
  components: {
  },
  props: {
    active: Boolean,
    isMobileApp: Boolean,
  },
  data() {
    return {
      presets: Avatars,
      selectedPreset: null,
      form: null,
      input: null,
      selectedFile: null,
      avatarImage: '',
      MAX_FILE_SIZE: 2 ** 20 * 4,
      saveDisabled: true,
      saving: false,
      showError: false,
    };
  },
  computed: {
    ...mapGetters('user', ['profile', 'avatarStatus']),
  },
  watch: {
    active(val) {
      if (val) {
        setTimeout(() => {
          const form = document.getElementById('imageForm');
          if (!form) return;
          this.form = form;
          this.setPictureHandlers();
          this.defineCurrentAvatar();
          this.showError = false;
        }, 100);
        return;
      }
      if (this.form) this.form.reset();
    },
  },
  methods: {
    ...mapActions('user', ['setAvatar', 'getProfile']),
    onChangePhoto() {
      if (this.input) this.input.click();
    },
    onPictureChange() {
      if (!this.input) return;
      this.showError = false;

      const curFiles = this.input.files;

      if (curFiles.length === 0) {
        this.input.value = '';
        this.selectedFile = null;
      } else {
        // eslint-disable-next-line prefer-destructuring
        const file = curFiles[0];
        if (this.isFileValid(file)) {
          this.selectedFile = file;
          this.selectedPreset = null;
          this.setSaveDisabled();
          this.saving = true;
          this.showError = false;
          this.setAvatar({ avatarType: 'custom', file: this.selectedFile })
            .then(() => {
              if (this.avatarStatus === 204) {
                setTimeout(() => {
                  window.location.hash = '';
                  window.location.reload();
                }, 1000);
                return;
              }
              this.saving = false;
              this.showError = true;
            });
          return;
        }
        this.showError = true;
      }
    },
    onSelected(id) {
      if (this.saving) return;
      this.selectedPreset = id;
      this.selectedFile = null;
      this.avatarImage = '';
      this.showError = false;
      this.setSaveDisabled();
    },
    onSave() {
      if (this.selectedPreset !== null) {
        this.saving = true;
        this.setAvatar({ avatarType: this.selectedPreset })
          .then(() => {
            this.saving = false;
            if (this.avatarStatus === 204) {
              window.location.hash = '';
              window.location.reload();
            }
          });
      }
    },
    setPictureHandlers() {
      const input = document.getElementById('imageInput');
      if (!input) return;
      this.input = input;
      input.onchange = this.onPictureChange;
    },
    defineCurrentAvatar() {
      const { avatar } = this.profile;
      if (!avatar) return;
      if (!avatar.type) return;
      if (avatar.type === 'custom') {
        this.avatarImage = avatar.url || '';
        this.selectedPreset = null;
        return;
      }
      const found = Avatars.filter((i) => i.id === avatar.type);
      if (found.length === 0) return;
      this.selectedPreset = found[0].id || null;
    },
    isFileValid(file) {
      if (!file) return false;
      if (file.size > this.MAX_FILE_SIZE) return false;
      const name = file.name.toLowerCase();
      if (name.length < 5) return false;
      if (name.slice(name.length - 4) !== '.jpg') {
        if (name.length < 6) return false;
        if (name.slice(name.length - 5) !== '.jpeg') return false;
      }
      return true;
    },
    setSaveDisabled() {
      if (this.selectedFile === null) this.saveDisabled = true;
      if (this.selectedPreset === null) this.saveDisabled = true;
      this.saveDisabled = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import './SetAvatarModal';
</style>

<template>
  <div class="task-details">
    <Modal
      :active="active"
      name="TaskDetailsModal"
      :closable="true"
      class="task-details__modal"
      :class="{ 'modal_hidden': isMobileApp || quizModal || taskCancelModal || supportModal || successModal }"
      @close="$emit('close')"
    >
      <div class="task-details__img" :style="{ 'background-image': `url(${images ? images.desktop || '' : ''})` }">
        <div class="task-details__back" @click="$emit('close')">
          <Button
            variant="secondary"
            :size="$root.isMobile ? 'sm' : 'lg'"
            shape="circle"
            iconLeft="left"
            mobile
            @click="$emit('close')"
          ></Button>
        </div>
        <div v-if="state === 'inProgress'" class="task-details__menu">
          <Button
            variant="secondary"
            :size="$root.isMobile ? 'sm' : 'lg'"
            shape="circle"
            iconLeft="more-vertical"
            mobile
            id="menuButton"
          ></Button>
          <Dropdown
            triggerId="menuButton"
            placement="bottom"
            listeners="click"
            hide-overlay
            auto-width
          >
            <template slot="items">
              <div class="base-dropdown__item">
                <a class="base-dropdown__link" @click.prevent="onConfirmTaskCancel">
                  <Icon name="close" size="m" />
                  <span>Отказаться от задания</span>
                </a>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <div class="task-details__main">
        <div class="main__title text-left" v-html="title"></div>
        <div class="main__description text-left" v-html="subtitle"></div>
        <div class="main__reward text-left">
          <div class="reward-card">
            <div class="reward-card__title">Награда и очки</div>
            <div class="reward-card__value" :class="{ 'value--grayscale': getCompletedTaskState() === 'failed' || getCompletedTaskState() === 'checking' }">
              <img :src="coin" width="24" height="24" /><span>{{ state !== 'completed' ? getTaskReward() : getCompletedReward() }}</span>
            </div>
            <div class="reward-card__value" :class="{ 'value--grayscale': getCompletedTaskState() === 'checking' }">
              <img :src="point" width="24" height="24" /><span>{{ getTaskRatingPoints() }}</span>
            </div>
          </div>
          <div class="reward-card">
            <div class="reward-card__title">{{ state === 'completed' ? 'Завершено' : 'Доступно' }}</div>
            <div class="reward-card__value value--small">
              <span><span v-if="state !== 'completed'">до </span>{{ getFormattedDate() }}</span>
              <span v-if="getTaskTimeLimit()">в течение {{ getTaskTimeLimit() }}ч.</span>
            </div>
          </div>
          <div class="reward-card">
            <div class="reward-card__title">Сложность и Активность</div>
            <div>
              <div class="reward-card__complexity" :class="getComplexityClass()">
                <img v-if="getTaskComplexity() === 'Новичок'" :src="complexityLow" alt="" />
                <img v-if="getTaskComplexity() === 'Любитель'" :src="complexityMedium" alt="" />
                <img v-if="getTaskComplexity() === 'Опытный'" :src="complexityHigh" alt="" />
                <img v-if="getTaskComplexity() === 'Мастер'" :src="complexityMaster" alt="" />
                <span>{{ getTaskComplexity() }}</span>
              </div>
            </div>
            <div class="reward-card__value">
              <div
                v-for="(icon, i) in disciplines"
                :key="i"
                class="value__activity"
              >
                <Icon :name="icon" size="xs" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="isTrackerNeeded === true" class="main__tracker text-left">
          <Icon name="fitness-tracker" size="m" />Для выполнения потребуется трекер. <a :href="articleAboutTrackers" target="_blank">Что это?</a>
        </div>
        <div class="main__full-description text-left">
          <div class="main__subtitle">О задании</div>
          <div class="main__description" v-html="description"></div>
        </div>
        <div class="main__todo text-left">
          <div class="main__subtitle">Что делать?</div>
          <div class="main__description">Выполнить задачи по-порядку:</div>
          <div class="main__steps" v-if="!loadingQuizResult">
            <div
              v-for="(item, i) in steps"
              :key="i"
              class="main__steps-item"
            >
              <div v-if="tasks[i].type !== 'Quiz'" class="main__steps-item-heading">
                <span v-if="!isStepCompleted(i) && !isFailed && steps.length > 1 && item.state !== 'AwaitingConfirmation'">{{i + 1}}</span>
                <span v-if="!isStepCompleted(i) && !isFailed && steps.length === 1 && item.state !== 'AwaitingConfirmation'"><Icon name="arrow-right" size="l" /></span>
                <span v-if="tasks[i].type === 'UploadImage' && item.state === 'AwaitingConfirmation'" class="steps-item--checking"><Icon name="hourglass" size="l" /></span>
                <span v-if="isStepCompleted(i) && !isFailed" class="steps-item--completed"><Icon name="check-bold" size="l" /></span>
                <span v-if="!isStepCompleted(i) && isFailed" class="steps-item--failed"><Icon name="close" size="l" /></span>
                <span v-html="getStepTitle(i)"></span>
              </div>
              <!-- Quiz tasks -->
              <div v-if="tasks[i].type === 'Quiz'"  class="main__steps-item-heading">
                <span v-if="!['completed', 'failed'].includes(getQuizResultState(item)) && steps.length > 1">{{i + 1}}</span>
                <span v-if="!['completed', 'failed'].includes(getQuizResultState(item)) && steps.length === 1"><Icon name="arrow-right" size="l" /></span>
                <span v-if="getQuizResultState(item) === 'completed'" class="steps-item--completed"><Icon name="check-bold" size="l" /></span>
                <span v-if="getQuizResultState(item) === 'failed'" class="steps-item--failed"><Icon name="close" size="l" /></span>
                <span v-html="getStepTitle(i)"></span>
              </div>
              <div v-if="state === 'inProgress' && tasks[i].type === 'Invite'" class="invite__item">
                <p v-if="tasks[i].type === 'Invite' && item.state !== 'Completed'" class="invite__text">Отправьте вашу уникальную ссылку другу. Приглашение будет засчитано, когда друг зарегистрируется.</p>
                <div v-if="tasks[i].type === 'Invite' && item.state !== 'Completed'">
                  <div class="invite__title">Ваша уникальная ссылка</div>
                  <div class="invite__link no-select">
                    <p>{{ tasks[i].settings.Link }}</p>
                    <div title="Скопировать" @click="onInviteLink(tasks[i], item)">
                      <Icon class="invite__copy" name="copy" size="m"/>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="state === 'inProgress' && tasks[i].type === 'Registration'" class="registration__item">
                <p v-if="tasks[i].type === 'Registration' && item.state !== 'Completed'" class="registration__text">Внимание! Совершите покупку слота только в <br> открывшейся вкладке.</p>
                <div v-if="tasks[i].type === 'Registration' && item.state !== 'Completed'">
                  <Button
                    class="registration__button"
                    variant="primary"
                    size="md"
                    text="Перейти"
                    iconRight="externa-link"
                    mobile
                    tag="a"
                    :href="taskRedirectLink"
                    :loading="loading"
                    :disabled="loading"
                    target="_blank"
                  ></Button>
                </div>
              </div>
              <div v-if="state === 'inProgress' && tasks[i].type === 'Quiz'" class="quize-step">
                <div
                  class="quize-step__title"
                  :class="{
                    'title_success': getQuizResultState(item) === 'completed',
                    'title_retry': getQuizResultState(item) === 'retry',
                    'title_failed': getQuizResultState(item) === 'failed',
                  }"
                >
                  {{ getQuizResult(item) ? getQuizResult(item).text : 'Вопросы откроются здесь' }}
                </div>
                <div
                  v-if="getQuizResult(item) ? getQuizResult(item).state === 'retry' : true"
                  class="quize-step__action"
                >
                  <Button
                    variant="primary"
                    size="md"
                    text="Приступить"
                    iconLeft="play"
                    mobile
                    :loading="loading"
                    :disabled="loading"
                    @click="onStartQuiz(tasks[i], item)"
                  ></Button>
                </div>
              </div>
              <div v-if="getCompletedTaskState() === 'completed' && tasks[i].type === 'Quiz'" class="quize-step">
                <div class="quize-step__title title_success">{{ getQuizResult(item) ? getQuizResult(item).text : '' }}</div>
              </div>
              <div v-if="getCompletedTaskState() === 'failed' && tasks[i].type === 'Quiz'" class="quize-step">
                <div class="quize-step__title title_failed">{{ getQuizResult(item) ? getQuizResult(item).text : '' }}</div>
              </div>
              <div v-if="getCompletedTaskState() === 'checking' && tasks[i].type === 'Quiz'" class="quize-step">
                <div class="quize-step__title">Подсчитываем ваш результат</div>
              </div>
              <!-- Partner tasks -->
              <div v-if="state === 'inProgress' && isPartnerTask(tasks[i])" class="partner-step">
                <div v-if="getPartnerText(tasks[i], i)" v-html="getPartnerText(tasks[i], i)" class="partner-step__title"></div>
                <div v-if="tasks[i].type === 'PartnerLink' && item.state !== 'Completed'" class="partner-step__action">
                  <Button
                    variant="primary"
                    size="sm"
                    text="Перейти"
                    iconRight="externa-link"
                    tag="a"
                    target="_blank"
                    :href="getPartnerLink(tasks[i])"
                    mobile
                    :loading="loading"
                    :disabled="loading"
                    @click="onPartnerLink(tasks[i], item)"
                  ></Button>
                </div>
                <div v-if="tasks[i].type === 'UploadImage' && item.state !== 'AwaitingConfirmation' && item.state !== 'Completed'" class="partner-step__action partner-step__action_upload">
                  <div class="upload__container">
                    <Input
                      v-model="selectedFileName"
                      iconLeft="upload"
                      readonly
                      :state="showUploadError ? 'error' : null"
                      @click="onSelectFile(tasks[i], item)"
                    ></Input>
                    <div v-if="showUploadError && !selectedFile" class="upload__error">
                      Выберите файл формата jpg, png, heif размером не более 4 Мб
                    </div>
                    <div v-if="showUploadError && selectedFile" class="upload__error">
                      Допустимы jpg, png, heif размером не более 4 Мб
                    </div>
                  </div>
                  <Button
                    variant="primary"
                    size="md"
                    text="Отправить"
                    class="upload__button"
                    mobile
                    :loading="loading || uploadingFile"
                    :disabled="(input === null) || loading || showUploadError || uploadingFile"
                    @click="onFileUpload(tasks[i], item)"
                  ></Button>
                </div>
              </div>
              <div v-if="tasks[i].type === 'UploadImage' && item.state === 'AwaitingConfirmation'" class="partner-step">
                <div class="partner-step__title">Скриншот отправлен</div>
              </div>
              <div v-if="tasks[i].type === 'UploadImage' && item.state === 'Completed'" class="partner-step">
                <div class="partner-step__title title_success">Скриншот подтвержден</div>
              </div>
              <!-- GeoLocation tasks -->
              <div v-if="state === 'inProgress' && tasks[i].type === 'GeoLocation'" class="geolocation-step">
                <div v-if="item.state !== 'Completed'" class="geolocation-step__title">Поделитесь своей геопозицией, находясь на месте.</div>
                <div v-if="item.state !== 'Completed'" class="geolocation-step__details">
                  <Link
                    variant="primary"
                    size="sm"
                    :iconLeft="isMapExpanded(tasks[i]) ? 'minus' : 'plus'"
                    :text="isMapExpanded(tasks[i]) ? 'Скрыть подходящие места' : 'Показать подходящие места'"
                    @click="toggleMapExpanded(tasks[i])"
                  />
                  <div v-show="isMapExpanded(tasks[i])" class="geolocation-step__map" :class="{ 'geolocation-step__map_fullscreen': isFullscreen }">
                    <iframe src="/js/geolocation/map.html" @load="onFrameLoaded(tasks[i])"></iframe>
            </div>
                  <div class="geolocation-step__action">
                    <Button
                      variant="primary"
                      size="md"
                      text="Я на месте"
                      iconLeft="environment"
                      mobile
                      :loading="loading || geoLocationLoading"
                      :disabled="loading || geoLocationLoading"
                      @click="onSendLocation(tasks[i], item)"
                    ></Button>
                    <span
                      v-if="getGeoLocationResult(tasks[i])"
                      class="geolocation-step__action-result"
                    >
                      <span v-html="getGeoLocationResult(tasks[i])"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main__steps" v-if="loadingQuizResult">
            <Loader size="24" />
          </div>
          <div v-if="false" class="main__steps-info">Подробнее в <a href="#">правилах участия</a>.</div>
        </div>
      </div>
      <div class="task-details__placeholder" v-if="isAuthenticated"></div>
      <div class="task-details__action-container" v-if="isAuthenticated">
        <div
          class="task-details__action"
          :class="{
            'action--in-progress': state === 'inProgress',
            'action--completed': getCompletedTaskState() === 'completed',
            'action--failed': getCompletedTaskState() === 'failed',
            'action--checking': getCompletedTaskState() === 'checking',
          }"
        >
          <Button
            v-if="state === 'available'"
            variant="primary"
            size="lg"
            :text="!loading ? 'Начать' : ''"
            :wide="true"
            mobile
            :loading="loading"
            :disabled="loading"
            @click="$emit('start')"
          ></Button>
          <div v-if="state === 'inProgress'">
            <div class="action__badges">
              <Badge
                v-if="badgeText"
                :state="state"
                :text="badgeText"
              />
              <Badge
                state="countdown"
                :text="countdownText"
              />
            </div>
            <div class="action__progress">Выполните все задачи вовремя</div>
          </div>
          <div v-if="getCompletedTaskState() === 'completed'">
            <div class="action__badges">
              <Badge
                state="completed"
                text="Выполнено"
              />
            </div>
            <div class="action__progress">&nbsp;</div>
          </div>
          <div v-if="getCompletedTaskState() === 'failed'">
            <div class="action__badges">
              <Badge
                state="failed"
                text="Вы не успели :("
              />
            </div>
            <div class="action__progress">Не все задачи были выполнены. <a href="#" @click.prevent="onSupport">Ошибка?</a></div>
          </div>
          <div v-if="getCompletedTaskState() === 'checking'">
            <div class="action__badges">
              <Badge
                state="checking"
                text="Проверяется"
              />
            </div>
            <div class="action__progress">Ожидайте, обычно от 1 до 24 часов</div>
          </div>
        </div>
        <div class="task-details__action-message" v-if="messageCopy">
          <span>Ссылка скопирована</span>
          <Icon name="check-bold" size="m"></Icon>
        </div>
      </div>
    </Modal>

    <div v-show="false">
      <form id="imageForm" action="#" method="post" enctype="multipart/form-data">
        <input id="imageInput" name="imageInput" type="file" accept="image/jpeg,image/png" />
      </form>
    </div>

    <Modal
      name="task-cancel-confirm"
      state="warning"
      title="Вы уверены, что хотите отказаться от задания?"
      subtitle="Весь ваш прогресс по заданию будет потерян."
      :active="taskCancelModal"
      :noTransition="true"
      class="task-cancel-confirm"
      @close.prevent="taskCancelModal = false"
    >
      <Alert
        v-if="cancelError"
        :text="cancelError"
        state="error"
        no-action
      ></Alert>
      <div class="buttons-group">
        <div class="buttons-group__item">
          <Button
            variant="secondary"
            view="danger"
            size="lg"
            text="Да, отказаться"
            @click="onCancelTask"
            :loading="cancellingTask"
            :disabled="cancellingTask"
            wide
            mobile
          ></Button>
        </div>
        <div class="buttons-group__item buttons-group__item_first">
          <Button
            variant="secondary"
            size="lg"
            text="Нет, назад"
            @click="taskCancelModal = false"
            :loading="cancellingTask"
            :disabled="cancellingTask"
            wide
            mobile
          ></Button>
        </div>
      </div>
    </Modal>

    <QuizModal
      :active="quizModal"
      :options="quizOptions"
      @close="onQuizClose"
      @back="onQuizBack"
      @quizEnd="onQuizEnd"
    />

    <SupportDialog
      :active="supportModal"
      :isMobileApp="isMobileApp"
      @close="onSupportModalClose"
      @form-submitted="handleFormSubmit"
    />

    <Modal
      name="successModal"
      :active="successModal"
      :closable="true"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="onSuccessModalClose"
    >
      <div class="modal__success">
        <Icon name="check-circle"></Icon>
        <p class="modal__success-title">Вопрос отправлен</p>
        <p class="modal__success-text">Обычно отвечаем в течение трёх рабочих дней. Ответ придёт на почту <span>{{ email }}</span> </p>
        <div class="moadal__success-action">
          <Button
            variant="secondary"
            size="lg"
            tag="a"
            mobile
            @click="onSuccessModalClose"
          >
            Закрыть
          </Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import { Base64 } from 'js-base64';
import Input from '@rr-component-library/input/src/main';
import Alert from '@rr-component-library/alert/src/main';
import Badge from './Badge.vue';
import QuizModal from '@/components/QuizModal/QuizModal.vue';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import Coin from '@/assets/img/coin.svg';
import Point from '@/assets/img/point.svg';
import SupportDialog from '@/components/SupportDialog/SupportDialog.vue';
import Low from '@/assets/img/low.svg';
import Medium from '@/assets/img/medium.svg';
import High from '@/assets/img/high.svg';
import Master from '@/assets/img/master.svg';

const { v4: uuidv4 } = require('uuid');

export default {
  name: 'TaskDetailsModal',
  props: {
    active: Boolean,
    state: String,
    modalOptions: Object,
    rating: Object,
  },
  components: {
    Input,
    Alert,
    Badge,
    QuizModal,
    Dropdown,
    SupportDialog,
  },
  data() {
    return {
      loading: false,
      coin: Coin,
      point: Point,
      complexityLow: Low,
      complexityMedium: Medium,
      complexityHigh: High,
      complexityMaster: Master,
      dateValue: null,
      countdownText: '',
      isLongCountDown: false,
      taskId: null,
      images: null,
      title: null,
      subtitle: null,
      description: null,
      isTrackerNeeded: null,
      steps: null,
      badgeText: null,
      date: null,
      disciplines: [],
      disciplineCodes: [
        'walking',
        'running',
        'cycling',
        'swimming',
        'skiing',
        'quiz',
        'geolocation',
        'invite',
        'participation',
        'uploadimage',
        'partnerlink',
        'registration',
      ],
      quizModal: false,
      quizOptions: null,
      quizResult: {},
      quizesCount: 0,
      loadingQuizResult: true,
      input: null,
      selectedFile: null,
      selectedFileName: 'Скриншот',
      MAX_FILE_SIZE: 2 ** 20 * 4,
      uploadingFile: false,
      showUploadError: false,
      geoLocationTasks: [],
      map: null,
      isFullscreen: false,
      geoLocationLoading: false,
      taskCancelModal: false,
      cancellingTask: false,
      cancelError: '',
      activityId: null,
      exerciseTaskId: null,
      sessionId: null,
      raceId: null,
      taskRedirectLink: null,
      inviteLink: '',
      supportModal: false,
      successModal: false,
      email: '',
      messageCopy: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['options', 'resources']),
    ...mapGetters('user', ['isAuthenticated', 'profile', 'isMobileApp']),
    ...mapGetters('trackers', ['responseStatus', 'trackers']),
    ...mapGetters('quizes', ['activity', 'activityStatus']),
    ...mapGetters('partners', ['partnerStatus']),
    ...mapGetters('exercises', ['cancelStatus']),
    ...mapGetters('geolocation', ['geoLocationStatus']),
    ...mapGetters('externalevents', ['exerciseTasksStaus', 'exerciseTasksUrl']),
    isFailed() {
      if (this.$props.state === 'completed') {
        return this.$props.modalOptions.participantExercise ? this.$props.modalOptions.participantExercise.state === 'Rejected' : false;
      }
      return false;
    },
    tasks() {
      return this.$props.modalOptions.tasks;
    },
    showGuideSimpleRun() {
      return this.options ? this.options.showGuideSimpleRun === true : false;
    },
    articleAboutTrackers() {
      return this.resources ? this.resources.articleAboutTrackers || '#' : '#';
    },
    teamId() {
      return this.profile ? this.profile?.team?.teamId : null;
    },
  },
  watch: {
    active(val) {
      if (val) {
        if (this.showGuideSimpleRun) {
          const fromGuide = localStorage.getItem('returnFromGuide');
          if (fromGuide === 'true') {
            this.loading = true;
            this.checkTracker();
          }
        }
        this.onHashChange();
        this.taskId = this.$props.modalOptions.exerciseId;
        this.images = this.$props.modalOptions.images;
        this.title = this.$props.modalOptions.title;
        this.subtitle = this.$props.modalOptions.shortDescription;
        this.description = this.$props.modalOptions.description;
        this.isTrackerNeeded = this.$props.modalOptions.isTrackerNeeded === true;
        this.steps = (this.$props.state === 'available' ? this.$props.modalOptions.tasks : this.$props.modalOptions.participantExercise.tasks);
        this.badgeText = this.getBadgeText();
        this.disciplines = this.getDisciplines();
        this.date = this.$props.modalOptions.endDate;
        this.setCountdown();
        this.loadQuizesState();
        this.setPictureHandler();
        this.setGeoLocationTasks();
        this.setTaskRedirectURL();
      } else {
        this.setBodyNoScroll(false);
        this.quizResult = {};
        this.quizesCount = 0;
        this.quizOptions = null;
        this.loadingQuizResult = true;
        this.geoLocationTasks = [];
      }
    },
  },
  methods: {
    ...mapActions('trackers', ['getTrackers']),
    ...mapActions('quizes', ['getActivity']),
    ...mapActions('partners', ['createLinkActivity', 'createUploadActivity', 'createRegistrationActivity', 'getActivityRegistration']),
    ...mapActions('exercises', ['cancelTask', 'sendSupport']),
    ...mapActions('geolocation', ['createGeoActivity']),
    ...mapActions('externalevents', ['getExerciseTasks']),
    onHashChange() {
      const hash = window.location.hash.split('=');
      if (hash.length === 4 && hash[2].indexOf('&showSupport') >= 0) this.showSupport(hash);
      else if (hash.length === 4 && hash[2].indexOf('&showSuccess') >= 0) this.showSuccess(hash);
      else {
        this.supportModal = false;
        this.successModal = false;
      }
    },
    onSupport() {
      const support = '&showSupport=true';
      window.location.hash = `${window.location.hash}${support}`;
    },
    onSupportModalClose() {
      const hash = sessionStorage.getItem('taskSupportReturnHash') || '';
      window.location.hash = `${hash}&showSupport=false`;
      sessionStorage.removeItem('taskSupportReturnHash');
    },
    onSuccess() {
      const hash = sessionStorage.getItem('taskSupportReturnHash') || '';
      window.location.hash = `${hash}&showSuccess=true`;
    },
    onSuccessModalClose() {
      const hash = sessionStorage.getItem('taskSupportReturnHash') || '';
      window.location.hash = `${hash}&showSuccess=false`;
      sessionStorage.removeItem('taskSupportReturnHash');
    },
    handleFormSubmit(formData) {
      this.sendSupport(formData)
        .then(() => {
          this.email = formData.email;
          this.supportModal = false;
          setTimeout(() => { this.onSuccess(); }, 250);
        });
    },
    onStartQuiz(task, item) {
      const options = {};
      options.quizId = task.settings ? task.settings.QuizId : '';
      options.participantExerciseTaskId = item.participantExerciseTaskId || '';
      options.activityId = uuidv4();
      if (options.quizId) {
        this.quizOptions = options;
        this.quizModal = true;
      }
    },
    onInviteLink(task, item) {
      let inviteLink = '';
      const base64Ref = Base64.encode(item.participantExerciseTaskId);
      if (this.teamId !== undefined && this.teamId !== null) {
        const teamId = Base64.encode(this.teamId);
        const link = task.settings.Link;
        inviteLink = `${link}/?t=${teamId}&ref=${base64Ref}`;
      } else {
        const link = task.settings.Link;
        inviteLink = `${link}/?ref=${base64Ref}`;
      }
      const tempInput = document.createElement('input');
      tempInput.value = inviteLink;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      this.messageCopy = true;
      setTimeout(() => { this.messageCopy = false; }, 3000);
    },
    setTaskRedirectURL() {
      const { participantExerciseId } = this.$props.modalOptions.participantExercise;
      const taskWithRegistrationType = this.$props.modalOptions.tasks.find((task) => task.type === 'Registration');
      if (!taskWithRegistrationType) return;
      const { type } = taskWithRegistrationType;
      if (participantExerciseId && type === 'Registration') {
        this.getExerciseTasks(participantExerciseId)
          .then(() => {
            if (this.exerciseTasksStaus === 200) {
              this.taskRedirectLink = this.exerciseTasksUrl;
            }
          });
      }
    },
    onQuizClose() {
      this.quizModal = false;
      this.setBodyNoScroll(true);
    },
    onQuizBack() {
      this.quizModal = false;
      this.setBodyNoScroll(true);
    },
    onQuizEnd() {
      this.quizModal = false;
      this.loadingQuizResult = true;
      this.setBodyNoScroll(true);
      this.loadQuizesState();
    },
    onPartnerLink(task, item) {
      if (task && item) {
        const payload = {
          activityId: uuidv4(),
          exerciseTaskId: item.participantExerciseTaskId || '',
        };
        this.createLinkActivity(payload)
          .then(() => {
            if (this.partnerStatus === 200) {
              // success
              setTimeout(() => { window.location.reload(); }, 500);
            }
          });
      }
    },
    onSelectFile(task, item) {
      if (task && item) {
        this.showUploadError = false;
        if (this.input) this.input.click();
      }
    },
    onPictureChange() {
      if (!this.input) return;
      this.showUploadError = false;

      const curFiles = this.input.files;
      if (curFiles.length === 0) {
        this.input.value = '';
        this.selectedFile = null;
        this.selectedFileName = 'Скриншот';
      } else {
        // eslint-disable-next-line prefer-destructuring
        const file = curFiles[0];
        if (this.isFileValid(file)) {
          this.selectedFile = file;
          this.selectedFileName = file.name.toLowerCase();
          return;
        }
        this.showUploadError = true;
      }
    },
    onFileUpload(task, item) {
      if (!task || !item) return;

      this.onPictureChange();
      if (!this.selectedFile) {
        this.showUploadError = true;
        return;
      }

      this.uploadingFile = true;
      this.showUploadError = false;

      const payload = {
        activityId: uuidv4(),
        exerciseTaskId: item.participantExerciseTaskId || '',
        file: this.selectedFile,
      };
      this.createUploadActivity(payload)
        .then(() => {
          if (this.partnerStatus === 200) {
            // success
            this.uploadingFile = false;
            setTimeout(() => { window.location.reload(); }, 500);
            return;
          }
          this.uploadingFile = false;
          this.showUploadError = true;
        });
    },
    onFrameLoaded(item) {
      const markers = this.getMapMarkers(item);
      this.map = new window.frames[0].GeoLocationMap('map', {
        markers,
        createdCallback: () => {
          if (this.map) this.map.setPoints();
        },
        fullscreenCallback: (isFullscreen) => {
          this.isFullscreen = isFullscreen;
          document.body.style.overflowY = isFullscreen ? 'hidden' : 'unset';
        },
      });
      setTimeout(() => { if (this.map) this.map.addYandexMap(); }, 500);
    },
    onSendLocation(task, item) {
      if (task && item) {
        this.geoLocationLoading = true;
        navigator.geolocation.getCurrentPosition((position) => {
          const payload = {
            activityId: uuidv4(),
            participantExerciseTaskId: item.participantExerciseTaskId || '',
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          this.createGeoActivity(payload)
            .then(() => {
              if (this.geoLocationStatus === 204) {
                // success
                setTimeout(() => { window.location.reload(); }, 500);
                return;
              }
              if (this.geoLocationStatus === 400) {
                this.setGeoLocationResult(task, 'Место в котором вы находитесь не подходит.');
                this.geoLocationLoading = false;
                return;
              }
              this.setGeoLocationResult(task, `Возникла ошибка: ${this.geoLocationStatus}`);
              this.geoLocationLoading = false;
            });
        }, () => {
          this.setGeoLocationResult(task, 'Возникла ошибка. Проверьте, чтобы у страницы был доступ к геоданным (координатам).');
          this.geoLocationLoading = false;
        }, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        });
      }
    },
    onConfirmTaskCancel() {
      this.cancelError = '';
      this.cancellingTask = false;
      this.taskCancelModal = true;
    },
    onCancelTask() {
      this.cancelError = '';
      this.cancellingTask = true;
      this.cancelTask({ participantExerciseId: this.$props.modalOptions.participantExercise.participantExerciseId })
        .then(() => {
          if (this.cancelStatus === 204) {
            window.location.hash = '';
            window.location.reload();
            return;
          }
          this.cancelError = `Произошла ошибка: ${this.cancelStatus}`;
          this.cancellingTask = false;
        });
    },
    setBodyNoScroll(hidden) {
      setTimeout(() => {
        document.body.style.overflowY = hidden ? 'hidden' : '';
        if (hidden) {
          document.body.classList.add('has-modal');
        } else {
          document.body.classList.remove('has-modal');
        }
      }, 100);
    },
    loadQuizesState() {
      if (this.state === 'available') {
        this.loadingQuizResult = false;
        return;
      }

      this.quizResult = {};
      this.quizesCount = 0;

      for (let i = 0; i < this.tasks.length; i += 1) {
        if (this.tasks[i].type === 'Quiz') {
          const participantExerciseTaskId = this.steps ? this.steps[i].participantExerciseTaskId : '';
          if (!participantExerciseTaskId) continue;
          this.quizesCount += 1;
        }
      }
      for (let i = 0; i < this.tasks.length; i += 1) {
        if (this.tasks[i].type !== 'Quiz') continue;
        const participantExerciseTaskId = this.steps ? this.steps[i].participantExerciseTaskId : '';
        if (!participantExerciseTaskId) continue;
        this.getQuizCallbackResult(participantExerciseTaskId);
      }

      if (this.quizesCount === 0) this.loadingQuizResult = false;
    },
    getQuizCallbackResult(participantExerciseTaskId, count = 0) {
      if (!this.active) return;
      if (count >= 20) {
        this.loadingQuizResult = false;
        return;
      }
      this.getActivity(participantExerciseTaskId)
        .then(() => {
          if (this.activityStatus === 200) {
            this.quizResult[participantExerciseTaskId] = { ...this.activity };
            if (Object.keys(this.quizResult).length === this.quizesCount) {
              if (this.steps.length === 1) {
                const { exerciseId } = this.$props.modalOptions;
                const { participantExerciseId } = this.$props.modalOptions.participantExercise;
                setTimeout(() => {
                  const route = this.$route.name;
                  if (['Tasks', 'TasksAvailable', 'TasksInProgress'].includes(route)) {
                    window.location.href = `/tasks/completed#!showTask=${exerciseId}&peId=${participantExerciseId}`;
                  }
                  this.loadingQuizResult = false;
                }, 100);
                return;
              }
              this.loadingQuizResult = false;
            }
          } else if (this.activityStatus === 404) {
            // callback от quizgo еще не пришел
            if (this.quizOptions && participantExerciseTaskId === this.quizOptions.participantExerciseTaskId) {
              setTimeout(() => { this.getQuizCallbackResult(participantExerciseTaskId, count + 1); }, 1000);
              return;
            }
            this.quizResult[participantExerciseTaskId] = null;
            if (Object.keys(this.quizResult).length === this.quizesCount) {
              this.loadingQuizResult = false;
            }
          }
        });
    },
    getQuizResult(step) {
      if (!step) return null;
      if (this.quizResult[step.participantExerciseTaskId]) {
        const activity = this.quizResult[step.participantExerciseTaskId];
        const result = {
          text: '0 правильных ответов. Мы даем вам еще попытку!',
          state: 'retry',
        };
        if (this.isFailed) {
          result.text = `${activity.score} правильных ответов из ${activity.answers}`;
          result.state = 'failed';
          return result;
        }
        if (activity.score > 0) {
          result.text = `${activity.score} правильных ответов из ${activity.answers}`;
          result.state = 'completed';
        }
        return result;
      }
      return null;
    },
    getQuizResultState(step) {
      return this.getQuizResult(step) ? this.getQuizResult(step).state : null;
    },
    isPartnerTask(task) {
      const types = ['PartnerLink', 'UploadImage'];
      return types.includes(task.type);
    },
    getPartnerText(task, index) {
      if (!task) return '';
      if (task.type === 'PartnerLink' && !this.isStepCompleted(index)) {
        const promocodeLink = task.settings.PromocodeLink || '';
        return promocodeLink ? `Используйте <a href="${promocodeLink}">предложение из маркета</a>` : '';
      }
      if (task.type === 'UploadImage') {
        const state = this.steps[index] ? this.steps[index].state : '';
        if (state && state !== 'AwaitingConfirmation' && state !== 'Completed') {
          return 'На скриншоте должен быть четко виден результат успешного подтверждения';
        }
      }
      return '';
    },
    getPartnerLink(task) {
      const defaultLink = '#';
      if (!task) return defaultLink;
      return task.settings ? task.settings.PartnerLink || defaultLink : defaultLink;
    },
    setPictureHandler() {
      const input = document.getElementById('imageInput');
      if (!input) return;
      this.input = input;
      input.onchange = this.onPictureChange;
    },
    isFileValid(file) {
      if (!file) return false;
      if (file.size > this.MAX_FILE_SIZE) return false;
      const name = file.name.toLowerCase();
      if (name.length < 5) return false;
      if (name.slice(name.length - 4) !== '.jpg' && name.slice(name.length - 4) !== '.png') {
        if (name.length < 6) return false;
        if ((name.slice(name.length - 5) !== '.jpeg')
          && (name.slice(name.length - 5) !== '.heif')) return false;
      }
      return true;
    },
    setGeoLocationTasks() {
      if (this.state === 'available') return;

      for (let i = 0; i < this.tasks.length; i += 1) {
        if (this.tasks[i].type === 'GeoLocation') {
          this.geoLocationTasks.push({
            ...this.tasks[i],
            mapExpanded: false,
            geoLocationResult: '',
          });
        }
      }
    },
    isMapExpanded(item) {
      const task = this.geoLocationTasks.find((i) => i.exerciseTaskId === item.exerciseTaskId);
      return task ? task.mapExpanded === true : false;
    },
    toggleMapExpanded(item) {
      const task = this.geoLocationTasks.find((i) => i.exerciseTaskId === item.exerciseTaskId);
      if (task) {
        task.mapExpanded = !task.mapExpanded;
      }
    },
    getMapMarkers(item) {
      const task = this.geoLocationTasks.find((i) => i.exerciseTaskId === item.exerciseTaskId);
      if (task) {
        try {
          const locations = JSON.parse(task.settings.Locations);
          return locations.map((i) => (
            {
              id: uuidv4(),
              latitude: parseFloat(i.Latitude),
              longitude: parseFloat(i.Longitude),
              name: i.Name || '',
            }
          ));
        } catch {
          return [];
        }
      }
      return [];
    },
    getGeoLocationResult(item) {
      const task = this.geoLocationTasks.find((i) => i.exerciseTaskId === item.exerciseTaskId);
      return task ? task.geoLocationResult || '' : '';
    },
    setGeoLocationResult(item, text) {
      const task = this.geoLocationTasks.find((i) => i.exerciseTaskId === item.exerciseTaskId);
      if (task) {
        task.geoLocationResult = text;
      }
    },
    getTaskReward() {
      if (!this.$props.modalOptions) return 0;
      let reward = 0;
      let maxReward = 0;
      if (!this.$props.modalOptions.tasks) return reward;
      for (let i = 0; i < this.$props.modalOptions?.tasks.length; i += 1) {
        reward += this.$props.modalOptions.tasks[i].reward;
        maxReward += this.$props.modalOptions.tasks[i].maxReward;
      }
      reward = Math.round(reward);
      return reward < maxReward ? `${reward}-${maxReward}` : reward;
    },
    getCompletedReward() {
      if (!this.$props.modalOptions) return 0;
      if (!this.$props.modalOptions.participantExercise) return 0;
      if (this.$props.modalOptions.participantExercise.state === 'Completed') {
        return this.$props.modalOptions.participantExercise.reward || 0;
      }
      return this.getTaskReward();
    },
    getTaskRatingPoints() {
      if (!this.$props.modalOptions) return 0;
      const minRatingPoints = Math.round(this.$props.rating.minRatingPoints || 0);
      const maxRatingPoints = Math.round(this.$props.rating.maxRatingPoints || 0);
      return minRatingPoints === maxRatingPoints ? `${minRatingPoints}` : `${minRatingPoints}+`;
    },
    getFormattedDate() {
      if (!this.$props.modalOptions) return '';
      let date = this.$props.modalOptions.endDate;
      if (this.$props.state === 'completed') {
        date = this.getCompletedTaskDate();
      }
      const value = dayjs.utc(date);
      if (value.isValid()) {
        return value.format('DD.MM.YYYY');
      }
      return '';
    },
    getCompletedTaskDate() {
      const task = this.$props.modalOptions;
      if (!task.participantExercise) return '';
      return `${task.participantExercise.completedOn}`;
    },
    getCompletedTaskState() {
      const task = this.$props.modalOptions;
      if (!task.participantExercise) return '';
      if (task.participantExercise.state === 'Completed') return 'completed';
      if (task.participantExercise.state === 'Rejected') return 'failed';
      if (task.participantExercise.state === 'AwaitingConfirmation') return 'checking';
      return '';
    },
    getTaskComplexity() {
      const task = this.$props.modalOptions;
      if (!task) return '';
      if (task.complexity <= 1.3) {
        return 'Новичок';
      } if (task.complexity <= 1.8) {
        return 'Любитель';
      } if (task.complexity <= 2.4) {
        return 'Опытный';
      }
      return 'Мастер';
    },
    getTaskTimeLimit() {
      const task = this.$props.modalOptions;
      if (!task) return '';
      if (task.timeLimit) {
        return task.timeLimit;
      }
      return null;
    },
    getComplexityClass() {
      const task = this.$props.modalOptions;
      if (!task) return '';
      if (task.complexity <= 1.3) {
        return 'reward-card__complexity_low';
      } if (task.complexity <= 1.8) {
        return 'reward-card__complexity_medium';
      } if (task.complexity <= 2.4) {
        return 'reward-card__complexity_high';
      }
      return 'reward-card__complexity_master';
    },
    isStepCompleted(index) {
      if (this.$props.state === 'available') {
        return false;
      }
      const { exerciseTaskId } = this.$props.modalOptions.tasks[index];
      const found = this.steps.filter((i) => i.exerciseTaskId === exerciseTaskId);
      return found.length > 0 ? found[0].state === 'Completed' : false;
    },
    getStepTitle(index) {
      if (this.$props.state === 'available') {
        return this.steps[index].title;
      }
      return this.$props.modalOptions.tasks[index].title;
    },
    getBadgeText() {
      if (this.$props.state === 'inProgress') {
        const completed = this.steps.filter((i) => i.state === 'Completed');
        if (this.steps.length > 0) {
          return `${completed.length}/${this.steps.length}`;
        }
        return '';
      }
      return '';
    },
    getDisciplines() {
      const disciplines = [];
      const steps = this.$props.modalOptions.tasks;
      if (!steps) return [];
      for (let i = 0; i < steps.length; i += 1) {
        if (!steps[i] || !steps[i].settings) {
          continue;
        }
        let discipline = steps[i].settings.Discipline || null;
        if (!discipline) continue;
        discipline = discipline.toLowerCase();
        if (this.disciplineCodes.includes(discipline)) {
          if (!disciplines.includes(discipline)) disciplines.push(discipline);
        }

        if (discipline === 'partnerlink') {
          if (!disciplines.includes('wellbeing')) disciplines.push('wellbeing');
        }
        if (discipline === 'uploadimage') {
          if (!disciplines.includes('wellbeing')) disciplines.push('wellbeing');
        }
        if (discipline === 'participation') {
          if (!disciplines.includes('running')) disciplines.push('running');
          if (!disciplines.includes('wellbeing')) disciplines.push('wellbeing');
        }
        if (discipline === 'registration') {
          if (!disciplines.includes('wellbeing')) disciplines.push('wellbeing');
        }
        if (discipline === 'invite') {
          if (!disciplines.includes('wellbeing')) disciplines.push('wellbeing');
        }
      }
      return disciplines;
    },
    setCountdown() {
      const value = dayjs.utc(this.date);
      if (value.isValid()) {
        this.dateValue = value;
        this.updateCountdown();
      }
    },
    updateCountdown() {
      const seconds = this.dateValue.diff(dayjs.utc(), 'seconds');
      if (seconds <= 0) {
        this.countdownText = '00:00:00';
        return;
      }
      this.countdownText = this.formatCountdown(seconds);
      setTimeout(() => { this.updateCountdown(); }, this.isLongCountDown ? 60000 : 1000);
    },
    formatCountdown(seconds) {
      const duration = dayjs.duration(seconds * 1000);
      const days = duration.asDays();
      if (days >= 1) {
        let d = 0;
        let h = 0;
        if (Number.parseInt(days, 10) !== days) {
          d = Number.parseInt(days, 10);
          h = Math.round(duration.add(-d, 'day').asHours());
        } else {
          d = days;
        }
        this.isLongCountDown = true;
        return `${d}д ${h}ч`;
      }
      this.isLongCountDown = false;
      return duration.format('HH:mm:ss');
    },
    checkTracker() {
      if (!this.active) return;
      this.getTrackers()
        .then(() => {
          if (this.responseStatus === 200 && this.trackers) {
            const connected = this.trackers.filter((i) => i.state === 'Connected');
            if (connected.length > 0) {
              localStorage.removeItem('returnFromGuide');
              this.loading = false;
            } else {
              setTimeout(() => { this.checkTracker(); }, 2000);
            }
          }
        });
    },
    showSupport(hash) {
      if (hash[3] === 'true') {
        this.supportModal = true;
        const index = window.location.hash.indexOf('&showSupport=true');
        sessionStorage.setItem('taskSupportReturnHash', window.location.hash.slice(0, index));
      } else {
        this.supportModal = false;
      }
    },
    showSuccess(hash) {
      if (hash[3] === 'true') this.successModal = true;
      else {
        this.successModal = false;
      }
    },
  },
  mounted() {
    window.addEventListener('hashchange', this.onHashChange);
    this.onHashChange();
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import './TaskDetailsModal';
</style>

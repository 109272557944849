const DIR = '/img/trackers';

export default [
  {
    id: 'Suunto',
    name: 'Suunto',
    img: `${DIR}/suunto-app.png`,
  },
  {
    id: 'Fitbit',
    name: 'Fitbit',
    img: `${DIR}/fitbit-app.png`,
  },
  {
    id: 'Garmin',
    name: 'Garmin',
    img: `${DIR}/garmin-app.png`,
  },
  {
    id: 'GoogleFit',
    name: 'Google Fit',
    img: `${DIR}/google-fit-app.png`,
  },
  {
    id: 'Nike',
    name: 'Nike+',
    img: `${DIR}/nike-app.png`,
  },
  {
    id: 'Polar',
    name: 'Polar',
    img: `${DIR}/polar-app.png`,
  },
  {
    id: 'Runkeeper',
    name: 'Runkeeper',
    img: `${DIR}/runkeeper-app.png`,
  },
  {
    id: 'Runtastic',
    name: 'Runtastic',
    img: `${DIR}/runtastic-app.png`,
  },
  {
    id: 'Strava',
    name: 'Strava',
    img: `${DIR}/strava-app.png`,
  },
  {
    id: 'SimpleRun',
    name: 'Simple Run',
    img: `${DIR}/simple-run-app.png`,
  },
  {
    id: 'ScountTracker',
    name: 'Скаунт',
    img: `${DIR}/scount-app.png`,
  },
  {
    id: 'ScountHealth',
    name: 'Любые трекеры',
    img: null,
  },
];

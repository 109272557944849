<template>
  <div v-if="themeDone" class="team">
    <div v-if="!isMyTeam" class="team__header team__header_default">
      <div class="header__row">
        <div class="header__group">
          <div class="header__item header__logo">
            <a href="#" @click.prevent><img :src="logo" alt="Scount"></a>
          </div>
        </div>
        <div class="header__group ml-auto">
          <div v-if="!isTeamStepRequired" class="header__item header__link">
            <Link variant="secondary" size="sm" @click="onSkip">Пропустить</Link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMyTeam && !isMobileApp" class="divider"></div>
    <div v-if="isMyTeam && !isMobileApp" class="team__menu">
      <Menu />
      <div class="divider"></div>
    </div>
    <div v-if="!isMyTeam && !isMobileApp" class="divider"></div>
    <div v-if="!isTeamSet" class="team__info-container">
      <div class="team__info">
        <div
          class="info__image"
          :style="`background-image: url('${teamSpiritRafiki}');`"
        ></div>
        <div class="info__text">
          <div v-if="!isMyTeam" class="info__step">
            <span>Шаг {{ totalSteps }}</span> из {{ totalSteps }}
          </div>
          <div class="info__title">
            {{ `${this.profile.firstName || ''}, вступите в команду` }}
          </div>
          <div class="info__subtitle">
            <span>Чтобы участвовать в&nbsp;командных номинациях. </span><br>
            <Link variant="primary" size="md" @click="onAboutTeams">Зачем вступать в команду?</Link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isTeamSet" class="team__about">
      <div v-if="myTeamLogo" class="about__logo"><img :src="myTeamLogo" :alt="myTeamName" /></div>
      <div class="about__title text-center" v-html="myTeamName"></div>
      <div class="about__members-status">
        <div class="members-status__members"><Icon name="user-groups" size="m" /><span>{{ membersTotalItems }}</span></div>
        <div class="members-status__status"><Icon name="check-bold" size="m" /><span>Вы участник</span></div>
      </div>
    </div>
    <div v-if="isTeamSet" class="team__tabs">
      <div class="divider"></div>
      <div class="tabs__tabs">
        <Tabs :items="showTeamRatingsTab ? tabsNaminationsHide : tabs" :activeItem="selectedType" @changed="onChangeTypePage" />
      </div>
    </div>

    <div v-if="isTeamSet && selectedType === 0" class="team__members">
      <div class="members__content text-left">
        <div class="members__entries">
          Показаны<br v-if="$root.isMobile" />
          {{ showPagination ? membersOffset + 1 : 1 }}–{{ showPagination ? Math.min(membersOffset + 10, membersTotalItems) : showItemsCount }} из {{ membersTotalItems }} записей
        </div>
        <div class="members__table">
          <table>
            <tr
              v-for="(item, i) in membersItems"
              :key="i"
              class="members__rating-item"
              :class="{ 'rating-item--active': item.current === true }"
            >
              <td class="rating-item__number" v-if="item.change === 'up'">{{ item.number }}<span class="number--up">&#8239;&#8593;</span></td>
              <td class="rating-item__number" v-else-if="item.change === 'down'">{{ item.number }}<span class="number--down">&#8239;&#8595;</span></td>
              <td class="rating-item__number" v-else>{{ item.number }}</td>
              <td class="rating-item__name">{{ item.name }}</td>
              <td class="rating-item__value">
                <div>
                  <span>{{ item.value }}</span>
                  <img :src="pointImage" width="18" height="18"/>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <Button
          v-if="membersCurrentPage === 1 && membersItems.length < membersTotalItems"
          variant="secondary"
          size="md"
          mobile
          :wide="true"
          class="members__more"
          @click="onMembersMore"
        >
          Показать ещё
        </Button>
        <Pagination
          v-if="showPagination && membersItems.length < membersTotalItems"
          :currentPage="membersCurrentPage"
          :totalPages="membersTotalPages"
          class="members__pagination"
          @prev-page="onMembersChangePage(membersCurrentPage - 1)"
          @next-page="onMembersChangePage(membersCurrentPage + 1)"
          @go-page="onMembersChangePage"
        ></Pagination>
      </div>
    </div>

    <div v-show="isTeamSet && selectedType === 1" class="team__nominations">
      <div class="nominations__types">
        <Tabs :items="[getTabText(0), getTabText(1)]" :activeItem="selectedTab" @changed="onChangeTab" />
      </div>
      <div class="nominations__items">
        <div v-show="selectedTab === 0">
          <NominationCard
            v-for="(item, i) in itemsTeamActive"
            :key="i"
            :ratingText="teamRatingText"
            :lockedText="lockedText"
            type="personal"
            state="in-progress"
            class="team-rating"
            :nominationText="getNominationDates(item)"
            :title="item.title"
            :values="getNominationRating(item)"
            @show-details="onShowRating(item, true)"
          ></NominationCard>
        </div>
        <div v-show="selectedTab === 1">
          <NominationCard
            v-for="(item, i) in itemsTeamCompleted"
            :key="i"
            :ratingText="teamRatingText"
            :lockedText="lockedText"
            :resultText="resultText"
            type="personal"
            state="result"
            class="team-rating"
            :nominationText="getNominationDates(item)"
            :title="item.title"
            :resultValues="getNominationResult(item)"
            @show-details="onShowRating(item, true)"
          ></NominationCard>
        </div>
        <div class="nominations__loader" v-if="updating">
          <Loader size="24" />
        </div>
        <div class="nominations__loader-block"></div>
      </div>
    </div>

    <div v-if="isTeamSet && selectedType === 2" class="team__details">
      <div class="details__container text-left">
        <div class="details__title">О команде</div>
        <div class="details__content">
          <div v-if="teamDescription" class="details__description" v-html="teamDescription"></div>
          <div v-if="teamLocationAndType" class="details__location-type">
            <div class="location-type__title">
              <span>Где находится клуб</span>
              <span></span>
            </div>
            <div class="location-type__text" v-html="teamLocationAndType"></div>
          </div>
          <div v-if="teamLink" class="details__link">
            <div class="link__title">
              <span>Сайт или соц. сеть клуба</span>
              <span></span>
            </div>
            <div class="link__text">
              <Link
                variant="primary"
                size="md"
                iconRight="externa-link"
                tag="a"
                :href="teamLink"
                :text="getLinkWithoutProtocol(teamLink)"
                :title="teamLink"
                target="_blank"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isTeamSet" class="team__change text-center">
      <Link variant="secondary" size="sm" text="Как сменить команду?" iconLeft="info-circle-s" @click="onTeamChange" />
    </div>

    <div v-if="!isTeamSet" class="team__teams-container">
      <div class="team__teams-search" @click.stop="onShowSearch">
        <div v-if="$root.isMobile" class="teams-search__text">{{ search ? search : 'Начать поиск команды' }}</div>
        <div v-if="$root.isMobile" class="teams-search__icon">
          <Icon name="search" size="s" />
        </div>
        <div v-if="!$root.isMobile" class="teams-search__field">
          <div class="teams-search__label">Название</div>
          <div class="teams-search__input">
            <Input
              v-model.trim="search"
              placeholder="Как называется команда?"
              id="team-search"
              noBorder
              :disabled="loading"
              @keydown="onSearchKeydown"
            ></Input>
          </div>
        </div>
        <div v-if="!$root.isMobile" class="teams-search__action">
          <Button
            variant="primary"
            size="lg"
            text="Найти"
            iconLeft="search"
            @click="onSearch"
            :disabled="loading"
            :loading="loading"
          ></Button>
        </div>
      </div>
      <div v-if="!isTeamSet && items.length > 0" class="team__teams" :class="{ 'team__teams_my': isMyTeam }">
        <TeamCard
          v-for="(item, i) in items"
          :key="i"
          :name="item.name"
          :image="item.logo || ''"
          location=""
          :members="getTeamMembersCount(item.teamId)"
          class="teams__card"
          @join="onShowConfirm(item)"
        ></TeamCard>
      </div>
      <div v-if="!isTeamSet && items.length === 0" class="team__teams" :class="{ 'team__teams_my': isMyTeam }">
        Ничего не найдено.
      </div>
      <div v-if="!isTeamSet && totalPages > 1" class="team__pagination">
        <Pagination
          v-if="totalPages > 1"
          :currentPage="currentPage"
          :totalPages="totalPages"
          class=""
          @prev-page="onTeamsChangePage(currentPage - 1)"
          @next-page="onTeamsChangePage(currentPage + 1)"
          @go-page="onTeamsChangePage"
        ></Pagination>
      </div>
      <div v-if="!isTeamSet && showTeamCreate" class="team__request" :class="{ 'team__request_my': isMyTeam }">
        <Icon name="empty-flag" />
        <div class="team__request-heading">Зарегистрируй команду своего клуба или ЗОЖ сообщества</div>
        <Button
          variant="primary"
          size="lg"
          text="Начать"
          @click="onTeamCreation"
        ></Button>
      </div>
      <div class="spacing-xl"></div>
    </div>

    <Modal
      name="about-teams"
      class="about-teams"
      :class="{ 'for-mobile-app': isMobileApp }"
      :img="teamSpiritRafiki"
      title="Присоединившись к команде:"
      :active="aboutTeamsModal"
      :noTransition="true"
      @close.prevent="onAboutTeamsClosed"
    >
      <div class="about-teams__text text-left">
        Очки, полученные вами за выполненные задания, будут добавляться в копилку вашей команды.<br><br>
        Вы становитесь участником внутрикомандного зачета.<br><br>
        Вы получаете возможность принимать участие в командных мероприятиях, получать полезную информацию, обрести новые знакомства с людьми, разделяющими ваши интересы к бегу и ЗОЖ.
      </div>
      <div class="buttons-group">
        <div class="buttons-group__item buttons-group__item_first">
          <Button
            variant="secondary"
            size="lg"
            text="Понятно"
            @click="onAboutTeamsClosed"
            wide
            mobile
          ></Button>
        </div>
      </div>
    </Modal>

    <Modal
      name="team-search"
      class="team-search"
      title="Поиск команды"
      :active="showSearch"
      :noTransition="true"
      @close.prevent="onSearchClose"
    >
      <div class="team-search__container">
        <div class="team-search__field text-left">
          <div class="field__label">Название</div>
          <div class="field__input">
            <Input
              v-model.trim="search"
              placeholder="Как называется команда?"
              id="team-search"
              noBorder
              mobile
              :disabled="loading"
              @keydown="onSearchKeydown"
            ></Input>
          </div>
        </div>
        <div class="divider"></div>
        <div class="">
          <Button
            variant="primary"
            size="lg"
            text="Найти"
            iconLeft="search"
            @click="onSearch"
            wide
            mobile
            :disabled="loading"
            :loading="loading"
          ></Button>
        </div>
      </div>
    </Modal>

    <Modal
      name="team-confirm"
      class="team-confirm"
      :img="teamLogo"
      :title="teamName"
      :subtitle="teamLocationAndType"
      :active="showConfirm"
      :noTransition="true"
      @close.prevent="onConfirmClose"
    >
      <div class="raffle-info">
        <div class="raffle-info__members">
          <Icon name="user-groups" size="m" /><span>{{ getTeamMembersCount(teamId) }} участников</span>
        </div>
        <div v-if="teamLink" class="raffle-info__link">
          <Icon name="global" size="m" />
          <Link
            variant="primary"
            size="md"
            iconRight="externa-link"
            tag="a"
            :href="teamLink"
            :text="getLinkWithoutProtocol(teamLink)"
            :title="teamLink"
            target="_blank"
          />
        </div>
      </div>
      <div
        v-if="teamDescription"
        class="team-confirm__description"
        v-html="teamDescription"
      ></div>
      <div class="team-confirm__actions">
        <Button
          variant="primary"
          size="lg"
          text="Вступить в команду"
          @click="onSelectTeam"
          :wide="$root.isMobile"
          :disabled="loading"
          :loading="loading"
          mobile
        ></Button>
        <div class="team-confirm__or">
          <div class="or__line"></div>
          <div class="or__text">или</div>
          <div class="or__line"></div>
        </div>
        <Button
          variant="secondary"
          size="sm"
          text="Выбрать другую команду"
          @click="onConfirmClose"
          :wide="$root.isMobile"
          :disabled="loading"
          :loading="loading"
          mobile
        ></Button>
      </div>
    </Modal>

    <Modal
      name="team-change"
      class="team-change"
      :class="{ 'for-mobile-app': isMobileApp }"
      icon="info-circle-s"
      title="Для смены команды:"
      :active="teamChangeModal"
      :noTransition="true"
      @close.prevent="onTeamChangeClosed"
    >
      <p class="team-change__subtitle">напишите, пожалуйста, письмо в <a href="#" @click.prevent="onSupport">Поддержку</a> с названием команды, в которую хотите попасть</p>
    </Modal>

    <SupportDialog
      :active="supportModal"
      :isMobileApp="isMobileApp"
      @close="onSupportClose"
      @form-submitted="handleFormSubmit"
    />

    <Modal
      :closable="true"
      name="successModal"
      :active="successModal"
      :isMobileApp="isMobileApp"
      @close="onSuccessClose"
    >
      <div class="modal__success">
        <Icon name="check-circle"></Icon>
        <p class="modal__success-title">Вопрос отправлен</p>
        <p class="modal__success-text">Обычно отвечаем в течение трёх рабочих дней. Ответ придёт на почту <span>{{ email }}</span> </p>
        <div class="moadal__success-action">
          <Button
            variant="secondary"
            size="lg"
            tag="a"
            mobile
            @click="onSuccessClose"
          >
            Закрыть
          </Button>
        </div>
      </div>
    </Modal>

    <Modal
      name="team-creation-warning"
      class="team-creation-warning"
      :class="{ 'for-mobile-app': isMobileApp }"
      state="warning"
      :title="teamCreationTitle"
      :active="showTeamCreationModal"
      :noTransition="true"
      @close.prevent="onTeamCreationClosed"
    >
      <div class="creation-warning__text text-left">
        <ul type="disc">
          <li v-for="(item, index) in teamCreateModalInfo" :key="index">{{ item.text }}</li>
        </ul>
      </div>
      <div class="creation-warning__subtext">
        Если все соответствует, то смело отправляйте заявку на регистрацию команды.
      </div>
      <div class="buttons-group">
        <div class="buttons-group__item">
          <Button
            v-if="!isMobileApp"
            variant="primary"
            size="lg"
            text="Продолжить"
            tag="a"
            :href="teamCreateLink"
            target="_blank'"
            wide
            mobile
          ></Button>
          <Button
            v-if="isMobileApp"
            variant="primary"
            size="lg"
            text="Продолжить"
            @click="onTeamCreationCreate"
            wide
            mobile
          ></Button>
        </div>
        <div class="buttons-group__item buttons-group__item_first">
          <Button
            variant="secondary"
            size="lg"
            text="Отменить"
            @click="onTeamCreationClosed"
            wide
            mobile
          ></Button>
        </div>
      </div>
    </Modal>

    <Modal
      name="creation-finished"
      state="success"
      class="creation-finished"
      :class="{ 'for-mobile-app': isMobileApp }"
      title="Заявка на регистрацию команды принята!"
      subtitle="Решение о создании команды придет вам на почту, указанную в заявке."
      :active="finishedModal"
      :noTransition="true"
      @close.prevent="onFinishedClosed"
    >
      <div class="buttons-group">
        <div class="buttons-group__item buttons-group__item_first">
          <Button
            variant="secondary"
            size="lg"
            text="Понятно"
            @click="onCreationFinished"
            mobile
          ></Button>
        </div>
      </div>
    </Modal>

    <Modal
      :active="teamOffLimits"
      name="TeamOffLimitsModal"
      title="Ваша команда уже собрана"
      :subtitle="teamOffLimitsSubtitle"
      icon="info-circle"
      @close.prevent="onTeamOffLimitsClose"
    >
      <Button
        variant="secondary"
        size="lg"
        mobile
        @click="onTeamOffLimitsClose"
      >
        Понятно
      </Button>
    </Modal>

    <RulesPrizesModal
      :active="rulesPrizesModal"
      :image="rulesPrizesImage"
      :title="rulesPrizesTitle"
      :subtitle="rulesPrizesSubtitle"
      :nominationId="rulesPrizesNominationId"
      :isTeam="rulesPrizesIsTeam"
      state="personal"
      :tabs="rulesPrizesTabs"
      :activeTab="rulesPrizesTabActive"
      @close="rulesPrizesModal = false"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import TeamCard from '@sc-component-library/team-card/src/main';
import Input from '@rr-component-library/input/src/main';
import NominationCard from '@sc-component-library/nomination-card/src/main';
import Pagination from '@/components/Pagination/Pagination.vue';
import Menu from '@/components/Menu/Menu.vue';
import RulesPrizesModal from '@/components/RulesPrizesModal/RulesPrizesModal.vue';
import Point from '@/assets/img/point.svg';
import SupportDialog from '@/components/SupportDialog/SupportDialog.vue';
import { formatDateRange } from '@/assets/js/helpers';

export default {
  name: 'Team',
  props: {
    firstName: String,
  },
  components: {
    Input,
    NominationCard,
    TeamCard,
    Pagination,
    Menu,
    RulesPrizesModal,
    SupportDialog,
  },
  data() {
    return {
      items: [],
      currentPage: 1,
      itemsPerPage: 6,
      totalPages: 0,
      teamName: '',
      teamId: '',
      teamLogo: '',
      teamLocationAndType: '',
      teamLink: '',
      teamDescription: '',
      showConfirm: false,
      teamOffLimits: false,
      search: '',
      showSearch: false,
      selectedType: 0,
      tabs: [
        { title: 'Участники', enabled: true },
        { title: 'Номинации', enabled: true },
        { title: 'Обзор', enabled: true },
      ],
      tabsNaminationsHide: [
        { title: 'Участники', enabled: true },
        { title: 'Обзор', enabled: true },
      ],
      selectedTab: 0,
      teamRatingText: { general: 'Текущий рейтинг', personal: 'Команда в рейтинге' },
      lockedText: { text: 'Для участия необходимо', link: 'подключить трекер' },
      resultText: { rating: 'Итоговый результат', reward: 'Ваша награда' },
      membersCurrentPage: 1,
      membersTotalPages: 1,
      membersTotalItems: 0,
      membersItems: [],
      pointImage: Point,
      showItemsCount: 10,
      membersOffset: 0,
      showPagination: true,
      rulesPrizesModal: false,
      rulesPrizesTitle: '',
      rulesPrizesSubtitle: '',
      rulesPrizesNominationId: '',
      rulesPrizesIsTeam: false,
      rulesPrizesImage: '',
      rulesPrizesTabs: [
        {
          title: 'Правила',
          enabled: true,
          content: '',
        },
        {
          title: 'Награды',
          enabled: true,
          content: '',
        },
        {
          title: 'Рейтинг',
          enabled: true,
        },
      ],
      rulesPrizesTabActive: 0,
      aboutTeamsModal: false,
      teamChangeModal: false,
      showTeamCreationModal: false,
      finishedModal: false,
      itemsTeamActive: [],
      itemsTeamCompleted: [],
      offsetTeamActive: 0,
      offsetTeamCompleted: 0,
      loading: false,
      updating: false,
      loaderBlock: null,
      supportModal: false,
      successModal: false,
      email: '',
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeDone', 'options', 'resources']),
    ...mapGetters('user', ['profile', 'isAuthenticated', 'emptyProfile', 'questionnaireStatus', 'isMobileApp']),
    ...mapGetters('teams', ['teams', 'team']),
    ...mapGetters('nominations', ['teamNominationsActive', 'teamNominationsCompleted', 'teamMembersResults', 'ratings', 'responseStatus']),
    isMyTeam() {
      return this.$route.name === 'MyTeam';
    },
    isTeamSet() {
      return this.profile ? this.profile.team !== undefined && this.profile.team.teamId !== undefined : false;
    },
    isTeamCreated() {
      return this.$route.query.isTeamCreated === 'true';
    },
    myTeamId() {
      return this.profile ? this.profile.team && this.profile.team.teamId : null;
    },
    myTeamName() {
      return this.profile ? this.profile.team && this.profile.team.name : '';
    },
    myTeamLogo() {
      return this.profile ? this.profile.team && this.profile.team.logo : '';
    },
    isTeamStepRequired() {
      return this.options ? this.options.isTeamStepRequired === true : false;
    },
    showTeamCreate() {
      return this.options ? this.options.showTeamCreate === true : false;
    },
    showTeamRatingsTab() {
      return this.options ? this.options.showTeamRatingsTab === true : false;
    },
    teamCreateModalInfo() {
      return this.options ? this.options.teamCreateModalInfo || [] : [];
    },
    showRegisterAdditionalStep() {
      return this.options ? this.options.showRegisterAdditionalStep === true : false;
    },
    disableTeamsForGuest() {
      return this.options ? this.options.disableTeamsForGuest === true : false;
    },
    logo() {
      return this.resources ? this.resources.logo || '' : '';
    },
    teamSpiritRafiki() {
      return this.resources ? this.resources.teamSpiritRafiki || '' : '';
    },
    articleTeams() {
      return this.resources ? this.resources.articleTeams || '#' : '#';
    },
    supportEmail() {
      return this.resources ? this.resources.supportEmail || '#' : '#';
    },
    totalSteps() {
      return this.showRegisterAdditionalStep ? 3 : 2;
    },
    showOffset() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    teamCreationTitle() {
      return `Требования к клубу<br>
        или ЗОЖ сообществу<br>
        для регистрации команды`;
    },
    teamCreateLink() {
      return this.isMyTeam ? '/my-team/create' : '/team/create';
    },
    teamOffLimitsSubtitle() {
      return `В вашей команде больше нет свободных слотов для участия. Попросите организатора в вашей компании связаться с нами<br/>
      <a href="mailto:${this.supportEmail}">${this.supportEmailText || this.supportEmail}</a>`;
    },
  },
  methods: {
    ...mapActions('user', ['getProfile', 'applyAnswers']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('teams', ['getTeams', 'getTeamById']),
    ...mapActions('nominations', ['getTeamNominationsActive', 'getTeamNominationsCompleted', 'getTeamMembersResults', 'getRatings']),
    ...mapActions('exercises', ['sendSupport']),
    onSupport() {
      this.teamChangeModal = false;
      window.location.hash = '#!showSupport=true';
    },
    onSupportClose() {
      window.location.hash = '#!showSupport=false';
    },
    onSuccessClose() {
      window.location.hash = '#!showSupportSuccess=false';
    },
    handleFormSubmit(formData) {
      this.supportModal = false;
      window.location.hash = '#!showSupport=false';
      this.sendSupport(formData)
        .then(() => {
          this.email = formData.email;
          window.location.hash = '#!showSupportSuccess=true';
        });
    },
    checkProfile() {
      this.getProfile()
        .then(() => {
          if (this.isAuthenticated) {
            if (this.emptyProfile === true) {
              this.$router.push({ name: 'Register' }).catch(() => {});
              return;
            }
            this.getBalance();
            if (this.isTeamPageDisabled() && !this.isMobileApp) {
              this.$router.push({ name: 'Dashboard' }).catch(() => {});
              return;
            }
            if (this.isTeamSet) {
              if (!this.isMyTeam) {
                this.$router.push({ name: 'Dashboard' }).catch(() => {});
                return;
              }
              this.getTeamMembersResults({ teamId: this.myTeamId, offset: 0, count: 10 })
                .then(() => {
                  if (this.teamMembersResults) {
                    this.membersItems = this.getRating(this.teamMembersResults.values);
                    this.membersTotalPages = Math.ceil(this.teamMembersResults.total / 10);
                    this.membersTotalItems = this.teamMembersResults.total;
                  }
                });
              this.getTeamById({ teamId: this.myTeamId })
                .then(() => {
                  if (this.team) {
                    this.teamName = this.team.name || '';
                    this.teamId = this.myTeamId || '';
                    this.teamLogo = this.team.logo || '';
                    this.setTeamValues(this.team);
                  }
                });
              return;
            }

            if (!this.isTeamSet && this.isTeamCreated) {
              this.onFinished();
            }

            this.loading = true;
            this.getTeams({ searchString: this.search, offset: 0, count: this.itemsPerPage })
              .then(() => {
                this.items = this.teams.values;
                this.totalPages = Math.ceil(this.teams.total / this.itemsPerPage);
                this.getRatings({ filter: null, aggregateField: 'TeamId' })
                  .then(() => {
                    this.loading = false;
                  });
              });
          } else {
            this.$router.push({ name: 'Auth' }).catch(() => {});
          }
        });
    },
    onHashChange() {
      if (this.$route.name !== 'Team' && this.$route.name !== 'MyTeam') {
        window.removeEventListener('hashchange', this.onHashChange);
        return;
      }
      const hash = window.location.hash.split('=');
      if (hash[0] === '#!showAboutTeams') this.showAboutTeams(hash);
      else if (hash[0] === '#!showTeamChange') this.showTeamChange(hash);
      else if (hash[0] === '#!showTeamCreation') this.showTeamCreation(hash);
      else if (hash[0] === '#!showTeamCreationFinished') this.showTeamCreationFinished(hash);
      else if (hash[0] === '#!showSupport') this.showSupport(hash);
      else if (hash[0] === '#!showSupportSuccess') this.showSupportSuccess(hash);
      else {
        this.clearHash();
        this.aboutTeamsModal = false;
        this.teamChangeModal = false;
        this.showTeamCreationModal = false;
        this.finishedModal = false;
        this.supportModal = false;
        this.successModal = false;
      }
    },
    onAboutTeams() {
      window.location.hash = '#!showAboutTeams=true';
    },
    onAboutTeamsClosed() {
      window.location.hash = '#!showAboutTeams=false';
    },
    onTeamChange() {
      window.location.hash = '#!showTeamChange=true';
    },
    onTeamChangeClosed() {
      window.location.hash = '#!showTeamChange=false';
    },
    onTeamCreation() {
      window.location.hash = '#!showTeamCreation=true';
    },
    onTeamCreationCreate() {
      this.onTeamCreationClosed();
      setTimeout(() => { this.$root.$goPage('TeamCreate', this.teamCreateLink); }, 250);
    },
    onTeamCreationClosed() {
      window.location.hash = '#!showTeamCreation=false';
    },
    onTeamsChangePage(page) {
      this.currentPage = page;
      this.loading = true;
      this.getTeams({ searchString: this.search, offset: this.showOffset, count: this.itemsPerPage })
        .then(() => {
          this.items = this.teams.values;
          this.totalPages = Math.ceil(this.teams.total / this.itemsPerPage);
          this.loading = false;
        });
    },
    onShowConfirm(item) {
      if (item) {
        this.getTeamById({ teamId: item.teamId })
          .then(() => {
            if (this.team) {
              this.teamName = this.team.name || '';
              this.teamId = item.teamId || '';
              this.teamLogo = this.team.logo || '';
              this.setTeamValues(this.team);
              if (this.teamName && this.teamId) {
                this.showConfirm = true;
              }
            }
          });
      }
    },
    onConfirmClose() {
      this.showConfirm = false;
    },
    onShowSearch() {
      if (this.$root.isMobile) {
        this.showSearch = true;
        setTimeout(() => {
          const item = document.getElementById('team-search');
          if (item) item.focus();
        }, 100);
      }
    },
    onSearchKeydown(event) {
      if (event.key === 'Enter') {
        this.onSearch();
      }
    },
    onSearch() {
      this.showSearch = false;
      this.loading = true;
      this.getTeams({ searchString: this.search, offset: 0, count: this.itemsPerPage })
        .then(() => {
          this.items = this.teams.values;
          this.currentPage = 1;
          this.totalPages = Math.ceil(this.teams.total / this.itemsPerPage);
          this.loading = false;
        });
    },
    onSearchClose() {
      this.showSearch = false;
    },
    onSelectTeam() {
      const data = { TeamId: this.teamId };
      this.loading = true;
      this.applyAnswers(data)
        .then(() => {
          if (this.questionnaireStatus === 204) {
            setTimeout(() => {
              if (this.isMyTeam) window.location.reload();
              else window.location.href = `${window.location.origin}/dashboard?isFirstLogin=true`;
            }, 250);
          } else if (this.questionnaireStatus === 409) {
            this.onConfirmClose();
            this.teamOffLimits = true;
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
    },
    onSkip() {
      this.$router.push({ name: 'Dashboard', query: { isFirstLogin: 'true' } }).catch(() => {});
    },
    onChangeTypePage(index) {
      if (index === 1) {
        this.loadNominations();
        return;
      }
      this.selectedType = index;
    },
    onChangeTab(index) {
      this.selectedTab = index;
    },
    onMembersChangePage(page) {
      window.scrollTo(0, 0);
      this.membersCurrentPage = page;
      this.loadRatingPage();
    },
    onMembersMore() {
      if (this.membersTotalItems === this.membersItems.length) return;
      let showItemsCount = this.showItemsCount + 10;
      if (showItemsCount > this.totalItems) showItemsCount = this.membersTotalItems;
      this.showItemsCount = showItemsCount;
      const offset = this.membersOffset + 10;
      if (offset > this.membersTotalItems) return;
      this.membersOffset = offset;
      this.showPagination = false;
      this.getTeamMembersResults({ teamId: this.myTeamId, offset: this.membersOffset, count: 10 })
        .then(() => {
          if (this.teamMembersResults) {
            this.membersItems.push(...this.getRating(this.teamMembersResults.values));
          }
        });
    },
    onShowRating(item, isTeam = false) {
      this.rulesPrizesTabs[0].content = item.rules || '';
      this.rulesPrizesTabs[1].content = item.awards || '';
      this.rulesPrizesTabs[2].enabled = true;
      this.rulesPrizesTabActive = 2;
      this.rulesPrizesImage = '';
      this.rulesPrizesTitle = this.getNominationDates(item);
      this.rulesPrizesSubtitle = item.title;
      this.rulesPrizesNominationId = item.nominationId;
      this.rulesPrizesIsTeam = isTeam;
      this.rulesPrizesModal = true;
    },
    onFinished() {
      window.location.hash = '#!showTeamCreationFinished=true';
    },
    onFinishedClosed() {
      window.location.hash = '#!showTeamCreationFinished=false';
    },
    onCreationFinished() {
      window.location.hash = '#!showTeamCreationFinished=false';
      setTimeout(() => { this.$router.replace({ query: '' }); }, 500);
    },
    setupLoader() {
      const vm = this;
      if (vm.loaderBlock !== null) return;
      if (vm.$route.name !== 'MyTeam') {
        return;
      }
      const loaders = document.getElementsByClassName('nominations__loader-block');
      vm.loaderBlock = loaders[0] || null;
      window.addEventListener('scroll', vm.checkForLoading);
      window.addEventListener('touchmove', vm.checkForLoading);
      vm.checkForLoading();
    },
    checkForLoading() {
      if (this.updating) return;
      if (!this.loaderBlock) return;
      if (this.$route.name !== 'MyTeam') {
        this.cleanup();
        return;
      }

      if (this.getNominations() === null) return;
      if (!this.hasNominationsForLoading()) return;

      const bottomCoord = this.loaderBlock.getBoundingClientRect().bottom;
      const height = document.documentElement.clientHeight;
      if (height >= bottomCoord) {
        this.updating = true;
        setTimeout(() => { this.updateTeamNominations(); }, 250);
      }
    },
    cleanup() {
      window.removeEventListener('scroll', this.checkForLoading);
      window.removeEventListener('touchmove', this.checkForLoading);
    },
    loadNominations() {
      const vm = this;
      this.getTeamNominationsActive({ offset: 0, count: 12 })
        .then(() => {
          this.itemsTeamActive = this.teamNominationsActive ? this.teamNominationsActive.values : [];
          this.selectedType = 1;
          setTimeout(() => {
            vm.setupLoader();
          }, 250);
        });
      this.getTeamNominationsCompleted({ offset: 0, count: 12 })
        .then(() => {
          this.itemsTeamCompleted = this.teamNominationsCompleted ? this.teamNominationsCompleted.values : [];
        });
    },
    updateTeamNominations() {
      if (this.selectedTab === 0) {
        this.offsetTeamActive += 12;
        this.getTeamNominationsActive({ offset: this.offsetTeamActive, count: 12 })
          .then(() => {
            if (this.responseStatus === 200) {
              this.itemsTeamActive.push(...this.teamNominationsActive.values);
            }
            this.updating = false;
          });
        return;
      }
      if (this.selectedTab === 1) {
        this.offsetTeamCompleted += 12;
        this.getTeamNominationsCompleted({ offset: this.offsetTeamCompleted, count: 12 })
          .then(() => {
            if (this.responseStatus === 200) {
              this.itemsTeamCompleted.push(...this.teamNominationsCompleted.values);
            }
            this.updating = false;
          });
      }
    },
    getTabText(tab) {
      if (tab === 0) return { title: `<div>${this.teamNominationsActive ? this.teamNominationsActive.total : 0}</div><div>Активны</div>`, enabled: this.teamNominationsActive ? this.teamNominationsActive.total > 0 : false };
      if (tab === 1) return { title: `<div>${this.teamNominationsCompleted ? this.teamNominationsCompleted.total : 0}</div><div>Завершены</div>`, enabled: this.teamNominationsCompleted ? this.teamNominationsCompleted.total > 0 : false };
      return { title: '', enabled: false };
    },
    getNominations() {
      if (this.selectedTab === 0) return this.teamNominationsActive;
      if (this.selectedTab === 1) return this.teamNominationsCompleted;
      return null;
    },
    hasNominationsForLoading() {
      let nominations = { count: 0 };
      let items = [];
      if (this.selectedTab === 0) {
        nominations = this.teamNominationsActive;
        items = this.itemsTeamActive;
      }
      if (this.selectedTab === 1) {
        nominations = this.teamNominationsCompleted;
        items = this.itemsTeamCompleted;
      }
      if (nominations.count === 0) return false;
      if (items.length >= nominations.total) return false;
      return true;
    },
    loadRatingPage() {
      this.membersOffset = (this.membersCurrentPage - 1) * 10;
      this.getTeamMembersResults({ teamId: this.myTeamId, offset: this.membersOffset, count: 10 })
        .then(() => {
          if (this.teamMembersResults) {
            this.membersItems = this.getRating(this.teamMembersResults.values);
            this.membersTotalPages = Math.ceil(this.teamMembersResults.total / 10);
            this.membersTotalItems = this.teamMembersResults.total;
          }
        });
    },
    getRating(values) {
      if (!values) return [];
      const rating = [];
      for (let i = 0; i < values.length; i += 1) {
        const it = {
          number: values[i].position.toString(),
          name: `${values[i].lastName} ${values[i].firstName}`,
          value: values[i].points.toString(),
          current: values[i].isCurrentUser === true,
        };
        rating.push(it);
      }
      return rating;
    },
    getNominationDates(item) {
      if (!item) return '';
      const startDate = dayjs.utc(item.startDate);
      const finishDate = dayjs.utc(item.finishDate);
      if (startDate.isValid() && finishDate.isValid()) {
        return formatDateRange({ start: startDate, finish: finishDate, showYear: true });
      }
      return '';
    },
    getNominationRating(item) {
      if (!item) return [];
      const rating = [];
      for (let i = 0; i < item.leaders.length; i += 1) {
        const it = {
          number: item.leaders[i].position.toString(),
          name: item.leaders[i].teamName,
          value: item.leaders[i].points.toString(),
          current: item.leaders[i].isCurrentTeam === true,
        };
        rating.push(it);
      }
      return rating;
    },
    getNominationResult(item) {
      const emptyResult = {
        rating: { number: '', name: '', value: '' },
      };
      if (!item) return emptyResult;
      const result = item.leaders.filter((i) => i.isCurrentTeam === true);
      if (result.length === 0) return emptyResult;
      return {
        rating: { number: result[0].position.toString(), name: result[0].teamName, value: result[0].points.toString() },
      };
    },
    getTeamMembersCount(teamId) {
      if (!this.ratings) return 0;
      const found = this.ratings.filter((i) => i.id === teamId);
      if (found.length === 0) return 0;
      return found[0].count || 0;
    },
    isTeamPageDisabled() {
      return !this.isTeamSet && this.isMyTeam && this.disableTeamsForGuest;
    },
    getLinkWithoutProtocol(link) {
      if (!link) return '';
      const https = 'https://';
      if (link.indexOf(https) === 0) return link.slice(https.length);
      const http = 'http://';
      if (link.indexOf(http) === 0) return link.slice(http.length);
      return link;
    },
    setTeamValues(item) {
      if (!item) return;
      const type = item.onlineClub === true ? 'Онлайн клуб' : 'Офлайн клуб';
      if (item.location) {
        this.teamLocationAndType = `${item.location} • ${type}`;
      } else {
        this.teamLocationAndType = `${type}`;
      }
      this.teamLink = item.url || '';
      this.teamDescription = item.description || '';
    },
    showAboutTeams(hash) {
      if (hash[1] === 'true') this.aboutTeamsModal = true;
      else {
        this.aboutTeamsModal = false;
        this.clearHash();
      }
    },
    showTeamChange(hash) {
      if (hash[1] === 'true') this.teamChangeModal = true;
      else {
        this.teamChangeModal = false;
        this.clearHash();
      }
    },
    showTeamCreation(hash) {
      if (hash[1] === 'true') this.showTeamCreationModal = true;
      else {
        this.showTeamCreationModal = false;
        this.clearHash();
      }
    },
    showTeamCreationFinished(hash) {
      if (hash[1] === 'true') this.finishedModal = true;
      else {
        this.finishedModal = false;
        this.clearHash();
      }
    },
    showSupport(hash) {
      if (hash[1] === 'true') this.supportModal = true;
      else {
        this.supportModal = false;
        this.clearHash();
      }
    },
    showSupportSuccess(hash) {
      if (hash[1] === 'true') this.successModal = true;
      else {
        this.successModal = false;
        this.clearHash();
      }
    },
    clearHash() {
      setTimeout(() => { window.location.hash = ''; }, 100);
    },
    onTeamOffLimitsClose() {
      this.teamOffLimits = false;
    },
  },
  mounted() {
    this.checkProfile();

    window.addEventListener('hashchange', this.onHashChange);
    this.onHashChange();
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import "./Team";
</style>

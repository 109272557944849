/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Api from '@/services/partners-api';

const state = {
  partnerStatus: null,
  registration: null,
};

const mutations = {
  setPartnerStatus(state, value) {
    state.partnerStatus = value;
  },
  setRegistration(state, value) {
    state.registration = value;
  },
};

const getters = {
  partnerStatus: (state) => state.partnerStatus,
  registration: (state) => state.registration,
};

const actions = {
  getActivityRegistration({ commit }, { participantExerciseTaskId }) {
    return Api.getActivityRegistration({ participantExerciseTaskId })
      .then((response) => {
        commit('setRegistration', response.data);
        commit('setPartnerStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setPartnerStatus', status || 0);
      });
  },
  createActivity({ commit }, { activityId, participantExerciseTaskId, participantId }) {
    return Api.createActivity({ activityId, participantExerciseTaskId, participantId })
      .then((response) => {
        commit('setPartnerStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setPartnerStatus', status || 0);
      });
  },
  createLinkActivity({ commit }, { activityId, exerciseTaskId }) {
    return Api.createLinkActivity({ activityId, exerciseTaskId })
      .then((response) => {
        commit('setPartnerStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setPartnerStatus', status || 0);
      });
  },
  inviteActivity({ commit }, { activityId, participantExerciseTaskId, participantId }) {
    return Api.inviteActivity({ activityId, participantExerciseTaskId, participantId })
      .then((response) => {
        commit('setPartnerStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setPartnerStatus', status || 0);
      });
  },
  createParticipatingActivities({ commit }, { activityId, exerciseTaskId, sessionId }) {
    return Api.createParticipatingActivities({ activityId, exerciseTaskId, sessionId })
      .then((response) => {
        commit('setPartnerStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setPartnerStatus', status || 0);
      });
  },
  createRegistrationActivity({ commit }, {
    activityId, participantExerciseTaskId, sessionId, raceId,
  }) {
    return Api.createRegistrationActivity({
      activityId, participantExerciseTaskId, sessionId, raceId,
    })
      .then((response) => {
        commit('setPartnerStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setPartnerStatus', status || 0);
      });
  },
  createUploadActivity({ commit }, { activityId, exerciseTaskId, file }) {
    return Api.createUploadActivity({ activityId, exerciseTaskId, file })
      .then((response) => {
        commit('setPartnerStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setPartnerStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

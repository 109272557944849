/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import Api from '@/services/externalevents-api';

const state = {
  exerciseTasksUrl: null,
  exerciseTasksStaus: null,
};

const mutations = {
  setExerciseTasksUrl(state, value) {
    state.exerciseTasksUrl = value;
  },
  setExerciseTasksStaus(state, value) {
    state.exerciseTasksStaus = value;
  },

};

const getters = {
  exerciseTasksUrl: (state) => state.exerciseTasksUrl,
  exerciseTasksStaus: (state) => state.exerciseTasksStaus,
};

const actions = {
  getExerciseTasks({ commit }, participantExerciseId) {
    return Api.getExerciseTasks({ participantExerciseId })
      .then((response) => {
        commit('setExerciseTasksUrl', response.data);
        commit('setExerciseTasksStaus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setExerciseTasksStaus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import api from '@/services/quizes-api';

const state = {
  activity: null,
  activityStatus: null,
};

const mutations = {
  setActivity(state, value) {
    state.activity = value;
  },
  setActivityStatus(state, value) {
    state.activityStatus = value;
  },
};

const getters = {
  activity: (state) => state.activity,
  activityStatus: (state) => state.activityStatus,
};

const actions = {
  getActivity({ commit }, activityId) {
    return api.getActivity({ activityId })
      .then((response) => {
        commit('setActivityStatus', response.status);
        if (response.status === 200) {
          commit('setActivity', response.data);
        } else {
          commit('setActivity', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setActivityStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

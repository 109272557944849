<template>
  <div class="participants">
    <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="!isMobileApp" class="participants__menu">
      <Menu />
      </div>
      <div class="divider"></div>
      <div v-if="!showBannerAccounts" class="participants-banner__wrapper" :class="{'participants-banner__wrapper_hide': !bannerAccounts}">
        <SettingUpGuide
          class="participants-banner"
          :state="bannerAccounts"
          v-bind="settingAccountsOptions"
          @click="$router.push({ name: 'SettingsProfile' }).catch(() => {})"
          @hide="bannerAccountsClose()"
        />
      </div>
      <div class="participants__intro">
        <div class="participants-search">
          <div class="participants-search__item">
            <Field
              label="Участник"
              label-for="participant"
            >
              <Input
                v-model="participantSearch"
                placeholder="Как зовут участника?"
                id="participant"
                name="participant"
                noBorder
              />
            </Field>
          </div>
          <div class="participants-search__item">
            <Field
              label="Местоположение"
              label-for="location"
            >
              <Input
                v-model="location"
                placeholder="Где тренируется?"
                id="location"
                name="location"
                noBorder
              />
            </Field>
            <Button
              variant="primary"
              size="lg"
              tag="a"
              mobile
              iconLeft="search"
              text="Найти"
              @click="getParticipantsData"
            />
          </div>
        </div>
        <div class="participants-search__mobile-wrapper">
          <div @click="filtersModalOpen" class="participants-search__mobile">
            <div class="participants-search__mobile-text">Начать поиск участника</div>
            <div class="participants-search__mobile-btn">
              <Icon name="search" size="xs" />
            </div>
          </div>
        </div>
        <div class="participants__wrapper">
          <div class="participants__wrapper-main">
            <div v-if="items.length > 0" class="participants__wrapper-count">
              Показаны {{ itemsOffset + 1 }} – {{itemsCount + itemsOffset === itemsTotal ? itemsTotal : itemsOffset + 10 }} из {{ itemsTotal }} записей
            </div>
            <div v-if="isFiltersLoaded" class="participants__wrapper-filters">
              <Filters
                :options="filters"
                @change="onFilterChange"
                @reset="onFilterReset"
              />
            </div>
            <table class="participants__table">
              <thead>
                <tr>
                  <th>Участник</th>
                  <th></th>
                  <th>Город</th>
                  <th>Команда</th>
                  <th>Вклад в общую цель</th>
                  <th>
                      Индекс ОФП
                      <Icon name="info-circle" size="xs" id="ofptTooltip" />
                      <Dropdown
                        triggerId="ofptTooltip"
                        :placement="$root.isDesktop ? 'top-end' : 'top'"
                        listeners="click"
                        hide-overlay
                        auto-width
                      >
                      <template slot="items">
                        <div class="participants__table-tooltip">
                          Персональный индикатор физической активности, учитывающий нормативы отечественной практики общей физической подготовки (ОФП) и рекомендации ВОЗ.<br/> <span>Обнуляется раз в неделю</span>.
                        </div>
                      </template>
                    </Dropdown>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr @click="participantRowClick(participant)" :class="{'active' : participant.isCurrent, 'alternate-row': index % 2 !== 0 }" v-for="(participant, index) in items" :key="index">
                  <td>
                    <div class="participants__table-info">
                      <div class="participants__table-img">
                        <div class="participants__table-img__inner" :style="{ 'background-image': `url(${getAvatarImage(participant.avatar)})`, 'background-color': participant.avatar ? '#c7c0c0' : ''}">
                          </div>
                          <div class="participants__table-level">{{ participant.level }} ур.</div>
                        </div>
                      <div class="participants__table-personal">
                        <div class="participants__table-name">{{ participant.firstName }} {{ participant.lastName }}</div>
                        <div class="participants__table-age">
                          <div :class="{'male' : participant.gender === 'Male', 'female' : participant.gender === 'Female'}" v-if="participant.gender">
                            <Icon v-if="participant.gender === 'Male'" name="male" size="xs" />
                            <Icon v-if="participant.gender === 'Female'" name="female" size="xs" />
                          </div>
                          <span v-if="participant.birthday">{{ calculateAge(participant.birthday) }}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div @click.stop="buttonClick"  v-if="Object.keys(participant.accounts).length" class="participants__table-btn">
                      <a v-if="Object.keys(participant.accounts).length> 0 && participant.accounts.vk && !participant.accounts.telegram" :href="participant ? participant.accounts.vk : ''" target="_blank" class="participant__dropdown-link link">
                        <Icon name="vk" size="xs" />
                      </a>
                      <a v-else-if="Object.keys(participant.accounts).length> 0 && participant.accounts.telegram && !participant.accounts.vk" :href="`https://t.me/${participant.accounts.telegram}`" target="_blank" class="participant__dropdown-link link">
                        <Icon name="telegram" size="xs" />
                      </a>
                      <div v-else @click.stop="buttonClick" :id="participant.participantId" class="participants__table-btn__inner">
                        <Icon name="chat-bubble" size="m" />
                        <Dropdown
                          :triggerId="participant.participantId"
                          :placement="$root.isDesktop ? 'top-end' : 'top'"
                          listeners="hover"
                          hide-overlay
                          auto-width
                        >
                          <template slot="items">
                            <div class="base-dropdown__item participant__dropdown">
                              <p class="participant__dropdown-title">Написать участнику</p>
                              <ul class="participant__dropdown-list">
                                <li class="participant__dropdown-item">
                                  <a :href="participant ? participant.accounts.vk : ''" target="_blank" class="participant__dropdown-link">
                                    <span>Вконтакте</span>
                                    <Icon name="vk" size="xs" />
                                  </a>
                                </li>
                                <li class="participant__dropdown-item">
                                  <a :href="`https://t.me/${participant.accounts.telegram}`" target="_blank" class="participant__dropdown-link">
                                    <span>Telegram</span>
                                    <Icon name="telegram" size="xs" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </template>
                        </Dropdown>
                      </div>
                    </div>
                    <div @click.stop="buttonClick" class="participants__table-btn participants__table-btn_disabled" v-else>
                      <Icon name="chat-bubble" size="m" />
                    </div>
                  </td>
                  <td>
                    <div class="participants__table-city">
                      {{ participant && participant.city ? participant.city : '--' }}
                    </div>
                  </td>
                  <td>
                    <div class="participants__table-team">
                      <div class="participants__table-team__logo">
                        <img v-if="participant.team && participant.team.logo" img :src="participant.team.logo" alt="" />
                        <Icon v-else name="user" size="xs" />
                      </div>
                      <span class="participants__table-team__name" :class="{'team' : participant.team}">{{ participant && participant.team ? participant.team.name : 'Без команды'  }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="participants__table-goal">
                      {{ participant && participant.goalContribution ? (participant.goalContribution / 1000).toFixed(0) : 0 }} <span>км</span>
                    </div>
                  </td>
                  <td>
                    <div class="participants__table-ofp" :class="{'participants__table-ofp--green': participant.sportIndex && participant.sportIndex > 150}">
                      {{ participant && participant.sportIndex ? participant.sportIndex : 0 }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="participants__items">
              <div class="participants__item" :class="{'active' : participant.isCurrent}" @click.stop="participantRowClick(participant)" v-for="(participant, index) in items" :key="index">
                <div class="participants__item-main">
                  <div class="participants__item-header">
                    <div class="participants__item-header__info">
                      <div class="participants__item-img">
                        <div class="participants__table-img">
                          <div class="participants__table-img__inner" :style="{ 'background-image': `url(${getAvatarImage(participant.avatar)})`, 'background-color': participant.avatar ? '#c7c0c0' : ''}">
                          </div>
                          <div class="participants__table-level">{{ participant.level }} ур.</div>
                        </div>
                      </div>
                      <div class="participants__item-header__block">
                        <div class="participants__table-name">{{ participant.firstName }} {{ participant.lastName }}</div>
                        <div class="participants__item-row">
                          <div class="participants__item-info">
                            <div class="participants__table-age">
                              <div :class="{'male' : participant.gender === 'Male', 'female' : participant.gender === 'Female'}" v-if="participant.gender">
                                <Icon v-if="participant.gender === 'Male'" name="male" size="xs" />
                                <Icon v-if="participant.gender === 'Female'" name="female" size="xs" />
                              </div>
                              <span v-if="participant.birthday">{{ calculateAge(participant.birthday) }}</span>
                              <span v-if="participant.birthday && participant.city" class="dot"></span>
                            </div>
                            <span class="participants__table-city" v-if="participant.city">{{ participant && participant.city ? participant.city : '' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="participants__table-btn" @click.stop="buttonClick"  v-if="Object.keys(participant.accounts).length > 0">
                      <a v-if="Object.keys(participant.accounts).length > 0 && participant.accounts.vk && !participant.accounts.telegram" :href="participant ? participant.accounts.vk : ''" target="_blank" class="participant__dropdown-link link">
                        <Icon name="vk" size="xs" />
                      </a>
                      <a v-else-if="Object.keys(participant.accounts).length > 0 && participant.accounts.telegram && !participant.accounts.vk" :href="`https://t.me/${participant.accounts.telegram}`" target="_blank" class="participant__dropdown-link link">
                        <Icon name="telegram" size="xs" />
                      </a>
                      <div v-else class="participants__table-btn" @click.stop="paticipantModalOpen(participant)">
                        <Icon name="chat-bubble" size="m" />
                      </div>
                    </div>
                    <div @click.stop="buttonClick" class="participants__table-btn participants__table-btn_disabled" v-else>
                      <Icon name="chat-bubble" size="m" />
                    </div>
                  </div>
                  <div class="participants__item-footer">
                    <div class="participants__table-team">
                      <div class="participants__table-team__logo">
                        <img v-if="participant.team && participant.team.logo" img :src="participant.team.logo" alt="" />
                        <Icon v-else name="user" size="xs" />
                      </div>
                      <span class="participants__table-team__name" :class="{'team' : participant.team}">{{ participant && participant.team ? participant.team.name : 'Без команды'  }}</span>
                    </div>
                    <div class="participants__item-icons">
                      <div class="participants__item-icon">
                        <Icon name="pulse" size="xs" />
                      <div class="participants__table-ofp" :class="{'participants__table-ofp--green': participant.sportIndex && participant.sportIndex > 150}">
                        {{ participant && participant.sportIndex ? participant.sportIndex : 0 }}
                      </div>
                      </div>
                      <div class="participants__item-icon">
                        <Icon name="goal" size="xs" />
                        <div class="participants__table-goal">
                          {{ participant && participant.goalContribution ? (participant.goalContribution / 1000).toFixed(0) : 0 }} <span>км</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="items.length === 0" class="participants__empty">Нет участников</div>
            <Button
              v-if="items.length > 0 && currentPage !== totalPages"
              variant="secondary"
              size="lg"
              class="participants__more"
              mobile
              wide
              @click="onMore"
            >
              Показать ещё
            </Button>
            <Pagination
              v-if="items.length > 0 && totalPages > 1"
              :currentPage="currentPage"
              :totalPages="totalPages"
              class="participants__pagination"
              @prev-page="onChangePage(currentPage - 1)"
              @next-page="onChangePage(currentPage + 1)"
              @go-page="onChangePage"
            ></Pagination>
          </div>
        </div>
      </div>

      <Modal
        :closable="true"
        name="filtersModal"
        title="Поиск участника"
        :active="filtersModal"
        :class="{ 'for-mobile-app show-modal-close': isMobileApp }"
        @close="filtersModalClose"
      >
        <div class="modal__search">
          <div class="modal__search-item">
            <Field
              label="Участник"
              label-for="participant"
            >
              <Input
                v-model="participantSearch"
                placeholder="Как зовут участника?"
                id="participant"
                name="participant"
                noBorder
              />
            </Field>
          </div>
          <div class="modal__search-item">
            <Field
              label="Местоположение"
              label-for="location"
            >
              <Input
                v-model="location"
                placeholder="Где тренируется?"
                id="location"
                name="location"
                noBorder
              />
            </Field>
            </div>
            <Button
              variant="primary"
              size="lg"
              wide
              iconLeft="search"
              text="Найти"
              @click="getParticipantsData"
            />
        </div>
    </Modal>

    <Modal
      :closable="true"
      name="paticipantModal"
      title="Написать участнику"
      :active="paticipantModal"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="paticipantModalClose"
    >
      <ul class="participant__dropdown-list">
        <li class="participant__dropdown-item">
          <a :href="accounts ? accounts.vk : ''" target="_blank" class="participant__dropdown-link">
            <span>Вконтакте</span>
            <Icon name="vk" size="xs" />
          </a>
        </li>
        <li class="participant__dropdown-item">
          <a :href="`https://t.me/${accounts ? accounts.telegram : ''}`" target="_blank" class="participant__dropdown-link">
            <span>Telegram</span>
            <Icon name="telegram" size="xs" />
          </a>
        </li>
      </ul>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Field from '@rr-component-library/field/src/main';
import Input from '@rr-component-library/input/src/main';
import Menu from '@/components/Menu/Menu.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import Avatars from '@/assets/data/avatars';
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import SettingUpGuide from '@/components/SettingUpGuide/SettingUpGuide.vue';
import Filters from '@/components/Filters/Filters.vue';

export default {
  name: 'Participants',
  components: {
    Menu,
    Field,
    Input,
    Pagination,
    Dropdown,
    Filters,
    SettingUpGuide,
  },
  data() {
    return {
      location: '',
      participantSearch: '',
      search: '',
      currentPage: 1,
      itemsPerPage: 10,
      totalPages: 0,
      items: [],
      itemsTotal: 0,
      itemsOffset: 0,
      itemsCount: 0,
      avatar: '',
      showMoreOffset: 0,
      filtersModal: false,
      paticipantModal: false,
      activeFilters: {},
      showMarketFilters: ['teamIds', 'gender', 'age'],
      filters: {
        teamIds: [],
        gender: [],
        age: [],
      },
      genders: [
        { id: 'Male', name: 'Мужчины' },
        { id: 'Female', name: 'Женщины' },
      ],
      age: [16, 100],
      isFiltersLoaded: false,
      sortItems: [
        { id: '-', text: 'В тренде', active: true },
        { id: 'createdOn', text: 'Новые' },
        { id: 'price', text: 'По цене', descending: false },
      ],
      accounts: {},
      settingAccountsOptions: {
        icon: 'chat-bubble',
        text: 'Добавьте ссылки на свои профили в социальных сетях, чтобы с вами могли связаться',
        actionText: 'Добавить соц. сети',
        actionIconLeft: '',
        storageKey: 'hideTreainingBanner',
      },
      bannerAccounts: true,
    };
  },
  watch: {
    themeDone(val) {
      if (val) {
        this.setupFiltersAndSorting();
      }
    },
    isFiltersLoaded(val) {
      if (val) {
        this.getParticipants({
          offset: 0, count: this.itemsPerPage, filters: this.getFilters(),
        })
          .then(() => {
            this.items = this.participants ? this.participants.values : [];
            this.totalPages = Math.ceil((this.participants ? this.participants.total : 0) / this.itemsPerPage);
          });
      }
    },
  },
  computed: {
    ...mapGetters('user', ['isMobileApp']),
    ...mapGetters('settings', ['themeDone']),
    ...mapGetters('communications', ['participants', 'profileCommunications']),
    ...mapGetters('market', ['promotions', 'priceRange', 'promotionTypes', 'partners', 'categories']),
    ...mapGetters('teams', ['teams']),

    isMarketPage() {
      return this.$route.name === 'Market';
    },
    showOffset() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    // showMarketFilters() {
    //   return this.options ? this.options.showMarketFilters === true : false;
    // },
    enabledMarketFilters() {
      return this.options ? this.options.enabledMarketFilters || [] : [];
    },
    showMarketSorting() {
      return this.options ? this.options.showMarketSorting === true : false;
    },
    enabledMarketSorting() {
      return this.options ? this.options.enabledMarketSorting || [] : [];
    },
    getRewardsTitle() {
      return this.resources ? this.resources.getRewardsTitle || '' : '';
    },
    getRewardsSubtitle() {
      return this.resources ? this.resources.getRewardsSubtitle || '' : '';
    },
    getRewardsNote() {
      return this.resources ? this.resources.getRewardsNote || '' : '';
    },
    showBannerAccounts() {
      const accounts = this.profileCommunications && this.profileCommunications.accounts ? this.profileCommunications.accounts : {};
      return Object.keys(accounts).length > 0;
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('communications', ['getParticipants', 'getProfileCommunications']),
    ...mapActions('market', ['getPromotions', 'getPriceRange', 'getPromotionTypes', 'getPartners', 'getCategories']),
    ...mapActions('teams', ['getTeams']),
    bannerAccountsClose() {
      this.bannerAccounts = false;
    },
    onHashChange() {
      if (this.$route.name !== 'Participants') {
        window.removeEventListener('hashchange', this.onHashChange);
        return;
      }
      const hash = window.location.hash.split('=');
      if (hash[0] === '#!showFilters') this.showFilters(hash);
      else if (hash[0] === '#!showParticipant') this.showParticipant(hash);
      else {
        this.filtersModal = false;
        this.paticipantModal = false;
      }
    },
    buttonClick() {
      console.log();
    },
    onFilterChange(filters) {
      const result = {};
      const keys = Object.keys(filters);
      for (let i = 0; i < keys.length; i += 1) {
        if (filters[keys[i]].val !== null) result[keys[i]] = filters[keys[i]];
      }
      this.activeFilters = result;
      this.getParticipantsData();
    },
    setupFiltersAndSorting() {
      if (this.enabledMarketFilters) {
        const keys = Object.keys(this.filters);
        for (let i = 0; i < keys.length; i += 1) {
          if (this.enabledMarketFilters.includes(keys[i])) {
            delete this.filters[keys[i]];
          }
        }
      }
    },
    setTeamsFilter() {
      if (this.teams && this.filters.teamIds) {
        const teams = this.teams ? this.teams.values : [];
        const filters = [];
        for (let i = 0; i < teams.length; i += 1) {
          filters.push({ id: teams[i].teamId, name: teams[i].name });
        }
        this.filters.teamIds = filters;
      }
    },
    setGenderFilter() {
      if (this.genders && this.filters.gender) {
        const filters = [];
        for (let i = 0; i < this.genders.length; i += 1) {
          filters.push({ id: this.genders[i].id, name: this.genders[i].name });
        }
        this.filters.gender = filters;
      }
    },
    setAgeFilter() {
      if (this.age && this.filters.age) {
        const max = this.age.max !== undefined ? this.age.max : 100;

        this.filters.age.push({ id: 'min', name: 'min', value: 16 });
        this.filters.age.push({ id: 'max', name: 'max', value: max });
        this.filters.age.push({ id: 'from', name: 'from', value: 16 });
        this.filters.age.push({ id: 'to', name: 'to', value: max });
      }
    },
    getFilters() {
      const result = {};
      if (this.activeFilters.teamIds) {
        result.teamIds = this.activeFilters.teamIds.val || [];
      }
      if (this.activeFilters.gender) {
        result.gender = this.activeFilters.gender.val || [];
      }
      if (this.activeFilters.partner) {
        result.partnerId = this.activeFilters.partner.val || '';
      }
      if (this.activeFilters.age) {
        result.ageFrom = this.activeFilters.age.val.from || '';
        result.ageTo = this.activeFilters.age.val.to || '';
      }
      return result;
    },
    paticipantModalOpen(participant) {
      window.location.hash = '#!showParticipant=true';
      this.accounts = participant.accounts;
      console.log('paticipantModalOpen', this.accounts);
    },
    paticipantModalClose() {
      window.location.hash = '#!showParticipant=false';
    },
    onFilterReset() {
      this.activeFilters = {};
      this.getParticipantsData();
    },
    filtersModalOpen() {
      window.location.hash = '#!showFilters=true';
    },
    filtersModalClose() {
      window.location.hash = '#!showFilters=false';
    },
    calculateAge(birthDate) {
      const currentDate = new Date();
      const birthYear = new Date(birthDate).getFullYear();
      const age = currentDate.getFullYear() - birthYear;
      let yearsStr = 'лет';
      if (age % 10 === 1 && age % 100 !== 11) {
        yearsStr = 'год';
      } else if (age % 10 >= 2 && age % 10 <= 4 && (age % 100 < 10 || age % 100 >= 20)) {
        yearsStr = 'года';
      }
      return `${age} ${yearsStr}`;
    },
    participantRowClick(item) {
      const { participantId } = item;
      this.$router.push({ name: 'ParticipantItem', params: { participantId } });
    },
    getAvatarImage(avatar) {
      if (!avatar) return '';
      if (!avatar.type) return '';
      if (avatar.type === 'custom') {
        return avatar.url || '';
      }
      const found = Avatars.filter((i) => i.id === avatar.type);
      if (found.length === 0) return '';
      return found[0].img || '';
    },
    onChangePage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.getParticipants({
        searchString: this.participantSearch,
        searchByCityString: this.location,
        offset: this.showOffset,
        count: this.itemsPerPage,
        filters: this.getFilters(),
      })
        .then(() => {
          this.items = this.participants ? this.participants.values : [];
          this.itemsTotal = this.participants ? this.participants.total : 0;
          this.itemsOffset = this.participants ? this.participants.offset : 0;
          this.itemsCount = this.participants ? this.participants.count : 0;
        });
    },
    onMore() {
      const showMoreOffset = this.showMoreOffset + this.itemsPerPage;
      if (showMoreOffset > this.participants.total - 1) {
        this.currentPage = 0;
        return;
      }
      this.showMoreOffset = showMoreOffset;
      this.currentPage = Math.ceil((this.showMoreOffset + this.itemsPerPage) / this.itemsPerPage);
      this.getParticipants({
        searchString: this.participantSearch, searchByCityString: this.location, offset: this.showOffset, count: this.itemsPerPage, filters: this.getFilters(),
      })
        .then(() => {
          this.items.push(...(this.participants ? this.participants.values : []));
          setTimeout(() => {
            this.getParticipants({
              searchString: this.participantSearch, searchByCityString: this.location, offset: this.showOffset, count: this.itemsPerPage, filters: this.getFilters(),
            });
          }, 250);
        });
    },
    getParticipantsData() {
      this.getParticipants({
        searchString: this.participantSearch, searchByCityString: this.location, offset: 0, count: this.itemsPerPage, filters: this.getFilters(),
      })
        .then(() => {
          this.items = this.participants ? this.participants.values : [];
          this.totalPages = Math.ceil(this.participants.total / this.itemsPerPage);
          this.itemsTotal = this.participants ? this.participants.total : 0;
          this.filtersModalClose();
        }).catch((err) => {
          console.log('err', err);
        });
    },
    setPartnerFilter() {
      if (this.partners && this.filters.partner) {
        for (let i = 0; i < this.partners.length; i += 1) {
          this.filters.partner.push({ id: this.partners[i].partnerId, name: this.partners[i].name });
        }
      }
    },
    setPriceFilter() {
      if (this.priceRange && this.filters.price) {
        const max = this.priceRange.max !== undefined ? this.priceRange.max : 100;
        this.filters.price.push({ id: 'min', name: 'min', value: 1 });
        this.filters.price.push({ id: 'max', name: 'max', value: max });
        this.filters.price.push({ id: 'from', name: 'from', value: 1 });
        this.filters.price.push({ id: 'to', name: 'to', value: max });
      }
    },
    showFilters(hash) {
      if (hash[1] === 'true') this.filtersModal = true;
      else {
        this.filtersModal = false;
        this.clearHash();
      }
    },
    showParticipant(hash) {
      if (hash[1] === 'true') this.paticipantModal = true;
      else {
        this.paticipantModal = false;
        this.clearHash();
      }
    },
    clearHash() {
      setTimeout(() => { window.location.hash = ''; }, 100);
    },
  },
  mounted() {
    this.getParticipantsData();
    this.getProfile();
    this.getProfileCommunications();
    this.getBalance();
    window.addEventListener('hashchange', this.onHashChange);
    this.onHashChange();
    // this.setupFiltersAndSorting();
    this.getTeams({ offset: 0, count: 100 })
      .then(() => {
        // this.setCategoriesFilter();
        this.setTeamsFilter();
        this.setGenderFilter();
        this.setAgeFilter();

        this.isFiltersLoaded = true;
      });

    // this.getTeamsFromServer();
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import "./Participants";
  @import "./ParticipantItem";
</style>

<template>
  <div class="quiz">
    <Modal
      :active="active"
      name="QuizModal"
      :class="{ 'for-mobile-app': isMobileApp }"
      :closable="false"
      @close.prevent="$emit('close')"
    >
      <div class="quiz__back" v-show="!isMobileApp">
        <Button
          variant="clear"
          size="lg"
          shape="square"
          iconLeft="left"
          mobile
          @click="$emit('back')"
        ></Button>
      </div>
      <div class="quiz__container">
        <div class="quiz__loader" v-if="!scriptLoaded">
          <Loader size="24" />
        </div>
        <div
          ref="quizBlock"
          class="b-quizgo-quiz-block"
          :class="`js-quizgo-quiz-block-${quizId}`">
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>

export default {
  name: 'QuizModal',
  props: {
    active: Boolean,
    options: Object,
    isMobileApp: Boolean,
  },
  data() {
    return {
      quizes: [],
      scriptLoaded: false,
    };
  },
  computed: {
    quizId() {
      return this.options ? this.options.quizId : null;
    },
    participantExerciseTaskId() {
      return this.options ? this.options.participantExerciseTaskId : null;
    },
    activityId() {
      return this.options ? this.options.activityId : null;
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.initQuiz();
      } else {
        this.removeQuiz();
      }
    },
  },
  methods: {
    onQuizGoLoaded(widget) {
      widget.setCustomFields({
        participantExerciseTaskId: this.participantExerciseTaskId,
        activityId: this.activityId,
      });
      widget.setRedirectUrl(`${window.location.href}${this.isMobileApp ? '#closed' : '&quizEnd=true'}`);
      window.onQuizGoCompleted = () => {
        window.location.href = `${window.location.href}${this.isMobileApp ? '#closed' : '&quizEnd=true'}`;
      };
    },
    onHashChange() {
      if (this.active === false) {
        return;
      }
      if (this.isMobileApp) {
        if (window.location.hash === '#closed') this.$emit('quizEnd');
        return;
      }
      const hash = window.location.hash.split(this.isMobileApp ? '#' : '&');
      for (let i = 0; i < hash.length; i += 1) {
        if (hash[i] === 'quizEnd=true') {
          hash.splice(i);
          window.location.hash = hash.join('&');
          this.$emit('quizEnd');
          break;
        }
      }
    },
    initQuiz() {
      if (this.quizes.includes(this.quizId)) {
        this.scriptLoaded = true;
        window.document.dispatchEvent(new Event('DOMContentLoaded', {
          bubbles: true,
          cancelable: true,
        }));
        return;
      }
      this.quizes.push(this.quizId);
      const script = document.createElement('script');
      script.type = 'application/javascript';
      script.defer = true;
      script.src = `//panel.quizgo.ru/common?q=${this.quizId}`;
      script.onload = () => {
        setTimeout(() => {
          this.scriptLoaded = true;
          window.document.dispatchEvent(new Event('DOMContentLoaded', {
            bubbles: true,
            cancelable: true,
          }));
        }, 1000);
      };
      this.resetQuizBlock();
      setTimeout(() => { document.head.appendChild(script); }, 50);
    },
    removeQuiz() {
      this.scriptLoaded = false;
      this.resetQuizBlock();
    },
    resetQuizBlock() {
      const { quizBlock } = this.$refs;
      if (quizBlock) quizBlock.innerHTML = '';
    },
  },
  mounted() {
    window.onQuizGoLoaded = this.onQuizGoLoaded;
    window.addEventListener('hashchange', this.onHashChange);
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import './QuizModal';
</style>

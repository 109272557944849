<template>
  <div class="community">
    <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="!isMobileApp" class="community__menu">
      <Menu />
      <div class="divider"></div>
    </div>

    <div class="community__intro">
      <div class="community__info">
        <div
          class="info__image"
          :style="`background-image: url('${contentRafiki}');`"
        ></div>
        <div class="info__text">
          <div class="info__title" v-html="title"></div>
          <div class="info__subtitle" v-html="subtitle"></div>
          <div class="info__subscribe">
            <div id="vk_subscribe"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div
      id="communityItems"
      class="community__items"
      :class="{ 'community__items_no-sorting': !showCommunitySorting }"
      :style="{ 'min-height': communityItemsHeight || 'auto' }"
    >
      <div>
        <Sort
          v-if="showCommunitySorting"
          :items="sortItems"
          @selected="onSortItem"
        />
      </div>
      <div>
        <div
          v-for="(item, index) in items"
          :key="index"
          class="community__article"
        >
          <div :id="`my_article${index+1}`"></div>
        </div>
      </div>
      <div>
        <Button
          v-if="items.length > 0 && currentPage !== totalPages"
          variant="secondary"
          size="lg"
          @click="onMore"
          class="community__more"
          mobile
          wide
        >
          Показать ещё
        </Button>
        <Pagination
          v-if="items.length > 0 && totalPages > 1"
          :currentPage="currentPage"
          :totalPages="totalPages"
          class="community__pagination"
          @prev-page="onChangePage(currentPage - 1)"
          @next-page="onChangePage(currentPage + 1)"
          @go-page="onChangePage"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Menu from '@/components/Menu/Menu.vue';
import Sort from '@/components/Sort/Sort.vue';
import Pagination from '@/components/Pagination/Pagination.vue';

export default {
  name: 'Community',
  components: {
    Menu,
    Sort,
    Pagination,
  },
  data() {
    return {
      title: 'Статьи, видео, советы для участников',
      subtitle: 'от нашего спорт-сообщества',
      items: [],
      currentPage: 1,
      itemsPerPage: 9,
      totalPages: 0,
      showMoreOffset: 0,
      sortItems: [
        { id: '-', text: 'В тренде', active: true },
        { id: 'createdOn', text: 'Новые' },
      ],
      apiId: 8118913,
      communityItemsHeight: '',
      creatingSubscribe: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['themeDone', 'theme', 'options', 'resources']),
    ...mapGetters('community', ['community', 'articles', 'responseStatus']),
    ...mapGetters('user', ['isMobileApp']),
    showOffset() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    showCommunitySorting() {
      return this.options ? this.options.showCommunitySorting === true : false;
    },
    contentRafiki() {
      return this.resources ? this.resources.contentRafiki || '' : '';
    },
    communityId() {
      return this.community ? this.community.communityId : '';
    },
  },
  watch: {
    themeDone(val) {
      if (val) {
        this.addVkScript();
      }
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('community', ['getCommunity', 'getArticles']),
    onChangePage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.getArticles({
        communityId: this.communityId, offset: this.showOffset, count: this.itemsPerPage,
      })
        .then(() => {
          this.items = this.articles ? this.articles.values : [];
          this.totalPages = Math.ceil((this.articles ? this.articles.total : 0) / this.itemsPerPage);
          setTimeout(() => { this.createArticles(); }, 250);
        });
    },
    onMore() {
      const showMoreOffset = this.showMoreOffset + this.itemsPerPage;
      if (showMoreOffset > this.articles.total - 1) {
        this.currentPage = 0;
        return;
      }
      this.showMoreOffset = showMoreOffset;
      this.currentPage = Math.ceil((this.showMoreOffset + this.itemsPerPage) / this.itemsPerPage);
      this.getArticles({
        communityId: this.communityId, offset: this.showMoreOffset, count: this.itemsPerPage,
      })
        .then(() => {
          this.items.push(...(this.articles ? this.articles.values : []));
          setTimeout(() => { this.createArticles(); }, 250);
        });
    },
    onSortItem(index) {
      if (this.sortItems.length === 0) return;
      if (index >= 0 && index < this.sortItems.length) {
        const items = [];
        let needUpdate = false;
        for (let i = 0; i < this.sortItems.length; i += 1) {
          const item = this.sortItems[i];
          if (i !== index) {
            item.active = false;
            if (item.descending !== undefined) item.descending = false;
          } else if (item.active === true && item.descending !== undefined) {
            item.descending = !item.descending;
            needUpdate = true;
          } else if (item.active !== true) {
            item.active = true;
            needUpdate = true;
          }
          items.push(item);
        }
        this.sortItems = items;
        if (needUpdate) {
          this.items = [];
          this.currentPage = 1;
          this.totalPages = 0;
          this.getArticles({
            communityId: this.communityId, offset: 0, count: this.itemsPerPage, sort: this.getSort(),
          })
            .then(() => {
              this.items = this.articles ? this.articles.values : [];
              this.totalPages = Math.ceil((this.articles ? this.articles.total : 0) / this.itemsPerPage);
              setTimeout(() => { this.createArticles(); }, 250);
            });
        }
      }
    },
    addVkScript() {
      const scriptId = 'scount-vk-api';
      if (document.getElementById(scriptId) !== null) {
        setTimeout(() => { this.createWidgets(); }, 500);
        return;
      }
      const script = document.createElement('script');
      script.id = scriptId;
      script.async = true;
      script.onload = setTimeout(() => { this.createWidgets(); }, 500);
      script.src = 'https://vk.com/js/api/openapi.js?169';
      document.head.appendChild(script);
    },
    createWidgets() {
      this.initApi();
      setTimeout(() => {
        this.getCommunity()
          .then(() => {
            if (this.responseStatus === 200) {
              this.createSubscribe();
              this.loadArticles();
            }
          });
      }, 500);
    },
    initApi() {
      window.VK.init({ apiId: this.apiId });
    },
    createSubscribe() {
      if (!this.community) return;
      if (this.creatingSubscribe) return;
      const communityId = this.community.externalId ? parseInt(this.community.externalId, 10) : 0;
      if (!communityId || !Number.isFinite(communityId)) return;
      if (document.getElementById('vk_subscribe').innerHTML !== '') return;
      this.creatingSubscribe = true;
      window.VK.Widgets.Subscribe('vk_subscribe', { soft: 1 }, -communityId);
      setTimeout(() => { this.creatingSubscribe = false; }, 500);
    },
    loadArticles() {
      this.items = [];
      this.currentPage = 1;
      this.totalPages = 0;
      this.getArticles({
        communityId: this.communityId, offset: 0, count: this.itemsPerPage,
      })
        .then(() => {
          this.items = this.articles ? this.articles.values : [];
          this.totalPages = Math.ceil((this.articles ? this.articles.total : 0) / this.itemsPerPage);
          setTimeout(() => { this.createArticles(); }, 250);
        });
    },
    createArticles() {
      const items = Object.assign([], this.items);
      if (items.length === 0) return;
      const communityItems = document.getElementById('communityItems');
      if (!communityItems) return;
      this.communityItemsHeight = `${communityItems.offsetHeight}px`;
      this.items = [];

      setTimeout(() => {
        this.items = items;
        for (let i = 0; i < this.items.length; i += 1) {
          const article = this.getArticleIdFromUrl(this.items[i].link);
          window.VK.Widgets.Article(`my_article${i + 1}`, article, { width: this.getArticleWidth(this.items[i]) });
        }
        setTimeout(() => { this.communityItemsHeight = ''; }, 2000);
      }, 100);
    },
    getArticleIdFromUrl(url) {
      if (!url) return '';
      const index = url.indexOf('@');
      if (index >= 0) return url.slice(index);
      return '';
    },
    getArticleWidth(article) {
      if (!article) return 328;
      if (article.isMain === true) {
        return this.$root.isMobile ? 328 : 960;
      }
      return this.$root.isMobile ? 328 : 470;
    },
    getSort() {
      const result = {};
      const found = this.sortItems.filter((i) => i.active === true);
      if (found.length === 0) return {};
      if (found[0].id !== '-') result.sortBy = found[0].id;
      if (found[0].id === 'createdOn') result.descending = true;
      if (found[0].descending !== undefined) result.descending = found[0].descending === true;
      return result;
    },
  },
  mounted() {
    this.getProfile();
    this.getBalance();
    if (this.themeDone) {
      this.addVkScript();
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "./Community";
</style>

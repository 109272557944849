/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import api from '@/services/sportactivities-api';

const state = {
  statistic: null,
  trainings: null,
  indexes: null,
  trainingsStatus: null,
};

const mutations = {
  setStatistic(state, value) {
    state.statistic = value;
  },
  setTrainings(state, value) {
    state.trainings = value;
  },
  setIndexes(state, value) {
    state.indexes = value;
  },
  setTrainingsStatus(state, value) {
    state.trainingsStatus = value;
  },
};

const getters = {
  statistic: (state) => state.statistic,
  trainings: (state) => state.trainings,
  sportIndexes: (state) => state.indexes,
  trainingsStatus: (state) => state.trainingsStatus,
};

const actions = {
  getStatistic({ commit }, { startFrom, startTo }) {
    return api.getStatistic({
      startFrom, startTo,
    })
      .then((response) => {
        commit('setTrainingsStatus', response.status);
        if (response.status === 200) {
          commit('setStatistic', response.data);
        } else {
          commit('setStatistic', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setTrainingsStatus', status || 0);
      });
  },
  getTrainings({ commit }, {
    startFrom, startTo, offset, count,
  }) {
    return api.getActivities({
      startFrom, startTo, offset, count,
    })
      .then((response) => {
        commit('setTrainingsStatus', response.status);
        if (response.status === 200) {
          commit('setTrainings', response.data);
        } else {
          commit('setTrainings', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setTrainingsStatus', status || 0);
      });
  },
  getIndexes({ commit }) {
    return api.getIndexes()
      .then((response) => {
        commit('setTrainingsStatus', response.status);
        if (response.status === 200) {
          commit('setIndexes', response.data);
        } else {
          commit('setIndexes', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setTrainingsStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

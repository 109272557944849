<template>
  <div class="activities">
    <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="!isMobileApp" class="activities__menu">
      <Menu />
      <div class="divider"></div>
    </div>

    <div v-if="false" class="activities__categories">
      <span
        v-for="(category, i) in categories"
        :key="i"
        class="categories__item"
        v-html="category"
        @click.stop="onSetCategory(i)"
      ></span>
    </div>
    <div v-if="!loading" class="activities__items">
      <ActivityCard
        v-for="(activity, i) in activities"
        :key="i"
        :title="activity.date"
        :items="activity.items"
      ></ActivityCard>

      <div class="activities__loader" v-if="updating">
        <Loader size="24" />
      </div>
    </div>

    <div class="activities__loader-block"></div>

    <TaskDetailsModal
      :active="taskDetailsModal"
      :state="taskDetailsState"
      :modalOptions="taskDetailsOptions"
      :rating="taskRating"
      @close="onTaskDetailsClose"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Menu from '@/components/Menu/Menu.vue';
import ActivityCard from '@/components/ActivityCard/ActivityCard.vue';
import TaskDetailsModal from '@/components/TaskDetailsModal/TaskDetailsModal.vue';
import ActivityCover3 from '@/assets/img/activity-card/updated.svg';
import ActivityCoverXP from '@/assets/img/activity-card/xp.svg';
import AchievementsAll from '@/assets/data/achievements';

export default {
  name: 'Activities',
  components: {
    Menu,
    ActivityCard,
    TaskDetailsModal,
  },
  data() {
    return {
      categories: ['Задания', 'Тренировки', 'Покупки', 'Достижения', 'Другое'],
      achievementsAll: AchievementsAll,
      activities: [],
      offset: 0,
      taskDetailsModal: false,
      taskDetailsOptions: {},
      taskDetailsState: 'inProgress',
      pageScrollY: 0,
      loading: true,
      updating: false,
      loaderBlock: null,
      taskRating: {},
    };
  },

  computed: {
    ...mapGetters('settings', ['theme']),
    ...mapGetters('operations', ['activityOperations']),
    ...mapGetters('exercises', ['task', 'taskPoints', 'taskGuest']),
    ...mapGetters('user', ['isMobileApp']),
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('operations', ['getActivityOperations']),
    ...mapActions('exercises', ['getTask', 'getTaskPoints', 'getTaskGuest']),
    onSetCategory(index) {
      console.log(`Выбор категории: ${this.categories[index] || ''}`);
    },
    onHashChange() {
      if (this.$route.name !== 'Activities') {
        window.removeEventListener('hashchange', this.onHashChange);
        return;
      }
      const hash = window.location.hash.split('=');
      if (hash[0] !== '#!showTask') return;
      if (hash.length === 2) {
        this.loadTask(hash[1]);
      }
      if (hash.length === 3 && hash[1].indexOf('&peId') >= 0) {
        this.loadMyTask(hash[1].split('&peId')[0], hash[2]);
      }
    },
    onTaskDetailsClose() {
      this.taskDetailsModal = false;
      window.scroll(0, this.pageScrollY);
      window.location.hash = '';
    },
    loadTask(id) {
      this.getTaskGuest(id)
        .then(() => {
          if (this.taskGuest) {
            this.pageScrollY = window.scrollY;
            this.taskDetailsState = 'available';
            this.taskDetailsOptions = this.taskGuest;
            this.getTaskPoints(id)
              .then(() => {
                this.taskRating = this.taskPoints;
              });
            this.taskDetailsModal = true;
            return;
          }
          window.location.hash = '';
        });
    },
    loadMyTask(id, peId) {
      this.getTask(peId)
        .then(() => {
          if (this.task) {
            this.pageScrollY = window.scrollY;
            this.taskDetailsState = this.getTaskDetailsState(this.task);
            this.taskDetailsOptions = this.task;
            this.getTaskPoints(id)
              .then(() => {
                this.taskRating = this.taskPoints;
              });
            this.taskDetailsModal = true;
            return;
          }
          window.location.hash = `#!showTask=${id}`;
        });
    },
    setupLoader() {
      const vm = this;
      if (vm.loaderBlock !== null) return;
      if (vm.$route.name !== 'Activities') {
        return;
      }
      const loaders = document.getElementsByClassName('activities__loader-block');
      vm.loaderBlock = loaders[0] || null;
      window.addEventListener('scroll', vm.checkForLoading);
      window.addEventListener('touchmove', vm.checkForLoading);
      vm.checkForLoading();
    },
    checkForLoading() {
      if (this.updating) return;
      if (!this.loaderBlock) return;
      if (this.$route.name !== 'Activities') {
        this.cleanup();
        return;
      }

      if (!this.hasItemsForLoading()) return;

      const bottomCoord = this.loaderBlock.getBoundingClientRect().bottom;
      const height = document.documentElement.clientHeight;
      if (height >= bottomCoord) {
        this.updating = true;
        setTimeout(() => { this.updateActivities(); }, 250);
      }
    },
    cleanup() {
      window.removeEventListener('scroll', this.checkForLoading);
      window.removeEventListener('touchmove', this.checkForLoading);
    },
    hasItemsForLoading() {
      if (!this.activityOperations) return false;
      if (this.activityOperations.count === 0) return false;
      if (this.getActivitiesCount() >= this.activityOperations.total) return false;
      return true;
    },
    getActivitiesCount() {
      let count = 0;
      for (let i = 0; i < this.activities.length; i += 1) {
        count += this.activities[i].items.length;
      }
      return count;
    },
    updateActivities() {
      this.offset += 25;
      this.getActivityOperations({ offset: this.offset, count: 25 })
        .then(() => {
          this.activities.push(...this.getMapppedOperations(this.activityOperations.values));
          this.updating = false;
        });
    },
    hasSameTimeAndType(operation1, operation2) {
      if (!operation1 || !operation2) {
        return false;
      }
      if (operation1.operationType !== 'SportActivity' || operation2.operationType !== 'SportActivity') {
        return false;
      }
      const date1 = dayjs(operation1.operationDate);
      const date2 = dayjs(operation2.operationDate);
      if (!date1.isValid() || !date2.isValid()) {
        return false;
      }
      return date1.isSame(date2, 'second');
    },
    filterOperationsByTime(operations) {
      const filteredOperations = [];
      let previousOperation = null;
      for (let i = 0; i < operations.length; i += 1) {
        const currentOperation = operations[i];
        if (previousOperation && this.hasSameTimeAndType(previousOperation, currentOperation)) {
          console.log(previousOperation, currentOperation);
          previousOperation.title += `, ${this.getFormattedDistance(currentOperation)}`;
        } else {
          filteredOperations.push(currentOperation);
          previousOperation = currentOperation;
        }
      }
      return filteredOperations;
    },
    getMapppedOperations(operations) {
      if (!operations) return [];
      const filteredOperations = this.filterOperationsByTime(operations);
      const days = [];
      let currentDay = { date: '', items: [] };
      let sportActivities = [];
      let lastDate = '';
      for (let i = 0; i < filteredOperations.length; i += 1) {
        const value = filteredOperations[i];
        const date = dayjs.utc(value.operationDate).local();
        if (!date.isValid()) continue;
        const formattedDate = date.format('D MMMM');
        const item = this.getMappedItem(value);
        const currentDate = date.format('YYYY-MM-DD');
        if (currentDate !== lastDate) {
          if (lastDate !== '') {
            days.push(currentDay);
          }
          currentDay = { date: formattedDate, items: [] };
          sportActivities = [];
        }
        if (value.operationType === 'SportActivity') {
          const existingSportActivity = sportActivities.find((activity) => activity.time === item.time);
          if (existingSportActivity) {
            existingSportActivity.titles = existingSportActivity.titles || [];
            existingSportActivity.titles.push(item.title);
            existingSportActivity.title = `Получены новые тренировки: ${new Intl.ListFormat('ru').format(existingSportActivity.titles)}`;
          } else {
            item.titles = [item.title];
            item.title = `Получены новые тренировки: ${item.title}`;
            currentDay.items.push(item);
            sportActivities.push(item);
          }
        } else {
          currentDay.items.push(item);
        }
        lastDate = currentDate;
      }
      if (currentDay.items.length > 0) {
        days.push(currentDay);
      }
      return days;
    },
    pluralizeSteps(count) {
      if (count % 10 === 1 && count % 100 !== 11) {
        return `<b>${count}</b> шаг`;
      } if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
        return `<b>${count}</b> шага`;
      }
      return `<b>${count}</b> шагов`;
    },
    getMappedItem(value) {
      const item = {};
      if (value.operationType === 'TeamParticipant') {
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        item.img = value.attributes.TeamLogo || '';
        if (value.attributes.StateOperation === 'Created') {
          item.title = `Вы присоединились к команде <a href="/my-team">${value.attributes.TeamName}`;
        } else {
          item.title = `Вы покинули команду <span class="team-out">${value.attributes.TeamName}</span>`;
          item.isTaskRejected = true;
        }
        return item;
      }
      if (value.operationType === 'Purchase') {
        item.img = value.attributes.ProductMobileImageUrl || '';
        item.isInternalImg = false;
        const rewardLink = `/market/reward?id=${value.attributes.PromotionId || ''}`;
        const mobileRewardLink = `/goPage?name=RewardDetails&path=${encodeURIComponent(rewardLink)}`;
        item.title = `Вы купили <a href="${this.isMobileApp ? mobileRewardLink : rewardLink}">${value.attributes.ProductName}</a>`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        item.coins = -Math.round(value.attributes.Amount);
        return item;
      }
      if (value.operationType === 'ParticipantExercise') {
        if (!['RejectedManually', 'Rejected'].includes(value.attributes.StateOperation)) {
          item.img = value.attributes.ExerciseMobileImageUrl || '';
          item.isInternalImg = false;
          item.title = `${this.getFormattedTaskState(value)} <a href="#!showTask=${value.sourceId}&peId=${value.operationSourceId}">${value.attributes.ExerciseTitle}</a>`;
          item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
          if (value.attributes.StateOperation === 'Completed') {
            item.coins = `+${Math.round(value.attributes.Reward)}`;
          }
          item.points = 0;
          return item;
        }
        item.img = value.attributes.ExerciseMobileImageUrl || '';
        item.isInternalImg = false;
        item.isTaskRejected = true;
        item.title = `Отменено задание <a href="#!showTask=${value.sourceId}">${value.attributes.ExerciseTitle}</a>`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        return item;
      }
      if (value.operationType === 'SportActivity') {
        item.img = ActivityCover3;
        item.isInternalImg = true;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        item.title = `<a href="/my-settings/trainings">${this.getFormattedDistance(value)}</a>`;
        return item;
      }
      if (value.operationType === 'Achievement') {
        const achievement = this.getAchievement(value);
        item.img = achievement.img;
        item.isInternalImg = true;
        item.achievementType = value.attributes.AchievementType;
        item.title = `Вы разблокировали достижение <b>${achievement.name}</b>`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        return item;
      }
      if (value.operationType === 'ParticipantExperienceLevel') {
        item.img = ActivityCoverXP;
        item.isInternalImg = true;
        item.title = `Вы достигли <b>${value.attributes.Level} ур.</b>`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        return item;
      }
      if (value.operationType === 'ParticipantSteps') {
        item.isInternalImg = false;
        item.img = '';
        item.isTaskSteps = true;
        item.StepsCount = Math.round(value.attributes.StepsCount);
        item.title = `Вы прошли ${this.pluralizeSteps(item.StepsCount)}`;
        item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
        item.coins = `+${Math.round(value.attributes.AccruedPoints)}`;
        return item;
      }
      item.img = '';
      item.isInternalImg = false;
      item.achievementType = '';
      item.title = '';
      item.time = dayjs.utc(value.operationDate).local().format('HH:mm');
      return item;
    },
    getFormattedDistance(value) {
      if (!value || !value.attributes) return '';
      const distance = parseInt(value.attributes.Distance, 10);
      const unit = distance >= 1000 ? 'км' : 'м';
      const formatted = distance >= 1000 ? Math.round(distance / 1000) : distance;
      if (value.attributes.Discipline === 'Walking') return `${formatted} ${unit} ходьба`;
      if (value.attributes.Discipline === 'Running') return `${formatted} ${unit} бег`;
      if (value.attributes.Discipline === 'Cycling') return `${formatted} ${unit} велозаезд`;
      if (value.attributes.Discipline === 'Swimming') return `${formatted} ${unit} плавание`;
      if (value.attributes.Discipline === 'Skiing') return `${formatted} ${unit} лыжи`;
      return '';
    },
    getFormattedTaskState(value) {
      if (!value || !value.attributes) return '';
      if (value.attributes.StateOperation === 'Created') return 'Вы начали задание';
      if (value.attributes.StateOperation === 'Completed') return 'Вы успешно выполнили задание';
      return '';
    },
    getAchievement(value) {
      if (!value || !value.attributes) return '';
      const found = this.achievementsAll.filter((i) => i.achievementType === value.attributes.AchievementType);
      if (found.length === 0) return { name: '', img: '' };
      return found[0];
    },
    getTaskDetailsState(task) {
      if (task.participantExercise.state === 'Created') return 'inProgress';
      if (task.participantExercise.state === 'Completed') return 'completed';
      if (task.participantExercise.state === 'Rejected') return 'failed';
      if (task.participantExercise.state === 'AwaitingConfirmation') return 'checking';
      return '';
    },
  },
  mounted() {
    this.getProfile();
    this.getBalance();
    const vm = this;
    this.getActivityOperations({ offset: 0, count: 25 })
      .then(() => {
        this.activities = this.getMapppedOperations(this.activityOperations.values);
        this.loading = false;
        setTimeout(() => { vm.setupLoader(); }, 250);
      });

    window.addEventListener('hashchange', this.onHashChange);
    this.onHashChange();
    this.loading = false;
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import "./Activities";
</style>
